import styled, { css } from 'styled-components';
import { Label } from 'components/Label';
import { fontSans, textBase, textSmall } from '../../design-system/style-mixins/text';
import {
	error,
	disabled,
	gray,
	grayMedium,
	textColorDark,
	textColorDisabled,
	textColorMedium,
	white,
} from '../../design-system/variables/colors';
import { spaceSmall, spaceTiny } from '../../design-system/variables/spaces';
import { visuallyHidden } from '../../design-system/utils';

export const InputStyles = css<{ hasError }>`
	&& {
		${fontSans}
		${textBase}
		${textColorDark}
		background-color: ${white};
		border: 1px solid ${props => (props.hasError ? error : gray)};
		border-radius: 0;
		box-sizing: border-box;
		display: flex;
		flex-wrap: wrap;
		height: initial;
		margin-bottom: ${spaceSmall};
		min-height: 40px;
		outline: none;
		padding: 0 ${spaceSmall};
		position: relative;
		text-align: left;
		width: 100%;

		&:focus {
			border-color: ${grayMedium};
			outline: none;
		}

		&:disabled {
			background-color: ${disabled};
			border-color: ${disabled};
			${textColorDisabled}
			cursor: default;
			outline: none;
			user-select: none;

			&::placeholder {
				${textColorDisabled}
			}

			&::-webkit-input-placeholder {
				${textColorDisabled}
			}

			&::-moz-placeholder {
				${textColorDisabled}
			}

			&:-ms-input-placeholder {
				&& {
					${textColorDisabled}
				}
			}

			&:-moz-placeholder {
				${textColorDisabled}
			}
		}
	}
`;

export const InputLabel = styled(Label)<{ hideLabel }>`
	${textColorMedium}
	display: inline-block;
	margin-bottom: ${spaceSmall};
	position: relative;
	text-align: left;
	white-space: nowrap;

	${props => props.hideLabel && visuallyHidden};
`;

export const RequiredContainer = styled.span<{ required }>`
	position: absolute;
	top: -5px;

	svg {
		fill: ${error};
	}
`;

export const HelpText = styled.div<{ hasError }>`
	${textSmall}
	color: ${props => (props.hasError ? error : grayMedium)};
	line-height: 15px;
	margin-bottom: 0.75rem; /* 1 */
	margin-top: -${spaceTiny}; /* 2 */
	text-align: left;

	${props => props.theme.helpText}
`;
