import styled from 'styled-components';
import { grayMedium, textColorDark, textColorLight } from 'design-system/variables/colors';
import { bold, textLarge, textTiny } from 'design-system/style-mixins/text';
import { truncateText } from 'design-system/utils';
import { spaceMedium, spaceSmall, spaceTiny, spaceBetween, spaceBase } from 'design-system/variables/spaces';
import { lg } from 'design-system/media-queries';
import { Icon } from '..';

export const TextColorLight = styled.p`
	display: flex;
	align-items: center;
	${textColorLight}
	margin-block-start:${spaceSmall};
	margin-block-end: ${spaceSmall};
	margin-left:-${spaceTiny};

	${lg`
	margin-left:-${spaceSmall};
	`}
	@-moz-document url-prefix() {
		margin-left:-${spaceBetween(spaceSmall, spaceBase) };
	}
`;
export const GrayIcon = styled(Icon)`
	height: ${spaceMedium};
	width: ${spaceMedium};
	position: relative;
	margin-right: ${spaceTiny};
	fill: ${grayMedium};
`;

export const Header = styled.p`
	${textColorDark}
	${bold}
    ${truncateText}
    ${textLarge}
    margin-block-end: 0px;
	a:hover,
	a:focus {
		text-decoration: none;
	}
`;
export const HeaderLight = styled.span`
	${textColorLight}
`;
export const Shrink = styled.span`
	${textTiny}
	${textColorLight}
    position:relative;
	top: -${spaceBetween(spaceTiny, spaceBase)};
`;
