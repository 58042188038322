import React, { FC } from 'react';
import { UserValues } from 'pages/Chat/Chat.Interfaces';
import { Input, TextArea } from 'components/';
import { FormikProps } from 'formik';
import { Form, Instructions } from '../OfflineForm.styles';
import { SubmitButton } from '../../modal/ChatModal.styles';

export const PubUserForm: FC<FormikProps<UserValues>> = ({
	errors,
	values,
	touched,
	handleChange,
	handleBlur,
	handleSubmit,
	setFieldValue,
	setFieldTouched,
	isSubmitting,
}) => {
	const handleChangeTextArea = name => value => {
		setFieldValue(name, value);
	};
	const handleBlurTextArea = name => () => {
		setFieldTouched(name);
	};
	return (
		<Form onSubmit={handleSubmit}>
			<Instructions size='medium'>We're here to help. Let's get started.</Instructions>
			<Input
				labelText='Name'
				name='name'
				tooltip='Enter your name'
				type='text'
				max='80'
				value={values.name}
				onChange={handleChange}
				onBlur={handleBlur}
				hasError={errors.name && touched.name}
				helpText={errors.name && touched.name && errors.name}
			/>
			<Input
				labelText='Email address'
				name='email'
				tooltip='Enter your email address'
				type='text'
				max='80'
				value={values.email}
				onChange={handleChange}
				onBlur={handleBlur}
				hasError={errors.email && touched.email}
				helpText={errors.email && touched.email && errors.email}
			/>
			<TextArea
				labelText='How can we help you?'
				name='description'
				onChange={handleChangeTextArea('description')}
				onBlur={handleBlurTextArea('description')}
				rows='3'
				hasError={errors.description && touched.description}
				helpText={errors.description && touched.description && errors.description}
			/>
			<SubmitButton buttonType='primary' type='submit' disabled={isSubmitting}>
				Continue
			</SubmitButton>
		</Form>
	);
};
