import React, { FC, useState, useEffect, useContext } from 'react';

import { EnhancedInput } from '../Input';
import { Constants } from './enums';
import { FormContext } from '../FormContext';

export const LastName: FC<{}> = () => {
	const { validationRules, setFieldValue, t } = useContext(FormContext);
	const [value, setValue] = useState('');

	useEffect(() => {
		setFieldValue({ [Constants.name]: value });
	}, [value]);

	return (
		<EnhancedInput
			type='text'
			id={Constants.id}
			name={Constants.name}
			label={t('lastName')}
			value={value}
			validationRules={validationRules.LAST_NAME}
			setValue={setValue}
		/>
	);
};
