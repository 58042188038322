import React, { useState } from 'react';
import { StyledImageCarouselItem, ImageCarouselItemFigure, ImageCarouselItemCaption } from './ImageCarouselItem.styles';
import { ImageCarouselItemProps } from './ImageCarouselItem.types';

export const ImageCarouselItem: React.FC<ImageCarouselItemProps> = ({ item, index, caption, selectedIndex, theme }) => {
	const [isLoaded, setIsLoaded] = useState(false);

	return (
		<StyledImageCarouselItem as='li' loaded={isLoaded} onLoad={() => setIsLoaded(true)}>
			{caption ? (
				<ImageCarouselItemFigure>
					{item}
					<ImageCarouselItemCaption showCaption={index === selectedIndex} theme={theme}>
						{caption}
					</ImageCarouselItemCaption>
				</ImageCarouselItemFigure>
			) : (
				item
			)}
		</StyledImageCarouselItem>
	);
};
