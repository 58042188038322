import React from 'react';

import { MemberActivityErrorProps } from './MemberActivityErrorProps';

import { Link } from '../../Link';

export const MemberActivityError: React.FunctionComponent<MemberActivityErrorProps> = ({ linkTitle, url }) => (
	<p>
		There was an error loading your account information. Try again later.
		<Link color="primary" href={url}>
			{linkTitle}
		</Link>
	</p>
);
