import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { getErrorMessages } from '../../../../shared/utils';
import { PROPOSAL_NAME, PROPOSAL_NAME_UPDATED } from '../constants';
import { setFieldError } from './errors';
import { updateTripProposal } from '../../../../services/tripRecommendationsApiService';
import { UpdateTripProposalRequest } from '../../../../interfaces';
import { State } from '../context';

export const updateProposalName = (name: string, modifiedBy: string) => (
	dispatch: ThunkDispatch<State, undefined, Action>,
	getState: () => State,
) => {
	const { selectedRecipient, metadata, message } = getState();
	const request: UpdateTripProposalRequest = {
		name,
		message,
		recipientId: selectedRecipient ? selectedRecipient.accountUserID : null,
		ownerId: metadata.ownerId,
		pvaTeamId: metadata.pvaTeamId,
		modifiedBy,
	};

	updateTripProposal(metadata.id, request)
		.then(() => {
			dispatch({ type: PROPOSAL_NAME_UPDATED, payload: { name } });
		})
		.catch(error => {
			const errorMessage = getErrorMessages(error, 'Name');
			dispatch(setFieldError(PROPOSAL_NAME, errorMessage));
		});
};
