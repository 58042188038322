import { FC, useContext, ReactNode, cloneElement } from 'react';

import { Context } from '../Context';

interface Props {
	children: ReactNode;
	handleKeyDown: Function;
}

export const AccessibleInput: FC<Props> = ({ children, handleKeyDown }) => {
	const { isMenuOpen, activeId, setActiveId, menuId, isMulti } = useContext(Context);
	const attributes = {
		role: isMulti ? 'combobox' : 'textbox',
		'aria-haspopup': true,
		'aria-expanded': isMenuOpen,
		'aria-owns': isMenuOpen ? menuId : '',
		'aria-activedescendant': activeId,
		onKeyDown: e => handleKeyDown({ e, activeId, setActiveId }),
	};

	return cloneElement(children, { ...attributes });
};
