import styled from 'styled-components';
import { textColorDark, blue } from '../../design-system/variables/colors';
import { spaceTiny } from '../../design-system/variables/spaces';
import { Subtext } from '../Subtext';

export const Badge = styled(props => <Subtext {...props} />)`
	${textColorDark}

	background-color: ${blue};
	border-radius: 10px;
	display: inline;
	padding-left: ${spaceTiny};
	padding-right: ${spaceTiny};
`;
