import styled from 'styled-components';
import { fontSans, textSmall } from '../../design-system/style-mixins/text';
import { textColorMedium } from '../../design-system/variables/colors';

/**
 * Subtext:
 * Use in cards, information underneath card titles.
 * Examples: Locations, Property, Proximity, etc.
 */
export const Subtext = styled.span`
    ${fontSans}
    ${textSmall}
    ${textColorMedium}
    display: block;
	font-weight: normal;
	letter-spacing: initial;
	line-height: 15px;
`;
