import React from 'react';
import { VideoPlayer } from 'components/VideoPlayer';
import styled from 'styled-components';
import { Blockquote } from 'components/Blockquote';
import { AllCaps } from 'components/AllCaps';
import { getSafeHtml } from 'services/Helpers';
import { ComponentProps } from '../../interfaces/ComponentProps';
import { white, textColorMedium, textColorLight } from '../../design-system/variables/colors';
import { spaceBase, spaceLarge, spaceMedium, spaceTiny } from '../../design-system/variables/spaces';
import { md, xsOnly, xsSmOnly } from '../../design-system/media-queries';
import { shadowHover } from '../../design-system/style-mixins/animations';

export interface TestimonialCardProps extends ComponentProps {
	attribution: string;
	imageUrl: string;
	location: string;
	quote: string;
	vidyardEmbedCode: string;
}

const Container = styled.div`
	margin-left: auto;
	margin-right: auto;
	overflow: hidden;
	padding-left: 0;
	padding-right: 0;
	background-color: ${white};
	${textColorMedium}
	padding-bottom: ${spaceLarge};
	${shadowHover}

	${xsOnly`
		max-width: 320px;
	`};
	${xsSmOnly`
		margin-bottom: ${spaceBase};
	`};
`;

const TestimonialCardImage = styled.div`
	background-position: center;
	background-size: cover;
	padding-bottom: 56.25%;
`;

const TestimonialCardQuote = styled(props => <Blockquote {...props} />)`
	padding: ${spaceMedium};
	${textColorMedium}

	${md`
		padding: ${spaceLarge} ${spaceLarge} ${spaceMedium} ${spaceLarge};
	`};

	p {
		margin: 0;
		padding: 0;
	}
`;

const TestimonialCardAttribution = styled(props => <AllCaps {...props} />)`
	margin-bottom: ${spaceTiny};
	display: block;
`;

const TestimonialCardLocation = styled(props => <AllCaps {...props} />)`
	${textColorLight}
	font-weight: normal;
	display: block;
`;

export const TestimonialCard: React.FC<TestimonialCardProps> = ({
	attribution,
	location,
	quote,
	vidyardEmbedCode,
	imageUrl,
}) => {
	function hasVideo(): boolean {
		return vidyardEmbedCode !== null;
	}
	function hasImage(): boolean {
		return !hasVideo() && imageUrl !== null;
	}

	let playerType = null;
	if (hasVideo()) {
		playerType = 'lightbox';
	}

	const backgroundImage = {};
	if (hasImage()) {
		backgroundImage['backgroundImage'] = `url(${imageUrl});`;
	}

	return (
		<Container className='center-xs'>
			<VideoPlayer vidyardEmbedCode={vidyardEmbedCode} playerType={playerType} componentName='VideoPlayer' />
			{hasImage() && <TestimonialCardImage style={backgroundImage} />}
			<TestimonialCardQuote dangerouslySetInnerHTML={getSafeHtml(quote)} />
			<TestimonialCardAttribution size='small'>{attribution}</TestimonialCardAttribution>
			<TestimonialCardLocation size='small'>{location}</TestimonialCardLocation>
		</Container>
	);
};
