import React from 'react';
import { DropdownButtonProps } from './DropdownButton.types';
import { MainButton, DropdownLabel, DropdownArrow, Loader } from './DropdownButton.styles';

export const DropdownButton = React.forwardRef(
	({ children, isDisabled, isActive, isLoading, onClick, hideArrow }: DropdownButtonProps, ref) => {
		return (
			<MainButton ref={ref} isActive={isActive} isLoading={isLoading} disabled={isDisabled} onClick={onClick}>
				{isLoading && <Loader />}
				<DropdownLabel>{children}</DropdownLabel>
				{!hideArrow && <DropdownArrow iconName='arrow' iconSize='md' isActive={isActive} />}
			</MainButton>
		);
	},
);
