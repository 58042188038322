import React, { Fragment } from 'react';
import styled, { css } from 'styled-components';

import { ArrowsProps } from './ArrowsProps';

import { IconButton } from '../../../Icon';

const ButtonLeft = styled(props => <IconButton {...props} />)<{ isShown: boolean | (() => void) }>`
	height: 42px;
	width: 42px;
	left: -55px;
	right: auto;
	position: absolute;
	top: calc(50% - 21px);
	z-index: 10;
	transform: rotate(180deg);
	transition: left 335ms cubic-bezier(0.42, 0, 0.58, 1);
	-webkit-transition: left 335ms cubic-bezier(0.42, 0, 0.58, 1);

	${props =>
		props.isShown &&
		css`
			left: 0px;
		`}
`;

const ButtonRight = styled(props => <ButtonLeft {...props} />)<{ isShown: boolean | (() => void) }>`
	left: auto;
	right: -55px;
	transform: rotate(0deg);
	-webkit-transition: right 335ms cubic-bezier(0.42, 0, 0.58, 1);
	transition: right 335ms cubic-bezier(0.42, 0, 0.58, 1);

	${props =>
		props.isShown &&
		css`
			right: 0px;
		`}
`;

export const Arrows: React.FunctionComponent<ArrowsProps> = ({ isShown, navigateForward, navigateBack }) => {
	return (
		<Fragment>
			<ButtonLeft
				buttonType='squarePrimary'
				iconName='arrow'
				iconSize='xlg'
				isShown={isShown}
				onClick={navigateBack}
			/>
			<ButtonRight
				buttonType='squarePrimary'
				iconName='arrow'
				iconSize='xlg'
				isShown={isShown}
				onClick={navigateForward}
			/>
		</Fragment>
	);
};
