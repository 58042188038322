import React, { FC, Fragment } from 'react';
import { LoaderSkeleton } from 'components/Loader/LoaderSkeleton';
import { SpecsProps } from './SpecsProps';
import { SpecsContainer, ItemContainer, Spec, SpecLabel } from './SpecsStyles';

/**
 * Specs:
 * Use in cards, unit specs
 * Example: 3 bd 3.5 ba 6 occ 2283 ft^2
 */

export const Specs: FC<SpecsProps> = ({ bedrooms, bathrooms, occupancy, squareFeet, isPlaceholder = false }) => {
	return (
		<SpecsContainer>
			{isPlaceholder ? (
				<Fragment>
					<ItemContainer>
						<LoaderSkeleton width='3.5em' />
					</ItemContainer>
					<ItemContainer>
						<LoaderSkeleton width='3.5em' />
					</ItemContainer>
					<ItemContainer>
						<LoaderSkeleton width='3.5em' />
					</ItemContainer>
					<ItemContainer>
						<LoaderSkeleton width='3.5em' />
					</ItemContainer>
				</Fragment>
			) : (
				<Fragment>
					<Spec>
						{bedrooms}
						<SpecLabel>bd</SpecLabel>
					</Spec>
					<Spec>
						{bathrooms}
						<SpecLabel>ba</SpecLabel>
					</Spec>
					<Spec>
						{occupancy}
						<SpecLabel>occ</SpecLabel>
					</Spec>
					<Spec>
						{squareFeet}
						<SpecLabel>
							ft<sup>2</sup>
						</SpecLabel>
					</Spec>
				</Fragment>
			)}
		</SpecsContainer>
	);
};
