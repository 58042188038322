import { SectionComponentsPerRow, VerticalAlignment } from './SectionComponentProps';

export const getColumnCssClasses = (componentsPerRow: any) => {
	switch (componentsPerRow) {
		case SectionComponentsPerRow.mobile1Tablet2Desktop2:
			return 'col-xs-12 col-sm-6';
		case SectionComponentsPerRow.mobile1Tablet1Desktop3:
			return 'col-xs-12 col-md-4';
		case SectionComponentsPerRow.mobile1Tablet3Desktop3:
			return 'col-xs-12 col-sm-4 col-md-4';
		case SectionComponentsPerRow.mobile1Tablet2Desktop4:
			return 'col-xs-12 col-sm-6 col-md-3 col-lg-3';
		case SectionComponentsPerRow.fullScreen:
			return '';
		default:
			return 'col-xs-12';
	}
};

export const determineLayoutStyle = ({ nestedComponents, verticalAlignment }): string => {
	let classNames = '';
	const experienceOrExpandableCardNames = ['experiences-card', 'expandable-card'];
	const hasExperienceOrExpandableCards = Boolean(
		nestedComponents.find(x => experienceOrExpandableCardNames.includes(x.componentName)),
	);

	if (!hasExperienceOrExpandableCards) {
		classNames += ' center-xs';
	}

	if (verticalAlignment) {
		switch (verticalAlignment) {
			case VerticalAlignment.middle:
				classNames += ' middle-xs';
				break;
			case VerticalAlignment.bottom:
				classNames += ' bottom-xs';
				break;
			default:
				classNames += ' top-xs';
				break;
		}
	}

	return classNames;
};

// Returns array with 2 items:
// 1) first - current column
// 2) second - total columns
export const getComponentsPerRowSchema = (componentsPerRow: string, $index: number): number[] => {
	if (!componentsPerRow) {
		return [null, null];
	}
	const lastCharIndex = componentsPerRow.length - 1;
	const totalColumns = parseInt(componentsPerRow.charAt(lastCharIndex), 10);

	const currentColumn = ($index + 1) % totalColumns === 0 ? totalColumns : ($index + 1) % totalColumns;

	return [currentColumn, totalColumns];
};

export const getComponentsPerRowCssClass = (componentsPerRow: string, $index: number): string => {
	if (!componentsPerRow) {
		return '';
	}
	const schema = getComponentsPerRowSchema(componentsPerRow, $index);
	return `section-component__components-per-row--${schema[0]}-of-${schema[1]}`;
};
