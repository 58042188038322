import { equals } from 'ramda';
import React, { FC, useEffect } from 'react';

import styled from 'styled-components';
import { xsSmOnly } from 'design-system/media-queries';
import { useSelector } from 'react-redux';
import { spaceLarge } from 'design-system/variables/spaces';
import { ExperiencesCard } from 'components/Cards/ExperiencesCard';
import { getOffset, getSearchResults } from '../redux/selectors';
import { PAGE_SIZE } from '../redux/constants';

interface Props {
	onResultsChanged: () => void;
}

const CardWrapper = styled.div`
	position: relative;

	${xsSmOnly`
		width: 100%;
	`}
`;

const ResultsContainer = styled.div`
	margin-bottom: ${spaceLarge};
`;
let lastExperiences = null;

export const Results: FC<Props> = ({ onResultsChanged }) => {
	const experiences = useSelector(state => getSearchResults(state));
	const offset = useSelector(state => getOffset(state));

	useEffect(() => {
		if (!equals(experiences, lastExperiences)) {
			onResultsChanged();
		}
		lastExperiences = experiences;
	}, [experiences]);

	return (
		<ResultsContainer className='row'>
			{experiences.slice(offset, offset + PAGE_SIZE).map((card, idx) => (
				<CardWrapper className='col-xs-12 col-sm-6' key={idx}>
					<ExperiencesCard {...card} />
				</CardWrapper>
			))}
		</ResultsContainer>
	);
};
