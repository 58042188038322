import React, { FC, useRef, useState } from 'react';
import { useEffect } from 'react';
import {
	ChatButtonStyled,
	ChatCloseButton,
	ChatContent,
	ChatImage,
	ChatText,
	ChatWrapper,
	PopUp,
} from './ChatButton.styles';

export interface ChatButtonProps {
	isOnline: boolean;
	onClick: () => void;
	showChatButton: boolean;
	isPublic: boolean;
	tabIndex?: string | number;
}

const ChatButton: FC<ChatButtonProps> = ({ isOnline, onClick, showChatButton, isPublic }) => {
	const [isClosed, setIsClosed] = useState(false);
	const popupRef = useRef(null);

	useEffect(() => {
		if (popupRef.current && isOnline && isPublic) {
			setTimeout(() => {
				popupRef.current.classList.add('is-visible');
			}, 0);
		}
	}, [isOnline, isPublic]);

	useEffect(() => {
		if (isClosed && popupRef.current) {
			popupRef.current.classList.remove('is-visible');
			setTimeout(() => {
				popupRef.current.classList.add('hide');
			}, 0.7 * 1000);
		}
	}, [isClosed]);

	return isOnline && isPublic ? (
		<PopUp
			id='chat-popup'
			className='chat'
			onClick={onClick}
			showChatButton={showChatButton}
			role='button'
			ref={popupRef}
			tabIndex={0}
			aria-label='Begin Chat with Support'
		>
			<ChatWrapper>
				<ChatContent>
					<ChatCloseButton
						buttonType='containerless'
						iconName='close'
						iconSize='md'
						aria-label='Dismiss this chat popup'
						onClick={(event) => {
							event.stopPropagation();
							setIsClosed(!isClosed);
						}}
					/>
					<ChatText>
						<strong>Questions?</strong> We're here to help.
					</ChatText>
				</ChatContent>
			</ChatWrapper>
			<ChatImage />
		</PopUp>
	) : (
		<ChatButtonStyled
			buttonType='primary'
			iconName='chat'
			iconSize='lg'
			buttonSize='large'
			onClick={onClick}
			showChatButton={showChatButton}
		/>
	);
};

export default ChatButton;
