import { RegExps, PasswordRegExps } from 'enums/RegExps';
import * as i18next from 'i18next';

export type ValidationFunctionType = (value: string | number) => boolean;
export type ValidationRuleType = [ValidationFunctionType, string];
export type ValidationRulesType = ValidationRuleType[];
export type ValidationResultType = [boolean, string];
export type ValidateValueByRulesType = (
	value: string | number,
	validationRules: ValidationRulesType,
) => ValidationResultType;

const required = (value: string | number): boolean => {
	return value && value.toString().length > 0;
};

const pattern = (patternString: string) => (value: string | number): boolean => {
	let result = true;
	if (!patternString || patternString.length === 0 || !value) {
		return result;
	}
	const regexp = new RegExp(patternString);
	result = regexp.test(value.toString());
	return result;
};

export class ValidationRules {
	public constructor(private t: i18next.TFunction = e => e) {}

	public EMAIL: ValidationRulesType = [
		[required, this.t('errorMessages.required')],
		[pattern(RegExps.EMAIL), this.t('errorMessages.email')],
	];

	public PASSWORD: ValidationRulesType = [
		[required, this.t('errorMessages.required')],
		[pattern(PasswordRegExps.MIN_LENGTH), this.t('errorMessages.minPasswordLength')],
		[pattern(PasswordRegExps.SPECIAL_CHARACTERS), this.t('errorMessages.specialCharacters')],
		[pattern(PasswordRegExps.LOWERCASE_CHARACTER), this.t('errorMessages.lowercaseCharacter')],
		[pattern(PasswordRegExps.UPPERCASE_CHARACTER), this.t('errorMessages.uppercaseCharacter')],
		[pattern(PasswordRegExps.DIGIT), this.t('errorMessages.digit')],
	];

	public FIRST_NAME: ValidationRulesType = [
		[required, this.t('errorMessages.required')],
		[pattern(RegExps.MIN_LENGTH_2), this.t('errorMessages.minLength2')],
	];

	public LAST_NAME: ValidationRulesType = [
		[required, this.t('errorMessages.required')],
		[pattern(RegExps.MIN_LENGTH_2), this.t('errorMessages.minLength2')],
	];

	public PHONE: ValidationRulesType = [
		[required, this.t('errorMessages.required')],
		[pattern(RegExps.PHONE), this.t('errorMessages.phone')],
	];

	public POSTAL_CODE: ValidationRulesType = [
		[required, this.t('errorMessages.required')],
		[pattern(RegExps.ZIP), this.t('errorMessages.validZip')],
	];

	public REFERRAL_FIRST_NAME: ValidationRulesType = [
		[required, this.t('errorMessages.required')],
		[pattern(RegExps.MIN_LENGTH_2), this.t('errorMessages.minLength2')],
	];

	public REFERRAL_LAST_NAME: ValidationRulesType = [
		[required, this.t('errorMessages.required')],
		[pattern(RegExps.MIN_LENGTH_2), this.t('errorMessages.minLength2')],
	];
}

export const validateValueByRules: ValidateValueByRulesType = (value, validationRules) => {
	let message = '';
	if (!validationRules || validationRules.length === 0) {
		return [true, message];
	}
	for (let i = 0; i < validationRules.length; i++) {
		const [validator, invalidMessage] = validationRules[i];
		if (!validator(value)) {
			message = invalidMessage;
			break;
		}
	}
	return [!message || message.length === 0, message];
};
