import React, { FC, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
	updateExperiencesFilter,
	getResults,
	clearExperiencesFilter,
} from 'components/ExperienceCardListing/redux/actions';
import root from 'window-or-global';
import { useIsDesktop } from 'components/hooks';
import { CheckboxGroup } from 'components/Checkbox';
import { FilterButtonActions } from 'components/FilterButtonActions';
import { FilterAccordion } from 'components/Accordion';
import { Dropdown } from 'components/Dropdown';
import { getExperienceTypesFilter } from '../../redux/selectors';
import { useCheckedCount } from '../utils';
import { FilterContainer, FilterLabel } from '../Filters.styles';

export const ExperienceTypeFilter: FC = () => {
	const dispatch = useDispatch();
	const experienceTypes = useSelector(state => getExperienceTypesFilter(state));
	const [selectedCount] = useCheckedCount(experienceTypes);
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [isDesktop] = useIsDesktop(768);

	const toggleOpen = () => setIsOpen(!isOpen);

	const handleExperienceClick = ({ id }) => {
		dispatch(updateExperiencesFilter(id));
		if (!isDesktop) {
			dispatch(getResults());
		}
	};

	const handleExperienceClear = () => {
		dispatch(clearExperiencesFilter());
		dispatch(getResults());
	};

	const handleExperienceApply = () => {
		dispatch(getResults());
		toggleOpen();
	};

	/*
	 TODO: Using this to switch out the filter title if user is on the /pass/cruises page.
	 We'll need to remove once the data is delivered properly. +hack
	*/
	const filterTitle = root.location && root.location.pathname.includes('cruise') ? 'Brand' : 'Type';

	const alphabetizedExperienceTypes = experienceTypes.sort((a, b) => {
		if (a.label.toUpperCase() > b.label.toUpperCase()) {
			return 1;
		}

		if (a.label.toUpperCase() < b.label.toUpperCase()) {
			return -1;
		}

		return 0;
	});

	const Filter = (
		<FilterContainer>
			{isDesktop && <FilterLabel>{filterTitle}</FilterLabel>}
			<CheckboxGroup onCheckboxClick={handleExperienceClick} members={alphabetizedExperienceTypes} />
		</FilterContainer>
	);

	const Footer = (
		<FilterButtonActions
			onClearClick={handleExperienceClear}
			onApplyClick={handleExperienceApply}
			isDisabled={selectedCount === 0}
		/>
	);

	if (!isDesktop) {
		return (
			<FilterAccordion title={filterTitle} accordionId='EXPERIENCE_TYPE'>
				{Filter}
			</FilterAccordion>
		);
	}

	return (
		<Dropdown
			title={`${filterTitle} ${selectedCount > 0 ? `(${selectedCount})` : ''}`}
			footer={Footer}
			onOpen={() => {}}
			controlledOpen={{ open: isOpen, action: toggleOpen }}
			onClose={() => dispatch(getResults())}
		>
			{Filter}
		</Dropdown>
	);
};
