import axios from 'axios';
import { AccountUserSearchInfo, AccountUserContactName, UnitDetails, DateRange } from '../interfaces';
import { getConfig } from '../environmentConfig';

let cancelToken = null;

const getDryflyApiUrl = () => {
	const configuration = getConfig();
	return configuration.dryflyApiUrl;
}

const handleError = error => {
	if (axios.isCancel(error)) {
		return;
	}

	const errorMessage = error.errorMessage || error.response.data.message;
	throw new Error(errorMessage);
};

export const getUnitCardText = async (unitIds: string[]): Promise<Record<string, UnitDetails>> => {
	if (cancelToken) {
		cancelToken();
	}

	try {
		const response = await axios.get(`${getDryflyApiUrl()}/Unit/CardText`, {
			params: {
				unitIds,
			},
			cancelToken: new axios.CancelToken(token => {
				cancelToken = token;
			}),
		});
		return response && response.data;
	} catch (error) {
		handleError(error);
		return null;
	}
};

export const getNamesByQuery = async (query: string) => {
	if (cancelToken) {
		cancelToken();
	}
	try {
		const response = await axios.get(`${getDryflyApiUrl()}/Account/Filter`, { params: { query } });
		return response && response.data;
	} catch (error) {
		handleError(error);
		return null;
	}
};

export const getNames = async (accountUserIds: number[]): Promise<AccountUserContactName[]> => {
	try {
		const response = await axios.get(`${getDryflyApiUrl()}/Account/Names`, {
			params: {
				accountUserIds: accountUserIds.join(','),
			},
		});
		return response && response.data;
	} catch (error) {
		handleError(error);
		return null;
	}
};

export const getAccountUserSearchInfo = async (accountUserId: number): Promise<{ data: AccountUserSearchInfo }> => {
	try {
		const response = await axios.get(`${getDryflyApiUrl()}/User/${accountUserId}/Search`);
		return response && response.data;
	} catch (error) {
		handleError(error);
		return null;
	}
};

export const checkUnitPricingAvailability = async (unitId: number, dateRanges: DateRange[]) => {
	try {
		const response = await axios.post(`${getDryflyApiUrl()}/pricing/availability/${unitId}`, dateRanges, {
			cancelToken: new axios.CancelToken(token => {
				cancelToken = token;
			}),
		});
		return response && response.data;
	} catch (error) {
		handleError(error);
		return null;
	}
};

export const getHeroImage = async (id: number) => {
	return axios.get(`${getDryflyApiUrl()}/heroimage/unit/${id}`).then((res) => res.data)
		.catch((error) => {
			if (axios.isCancel(error)) {
				return;
			}
		});
};
