import React from 'react';
import styled, { css } from 'styled-components';

import { ResidenceCardProps } from './IRecommendedForYouCardProps';

import { spaceBase, spaceSmall, spaceLarge } from '../../../../design-system/variables/spaces';
import { shadowHover } from '../../../../design-system/style-mixins/animations';
import { textSmall } from '../../../../design-system/style-mixins/text';
import { xsOnly } from '../../../../design-system/media-queries';
import { white, textColorMedium } from '../../../../design-system/variables/colors';

import { ActionLink } from '../../../ActionLink';
import { Link } from '../../../Link';

const CardWrapper = styled.section`
	${shadowHover}
	margin: ${spaceBase};
	height: 410px;
	width: 600px;
	cursor: pointer;
	flex: 0 0 auto;
	overflow: hidden;
	transform: translate3d(0px, 0px, 0px);

	${xsOnly`
		margin-bottom: ${spaceLarge};
		flex: 0 0 auto;
		height: 240px;
		width: 300px;
	`}
`;

const BackgroundImage = styled.div<{ src: string }>`
	background: transparent;
	background-position: center;
	background-size: cover;
	height: 340px;
	${props => css`
		background-image: url(${props.src});
	`}

	${xsOnly`
		background-position: center;
		background-size: cover;
		height: 170px;
	`}
`;

const ContentWrapper = styled.div`
	background-color: ${white};
	margin: 0;
	padding-bottom: 12px;
	padding-top: 12px;
`;

const Content = styled.div`
	align-items: center;
	display: flex;
	justify-content: space-between;
	margin-left: ${spaceSmall};
	margin-right: ${spaceSmall};
`;

const DestinationInfo = styled.div`
	margin-left: ${spaceSmall};
	text-align: left;
`;

const DestinationName = styled.div`
	${textSmall}
	${textColorMedium}
	max-width: 380px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	display: block;

	${xsOnly`
		max-width: 230px;
	`}
`;

const ResidenceName = styled(props => <Link {...props} />)`
	max-width: 380px;
	font-size: 24px;
	line-height: 27px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	display: block;

	&:hover {
		text-decoration: none;
	}

	${xsOnly`
		max-width: 230px;
	`}
`;

const ViewButton = styled(props => <ActionLink {...props} />)`
	margin-left: ${spaceSmall};
	margin-right: ${spaceSmall};

	${xsOnly`
		display: none;
	`}
`;

export const RecommendedForYouCard: React.FC<ResidenceCardProps> = ({
	destinationName,
	imageUrl,
	isFavorite,
	residenceName,
	residenceUrl,
	resortName,
}) => {
	return (
		<CardWrapper>
			<BackgroundImage src={imageUrl} />
			<ContentWrapper>
				<Content>
					<DestinationInfo>
						<ResidenceName color='black' href={residenceUrl}>
							{residenceName}
						</ResidenceName>
						<DestinationName>{destinationName}</DestinationName>
					</DestinationInfo>
					<ViewButton
						url={residenceUrl}
						canDisplayAsImageLink={false}
						canDisplayAsTextLink
						linkTitle='View'
						newWindow={false}
						secondary
						componentName='RecommendedForYouCard-view-btn'
					/>
				</Content>
			</ContentWrapper>
		</CardWrapper>
	);
};
