import {
	UPDATE_VACATION_STYLES,
	CLEAR_VACATION_STYLES,
	UPDATE_FILTER_DATA,
	UPDATE_V2_FILTER_STYLES,
} from '../../constants';

export const updateVacationStyles = (id: string) => (dispatch, getState) => {
	const { filterData, filters } = getState();

	const vacationStyles = filterData.vacationStyles.map(style =>
		style.label === id ? { ...style, checked: !style.checked } : style,
	);

	const paramIndex = filters.styles.indexOf(id);

	const styles =
		(paramIndex === -1)
			? [...filters.styles, id]
			: filters.styles.filter(e => e !== id);

	dispatch({ type: UPDATE_VACATION_STYLES, payload: { styles } });
	dispatch({ type: UPDATE_V2_FILTER_STYLES, payload: { styles } });
	dispatch({ type: UPDATE_FILTER_DATA, payload: { vacationStyles } });
};

export const clearVacationStyles = () => (dispatch, getState) => {
	const vacationStyles = getState().filterData.vacationStyles.map(style => ({ ...style, checked: false }));
	const unitSources = getState().filterData.unitSources.map(source => ({
		...source,
		checked: false,
	}));

	dispatch({ type: CLEAR_VACATION_STYLES });
	dispatch({ type: UPDATE_FILTER_DATA, payload: { vacationStyles, unitSources } });
};
