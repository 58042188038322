import React from 'react';
import { useTransition, animated } from 'react-spring';

import styled from 'styled-components';
import { ImageTitle } from '../ImageTitle';
import { ImagesProps } from './ImagesProps';

const Img = styled(animated.img)`
	height: 100%;
	width: 100%;
`;

// TODO: remove ts-ignore and update interface
// @ts-ignore
export const Images: React.FC<ImagesProps> = ({ images, activeIndex }) => {
	const transitions = useTransition(images[activeIndex], item => item.src, {
		enter: { opacity: 1 },
		config: { duration: 500 },
		leave: { opacity: 0.5 },
	});

	return transitions.map(({ item, props, key }) => {
		return (
			<React.Fragment>
				<Img
					key={key}
					style={{
						...props,
						willChange: 'opacity',
					}}
					src={item.src || item.fullPath}
				/>
				{/* TODO: figure out how to render title correctly */}
				{item.isTitleShown && <ImageTitle title={item.title} />}
			</React.Fragment>
		);
	});
};
