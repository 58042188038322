import React, { FC, useEffect, useState, memo, useCallback } from 'react';
import root from 'window-or-global';
import debounce from 'lodash/debounce';

import { fetchProductData } from './services';
import { findMatchingResults } from './utils';
import { QuickSearchContainerProps } from './QuickSearchProps';

import { QuickSearch } from './QuickSearch';

export const QuickSearchContainer: FC<QuickSearchContainerProps> = memo(({ baseUrl, onToggleVisibility }) => {
	const [data, setData] = useState([]);
	const [searchResults, setSearchResults] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [isDropdownShown, showDropdown] = useState(false);

	const filterSearchResults = useCallback(debounce(keyword => {
		showDropdown(true);
		if (!keyword) {
			setSearchResults(data);
			return;
		}

		const matchingSearchResults = findMatchingResults({ data, keyword });
		setSearchResults(matchingSearchResults);
	}, 50), [data]);

	const clearSearchResults = useCallback(() => {
		setSearchResults([]);
		showDropdown(false);
	}, []);

	const navigateToSelected = useCallback(selected => {
		root.location = selected.url;
	}, [root]);

	useEffect(() => {
		async function fetchData() {
			setIsLoading(true);
			const serverData = await fetchProductData(baseUrl);
			setIsLoading(false);
			setData(serverData)
		}
		fetchData();
	}, []);

	return (
		<QuickSearch
			searchResults={searchResults}
			onSearch={filterSearchResults}
			onSelectResult={navigateToSelected}
			onClearSearch={clearSearchResults}
			isLoading={isLoading}
			isResultsShown={isDropdownShown}
			onToggleVisibility={onToggleVisibility}
		/>
	);
});
