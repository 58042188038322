import styled, { css } from 'styled-components';
import { spaceSmall, spaceTiny, spaceBase } from 'design-system/variables/spaces';
import { black, gray, textColorMedium, textColorDark, textColorLight, itemHover } from 'design-system/variables/colors';
import { TextButton } from 'components/TextButton';
import { Subtext } from 'components/Subtext';
import { sm } from 'design-system/media-queries';
import { bold } from 'design-system/style-mixins/text';
import { IconButton } from '../Icon';

export const Nav = styled.nav`
	position: relative;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	${sm`
		flex-direction: row;
	`}
`;

export const CurrentRangeLabel = styled(Subtext)`
	margin-bottom: ${spaceBase};
	user-select: none;
	width: 100%;
	text-align: center;

	${sm`
		border-right: 1px solid ${gray};
		margin-bottom: 0;
		padding-right: ${spaceBase};
		width: auto;
	`}
`;

export const Items = styled.ul<{ simple?: boolean }>`
	display: flex;
	align-items: center;

	${props =>
		props.simple &&
		css`
			width: auto;
			justify-content: unset;
		`}
`;

export const Item = styled.li`
	display: flex;
	align-items: center;

	+ * {
		margin-left: 0.125rem;
	}
`;

export const Next = styled(props => <IconButton {...props} />)``;

export const Previous = styled(props => <IconButton {...props} />)`
	> svg {
		transform: rotate(180deg);
	}
`;

export const PageNumber = styled(props => <TextButton {...props} />)`
	${textColorLight}
	display: block;
	background-color: transparent;
	min-width: 30px;
	text-align: center;
	user-select: none;

	// Set stricter specificity to fix production style override bug
	&& {
		padding: ${spaceTiny} ${spaceSmall};
	}

	&:hover,
	&:active,
	&:focus {
		${textColorMedium}
	}

	${props =>
		props.isActive &&
		css`
			${textColorDark};
			${bold};
			background-color: ${itemHover};

			&:hover {
				${textColorDark};
			}
		`}
`;

export const PageJump = styled.span`
	color: ${gray};
	user-select: none;

	&:after {
		content: '•••';
	}
`;

export const SimplePageNumber = styled(PageNumber)`
	&:not(:disabled):hover {
		color: ${black};
		background-color: transparent;
		border-color: transparent;
	}
`;

export const SimpleWrapper = styled.li`
	flex: 0 0 auto;
	display: flex;
	align-items: center;
	justify-content: unset;

	input {
		margin-bottom: 0;
		min-height: initial;
		padding-top: ${spaceTiny};
		padding-bottom: ${spaceTiny};
		width: 3.525rem;
	}
`;

export const SimpleTextDivider = styled.span`
	margin-left: ${spaceSmall};
	user-select: none;
`;
