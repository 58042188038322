import React, { FC, useState, useEffect, useContext } from 'react';
import ReactDOMServer from 'react-dom/server';
import styled, { ThemeProvider, css } from 'styled-components';

import { textTiny } from 'design-system/style-mixins/text';
import { Link } from 'components/Link/Link';
import { Label } from 'components/Label/Label';
import { EnhancedInput } from '../Input';
import { PasswordRules } from './PasswordRules';
import { Constants } from './enums';
import { FormContext } from '../FormContext';
import { mint } from '../../../../design-system/variables/colors';

const Wrapper = styled.div`
	position: relative;
	margin-bottom: ${props => (props.theme.isHelpShown ? '11px' : '30px')};
`;

const ToggleInputType = styled.div`
	position: absolute;
	right: 0;
	top: 44px;
	${textTiny}
`;

const helpTextStyles = css`
	width: 70%;
	margin-bottom: ${props => (props.theme.isHelpShown ? '0' : '-12px')};
`;

const PasswordLabel = styled(props => <Label {...props} />)`
	color: ${mint};
`;

const StrongPassword: FC<{}> = () => <PasswordLabel size='small'>Strong Password.</PasswordLabel>;

export const Password: FC<{}> = () => {
	const { validationRules, setFieldValue, t } = useContext(FormContext);
	const [value, setValue] = useState('');
	const [isTypePassword, toggleTypePassword] = useState(true);
	const [helpText, setHelpText] = useState(null);

	useEffect(() => {
		setFieldValue && setFieldValue({ [Constants.name]: value });
	}, [value]);

	const showPasswordRules = () => {
		setHelpText(ReactDOMServer.renderToString(<PasswordRules />));
	};

	const hidePasswordRules = () => {
		setHelpText(null);
	};

	const showPasswordIsStrong = () => {
		setHelpText(ReactDOMServer.renderToString(<StrongPassword />));
	};

	return (
		<ThemeProvider theme={{ helpText: helpTextStyles, isHelpShown: Boolean(helpText) }}>
			<Wrapper>
				<EnhancedInput
					type={isTypePassword ? 'password' : 'text'}
					id={Constants.id}
					name={Constants.name}
					label={t('password')}
					value={value}
					validationRules={validationRules.PASSWORD}
					helpText={helpText}
					setValue={setValue}
					onFocus={showPasswordRules}
					onBlur={hidePasswordRules}
					onErrorFixed={showPasswordIsStrong}
				/>
				<ToggleInputType>
					<Link color='primary' onClick={() => toggleTypePassword(!isTypePassword)}>
						{isTypePassword ? 'Show' : 'Hide'} password
					</Link>
				</ToggleInputType>
			</Wrapper>
		</ThemeProvider>
	);
};
