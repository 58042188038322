import React, { FC } from 'react';
import styled from 'styled-components';
import { spaceSmall } from 'design-system/variables/spaces';
import { IconButton } from 'components/Icon/IconButton';

interface Props {
	handleClick: Function;
}

const StyledIcon = styled(props => <IconButton {...props} />)`
	position: absolute;
	right: ${spaceSmall};
	cursor: pointer;
`;

export const CloseButton: FC<Props> = ({ handleClick }) => (
	<StyledIcon iconName='close' iconSize='md' buttonType='containerless' onClick={handleClick} />
);
