import { useReducer } from 'react';

import { CarouselControls } from './CarouselEnums';

const initialState = { pos: 0, sliding: false, dir: CarouselControls.NEXT };

function reducer(state, { type, numItems }) {
	let currentType = type;

	if (
		(state.pos === 0 && type === CarouselControls.PREV) ||
		(state.pos === numItems - 1 && type === CarouselControls.NEXT)
	) {
		currentType = CarouselControls.STOP;
	}

	switch (currentType) {
		case 'reset':
			return initialState;
		case CarouselControls.PREV:
			return {
				...state,
				dir: CarouselControls.PREV,
				sliding: true,
				pos: state.pos === 0 ? numItems - 1 : state.pos - 1,
			};
		case CarouselControls.NEXT:
			return {
				...state,
				dir: CarouselControls.NEXT,
				sliding: true,
				pos: state.pos === numItems - 1 ? 0 : state.pos + 1,
			};
		case CarouselControls.STOP:
			return { ...state, sliding: false };
		default:
			return state;
	}
}

export const useCarousel = () => {
	const [state, dispatch] = useReducer(reducer, initialState);

	return [state, dispatch];
};
