import React, { useRef } from 'react';
import { getSafeHtml } from 'services/Helpers';
import styled, { createGlobalStyle } from 'styled-components';
import { sm, xsOnly } from '../../design-system/media-queries';
import { richText } from '../../design-system/utils';
import { textColorMedium } from '../../design-system/variables/colors';
import { spaceBase, spaceBig, spaceLarge, spaceMassive, spaceMedium } from '../../design-system/variables/spaces';
import { Title } from '../Title';
import { IconCardProps } from './IconCardProps';

const Heading = styled(props => <Title {...props} />)`
	text-align: center;
	margin-bottom: ${spaceMedium};
	display: block;
`;

const Copy = styled.div`
	${richText}
	margin-left: auto;
	margin-right: auto;
	text-align: center;
	${textColorMedium}
`;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	margin-left: auto;
	margin-right: auto;
	min-width: 100%;
	padding-bottom: ${spaceBig};

	${xsOnly`
		&:last-child {
			margin-bottom: 0;
		}
	`}
	${sm`
		padding-bottom: ${spaceBase};
	`}
`;

const IconHolder = styled.div`
	align-items: center;
	display: flex;
	justify-content: center;
	padding-bottom: ${spaceBase};
	padding-top: ${spaceBase};
`;

const IconImage = styled.img`
	max-height: 80px;
	max-width: 80px;
	object-fit: contain;
`;

const Styles = createGlobalStyle`

	.story__item {
		${Container} {
			margin-bottom: 0;
			padding-bottom: 0;
			padding-top: 0
		}
		${IconImage} {
			max-height: 120px;
			max-width: 120px
		}
	}

	 ${xsOnly`
	 	.story__item {
			&:last-of-type ${Container} {
				margin-bottom: 0;
			}
			${Container} {
				margin-bottom: ${spaceLarge};
				margin-top: ${spaceMassive};
			}
			${IconHolder} {
				display: none;
			}
	 	}
	 `}
`;

export const IconCard = (props: IconCardProps) => {
	const { heading, bodyCopy, backgroundColor, textColor, icon } = props;

	const iconCardElement = useRef(null);

	const getBackgroundColor = (): object => {
		return backgroundColor ? { backgroundColor } : {};
	};

	const setParentBackgroundColor = (): void => {
		if (!iconCardElement.current) {
			return;
		}
		iconCardElement.current.parentNode.style.backgroundColor = backgroundColor;
	};

	if (backgroundColor) {
		setParentBackgroundColor();
	}

	const textColorStyle = textColor ? { color: textColor } : {};

	return (
		<React.Fragment>
			<Styles />
			<Container style={getBackgroundColor()} ref={iconCardElement}>
				<div>
					<IconHolder>
						<IconImage style={textColorStyle} src={icon && icon.fullPath} alt='' />
					</IconHolder>

					<Heading style={textColorStyle}>{heading}</Heading>

					<Copy
						className='col-xs-11'
						dangerouslySetInnerHTML={getSafeHtml(bodyCopy)}
						style={textColorStyle}
					/>
				</div>
			</Container>
		</React.Fragment>
	);
};
