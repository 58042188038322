import axios from 'axios';
import {
	ProposalElementOrder,
	PagedResponse,
	ProposalDividerRequest,
	TripProposal,
	UpdateProposalAccommodationRequest,
	UpdateTripProposalRequest,
	CloneTripProposalRequest,
	TripProposalsRequest,
	TripProposalRequest,
	SendProposalRequest,
	CreateNPORequest,
	EditNPORequest
} from '../interfaces';
import { getConfig } from '../environmentConfig';

let cancelToken = null;

const getTripRecsApiUrl = () => {
	const configuration = getConfig();
	return configuration.tripRecsApiUrl;
}

const handleError = error => {
	if (axios.isCancel(error)) {
		return;
	}

	throw error;
};

export const getTripProposal = async (id: number): Promise<TripProposal> => {
	if (cancelToken) {
		cancelToken();
	}

	try {
		const response = await axios.get(`${getTripRecsApiUrl()}/${id}`, {
			cancelToken: new axios.CancelToken(token => {
				cancelToken = token;
			}),
		});
		return response && response.data;
	} catch (error) {
		handleError(error);
		return null;
	}
};

export const updateTripProposal = async (id: number, request: UpdateTripProposalRequest): Promise<void> => {
	if (cancelToken) {
		cancelToken();
	}

	try {
		const response = await axios.patch(`${getTripRecsApiUrl()}/${id}`, request, {
			cancelToken: new axios.CancelToken(token => {
				cancelToken = token;
			}),
		});
		return response && response.data;
	} catch (error) {
		handleError(error);
		return null;
	}
};

export const createTripProposal = async (proposal: TripProposalRequest): Promise<number> => {
	if (cancelToken) {
		cancelToken();
	}

	try {
		const response = await axios.post(`${getTripRecsApiUrl()}`, proposal, {
			cancelToken: new axios.CancelToken(token => {
				cancelToken = token;
			}),
		});
		return response && response.data;
	} catch (error) {
		handleError(error);
		return null;
	}
};

export const cloneTripProposal = async (id: number, request: CloneTripProposalRequest): Promise<number> => {
	if (cancelToken) {
		cancelToken();
	}

	try {
		const response = await axios.post(`${getTripRecsApiUrl()}/${id}/clone`, request);
		return response && response.data;
	} catch (error) {
		handleError(error);
		return null;
	}
};

export const updateProposalAccommodation = async (
	proposalId: number,
	accommodationId: number,
	request: UpdateProposalAccommodationRequest,
): Promise<void> => {
	if (cancelToken) {
		cancelToken();
	}

	try {
		await axios.patch(`${getTripRecsApiUrl()}/${proposalId}/accommodations/${accommodationId}`, request, {
			cancelToken: new axios.CancelToken(token => {
				cancelToken = token;
			}),
		});
	} catch (error) {
		handleError(error);
		return null;
	}
};

export const addProposalNPO = async (
	proposalId: number,
	request: CreateNPORequest,
): Promise<boolean> => {
	if (cancelToken) {
		cancelToken();
	}

	try {
		const response = await axios.post(`${getTripRecsApiUrl()}/${proposalId}/npo/`, request, {
			cancelToken: new axios.CancelToken(token => {
				cancelToken = token;
			}),
		});
		return true;
	} catch (error) {
		handleError(error);
		return false;
	}
};

export const editProposalNPO = async (
	proposalId: number,
	npoProposalElementId: number,
	request: EditNPORequest,
): Promise<boolean> => {
	if (cancelToken) {
		cancelToken();
	}

	try {
		const response = await axios.patch(`${getTripRecsApiUrl()}/${proposalId}/npo/${npoProposalElementId}`, request, {
			cancelToken: new axios.CancelToken(token => {
				cancelToken = token;
			}),
		});
		return true;
	} catch (error) {
		handleError(error);
		return false;
	}
};

export const updateProposalDivider = async (
	proposalId: number,
	dividerId: number,
	request: ProposalDividerRequest,
): Promise<void> => {
	if (cancelToken) {
		cancelToken();
	}

	try {
		await axios.patch(`${getTripRecsApiUrl()}/${proposalId}/dividers/${dividerId}`, request, {
			cancelToken: new axios.CancelToken(token => {
				cancelToken = token;
			}),
		});
	} catch (error) {
		handleError(error);
		return null;
	}
};

export const createProposalDivider = async (proposalId: number, request: ProposalDividerRequest): Promise<number> => {
	if (cancelToken) {
		cancelToken();
	}

	try {
		const response = await axios.post(`${getTripRecsApiUrl()}/${proposalId}/dividers`, request, {
			cancelToken: new axios.CancelToken(token => {
				cancelToken = token;
			}),
		});
		return response && response.data;
	} catch (error) {
		handleError(error);
		return null;
	}
};

export const deleteProposalElement = async (proposalId: number, proposalElementId: number): Promise<void> => {
	if (cancelToken) {
		cancelToken();
	}

	try {
		await axios.delete(`${getTripRecsApiUrl()}/${proposalId}/proposal-elements/${proposalElementId}`, {
			cancelToken: new axios.CancelToken(token => {
				cancelToken = token;
			}),
		});
	} catch (error) {
		handleError(error);
		return null;
	}
};

export const deleteAccommodationDateRange = async (
	proposalId: number,
	accommodationDateRangeId: number,
): Promise<void> => {
	if (cancelToken) {
		cancelToken();
	}

	try {
		const response = await axios.delete(
			`${getTripRecsApiUrl()}/${proposalId}/accommodations/date-ranges/${accommodationDateRangeId}`,
			{
				cancelToken: new axios.CancelToken(token => {
					cancelToken = token;
				}),
			},
		);
		return response && response.data;
	} catch (error) {
		handleError(error);
		return null;
	}
};

export const deleteNonPortfolioAccommodationDateRange = async (
	proposalId: number,
	nonPortfolioAccommodationDateRangeId: number,
): Promise<void> => {
	if (cancelToken) {
		cancelToken();
	}

	try {
		const response = await axios.delete(
			`${getTripRecsApiUrl()}/${proposalId}/npo/date-ranges/${nonPortfolioAccommodationDateRangeId}`,
			{
				cancelToken: new axios.CancelToken(token => {
					cancelToken = token;
				}),
			},
		);
		return response && response.data;
	} catch (error) {
		handleError(error);
		return null;
	}
};

export const getTripProposals = async (params: TripProposalsRequest): Promise<PagedResponse<TripProposal>> => {
	if (cancelToken) {
		cancelToken();
	}

	// The recipientIds need to be converted to a comma separated list for the server.
	const recipientIds = params && params.recipientIds ? params.recipientIds.join(',') : null;
	const adjustedParams = { ...params, recipientIds };

	try {
		const response = await axios.get(`${getTripRecsApiUrl()}`, {
			cancelToken: new axios.CancelToken(token => {
				cancelToken = token;
			}),
			params: adjustedParams,
		});
		return response && response.data;
	} catch (error) {
		handleError(error);
		return null;
	}
};

export const updateProposalElementsOrder = async (proposalId: number, request: ProposalElementOrder): Promise<void> => {
	if (cancelToken) {
		cancelToken();
	}

	try {
		await axios.patch(`${getTripRecsApiUrl()}/${proposalId}/proposal-elements/order`, request, {
			cancelToken: new axios.CancelToken(token => {
				cancelToken = token;
			}),
		});
	} catch (error) {
		handleError(error);
		return null;
	}
};

export const sendTripProposal = async (
	proposalId: number,
	sendProposalRequest: SendProposalRequest,
): Promise<boolean> => {
	if (cancelToken) {
		cancelToken();
	}

	try {
		await axios.post(`${getTripRecsApiUrl()}/${proposalId}/send`, sendProposalRequest, {
			cancelToken: new axios.CancelToken(token => {
				cancelToken = token;
			}),
		});
		return true;
	} catch (error) {
		handleError(error);
		return false;
	}
};
