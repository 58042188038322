import styled from 'styled-components';

import { Wrapper } from 'components/Banner/Banner';
import { Button } from 'components/Button/Button';
import { Icon } from 'components/Icon/Icon';
import { spaceBase, spaceBig, spaceLarge, spaceMedium, spaceSmall } from '../../design-system/variables/spaces';
import { black, dividerGray, textColorDark, textColorMedium, white } from '../../design-system/variables/colors';
import { bold, textBase, textSmall, textTiny } from '../../design-system/style-mixins/text';
import { md, sm } from '../../design-system/media-queries';
import { shadowHover } from '../../design-system/style-mixins/animations';
import { transparentDark80 } from '../../design-system/style-mixins/shadows-and-scrims';
import { truncateText } from '../../design-system/utils';

export const Container = styled.div`
	text-align: left;
	.video-player {
		background-color: rgba($black, 0.075);
	}
`;

export const VideoWrapper = styled.div`
	margin-bottom: ${spaceLarge};
`;
export const VideoSubtitle = styled.div`
	margin-top: ${spaceSmall};
`;
export const DateDetails = styled.div`
	border-bottom: ${dividerGray};
	padding-bottom: ${spaceBase};
	padding-top: ${spaceMedium};
`;
export const DurationDetails = styled.div`
	text-align: right;
`;
export const VideoGalleryDetails = styled.div`
	position: relative;
`;

export const Gallery = styled.div`
	border-top: ${dividerGray};
	margin-bottom: ${spaceBase};
	padding-top: ${spaceLarge};

	${md`
		margin-bottom: ${spaceLarge};
		padding-top: ${spaceBig};
	`}
`;

export const CardIsNew = styled(props => <Wrapper {...props} />)`
	position: absolute;
	right: 0;
	bottom: ${spaceBase};
	height: auto;
`;

export const ImgContainer = styled.div`
	background-color: rgba(${black}, 0.075);
	background-position: center;
	background-size: cover;
	position: relative;

	img {
		width: 100%;
	}
`;

export const IconPlay = styled(props => <Icon {...props} />)`
	position: absolute;
	left: calc(50% - 20px);
	top: calc(50% - 20px);
`;

export const CardDuration = styled.div`
	text-align: right;
	padding-left: ${spaceSmall};
	${textColorMedium}
	${textTiny}
`;

export const CardTitle = styled.div`
	text-align: left;
	font-weight: ${bold};
	${truncateText}
	${textBase}
	${textColorDark}
`;

export const CardContent = styled.div`
	display: flex;
	justify-content: space-between;
	line-height: initial;
	padding: ${spaceBase};
`;

export const VideoGalleryCard = styled.a`
	display: block;
	overflow: hidden;
	padding-left: 0;
	padding-right: 0;
	background-color: ${white};
	margin-bottom: ${spaceBase};
	${shadowHover}
	${textColorMedium}
	
	&:hover {
		${IconPlay} {
			fill: ${transparentDark80};
		}
	}
`;

export const GalleryResults = styled.div`
	margin-bottom: ${spaceBase};
	${textSmall}
	${textColorMedium}
`;

export const ButtonCTA = styled(props => <Button {...props} />)`
	width: 100%;
	${sm`
		max-width: 220px;
	`}
`;
