import styled from 'styled-components';
import { transparentLight80, transparentDark70 } from '../../design-system/style-mixins/shadows-and-scrims';

interface OverlayProps {
	contrast?: 'light' | 'dark';
}

export const Overlay = styled.div<OverlayProps>`
	width: 100%;
	height: 100%;
	background: ${props => (props.contrast === 'dark' ? transparentDark70 : transparentLight80)};
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 11;
` as any;
