import React, { LegacyRef } from 'react';
import DayPicker, { DayModifiers } from 'react-day-picker';
import styled, { createGlobalStyle } from 'styled-components';
import moment from 'moment';
import {
	black,
	white,
	blue,
	textColorDark,
	gray,
	primaryLinkColorHover,
	grayLight,
} from '../../design-system/variables/colors';
import { spaceBase } from '../../design-system/variables/spaces';
import { sm } from '../../design-system/media-queries';
import { textSmall } from '../../design-system/style-mixins/text';
import { SelectedDateRange } from '../../pages/WatchList/interfaces';
import { transition250ms } from '../../design-system/style-mixins/animations';
import 'react-day-picker/lib/style.css';

export const GlobalCalendarStyle = createGlobalStyle`
	&& {
		.DayPicker.Range {
			.DayPicker-Day {
				${textColorDark}
				${textSmall}
				${transition250ms}

				border-radius: 0;
				padding-bottom: ${spaceBase};
				padding-left: ${spaceBase};
				padding-right: ${spaceBase};

				&--outside,
				&--outside:hover {
					color: ${grayLight}

					background-color: ${white};
				}

				&--selected:not(.DayPicker-Day--outside),
				&--selected:not(.DayPicker-Day--outside):hover {
					background-color: ${blue};

					&.DayPicker-Day--checkInDate:not(.DayPicker-Day--disabled),
					&.DayPicker-Day--checkOutDate:not(.DayPicker-Day--disabled),
					&.DayPicker-Day--checkOutDate:not(.DayPicker-Day--disabled):hover,
					&.DayPicker-Day--checkInDate:not(.DayPicker-Day--disabled):hover {
						color: ${white}

						background-color: ${black};
					}
				}

				&--disabled:not(.DayPicker-Day--outside),
				&--disabled:not(.DayPicker-Day--outside):hover {
					background-color: ${white};
					color: ${gray};
				}

				&:not(.DayPicker-Day--outside):not(.DayPicker-Day--disabled):hover {
					background-color: ${primaryLinkColorHover} !important;
					color: ${white};
				}
			}
		}
	}

	.DayPicker-NavButton--prev {
		&& {
			left: ${spaceBase};
		}
	}

	.DayPicker-Caption {
		&& {
			text-align: center;
		}
	}
`;

const CalendarContainer = styled.div`
	display: block;

	${sm`
		display: inline-block;
		height: auto;
	`}
`;

interface CalendarProps {
	monthsToDisplay?: number;
	onFinalSelect?: () => void;
	onDaysSelected?: (dateRange: SelectedDateRange) => void;
	enabledRange?: { from: Date; to: Date };
	ref: LegacyRef<DayPicker>;
	initSelectedDays?: { checkInDate: Date; checkOutDate: Date };
}

const Calendar: React.ForwardRefExoticComponent<Pick<CalendarProps, any>> = React.forwardRef(
	(
		{ monthsToDisplay = 2, onDaysSelected, enabledRange, onFinalSelect, initSelectedDays },
		ref: LegacyRef<DayPicker>,
	) => {
		const [from, setFrom] = React.useState<Date>(initSelectedDays ? initSelectedDays.checkInDate : null);
		const [to, setTo] = React.useState<Date>(initSelectedDays ? initSelectedDays.checkOutDate : null);
		const [enteredTo, setEnteredTo] = React.useState<Date>(initSelectedDays ? initSelectedDays.checkOutDate : null);

		const modifiers = {
			checkInDate: enteredTo,
			checkOutDate: from,
		};

		const today = new Date();
		const oneYearOut = moment()
			.utc()
			.add(1, 'year')
			.toDate();
		const disabledDays = {
			before: enabledRange ? enabledRange.from : today,
			after: enabledRange ? enabledRange.to : oneYearOut,
		};
		const selectedDays = { from, to: enteredTo };
		const fromMonth = enabledRange ? enabledRange.from : today;
		const toMonth = enabledRange ? enabledRange.to : oneYearOut;

		const isSelectingFirstDay = day => {
			const isBeforeFirstDay = from && moment.utc(day).isBefore(from);
			const isRangeSelected = from && to;

			return !from || isBeforeFirstDay || isRangeSelected;
		};

		React.useEffect(() => {
			if (!onDaysSelected) {
				return;
			}

			const dateRange: SelectedDateRange = { id: 0, startDate: selectedDays.from, endDate: selectedDays.to };
			onDaysSelected(dateRange);
		}, [from, to]);

		const reset = () => {
			setFrom(null);
			setTo(null);
			setEnteredTo(null);
		};

		const handleDayClick = (day: Date, { disabled }: DayModifiers) => {
			if (disabled) {
				return;
			}

			if (from && to && day >= from && day <= to) {
				reset();
			}

			if (isSelectingFirstDay(day)) {
				setFrom(day);
				setTo(null);
				setEnteredTo(null);
			} else {
				setTo(day);

				if (onFinalSelect) {
					if (onDaysSelected) {
						const dateRange: SelectedDateRange = {
							id: 0,
							startDate: selectedDays.from,
							endDate: selectedDays.to,
						};
						onDaysSelected(dateRange);
					}
					onFinalSelect();
				}
			}
		};

		const handleDayMouseEnter = day => {
			if (!isSelectingFirstDay(day)) {
				setEnteredTo(day);
			}
		};

		return (
			<CalendarContainer>
				<GlobalCalendarStyle />
				<DayPicker
					className='Range'
					numberOfMonths={monthsToDisplay}
					fromMonth={fromMonth}
					initialMonth={fromMonth}
					toMonth={toMonth}
					selectedDays={selectedDays}
					disabledDays={disabledDays}
					onDayClick={handleDayClick}
					onDayMouseEnter={handleDayMouseEnter}
					modifiers={modifiers}
				/>
				<input type='hidden' ref={() => ref} onClick={reset} />
			</CalendarContainer>
		);
	},
);

export default Calendar;
