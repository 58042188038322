import moment from 'moment';
import { equals, omit, map } from 'ramda';
import { setResultsChildrenDropdown } from 'utils';
import { Place } from '../../interfaces';

interface FilterParams {
	sort: number;
	sortDirection: number;
	skip?: number;
	pageSize: number;
	filters: Parameters<(s: string) => void>;
}

const sortFilterParams = (paramObj: FilterParams) => ({
	...paramObj,
	filters: Object.keys(paramObj.filters).length
		? map((filterParam) => (filterParam.length ? filterParam.sort() : filterParam), paramObj.filters)
		: paramObj.filters,
});

export const compareParams = (a, b, skipParams?: string[]) => {
	const objA = skipParams && skipParams.length ? omit(skipParams, a) : a;
	const objB = skipParams && skipParams.length ? omit(skipParams, b) : b;
	return equals(sortFilterParams(objA), sortFilterParams(objB));
};

const buildOptionFromTag = (result, name) => {
	const tags = result.options[0].tags.filter((tag) => tag.name === name);
	if (tags.length > 0) {
		return tags[0].value;
	}
	return null;
};

export const transformResults = (res) =>
	res.results.reduce((obj, result) => {
		const parentCategory = buildOptionFromTag(result, 'ParentCategory');
		obj[result.id] = {
			id: result.id,
			showTripValue: !!parseInt(buildOptionFromTag(result, 'ShowAssetValue'), 10),
			checkInDate: result.options[0].startDateTime,
			checkOutDate: result.options[0].endDateTime,
			numberOfDays: moment
				.utc(result.options[0].endDateTime)
				.diff(moment.utc(result.options[0].startDateTime), 'days'),
			// curatedTripMasterInventoryId: 935929244,
			unitName: result.options[0].assetOptionName,
			unitID: buildOptionFromTag(result, 'LegacyId'),
			// unitSourceID: 2,
			rid: result.options[0].assetId,
			unitParentCategoryID: parentCategory === 'Homes' ? 1 : parentCategory === 'Rooms/Suites' ? 2 : 3,
			parentCategoryName: buildOptionFromTag(result, 'ParentCategory'),
			// propertyID: 0,
			propertyName: buildOptionFromTag(result, 'Property') || buildOptionFromTag(result, 'ResortName'),
			// locationID: 45,
			locationName: buildOptionFromTag(result, 'Location'),
			// locationGroupID: 49,
			locationGroupName: buildOptionFromTag(result, 'Region'),
			// unitStyleID: 2,
			unitStyleName: buildOptionFromTag(result, 'Style'),
			coverImage: result.options[0].content.coverImageUrl, // '/media/9405337/telluride_dynamo_living-area-3_cover.jpg',
			bedrooms: buildOptionFromTag(result, 'Bedrooms'),
			bathrooms: buildOptionFromTag(result, 'Bathrooms'),
			occupancy: buildOptionFromTag(result, 'SuggestedOccupancy'),
			squareFootage: buildOptionFromTag(result, 'SquareFootage'),
			// umbracoNodeID: 167189,
			unitURL: buildOptionFromTag(result, 'UnitUrl'),
			value: result.options[0].extrinsicValueSubtotal + result.options[0].extrinsicValueTax,
			overrideUrl: result.options[0].content.navigationUrl, // TODO: Set correct overrideUrl
			featuredOrder: parseInt(buildOptionFromTag(result, 'IsRecommended'), 10),
			heroImage: result.options[0].content.coverImageUrl, // TODO: Set correct heroImage
			isNew: parseInt(buildOptionFromTag(result, 'IsNew'), 10) || 0,
			// dataMonthId: '4-2020',
			// dataMonthName: 'April, 2020',
		};
		return obj;
	}, {});

/**
 *
 * @param data Default state of the filters??
 * @param params All requested params
 * @param response new data from server
 */
export const transformRefinedFilters = (data, params, response) => {
	const enabledVacationStyles = response.filters.Styles;
	const enabledUnitSources = response.filters.ParentCategories.map((cat) =>
		cat === 'Rooms/Suites' ? 'Hotels' : cat,
	);

	let homes = data.homes.map((home) => ({
		...home,
		checked: params.filters.HomeName ? params.filters.HomeName.includes(home.label) : false,
		disabled: response.filters.HomeName ? !response.filters.HomeName.includes(home.label) : true,
	}));

	let hotels = data.hotels.map((hotel) => ({
		...hotel,
		checked: params.filters.Property ? params.filters.Property.includes(hotel.label) : false,
		disabled: response.filters.Property ? !response.filters.Property.includes(hotel.label) : true,
	}));

	let unitSources = data.unitSources.map((unitSource) => ({
		...unitSource,
		checked:
			params.filters.ParentCategories &&
			params.filters.ParentCategories.map((cat) => (cat === 'Rooms/Suites' ? 'Hotels' : cat)).includes(
				unitSource.label,
			),
		disabled: !enabledUnitSources.includes(unitSource.label),
	}));

	return {
		vacationStyles: data.vacationStyles.map((style) => ({
			...style,
			checked: params.filters.Styles ? params.filters.Styles.includes(style.label) : false,
			disabled: !enabledVacationStyles.includes(style.label),
		})),
		unitSources: unitSources,
		checkInDatesList: {
			enabledDates: response.filters.CheckInDates
				? response.filters.CheckInDates.map((date) => moment.utc(date).format('ddd MMM DD YYYY')).sort(
						(a, b) => moment.utc(a).unix() - moment.utc(b).unix(),
				  )
				: data.checkInDatesList.enabledDates,
		},
		occupancy: response.filters.Occupancy
			? response.filters.Occupancy.map((numString) => parseInt(numString, 10)).sort((a, b) => a - b)
			: data.occupancy,
		lengthOfStay: response.filters.NumberOfDays
			? response.filters.NumberOfDays.map((numString) => parseInt(numString, 10)).sort((a, b) => a - b)
			: data.lengthOfStay,
		regions: setResultsChildrenDropdown(
			data.regions,
			response.filters.Regions,
			response.filters.Locations,
			'label',
			params.filters.Locations,
			params.filters.Regions,
		),
		homes: homes,
		hotels: hotels,
	};
};

const buildArrProp = (propName, data, transformFn = (arr) => arr) => [
	propName,
	data && data.length && transformFn(data),
];

const buildFilterObj = (transforms) =>
	transforms.reduce((acc, curr) => (curr[1] ? { ...acc, [curr[0]]: curr[1] } : acc), {});

export const transformRequestParams = (filterParams, filters) => ({
	sort: filterParams.sort,
	sortDirection: filterParams.sortDirection,
	pageSize: filterParams.pageSize,
	page: filterParams.skip / filterParams.pageSize + 1,
	filters: buildFilterObj([
		buildArrProp('Occupancy', filters.occupancy, (data) =>
			data.map((numString) => parseInt(numString, 10)).sort((a, b) => a - b),
		),
		buildArrProp('CheckInDates', filters.checkInDates, (data) =>
			data.map((date) => moment.utc(date).format('YYYY-MM-DD')),
		),
		buildArrProp('NumberOfDays', filters.numberOfDays, (data) =>
			data.map((numString) => parseInt(numString, 10)).sort((a, b) => a - b),
		),
		buildArrProp('Styles', filters.styles),
		buildArrProp('ParentCategories', filters.parentCategory, (data) =>
			data.map((cat) => (cat === 'Hotels' ? 'Rooms/Suites' : cat)),
		),
		buildArrProp('Regions', filters.regions),
		buildArrProp('Locations', filters.locations),
		buildArrProp('HomeName', filters.homeName),
		buildArrProp('Property', filters.property),
	]),
});

const isActiveLocation = (place: Place): boolean => !place.disabled && place.locationType === 'Location';

/**
 * Returns the count of destinations per an accommodation (Sun up) or property (Sonnenalp)
 * Complexity comes from having scenarios where the accommodation or property could already be tallied
 * by the region filter or the location filter
 */
const calculateCountForPlace = (filterType: string, dataType: string, params, data, newFilterData) => {
	let count = 0;
	const hasRegionFilters = 'Regions' in params.filters;
	const hasLocationFilters = 'Locations' in params.filters;

	for (const filter of params.filters[filterType]) {
		const place = data[dataType].find((p) => p.label === filter);

		if (place) {
			// check if unit is in the locations filter
			const isNotInLocationFilter = !(hasLocationFilters && params.filters.Locations.includes(place.subLabel));

			// check if unit is in the regions filter
			let isNotInRegionFilter = true;
			if (hasRegionFilters) {
				const selectedRegions = newFilterData.regions.filter((r) => r.checked);
				for (const region of selectedRegions) {
					const unitInRegion = region.children
						.filter(isActiveLocation)
						.some((l) => l.label === place.subLabel);
					if (unitInRegion) {
						isNotInRegionFilter = false;
					}
				}
			}

			if (isNotInLocationFilter && isNotInRegionFilter) {
				count++;
			}
		}
	}
	return count;
};

/**
 * Returns the count of enabled destinations in the location filter
 */
export const countLocationsInRegions = (regions: Place[]) =>
	regions.reduce(
		(accumulator: number, currentRegion: any) =>
			accumulator + currentRegion.children.filter(isActiveLocation).length,
		0,
	);

/**
 * Returns the count of destinations for the current search
 */
export const calculateDestinationCount = (data, params, response, newFilterData) => {
	const hasUnitFilters = 'HomeName' in params.filters;
	const hasPropertyFilters = 'Property' in params.filters;
	const hasLocationFilters = 'Locations' in params.filters;
	const hasRegionFilters = 'Regions' in params.filters;

	if (hasUnitFilters || hasPropertyFilters || hasLocationFilters || hasRegionFilters) {
		let countForRegions = 0;
		let countForAccommodation = 0;
		let countForProperties = 0;
		let countForLocations = 0;

		if (hasRegionFilters) {
			// must insure that the location is not disabled
			const selectedRegions = newFilterData.regions.filter((r) => r.checked);
			countForRegions += countLocationsInRegions(selectedRegions);
		}

		if (hasLocationFilters) {
			for (const region of newFilterData.regions) {
				countForLocations += region.children.filter((d) => isActiveLocation(d) && d.checked).length;
			}
		}

		if (hasPropertyFilters) {
			countForProperties = calculateCountForPlace('Property', 'hotels', params, data, newFilterData);
		}

		if (hasUnitFilters) {
			countForAccommodation = calculateCountForPlace('HomeName', 'homes', params, data, newFilterData);
		}

		return countForAccommodation + countForProperties + countForLocations + countForRegions;
	}

	// if no other filters apply use the count of the filtered locations
	return response.filters.Locations.length;
};
