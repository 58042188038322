import styled from 'styled-components';
import {
	boxShadow3,
	boxShadow2,
	boxShadow1,
	boxShadow4,
	boxShadow5,
} from '../../design-system/style-mixins/shadows-and-scrims';

const shadowStyles = {
	1: boxShadow1,
	2: boxShadow2,
	3: boxShadow3,
	4: boxShadow4,
	5: boxShadow5,
};

interface MenuProps {
	/**
	 * @shadow Defines shadow level.
	 * Default is no shadow.
	 */
	shadow?: 1 | 2 | 3 | 4 | 5;
}

/**
 * Menu:
 * Use as a container with shadow that holds a variety of components.
 * Can hold filters, quick search, incrementers, etc.
 */
export const Menu = styled.div<MenuProps>`
	z-index: 10;
	${props => shadowStyles[props.shadow] || null}
`;
