import { useState, useEffect, useCallback } from 'react';
import { RangeSlider } from '../RangeSlider';
import { TextColorLight, GrayIcon, Shrink } from './PassDaysSlider.styles';
import { PassDaysSliderProps } from './PassDaysSliderProps';
import { format } from 'date-fns';
import { pluralize } from 'design-system/utils';

let firstLoad = true;
const PassDaysBalanceSection = ({ isChanged, passDaysBalance }) => (
    <TextColorLight>
        <GrayIcon iconName="clock-clockwise" iconSize="sm" />
        {isChanged ? (
            <span>You will have a balance of {passDaysBalance} Pass Days.</span>
        ) : (
            <span>You have a balance of {passDaysBalance} Pass Days.</span>
        )}
    </TextColorLight>
);

export const PassDaysSlider = ({ passDaysToBook, passDaysAvailable, value, max, initialValue, onChange, nextBookingDate, ...nativeProps }: PassDaysSliderProps) => {
    const [passDaysBalance, setPassDaysBalance] = useState<number>(passDaysAvailable - (initialValue ?? value));

    const handleChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const newValue = parseInt(e.target.value);
            onChange(newValue);
            setPassDaysBalance(passDaysAvailable - newValue);
        },
        [onChange, passDaysAvailable],
    );

    useEffect(() => {
        if (firstLoad && initialValue>0){
			onChange(initialValue);
			firstLoad=false;
        }
        setPassDaysBalance(passDaysAvailable - value);
    }, [passDaysAvailable, value, initialValue]);

    return (
        <>
            <RangeSlider
                max={max}
                min={0}
                step={1}
                initialValue={initialValue ?? 0}
                onChange={handleChange}
                slots={{ ballonLegend: <Shrink>{pluralize(value, 'day', 'days').split(' ')[1]}</Shrink> }}
                {...nativeProps}
                />
            <PassDaysBalanceSection isChanged={value !== 0} passDaysBalance={passDaysBalance} />
            <TextColorLight>
                <GrayIcon iconName="calendar" iconSize="sm" /> Book your next reservation on {format(nextBookingDate, 'MMMM Do, YYYY')}.
        </TextColorLight>
        </>
    );
};
