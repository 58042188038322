import React, { useState } from 'react';
import moment from 'moment';
import styled, { css } from 'styled-components';
import root from 'window-or-global';
import { PressReleaseProps } from './PressReleaseProps';
import { FORMAT_DATE } from '../../../enums/Format';
import { sm, xsOnly, xsSmOnly, md } from '../../../design-system/media-queries';
import {
	spaceBase,
	spaceHuge,
	spaceLarge,
	spaceMassive,
	spaceMedium,
	spaceSuperMassive,
	spaceTiny,
} from '../../../design-system/variables/spaces';
import { textBase, textSmall } from '../../../design-system/style-mixins/text';
import { dividerGrayLight, paleBlue, textColorMedium } from '../../../design-system/variables/colors';
import { Button } from '../../Button';
import { Subtext } from '../../Subtext';
import { Headline } from '../../Headline';
import { Icon } from '../../Icon';
import { useStateIsMobile } from '../../hooks';
import { Modal } from '../../Modal';
import { TextButton } from '../../TextButton';
import { Subhead } from '../../Subhead';
import { getSafeHtml, openLink } from '../../../services/Helpers';
import { ActionLink } from '../../ActionLink';

const Cell = styled.td`
	border-top: ${dividerGrayLight};
	padding: ${spaceBase};
	${xsOnly`
		border: none;
		width: auto;
		display: block;
		padding: 0;
	`}
`;

const Row = styled.tr`
	padding: ${spaceBase} 0;
	position: relative;
	width: calc(100% - ${spaceMedium});

	&:hover {
		background-color: ${paleBlue};
	}

	${xsOnly`
		border-top: 1px solid hsla(0, 0%, 77%, 0.3);
		display: block;
		padding: ${spaceBase} 0;
		width: auto;
		cursor: pointer;

		> ${Cell} {
			width: auto;
			display: block;
		}
	`};

	${xsSmOnly`&:hover {background: none;}`}

	${sm`width: auto;`}

	${md`
		padding: initial;
		&:hover {cursor: pointer;}
	`}
`;

const DateCell = styled(props => <Cell {...props} />)`
	margin-bottom: ${spaceTiny};
	width: 100%;

	${sm`
		padding-right: 0;
		padding-top: 21px;
		vertical-align: top;
		width: 149px;
	`}
`;

const TextContainerCell = styled(props => <Cell {...props} />)`
	&& {
		width: calc(100% - ${spaceMedium});
	}
`;

const CTAContainerCell = styled(props => <Cell {...props} />)`
	&& {
		text-align: right;
	}

	${xsOnly`
		bottom: ${spaceBase};
		position: absolute;
		right: 0;
	`}
`;

const ArticleDetails = styled.div`
	${textBase}
	${textColorMedium};
`;

const IconCTA = styled(props => <Icon {...props} />)<{ hidden: boolean }>`
	${props =>
		props.hidden &&
		css`
			display: none;
		`}
`;

const IconCTAMobile = styled(props => <IconCTA {...props} />)`
	display: none;
	${xsOnly`display: block;`}
`;

const ButtonCTA = styled(props => <Button {...props} />)`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	max-width: 116px;
	flex-wrap: nowrap;

	${xsOnly`display: none;`}

	${IconCTA} {
		margin-left: ${spaceTiny};
	}
`;

const IconLeft = styled(props => <Icon {...props} />)`
	transform: rotate(180deg);
`;

const PressReleaseContainer = styled.div`
	padding: ${spaceBase};
	width: 500px;
	margin: auto;
`;

const DateText = styled(props => <Subtext {...props} />)`
	float: right;
	padding: ${spaceLarge} ${spaceSuperMassive} ${spaceBase} 0;
`;
const Back = styled(props => <TextButton {...props} />)`
	float: left;
	padding: ${spaceLarge} 0 ${spaceBase} ${spaceSuperMassive};
	${textSmall}
	svg {
		width: 1.25em;
		height: 1.25em;
	}
`;

const textAlignCenter = css`
	text-align: center;
	display: block;
`;
const PressReleaseTitle = styled(props => <Headline {...props} />)`
	${textAlignCenter}
`;
const PressReleaseSubTitle = styled(props => <Subhead {...props} />)`
	${textAlignCenter}
	padding-top: ${spaceLarge};
	padding-bottom: ${spaceBase};
`;

const Content = styled.div`
	padding: ${spaceHuge} 0 ${spaceBase} 0;
`;

const Footer = styled.div`
	text-align: center;
	padding: ${spaceBase} 0 ${spaceMassive} 0;
`;

export const PressRelease: React.FunctionComponent<PressReleaseProps> = (props: PressReleaseProps) => {
	const { releaseCopy, title, subtitle, date, externalSiteUrl, onPressReleaseSelect, initiallyLoaded } = props;
	const getRoot = () => root.document.body;

	// TODO: It's required to manage CTA content, but there is no particular property for that.
	const dateFormatted = date ? moment(date).format(FORMAT_DATE.PRESS_RELEASE) : null;
	const isMobile = useStateIsMobile();
	const [isOpen, setIsOpen] = useState(initiallyLoaded || false);

	const onLinkClick = () => {
		if (externalSiteUrl && externalSiteUrl.newWindow) {
			openLink(externalSiteUrl.url, externalSiteUrl.newWindow);
		} else {
			setIsOpen(!isOpen);
		}
		return onPressReleaseSelect && onPressReleaseSelect(externalSiteUrl);
	};

	const closeModal = e => {
		e && e.stopPropagation();
		setIsOpen(false);
	};

	return (
		<React.Fragment>
			<Row onClick={isMobile ? onLinkClick : null}>
				{/* Summary */}
				<DateCell>
					<Subtext>{dateFormatted || ''}</Subtext>
				</DateCell>
				<TextContainerCell>
					<Headline size='small'>{title}</Headline>
					{subtitle && subtitle.length > 0 ? <ArticleDetails>{subtitle}</ArticleDetails> : null}
				</TextContainerCell>
				{/* /Summary */}

				{/* Content */}
				<CTAContainerCell>
					<ButtonCTA buttonType='primary' size='small' onClick={onLinkClick}>
						{externalSiteUrl && externalSiteUrl.newWindow ? 'View' : 'Read'}
						<IconCTA
							iconName='link-external'
							iconSize='sm'
							fill='white'
							hidden={!externalSiteUrl.newWindow}
						/>
					</ButtonCTA>
					<IconCTAMobile
						iconName='link-external'
						iconSize='sm'
						fill='black'
						hidden={!externalSiteUrl.newWindow}
					/>
				</CTAContainerCell>
				{/* /Content */}
			</Row>

			<Modal isOpen={isOpen} onRequestClose={closeModal} parentSelector={getRoot} overflowAuto>
				<Back onClick={closeModal} iconSize='md'>
					<IconLeft iconName='arrow' iconSize='md' />
					Back
				</Back>
				<DateText>June 18, 2018</DateText>
				<PressReleaseContainer>
					<Content>
						{title && title.length > 0 && <PressReleaseTitle size='large'>{title}</PressReleaseTitle>}
						{subtitle && subtitle.length > 0 && <PressReleaseSubTitle>{subtitle}</PressReleaseSubTitle>}
						<div dangerouslySetInnerHTML={getSafeHtml(releaseCopy)} />
					</Content>
				</PressReleaseContainer>
				<Footer>
					<ActionLink
						onClick={closeModal}
						canDisplayAsImageLink={false}
						canDisplayAsTextLink
						linkTitle='Back to Press'
						newWindow={false}
						url='#'
						componentName=''
					/>
				</Footer>
			</Modal>
		</React.Fragment>
	);
};
