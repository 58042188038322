import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useScript } from 'services/Helpers';
import { spaceBase } from '../../design-system/variables/spaces';

const Container = styled.div`
	display: block;
	height: 100%;
	margin-top: ${spaceBase};
	overflow: auto;
	width: 100%;
`;

export const CareersListing: React.FC = () => {
	const [element, setElement] = useState(null);

	const elementRef = useCallback(node => {
		setElement(node);
	}, []);

	// clean up element that contains generated content
	useEffect(() => {
		return () => {
			if (element) {
				element.innerHTML = '';
			}
		};
	}, []);

	/**
	 * TODO: Fix it.
	 * Does not work locally and throws an error:
	 * "Sorry, the protocol or hostname does not match your careers page URL set in Newton.
	 * Please contact support@newtonsoftware.com for help"
	 */
	// const [isScriptLoaded, isScriptLoadError] = useScript(
	// 	'//newton.newtonsoftware.com/career/iframe.action?clientId=8a42a12b36e3f69d0136eb9e96c47613',
	// 	'gnewtonjs',
	// 	element,
	// );

	return <Container ref={elementRef} />;
};
