import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { ProposalError } from '../../../../interfaces';
import { SET_ERRORS, ERRORS_CLEARED } from '../constants';
import { State } from '../context';

export const setFieldError = (fieldName: string, errorMessage: string) => (
	dispatch: ThunkDispatch<State, undefined, Action>,
	getState: () => State,
) => {
	const { errors } = getState();

	const newError: ProposalError = {
		key: fieldName,
		message: errorMessage,
	};

	dispatch({ type: SET_ERRORS, payload: { errors: errors.concat(newError) } });
};

export const setFieldErrors = (fieldErrors: ProposalError[]) => (
	dispatch: ThunkDispatch<State, undefined, Action>,
	getState: () => State,
) => {
	const { errors } = getState();
	// filter out any duplicate errors
	const newErrors = fieldErrors.filter(error => errors.indexOf(error) < 0);

	dispatch({ type: SET_ERRORS, payload: { errors: errors.concat(newErrors) } });
};

export const clearFieldError = (fieldName: string) => (
	dispatch: ThunkDispatch<State, undefined, Action>,
	getState: () => State,
) => {
	const { errors } = getState();

	dispatch({
		type: SET_ERRORS,
		payload: {
			errors: errors.filter(error => {
				return error.key !== fieldName;
			}),
		},
	});
};

export const clearAllErrors = () => (dispatch: ThunkDispatch<State, undefined, Action>) => {
	dispatch({ type: ERRORS_CLEARED });
};
