import { CallToActionProps } from 'components/ActionLink/CallToActionProps';
import {
	CTAButton,
	FieldHalfSpace,
	Form,
	Headline,
	MessageField,
	Styles,
	Wrapper,
} from 'components/Registration/CampaignRegistrationForm/CampaignRegistrationForm.styles';
import { RegistrationResponseProps } from 'components/Registration/CampaignRegistrationForm/RegistrationRepository';
import { Paragraph } from 'components/Paragraph';
import { Country } from 'enums/Country';
import { ComponentProps } from 'interfaces/ComponentProps';
// eslint-disable-next-line import/no-extraneous-dependencies
import qs from 'qs';
import React, { FormEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getSafeHtml, sendGAPageview } from 'services/Helpers';
import { i18n as i18n2Instance, locales } from 'services/i18n';
import { ValidationRules } from 'services/ValidationRules';
import root from 'window-or-global';
import { FormContext, FormStateProps } from 'components/Registration/shared/FormContext';
import { FirstName } from 'components/Registration/shared/FirstName';
import { LastName } from 'components/Registration/shared/LastName';
import { Email } from 'components/Registration/shared/Email';
import { Password } from 'components/Registration/shared/Password';
import { RegistrationRepository } from './RegistrationRepository';
import { Country as CountryComponent } from '../shared/Country';

import { SignIn } from '../shared/SignIn';

const campaignVisitorType = 5;
const registrationRepository = new RegistrationRepository();

export interface CampaignRegistrationFormProps extends ComponentProps {
	headline: string;
	language: string;
	pardotFormHandlerTracking: string;
	privacyPolicy: string;
	salesforceId: string;
	submissionCallToAction: CallToActionProps;
	isInternalRegistration: boolean;
	confirmationCopy?: string;
	originalLeadSource?: string;
}

export const CampaignRegistrationForm: React.FC<CampaignRegistrationFormProps> = ({
	isInternalRegistration,
	language,
	headline,
	pardotFormHandlerTracking,
	privacyPolicy,
	salesforceId,
	submissionCallToAction,
}) => {
	const { t, i18n } = useTranslation(null, { i18n: i18n2Instance });
	const validationRules = new ValidationRules(t);
	const [state, setState] = useState<FormStateProps>({});
	const [isErrorShown, setIsErrorShown] = useState(false);

	const leadSourceOriginal = '';
	const visitorType: number = campaignVisitorType;

	const campaignRegistrationFormElement = useRef();
	const [serverErrors, setServerErrors] = useState([]);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [locale, setLocale] = useState(language);

	const setFieldValue = updatedField => {
		setState(prevState => {
			return { ...prevState, ...updatedField };
		});
	};

	useEffect(() => {
		const countryId = state.CountryID;
		if (!countryId) {
			return;
		}
		const newCountryKey = Object.keys(Country).find(key => {
			return Country[key].code === countryId;
		});
		const newLocale = Country[newCountryKey].locale || locales.English;
		setLocale(newLocale);
	}, [state.CountryID]);

	useEffect(() => {
		let CountryID = null;
		if (language === 'Spanish') {
			CountryID = Country.Spain.code;
		} else {
			// TODO: support other languages some day
			CountryID = Country.UnitedStates.code;
		}
		setFieldValue({ CountryID });
		setLocale(language);
	}, [language]);

	useEffect(() => {
		i18n.changeLanguage(locale);
	}, [locale]);

	useEffect(() => {
		if (salesforceId) {
			const queryString = qs.parse(root.location.search);
			queryString.cmpid = salesforceId;
			const urlPath = qs.stringify(queryString);
			root.history.replaceState(null, '', `?${urlPath}`);
		}
	}, [salesforceId]);

	useEffect(() => {
		sendGAPageview();
	}, []);

	const getSubmitData = (): FormStateProps => {
		return {
			...state,
			salesforceId,
			leadSourceOriginal,
			visitorType,
			isInternalRegistration,
			pardotFormUrl: pardotFormHandlerTracking,
		} as FormStateProps;
	};

	// TODO: check real API responses and update accordingly
	const handleRegistration = (response: RegistrationResponseProps) => {
		if (response.Success) {
			root.location.href = submissionCallToAction.url;
		} else {
			Object.keys(response.Errors).forEach(key => {
				setServerErrors([...serverErrors, response.Errors[key]]);
			});
		}
	};

	const onSubmitHandler = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		const hasInvalidInputs = [...campaignRegistrationFormElement.current.elements].some(
			input => input.dataset.isInvalid,
		);

		if (isSubmitting) {
			return;
		}
		if (hasInvalidInputs) {
			return setIsErrorShown(true);
		}

		setServerErrors([]);
		setIsSubmitting(true);

		try {
			const data = getSubmitData();
			registrationRepository
				.submit(data)
				.then(handleRegistration)
				.catch(errors => {
					console.info(errors);
				})
				.finally(() => {
					setIsSubmitting(false);
				});
		} catch (exception) {
			console.info(exception);
			setIsSubmitting(false);
		}
	};

	return (
		<FormContext.Provider value={{ validationRules, setFieldValue, isErrorShown, t, state }}>
			<Styles />
			<Form
				onSubmit={onSubmitHandler}
				className='campaign-registration-form'
				ref={campaignRegistrationFormElement}
			>
				<Wrapper>
					<div className='row center-xs'>
						<Headline size='medium'>{headline}</Headline>
					</div>
					<div className='row'>
						<FieldHalfSpace className='col-xs-12 col-sm'>
							<FirstName />
						</FieldHalfSpace>
						<FieldHalfSpace className='col-xs-12 col-sm'>
							<LastName />
						</FieldHalfSpace>
					</div>
					<div className='row'>
						<div className='col-xs-12'>
							<Email />
						</div>
						<div className='col-xs-12'>
							<Password />
						</div>
					</div>
					<CountryComponent />
					{serverErrors.length > 0 && (
						<div className='row'>
							<div className='col-xs-12'>
								{serverErrors &&
									serverErrors.map(error => <MessageField hasError>{error}</MessageField>)}
							</div>
						</div>
					)}
					<div className='row'>
						<div className='col-xs-12'>
							<CTAButton
								type='submit'
								buttonType='primary'
								disabled={isSubmitting}
								isSubmitting={isSubmitting}
							>
								{submissionCallToAction && submissionCallToAction.linkTitle}
							</CTAButton>
						</div>
					</div>
					<SignIn />
					{privacyPolicy.length > 0 && (
						<Paragraph
							className='center-xs'
							size='small'
							dangerouslySetInnerHTML={getSafeHtml(privacyPolicy)}
						/>
					)}
				</Wrapper>
			</Form>
		</FormContext.Provider>
	);
};
