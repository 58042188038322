import styled from 'styled-components';
import { spaceBase, spaceMedium, spaceLarge } from 'design-system/variables/spaces';

export const ErrorContainer = styled.section`
	max-width: 1000px;
	min-height: 100vh;
	margin: 0 auto;
	text-align: center;
`;

export const NotificationContainer = styled.div`
	margin: ${spaceBase} auto;
	width: fit-content;
`;

export const Spacer = styled.div`
	margin-top: ${spaceMedium};
`;

export const PvaSpacer = styled.div`
	margin-top: ${spaceLarge};
	display: inline-block;
`;
