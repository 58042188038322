import { object, string, number, array, boolean } from 'yup';

export const NpoValidationSchema = object().shape({
	NonPortfolioAccommodationName: string().required('An Accommodation name is required.').max(250, 'The Accommodation name field cannot contain more than 250 characters.'),
	propertyName: string().required('A Property name is required.').max(250, 'The Property name field cannot contain more than 250 characters.'),
	destination: string().required('A Destination is required.').max(250, 'The Destination field cannot contain more than 250 characters.'),
	propertyUrl: string().url('Please enter a valid URL (must include http://)').max(999, 'The Property URL field cannot contain more than 999 characters.').notRequired().nullable(),
	note: string().required('Notes are required.').max(999, 'The Notes field cannot contain more than 999 characters (with spaces).'),
	squareFeet: string().max(10, 'The Square Feet field cannot contain more than 10 characters.').notRequired(),
	dateRanges: array().of(
		object().shape({
			reservationTotalNeeded: boolean(),
			reservationTotal: number().when('reservationTotalNeeded', {
				is: true,
				then: number().required('A Reservation Total is required for these dates.').max(9999999.99, "A Reservation Total cannot exceed $9,999,999.99"),
			}),
		}),
	),
});