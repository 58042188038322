import React, { FC, useState, useEffect, memo, Fragment, forwardRef, RefObject, useMemo } from 'react';
import root from 'window-or-global';
import { ThemeProvider } from 'styled-components';
import debounce from 'lodash.debounce';
import smoothscroll from 'smoothscroll-polyfill';
import { useTouchScreenDevice } from 'components/hooks/useTouchScreenDevice';
import { Wrapper, ScrollContainer } from './ScrollDragContainer.styles';
import { Arrow } from './Arrow';
import { ScrollDragEnums } from './enums';

export const ScrollDragContainer: FC<{ ScrollDragContainerProps }> = memo(
	forwardRef(({ children }, containerRef: RefObject<HTMLInputElement>) => {
		const state = { scrolling: false, started: false, pressed: false };
		const coords = { scrollLeft: 0, clientX: 0 };
		const [, checkIfTouchDevice] = useTouchScreenDevice();
		const [arrowsVisibility, setArrowsVisibility] = useState({ left: false, right: false });

		const processStart = () => {
			state.started = true;
		};

		const processEnd = () => {
			state.scrolling = false;
			state.started = false;
			state.pressed = false;
		};

		const processClick = (e, clientX) => {
			if (e.type === 'touchstart') {
				coords.scrollLeft = containerRef.current.scrollLeft;
			} else {
				coords.clientX = clientX;
			}
			state.pressed = true;
		};
		const scrollLeft = useMemo(() => containerRef.current && containerRef.current.scrollLeft, [arrowsVisibility]);
		const scrollWidth = useMemo(() => containerRef.current && containerRef.current.scrollWidth, [arrowsVisibility]);
		const offsetWidth = useMemo(() => containerRef.current && containerRef.current.offsetWidth, [arrowsVisibility]);

		const defineArrowsVisibility = () => {
			const isLeftArrowVisible = Math.ceil(scrollLeft) > 8;
			const isRightArrowVisible = scrollWidth - scrollLeft > offsetWidth + 2;

			setArrowsVisibility({ left: isLeftArrowVisible, right: isRightArrowVisible });
		};

		const processMove = (e, newClientX) => {
			if (!state.started) {
				if (Math.abs(newClientX - coords.clientX) > ScrollDragEnums.ActivationDistance) {
					coords.clientX = newClientX;
					processStart();
				}
			} else {
				containerRef.current.scrollLeft -= newClientX - coords.clientX;
				coords.clientX = newClientX;
				coords.scrollLeft = containerRef.current.scrollLeft;

				defineArrowsVisibility();
			}
		};

		const onTouchMove = e => {
			if (state.pressed) {
				const touch = e.touches[0];
				if (touch) {
					processMove(e, touch.clientX);
				}
				e.preventDefault();
				e.stopPropagation();
			}
		};

		const processScroll = () => {
			defineArrowsVisibility();
			if (
				state.pressed &&
				!state.started &&
				Math.abs(containerRef.current.scrollLeft - coords.scrollLeft) > ScrollDragEnums.ActivationDistance
			) {
				processStart();
			}
		};

		const onEndScroll = () => {
			state.scrolling = false;
			if (!state.pressed && state.started) {
				processEnd();
			}
			defineArrowsVisibility();
		};

		const onDebouncedEndScroll = debounce(onEndScroll, ScrollDragEnums.SCROLL_END_DEBOUNCE);

		const onScroll = () => {
			if (containerRef.current.scrollLeft === coords.scrollLeft) {
				return;
			}
			if (!checkIfTouchDevice() && !state.started && !state.scrolling) {
				processStart();
			}
			state.scrolling = true;
			processScroll();
			onDebouncedEndScroll();
		};

		const onTouchStart = e => {
			if (state.scrolling) {
				state.pressed = true;
			} else {
				const touch = e.touches[0];
				processClick(e, touch.clientX);
			}
		};

		const onTouchEnd = () => {
			if (state.pressed) {
				if (state.started && !state.scrolling) {
					processEnd();
				} else {
					state.pressed = false;
				}
			}
		};

		const onMouseDown = e => {
			if (e.target.tagName === 'INPUT') {
				return;
			}
			processClick(e, e.clientX);
		};

		const onMouseMove = e => {
			if (state.pressed) {
				processMove(e, e.clientX);
				e.preventDefault();
			}
		};

		const onMouseUp = () => {
			if (state.pressed) {
				if (state.started) {
					processEnd();
				} else {
					state.pressed = false;
				}
			}
		};

		const onScrollLeft = () => {
			const currentScrollLeft = containerRef.current.scrollLeft;
			const left = currentScrollLeft - 90 > 0 ? currentScrollLeft - 90 : 0;

			containerRef.current.scrollTo({
				top: 0,
				left,
				behavior: 'smooth',
			});
		};

		const onScrollRight = () => {
			const currentScrollLeft = containerRef.current.scrollLeft;
			const width = containerRef.current.scrollWidth;
			const left = currentScrollLeft + 90 <= width ? currentScrollLeft + 90 : width;

			containerRef.current.scrollTo({
				top: 0,
				left,
				behavior: 'smooth',
			});
		};

		useEffect(() => {
			root.addEventListener('mouseup', onMouseUp);
			root.addEventListener('mousemove', onMouseMove);
			root.addEventListener('touchend', onTouchEnd);
			root.addEventListener('touchmove', onTouchMove, { passive: false });
			containerRef.current.addEventListener('touchstart', onTouchStart, { passive: false });
			containerRef.current.addEventListener('mousedown', onMouseDown, { passive: false });

			smoothscroll.polyfill();

			return () => {
				root.removeEventListener('mouseup', onMouseUp);
				root.removeEventListener('mousemove', onMouseMove);
				root.removeEventListener('touchend', onTouchEnd);
				root.removeEventListener('touchmove', onTouchMove);
				containerRef.current.removeEventListener('touchstart', onTouchStart);
				containerRef.current.removeEventListener('mousedown', onMouseDown);
			};
		}, []);

		useEffect(() => {
			defineArrowsVisibility();
		}, [children]);

		return (
			<ThemeProvider
				theme={{ isLeftArrowVisible: arrowsVisibility.left, isRightArrowVisible: arrowsVisibility.right }}
			>
				<Fragment>
					<Arrow onClick={onScrollLeft} direction='left' />
					<Wrapper>
						<ScrollContainer ref={containerRef} onScroll={onScroll} id='scroll-drag-container'>
							{children}
						</ScrollContainer>
					</Wrapper>
					<Arrow onClick={onScrollRight} direction='right' />
				</Fragment>
			</ThemeProvider>
		);
	}),
);
