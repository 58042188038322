import React from 'react';
import { useIsTabletOrDesktop } from 'components/hooks';
import { CarouselDotsProps } from './CarouselDots.types';
import { CarouselDotsContainer, CarouselDotsList, CarouselDotListItem, CarouselDotButton } from './CarouselDots.styles';

const getDotClassName = (selected, i) => {
	if (selected === i) {
		return 'selected';
	}
	if (selected === i - 1 || selected === i + 1) {
		return 'neighbor';
	}
	return '';
};

export const CarouselDots: React.FC<CarouselDotsProps> = ({
	className,
	count,
	onClick,
	theme,
	dotsToShow = [3, 5],
	selectedIndex = 0,
}) => {
	const [isTablet] = useIsTabletOrDesktop();

	const getDetectLoopClassName = i => {
		return i === 0 || i === count - 1 ? 'detect-loop' : '';
	};

	const getDotsToShow = () => (isTablet ? dotsToShow[1] : dotsToShow[0]);

	const setDotsListPosition = () => {
		if (count > getDotsToShow()) {
			const offset = Math.floor(getDotsToShow() / 2);
			const setContainerLeft = -Math.abs(count - offset * 2 - 1);
			const setContainerEnd = -Math.abs(selectedIndex - offset);

			if (selectedIndex < offset) {
				return 0;
			}
			if (selectedIndex >= count - offset) {
				return setContainerLeft;
			}
			return setContainerEnd;
		}
	};

	const dots = Array.from(Array(count)).map((_, i) => (
		<CarouselDotListItem key={`carousel-dot-${i}`} className={getDotClassName(selectedIndex, i)}>
			<CarouselDotButton tabIndex={-1} onClick={() => onClick(i)} />
		</CarouselDotListItem>
	));

	return (
		<CarouselDotsContainer
			className={className}
			dotsToShow={count > getDotsToShow() ? getDotsToShow() : count}
			theme={theme}
		>
			<CarouselDotsList className={getDetectLoopClassName(selectedIndex)} pull={setDotsListPosition()}>
				{dots}
			</CarouselDotsList>
		</CarouselDotsContainer>
	);
};
