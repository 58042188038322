import axios from 'axios';

import { defaultSearchResults } from './enums';

export const fetchProductData = async (baseUrl: string) => {
	const url = baseUrl ? `${baseUrl}/resource/GetProductDataJson` : './GetProductDataJson.json';
	const data = await axios.get(url).then(response => response.data);
	const searchResults = [...defaultSearchResults];

	searchResults[0].items = data.Destinations.map(destination => ({
		...destination,
		id: destination.Id,
		name: destination.Name,
		url: destination.Url,
		identificator: `${destination.Name}-${destination.Url}`
	}));

	searchResults[1].items = data.Properties.map(property => {
		const destination = data.Destinations.find(item => item.Id === property.DestinationID);

		return {
		...property,
		id: property.Id,
		name: property.Name,
		url: property.Url,
		destination: destination && destination.Name,
		identificator: `${property.Name}-${property.Url}`
	}});

	searchResults[2].items = data.Units.map(residence => {
		const destination = data.Destinations.find(item => item.Id === residence.Destination.Id);

		return {
		...residence,
		id: residence.Id,
		name: residence.Name,
		url: residence.Url,
		destination: destination && destination.Name,
		resort: residence.ResortName,
		identificator: `${residence.Name}-${residence.Url}`
	}});

	return searchResults;
}


