import React from 'react';
import { ComponentProps } from '../../interfaces/ComponentProps';

interface CmsComposerProps extends ComponentProps {
	componentMap: Record<string, (props) => JSX.Element>;
}

export const CmsComposer: React.FC<CmsComposerProps> = ({ componentName, componentMap, ...rest }) => {
	const ComposedComponent = componentMap[componentName];
	if (!ComposedComponent) {
		throw new Error(
			`${componentName} has no registered cms component. Register your components via the componentMap`,
		);
	}
	return <ComposedComponent {...rest} />;
};
