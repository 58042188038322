import styled from 'styled-components';
import React from 'react';
import { grayMedium } from '../../design-system/variables/colors';
import { blip } from '../../design-system/style-mixins/animations';
import { spaceBase } from '../../design-system/variables/spaces';

const LoaderContainer = styled.div`
	display: flex;
	justify-content: center;
	margin: ${spaceBase} 0;
	width: 100%;
`;

const Dot = styled.div`
	animation: ${blip} 1.6s infinite;
	background: ${grayMedium};
	border-radius: 100%;
	float: left;
	height: 8px;
	margin-right: 6px;
	width: 8px;

	&:first-child {
		animation-delay: 0.1s;
	}
	&:nth-child(2) {
		animation-delay: 0.3s;
	}
	&:nth-child(3) {
		animation-delay: 0.5s;
		animation-timing-function: ease;
		margin-right: 0;
	}
`;

/**
 * Loaders:
 * Used for progress & loading indicators.
 */
export const LoaderFlat = ({ ...props }) => (
	<LoaderContainer {...props}>
		<Dot />
		<Dot />
		<Dot />
	</LoaderContainer>
);
