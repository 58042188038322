import root from 'window-or-global';

export const storage = {
	get(key) {
		return root.localStorage.getItem(key);
	},
	set(key, value) {
		return root.localStorage.setItem(key, value);
	},
	clear() {
		root.localStorage.clear();
	},
};
