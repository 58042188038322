export const SET_INITIAL_DATA = 'SET_INITIAL_DATA';
export const UPDATE_DATE_FILTER = 'UPDATE_DATE_FILTER';
export const UPDATE_EXPERIENCES_FILTER = 'UPDATE_EXPERIENCES_FILTER';
export const SET_RESULTS = 'SET_RESULTS';
export const CLEAR_DATES = 'CLEAR_DATES';
export const CLEAR_EXPERIENCE_TYPES = 'CLEAR_EXPERIENCE_TYPES';
export const RESET_RESULTS = 'RESET_RESULTS';
export const UPDATE_SORT = 'UPDATE_SORT';
export const CLEAR_SORT = 'CLEAR_SORT';
export const UPDATE_LOCATION_FILTER = 'UPDATE_LOCATION_FILTER';
export const CLEAR_LOCATION_FILTER = 'CLEAR_LOCATION_FILTER';
export const SET_OFFSET = 'SET_OFFSET';

export const PAGE_SIZE = 12;
