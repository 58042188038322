import * as React from 'react';
import ReactDOM from 'react-dom';
import root from 'window-or-global';

import { useDropdown } from 'components/hooks';
import { CustomizedDropdownProps } from './Dropdown.types';
import { DropdownContainer, CustomizedDropdownMenu, CustomizedModalMenu } from './Dropdown.styles';

export const CustomizedDropdown: React.FC<CustomizedDropdownProps> = ({
	children,
	TriggerComponent,
	align,
	controlledOpen,
	onOpen = () => {},
	onClose = () => {},
	modal,
	modalStyles,
	parentSelector,
	overflowAuto,
	boundariesSelector,
	width,
}) => {
	const triggerElement = React.useRef(null);
	const dropdownBodyElement = React.useRef(null);

	const [isDropdownOpen, toggleDropdown] = useDropdown(dropdownBodyElement, triggerElement, boundariesSelector);
	const isClosed = (!isDropdownOpen && !controlledOpen) || (controlledOpen && !controlledOpen.open);
	const isOpen = isDropdownOpen || (controlledOpen && controlledOpen.open);

	const handleControlledOpen = () => {
		if (!controlledOpen) {
			toggleDropdown();
		} else {
			controlledOpen.action();
		}
	};

	const onClick = () => {
		if (isClosed && onOpen) {
			onOpen();
		}

		if (isOpen && onClose) {
			onClose();
		}

		handleControlledOpen();
	};

	const parent = parentSelector ? root.document.querySelector(parentSelector) : root.document.getElementById('root');

	React.useEffect(() => {
		isOpen ? onOpen && onOpen() : onClose && onClose();
	}, [isDropdownOpen, controlledOpen]);

	return (
		<DropdownContainer>
			{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
			<div ref={triggerElement} onClick={onClick}>
				<TriggerComponent isOpen={isOpen} />
			</div>
			{modal && parent ? (
				ReactDOM.createPortal(
					// @ts-ignore
					<CustomizedModalMenu
						ref={dropdownBodyElement}
						hidden={isClosed}
						modalStyles={modalStyles}
						overflowAuto={overflowAuto}
					>
						{children as JSX.Element[]}
					</CustomizedModalMenu>,
					parent,
				)
			) : (
				<div ref={dropdownBodyElement}>
					<CustomizedDropdownMenu shadow={4} align={align} width={width} hidden={!isOpen}>
						{children as JSX.Element[]}
					</CustomizedDropdownMenu>
				</div>
			)}
		</DropdownContainer>
	);
};
