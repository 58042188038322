import { useState, useEffect } from 'react';

export const useCheckedCount = data => {
	const [count, setCount] = useState(0);
	useEffect(() => {
		setCount(
			data.reduce((acc, curr) => {
				if (curr.checked) {
					return acc + 1;
				}
				return acc;
			}, 0),
		);
	}, [data]);

	return [count];
};
