import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { State } from '../context';
import { UPDATE_DATE_FILTER, CLEAR_DATES } from '../constants';

export const updateCheckInFilter = (id: string) => (
	dispatch: ThunkDispatch<State, undefined, Action>,
	getState: () => State,
) => {
	const dateFilter = getState().dates.map(date => ({
		...date,
		checked: id === date.id && !date.disabled ? !date.checked : date.checked,
	}));
	dispatch({ type: UPDATE_DATE_FILTER, payload: dateFilter });
};

export const clearDateFilter = () => (dispatch: ThunkDispatch<State, undefined, Action>, getState: () => State) => {
	const clearedDates = getState().dates.map(date => ({
		...date,
		checked: false,
	}));
	dispatch({ type: CLEAR_DATES, payload: clearedDates });
};
