import styled from 'styled-components';
import { InputStyles } from '../Form.styles';
import { spaceSmall } from '../../../design-system/variables/spaces';

export const TextAreaWrapper = styled.div``;

export const TextAreaField = styled.textarea<{ hasError }>`
	${InputStyles}
	&& {
		padding: ${spaceSmall};
		resize: vertical;
	}
`;
