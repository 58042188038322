import React from 'react';
import styled from 'styled-components';

import { generateArrayOfNumbers } from '../../../../services';

import { BulletsProps } from './BulletsProps';

import { Bullet } from './Bullet';
import { ActiveBullet } from './ActiveBullet';

const Wrapper = styled.div`
	display: flex;
	left: 16px;
	top: 16px;
	position: absolute;
	z-index: 10;
`;

export const Bullets: React.FunctionComponent<BulletsProps> = ({ activeIndex, total, navigate }) => (
	<Wrapper>
		{generateArrayOfNumbers(0, total).map(index =>
			activeIndex === index ? (
				<ActiveBullet key={index} />
			) : (
				<Bullet key={index} onClick={() => navigate(index)} />
			),
		)}
	</Wrapper>
);
