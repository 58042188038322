import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';

import { generateArrayOfNumbers } from '../../services';

import { MobileNavigationMenuProps } from './NavigationProps';

import { grayDark, white } from '../../design-system/variables/colors';

const closeStyles = css`
	span {
		&:nth-child(1) {
			width: 0%;
			opacity: 0;
		}
		&:nth-child(2) {
			transform: rotate(45deg);
			top: 40%;
			left: 0;
		}
		&:nth-child(3) {
			transform: rotate(-45deg);
			top: 40%;
			left: 0;
		}
		&:nth-child(4) {
			width: 0%;
			opacity: 0;
		}
	}
`;

const menuStyles = css`
	span {
		transform: rotate(0deg);
		left: 0px;

		&:nth-child(1) {
			top: 0px;
		}
		&:nth-child(2),
		:nth-child(3) {
			top: 7px;
		}
		&:nth-child(4) {
			top: 14px;
		}
	}
`;

const Wrapper = styled.nav`
	display: flex;
	order: 3;
`;

const MenuWrapper = styled.div`
	display: block;
	text-align: center;
`;

const HamburgerMenu = styled.div<{ isOpen: boolean; theme: { color: string }; isMounted: boolean }>`
	position: relative;
	width: 24px;
	height: 16px;
	left: 0;
	cursor: pointer;

	span {
		position: absolute;
		height: 2px;
		width: 100%;
		vertical-align: middle;
		background: ${white};
		border-radius: 8px;
		opacity: 1;
		transition: transform 0.25s ease-in-out;
		padding: 0px;

		${props =>
			props.theme.color === 'white' &&
			css`
				background: ${grayDark};
			`}
	}

	${props =>
		!props.isOpen &&
		css`
			${props.isMounted ? menuStyles : closeStyles}
		`}

	${props =>
		props.isOpen &&
		css`
			${props.isMounted ? closeStyles : menuStyles}
		`}
`;

export const MobileNavigationMenu: React.FC<MobileNavigationMenuProps> = ({ showModal, hideModal, isModalMode }) => {
	const toggle = () => (isModalMode ? hideModal() : showModal());
	const [isMounted, setMounted] = useState(false);
	useEffect(() => {
		setMounted(true);
	}, []);

	return (
		<Wrapper>
			<MenuWrapper onClick={toggle}>
				<HamburgerMenu isOpen={isModalMode} isMounted={isMounted}>
					{generateArrayOfNumbers(0, 4).map(index => (
						<span key={index} />
					))}
				</HamburgerMenu>
			</MenuWrapper>
		</Wrapper>
	);
};
