import React, { FC, memo } from 'react';
import { UnitParentCategory } from 'pages/CuratedTrips/enums';
import { renderLoaderOrContent } from 'components/Loader/LoaderSkeleton';
import Numeral from 'numeral';
import { ValueContainer, ItemContainer, ValueLabel } from '../ListCard.styles';

interface Props {
	isListAndIsDesktop: boolean;
	isPlaceholder: boolean;
	value: number;
	unitParentCategoryID: UnitParentCategory;
	showTripValue: boolean;
}
export const Value: FC<Props> = memo(({ isListAndIsDesktop, isPlaceholder, value, showTripValue }) => {
	return (
		<>
			{showTripValue && (
				<ValueContainer isList={isListAndIsDesktop}>
					<ItemContainer>
						{renderLoaderOrContent(isPlaceholder, Numeral(value).format('$0,0'), {
							width: '3.5em',
						})}
						<ValueLabel isList={isListAndIsDesktop}>
							{renderLoaderOrContent(isPlaceholder, 'Est. Value', { width: '5em' })}
						</ValueLabel>
					</ItemContainer>
				</ValueContainer>
			)}
		</>
	);
});
