import { Hero } from 'components/Hero';
import { CampaignRegistrationForm } from 'components/Registration/CampaignRegistrationForm';
import { ExpandableCard } from 'components/Cards/ExpandableCard/ExpandableCard';
import { ActionLink } from '../ActionLink';
import { AvisCarRental } from '../AvisCarRental';
import { Button } from '../Button';
import { ContactUsPhoneEmail } from '../ContactUsPhoneEmail';
import { CopyBlock } from '../CopyBlock';
import { ExperiencesCard } from '../Cards/ExperiencesCard';
import { Faq } from '../Faq';
import { FeatureList } from '../FeatureList';
import { IconCard } from '../IconCard';
import { IconListItem } from '../IconListItem';
import { ImageCard } from '../ImageCard';
import { ImageGallery } from '../ImageGallery';
import { InquiryCard } from '../InquiryCard';
import { MemberActivity } from '../MemberActivity';
import { MembershipCard } from '../MembershipCard';
import { PressReleaseListing } from '../PressReleaseListing';
import { RecommendedForYou } from '../Cards/RecommendedForYou';
import { TabListing } from '../TabListing';
import { TestimonialCard } from '../TestimonialCard';
import { TravelCard } from '../Cards/TravelCard';
import { VideoPlayer } from '../VideoPlayer';
import { StaticContentPage } from '../StaticContentPage/StaticContentPage';

// Mappings of Umbraco component names to React components
export const sectionComponentCmsMap: Record<string, (props) => JSX.Element> = {
	'avis-car-rental': AvisCarRental,
	'call-to-action-link': ActionLink,
	'campaign-registration-form': CampaignRegistrationForm,
	'contact-us-phone-email': ContactUsPhoneEmail,
	'copy-block': CopyBlock,
	'experiences-card': ExperiencesCard,
	'feature-list': FeatureList,
	'icon-card': IconCard,
	'icon-list-item': IconListItem,
	'image-card': ImageCard,
	'image-gallery': ImageGallery,
	'inquiry-card': InquiryCard,
	'member-activity': MemberActivity,
	'membership-card': MembershipCard,
	'press-release-listing': PressReleaseListing,
	'recommended-for-you': RecommendedForYou,
	'static-content-page': StaticContentPage,
	'tab-listing': TabListing,
	'testimonial-card': TestimonialCard,
	'travel-card': TravelCard,
	'video-player': VideoPlayer,
	'expandable-card': ExpandableCard,
	Button,
	button: Button,
	buttons: Button,
	CopyBlock,
	faq: Faq,
	hero: Hero,
};
