/* eslint-disable no-nested-ternary */
import React, { forwardRef, RefObject } from 'react';
import styled from 'styled-components';

import { LoaderFlat } from 'components/Loader/LoaderFlat';
import { SearchResultsSet } from 'components/SearchResultsSet/SearchResultsSet';
import { AccessibleMenu } from 'components/KeyboardAccessibility/components/AccessibleMenu';
import { SearchResultsProps } from '../TripFinder.types';
import { NoResults } from './NoResults';
import { SearchResultsWrapper } from '../TripFinder.styles';

const Loader = styled(props => <LoaderFlat {...props} />)`
	${props => props.theme.wrapperStyle}
	height: 400px;
	align-items: center;
`;

export const SearchResults = forwardRef(
	({ searchResults, isLoading, ...rest }: SearchResultsProps, ref: RefObject<HTMLInputElement>) => {
		return (
			<SearchResultsWrapper ref={ref}>
				{isLoading ? (
					<Loader loading={isLoading} />
				) : searchResults.length ? (
					<AccessibleMenu>
						<SearchResultsSet
							identificator='value'
							id='search-results'
							searchResults={searchResults}
							{...rest}
						/>
					</AccessibleMenu>
				) : (
					<NoResults />
				)}
			</SearchResultsWrapper>
		);
	},
);
