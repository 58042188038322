import React, { FC, useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import root from 'window-or-global';
import { DropdownButton } from 'components/DropdownButton';
import { black } from 'design-system/variables/colors';
import { useIsDesktop } from 'components/hooks';
import { Dropdown, DropdownSelect } from 'components/Dropdown';
import { SortContainer, SortOrder, SortOrderIcon } from './Sort.styles';
import { getSort, getSortOptions } from '../redux/selectors';
import { SortOption as SortOptionType } from '../types';
import { updateSort, getResults } from '../redux/actions';

import { SortFilters, SortDirection } from '../enums';

export const Sort: FC = () => {
	const [isOpen, setIsOpen] = useState(false);
	const dispatch = useDispatch();
	const currentSort = useSelector(state => getSort(state));
	const SortOptions = useSelector(state => getSortOptions(state));
	const isSortOrderShown = ![SortFilters.InspiratoOnly].includes(currentSort.key);
	const [isDesktop] = useIsDesktop(768);
	const toggleOpen = () => setIsOpen(!isOpen);
	const sortContainer = useRef(null);
	const handleClickOutside = event => {
		if (!(sortContainer && sortContainer.current && sortContainer.current.contains(event.target))) {
			setIsOpen(false);
		}
	};

	useEffect(() => {
		if (isOpen) {
			root.addEventListener('mousedown', handleClickOutside);
			root.addEventListener('touchend', handleClickOutside);
		} else {
			root.removeEventListener('mousedown', handleClickOutside);
			root.removeEventListener('touchend', handleClickOutside);
		}

		return () => {
			root.removeEventListener('mousedown', handleClickOutside);
			root.removeEventListener('touchend', handleClickOutside);
		};
	}, [isOpen]);

	const handleSort = (option: SortOptionType) => {
		setIsOpen(false);
		dispatch(updateSort({ ...option, direction: option.direction ? option.direction.default : 1 }));
		dispatch(getResults());
	};

	const toggleSortOrder = () => {
		dispatch(updateSort({ ...currentSort, direction: currentSort.direction === 1 ? 2 : 1 }));
		dispatch(getResults());
	};

	const sortDirectionLabel = () => {
		const currentSortRef = SortOptions.find(option => option.key === currentSort.key);
		let sortDirection = '';

		if (currentSort.direction === SortDirection.Ascending && currentSortRef.direction !== null) {
			sortDirection = `${currentSortRef.direction.low} to ${currentSortRef.direction.high}`;
		} else if (currentSort.direction === SortDirection.Descending && currentSortRef.direction !== null) {
			sortDirection = `${currentSortRef.direction.high} to ${currentSortRef.direction.low}`;
		}

		return sortDirection;
	};

	return (
		<SortContainer ref={sortContainer}>
			<Dropdown
				id='sort'
				title={`Sort By ${currentSort.value}`}
				controlledOpen={{ open: isOpen, action: toggleOpen }}
				onClose={() => setIsOpen(false)}
				onOpen={() => setIsOpen(true)}
				isLoading={false}
				width='250px'
				isDisabled={false}
				TriggerComponent={() => (
					<DropdownButton enableToggle isActive={isOpen} fill={black}>
						Sort By {currentSort.value}
					</DropdownButton>
				)}
			>
				<DropdownSelect items={[...SortOptions]} handleClick={handleSort} current={currentSort.key} />
			</Dropdown>
			{isSortOrderShown && (
				<SortOrder onClick={toggleSortOrder} disabled={false}>
					{isDesktop && <span>{sortDirectionLabel()}</span>}
					<SortOrderIcon iconName='sort' iconSize='sm' direction={currentSort.direction} />
				</SortOrder>
			)}
		</SortContainer>
	);
};
