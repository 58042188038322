import React, { FC, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useIsDesktop } from 'components/hooks';
import { CheckboxGroup } from 'components/Checkbox';
import { FilterButtonActions } from 'components/FilterButtonActions';
import { FilterAccordion } from 'components/Accordion';
import { Dropdown } from 'components/Dropdown';
import { getDateFilter } from '../../redux/selectors';
import { updateCheckInFilter, clearDateFilter, getResults } from '../../redux/actions';
import { useCheckedCount } from '../utils';
import { FilterContainer, FilterLabel } from '../Filters.styles';

export const DateFilter: FC = () => {
	const dispatch = useDispatch();
	const dates = useSelector(state => getDateFilter(state));
	const [selectedCount] = useCheckedCount(dates);
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [isDesktop] = useIsDesktop(768);

	const toggleOpen = () => setIsOpen(!isOpen);

	const handleDateClick = ({ id }) => {
		dispatch(updateCheckInFilter(id));
		if (!isDesktop) {
			dispatch(getResults());
		}
	};

	const handleDateClear = () => {
		dispatch(clearDateFilter());
		dispatch(getResults());
	};

	const handleDateApply = () => {
		dispatch(getResults());
		toggleOpen();
	};

	const Filter = (
		<FilterContainer>
			{isDesktop && <FilterLabel>Select from available months</FilterLabel>}
			<CheckboxGroup onCheckboxClick={handleDateClick} members={dates} />
		</FilterContainer>
	);

	const Footer = (
		<FilterButtonActions
			onClearClick={handleDateClear}
			onApplyClick={handleDateApply}
			isDisabled={selectedCount === 0}
		/>
	);

	if (!isDesktop) {
		return (
			<FilterAccordion title='Month' accordionId='DATE'>
				{Filter}
			</FilterAccordion>
		);
	}
	return (
		<Dropdown
			title={`Month ${selectedCount > 0 ? `(${selectedCount})` : ''}`}
			footer={Footer}
			onOpen={() => {}}
			controlledOpen={{ open: isOpen, action: toggleOpen }}
			onClose={() => dispatch(getResults())}
		>
			{Filter}
		</Dropdown>
	);
};
