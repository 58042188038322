import React from 'react';
import styled from 'styled-components';

import { white } from '../../../../design-system/variables/colors';

const StyledActiveBullet = styled.div`
	cursor: pointer;
	margin-right: 3px;
	width: 8px;
	height: 8px;
	background-color: ${white};
	border-radius: 50%;
	border: 1px solid ${white};
`;

export const ActiveBullet: React.FunctionComponent = () => <StyledActiveBullet />;
