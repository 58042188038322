import { IconButtonProps } from 'components/Icon/IconButton';
import { NextPrevButtonsWrapper, PrevButton, NextButton } from './NextPrevButtons.styles';

export type NextPrevButtonsProps = Omit<IconButtonProps, 'onClick'> & {
	buttonHeight?: string;
	buttonWidth?: string;
	disablePrevButton?: boolean;
	disableNextButton?: boolean;
	onPrev?: Function;
	onNext?: Function;
	orientation: NextPrevButtonsOrientation;
};

type NextPrevButtonsOrientation = 'vertical' | 'horizontal';

export const NextPrevButtons = ({
	onPrev,
	onNext,
	orientation,
	buttonHeight,
	buttonWidth,
	disablePrevButton = false,
	disableNextButton = false,
	...rest
}: NextPrevButtonsProps) => {
	const handleClickPrevious = () => typeof onPrev === 'function' && onPrev();
	const handleClickNext = () => typeof onNext === 'function' && onNext();
	return (
		<NextPrevButtonsWrapper orientation={orientation}>
			<PrevButton
				buttonHeight={buttonHeight}
				buttonWidth={buttonWidth}
				orientation={orientation}
				isDisabled={disablePrevButton}
				{...rest}
				onClick={handleClickPrevious}
			/>
			<NextButton
				buttonHeight={buttonHeight}
				buttonWidth={buttonWidth}
				orientation={orientation}
				isDisabled={disableNextButton}
				{...rest}
				onClick={handleClickNext}
			/>
		</NextPrevButtonsWrapper>
	);
};
