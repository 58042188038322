export enum Sort {
	LastUpdated,
	ProposalName,
	ProposalStatus,
}

export enum Filter {
	AllProposals,
	MyProposals,
	ArchivedProposals,
}

export enum SortDirection {
	None = 0,
	Ascending = 1,
	Descending = 2,
}

export enum Status {
	Draft = 0,
	Sent = 1,
}

export enum ProposalElementType {
	Accommodation = 0,
	Divider = 1,
	NonPortfolio = 2,
}

export enum MoveDirection {
	Up = -1,
	Down = 1,
}
