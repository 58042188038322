import moment from 'moment';
import { LocationType } from 'pages/CuratedTrips/enums';

export const enabledChildrenReducer = (arr, key) =>
	arr.reduce((acc, curr) => {
		curr.children.forEach(child => {
			acc.push(child[key]);
		});
		return acc;
	}, []);

const sortLocations = (a, b) => {
	const textA = a.label.toUpperCase();
	const textB = b.label.toUpperCase();
	return textA < textB ? -1 : textA > textB ? 1 : 0;
};

export const setResultsChildrenDropdown = (
	arr,
	enabledParents,
	enabledChildren,
	key,
	checkedChildren = [],
	checkedParents = [],
) =>
	arr.map(parent => ({
		...parent,
		disabled: !enabledParents.includes(parent[key]),
		checked: !!checkedParents.includes(parent[key]),
		children: [
			{
				labelFormatter: label => `All ${label}`,
				id: parent.id,
				disabled: !enabledParents.includes(parent[key]),
				checked: !!checkedParents.includes(parent[key]),
				label: parent.label,
				locationType: parent.locationType,
				childrenRegions: [
					...parent.children
						.filter(child => child.locationType === 'Location')
						.map(child => ({
							...child,
							disabled: !enabledChildren.includes(child[key]),
							checked: !!checkedChildren.includes(child[key]),
						})),
				],
			},
			...parent.children
				.filter(child => child.locationType === 'Location')
				.map(child => ({
					...child,
					disabled: !enabledChildren.includes(child[key]),
					checked: !!checkedChildren.includes(child[key]),
				})),
		],
	}));

export const initializeNode = arr =>
	arr.map(node => ({
		...node,
		checked: false,
		disabled: false,
	}));

export const initializeWithChildren = arr =>
	arr.map(parent => ({
		...parent,
		checked: false, // will go away with new select UI
		disabled: false, // will go away with new select UI
		children: initializeNode(parent.children),
	}));

export const initializeChildren = (arr, parent) => {
	const transformedArr = arr
		.filter(node => node.locationType === LocationType.Destination)
		.reduce((acc, curr) => (curr.parentId === parent.id ? [...acc, curr] : acc), [])
		.map(child => ({ ...child, checked: false, disabled: false }))
		.sort(sortLocations);

	if (!transformedArr[0].labelFormatter) {
		transformedArr.unshift({
			labelFormatter: label => `All ${label}`,
			id: parent.id,
			disabled: false,
			checked: false,
			label: parent.label,
			locationType: parent.locationType,
			childrenRegions: [...transformedArr],
		});
	}

	return transformedArr;
};

export const initializeRegions = arr =>
	arr
		.filter(node => node.locationType === LocationType.Region && node.label !== null)
		.map(parent => ({
			...parent,
			checked: false, // will go away with new select UI
			disabled: false, // will go away with new select UI
			children: initializeChildren(arr, parent),
		}));

export const initializeLocations = (arr, type) =>
	initializeNode(arr.filter(node => node.locationType === type && node.label !== null).sort(sortLocations));

export const isSelected = (id, selectedId) => id === selectedId;

export const initializeCheckInDates = datesList => {
	const enabledDates = datesList.reduce((enabledArr, month) => {
		return enabledArr.concat(
			month.children.reduce((acc, curr) => {
				acc.push(moment.utc(curr.label).format('ddd MMM DD YYYY'));
				return acc;
			}, []),
		);
	}, []);

	return {
		enabledDates,
	};
};
