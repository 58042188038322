import React from 'react';
import styled from 'styled-components';

import { Headline } from 'components/Headline';
import { Link } from 'components/Link';
import { Paragraph } from 'components/Paragraph';
import { spaceBase, spaceLarge, spaceMedium } from 'design-system/variables/spaces';
import { white } from 'design-system/variables/colors';

const Wrapper = styled.div`
	font-size: ${spaceBase};
	background-color: ${white};
	display: block;
	width: 100%;
	z-index: 9001;
	padding: ${spaceLarge} ${spaceMedium};
	text-align: center;
	box-sizing: border-box;
`;

const StyledLink = styled(props => <Link {...props} />)`
	:hover {
		text-decoration: none;
	}
`;

export const NoResultsFound: React.FC = () => (
	<Wrapper>
		<Headline size='small'>Not seeing the&nbsp;destination you are looking&nbsp;for?</Headline>
		<Paragraph>
			We can book any vacation, anywhere in the&nbsp;world. Please&nbsp;call us at
			<StyledLink href='tel:855815405' color='primary'>
			&nbsp;855.481.5405
			</StyledLink>
		</Paragraph>
	</Wrapper>
);
