import React, { FC } from 'react';
import { white } from 'design-system/variables/colors';
import { Icon } from 'components/';
import { ModalContent, ModalHeader, CloseButton, OfflineChatModalStyled, ModalBody } from './ChatModal.styles';

interface ModalProps {
	isOpen: boolean;
}

const Modal: FC<ModalProps> = ({ isOpen, children }) => {
	return isOpen ? <OfflineChatModalStyled isOpen={isOpen}>{children}</OfflineChatModalStyled> : null;
};

const ChatModal: FC<{ onClose: Function; isOpen: boolean }> = ({ onClose, isOpen, children }) => {
	return (
		<Modal isOpen={isOpen}>
			<ModalContent>
				<ModalHeader>
					<Icon iconName='logo-inspirato' fill={white} width='180px' />
					<CloseButton
						onClick={onClose}
						iconName='close'
						iconSize='lg'
						buttonType='containerless'
						fill={white}
					/>
				</ModalHeader>
				<ModalBody>{children}</ModalBody>
			</ModalContent>
		</Modal>
	);
};

export { ChatModal };
