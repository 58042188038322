import styled from 'styled-components';
import { boxShadow3 } from 'design-system/style-mixins/shadows-and-scrims';
import { white, black, paleBlue, primaryLinkColor } from 'design-system/variables/colors';
import { spaceBase, spaceMedium, spaceSmall, spaceLarge, spaceTiny } from 'design-system/variables/spaces';
import { TextButton } from 'components/TextButton';
import { textBase } from 'design-system/style-mixins/text';
import { md, sm, xsOnly } from 'design-system/media-queries';
import { transition250ms } from 'design-system/style-mixins/animations';
import { Icon } from 'components/Icon';
import { SortDirection } from '../enums';

export const SortFilterDropdown = styled.div<{ isOpen: boolean }>`
	${boxShadow3}

	align-items: flex-start;
	background-color: ${white};
	display: ${props => (props.isOpen ? 'flex' : 'none')};
	flex-basis: 100%;
	flex-direction: column;
	padding-bottom: ${spaceBase};
	padding-top: ${spaceBase};
	position: absolute;
	right: calc(50% - 89px);
	top: 48px;
	width: 275px;
	z-index: 10;

	${sm`
		right: -4px;
		padding-bottom: ${spaceMedium};
		padding-top: ${spaceMedium};
	`}

	${md`
		right: -12px;
		padding-bottom: ${spaceMedium};
		padding-top: ${spaceMedium};
	`}
`;

export const SortFilterItem = styled(props => <TextButton {...props} />)<{ enableToggle: boolean }>`
	${textBase}

	color: ${props => (props.enableToggle ? '#5991b2' : black)};
	line-height: 1rem;
	padding-bottom: ${spaceSmall};
	padding-left: ${spaceMedium};
	padding-right: ${spaceBase};
	padding-top: ${spaceSmall};
	text-align: left;
	width: 100%;

	&:hover {
		background-color: ${paleBlue};
	}

	${md`
		padding-left: ${spaceLarge};
	`}
`;

export const SortContainer = styled.div`
	position: relative;
	white-space: nowrap;
	display: flex;
	align-items: center;

	${xsOnly`
		flex-basis: auto;
		justify-content: flex-start;
	`}

	${sm`
		margin-right: 0;
	`}

	${md`
		margin-left: ${spaceBase};
		margin-right: ${spaceBase};
	`}
`;

export const SortButton = styled(props => <TextButton {...props} />)<{ enableToggle: boolean }>`
	padding: ${spaceTiny} 0;

	svg {
		margin-left: ${spaceTiny};
		margin-right: calc(${spaceTiny} * -1);
	}

	&:focus {
		text-decoration: none;
	}
`;

export const SortOrderIcon = styled(props => <Icon {...props} />)<{ direction: SortDirection }>`
	width: 20px;
	height: 16px;
	transform: scaleX(${props => (props.direction === SortDirection.Ascending ? -1 : 1)});
	${transition250ms}

	svg, svg:hover {
		fill: ${primaryLinkColor};
	}
`;

export const SortOrder = styled(TextButton)`
	${sm`
		margin-left: ${spaceSmall};

		span {
			margin-right: ${spaceTiny};
		}
	`}

	margin-left: 0;
`;
