export const displayNameMapper = {
	FirstName: 'First name',
	LastName: 'Last name',
	Email: 'Email',
	Password: 'Password',
	PostalCode: 'Postal code',
	Phone: 'Phone',
	ReferralFirstName: 'Referral first name',
	ReferralLastName: 'Referral last name',
};
