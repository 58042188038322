import React from 'react';
import {
	DetailCardWrapper,
	DetailCardTitle,
	DetailCardSubtitle,
	UnitDetails,
	Spec,
	Specs,
	UnitOfMeasure,
	TripPrice,
} from './DetailCard.styles';
import { DetailSection } from './DetailSection';
import { DetailCardProps } from './DetailCard.types';
import { TripTypeIR } from 'pages/RequestToBook/enums/TripType.enum';
import { unitDetails } from 'pages/TripProposals/views/ProposalManagementView/redux/reducers/unitDetails';

export const DetailCard: React.FunctionComponent<DetailCardProps> = ({
	accommodationName,
	propertyName,
	destinationName,
	specs,
	detailSections,
	coverImageUrl,
	imageServerUrl,
	displayPrice,
	inspiratoRewards,
}) => {
	let detailJSX;

	if (detailSections) {
		detailJSX = detailSections.map((current, index) => <DetailSection {...current} key={`detail-${index}`} />);
	}

	return (
		<DetailCardWrapper>
			{coverImageUrl && (
				<img
					src={`${imageServerUrl}${coverImageUrl}&width=384`}
					alt={`${accommodationName}, ${destinationName}`}
				/>
			)}
			<UnitDetails>
				<DetailCardTitle size='small'>{accommodationName}</DetailCardTitle>
				{propertyName && <DetailCardSubtitle>{propertyName}</DetailCardSubtitle>}
				<DetailCardSubtitle>{destinationName}</DetailCardSubtitle>
				{specs && (
					<Specs>
						{specs.bedrooms != 0 && (
							<Spec>
								<data value={specs.bedrooms}>{specs.bedrooms}</data>
								<UnitOfMeasure>bd</UnitOfMeasure>
							</Spec>
						)}
						{specs.bathrooms != 0 && (
							<Spec>
								<data value={specs.bathrooms}>{specs.bathrooms}</data>
								<UnitOfMeasure>ba</UnitOfMeasure>
							</Spec>
						)}
						{specs.occupancy != 0 && (
							<Spec>
								<data value={specs.occupancy}>{specs.occupancy}</data>
								<UnitOfMeasure>occ</UnitOfMeasure>
							</Spec>
						)}
						{specs.squareFeet != 0 && (
							<Spec>
								<data value={specs.squareFeet}>{specs.squareFeet}</data>
								<UnitOfMeasure>
									ft<sup>2</sup>
								</UnitOfMeasure>
							</Spec>
						)}
					</Specs>
				)}
				<>{detailJSX}</>

				{displayPrice && (
					<TripPrice key='trip-price' hasBorder={!inspiratoRewards} hasPadding={!inspiratoRewards}>
						<span>Total</span>{' '}
						<data value='0'>$0</data>
					</TripPrice>
				)}
			</UnitDetails>
		</DetailCardWrapper>
	);
};
