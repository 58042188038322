import React, { Fragment } from 'react';
import styled, { createGlobalStyle, css } from 'styled-components';

import { textHuge } from 'design-system/style-mixins/text';
import { md, sm, smOnly, xsSmOnly } from '../../design-system/media-queries';
import { shadowHover } from '../../design-system/style-mixins/animations';
import { white } from '../../design-system/variables/colors';
import { spaceBase, spaceHuge, spaceLarge, spaceMedium, spaceSmall } from '../../design-system/variables/spaces';

import { ActionLink } from '../ActionLink';
import { Headline } from '../Headline';
import { Title } from '../Title';

import { IconListItem } from '../IconListItem';
import { MembershipCardProps } from './MembershipCardProps';

const Wrapper = styled.div<{ backgroundColor?: string }>`
	&& {
		${shadowHover}
		background-color: ${white};
		margin-bottom: ${spaceBase};
		margin-left: auto;
		margin-right: auto;
		max-width: 320px;
		overflow: hidden;
		padding-bottom: ${spaceMedium};
		padding-left: ${spaceSmall};
		padding-right: ${spaceSmall};
		padding-top: ${spaceLarge};
		justify-content: center;
		text-align: center;
	}

	${props =>
		props.backgroundColor &&
		css`
			background-color: ${props.backgroundColor};
		`}


	${sm`
		margin-bottom: 0;
		max-width: 100%;
	`}

	${md`
		padding-bottom: ${spaceLarge};
		padding-left: ${spaceBase};
		padding-right: ${spaceBase};
	`}
`;

const ProductTitle = styled(Headline)`
	display: inline-block;
	padding-bottom: ${spaceSmall};

	${xsSmOnly`
		${textHuge}
		line-height: 42px;
	`}

	${md`
		padding-bottom: ${spaceLarge};
	`}
`;

const ProductCost = styled(props => <Title {...props} />)`
	display: flex;
	flex-direction: row;

	&:before,
	&:after {
		background-color: rgba(197, 197, 197, 0.5);
		content: '\xa0';
		display: inline;
		flex-grow: 1;
		height: 1px;
		margin-left: ${spaceBase};
		margin-right: ${spaceBase};
		position: relative;
		top: ${spaceBase};
	}
`;

const StyledActionLink = styled(props => <ActionLink {...props} />)`
	margin-top: ${spaceMedium};
	width: calc(100% - ${spaceLarge});

	${md`
		width: calc(100% - ${spaceHuge});
	`}
`;

const StyledIconListItem = styled(props => <IconListItem {...props} />)`
	padding-bottom: ${spaceSmall};
	padding-right: ${spaceSmall};
	padding-top: ${spaceSmall};

	${smOnly`
		padding-right: 0;
	`}

	${md`
		padding-bottom: ${spaceBase};
		padding-right: ${spaceBase};
		padding-top: ${spaceBase};
	`}
`;

const GlobalStyles = createGlobalStyle`
	${xsSmOnly`
		.section__components-per-row--3-of-3 ${Wrapper} {
				margin-bottom: 0
			}
		}
	`}
`;

export const MembershipCard: React.FC<MembershipCardProps> = ({
	backgroundColor,
	callToAction,
	productCost,
	productSpecs,
	productTitle,
}) => (
	<Fragment>
		<GlobalStyles />
		<Wrapper backgroundColor={backgroundColor}>
			<ProductTitle size='big'>{productTitle}</ProductTitle>
			<ProductCost size='medium'>{productCost}</ProductCost>
			{productSpecs.map(spec => (
				// @ts-ignore : ...spec includes missed attributes
				<StyledIconListItem {...spec} key={spec.listItem} />
			))}
			{callToAction.canDisplayAsTextLink && (
				<div className='row'>
					<div className='col-xs-12'>
						<StyledActionLink {...callToAction} />
					</div>
				</div>
			)}
		</Wrapper>
	</Fragment>
);
