import root from 'window-or-global';

export function isDocumentElement(el: Element) {
	return [root.document.documentElement, root.document.body, root].indexOf(el) > -1;
}

export function scrollTo(el: Element, left: number = 0, top: number = 0): void {
	if (isDocumentElement(el)) {
		root.scrollTo(left, top);
		return;
	}

	el.scrollTop = top;
	el.scrollLeft = left;
}

export function scrollIntoViewVertical(menuEl: HTMLElement, focused: HTMLElement): void {
	if (!menuEl || !focused) {
		return;
	}
	const isGroup = focused.hasAttribute('role') && focused.getAttribute('role') === 'group';
	const focusedEl = isGroup ? focused.firstElementChild : focused;

	const menuRect = menuEl.getBoundingClientRect();
	const focusedRect = focusedEl.getBoundingClientRect();
	const overScroll = focusedEl.offsetHeight / 3;

	if (focusedRect.bottom + overScroll > menuRect.bottom) {
		scrollTo(
			menuEl,
			0,
			Math.min(
				focusedEl.offsetTop + focusedEl.clientHeight - menuEl.offsetHeight + overScroll,
				menuEl.scrollHeight,
			),
		);
	} else if (focusedRect.top - overScroll < menuRect.top) {
		scrollTo(menuEl, 0, Math.max(focusedEl.offsetTop - overScroll, 0));
	}
}

export function scrollIntoViewHorizontal(menuEl: HTMLElement, focusedEl: HTMLElement): void {
	if (!menuEl || !focusedEl) {
		return;
	}

	const menuRect = menuEl.getBoundingClientRect();
	const focusedRect = focusedEl.getBoundingClientRect();
	const overScroll = focusedEl.offsetWidth;

	if (focusedRect.right + overScroll > menuRect.right) {
		scrollTo(
			menuEl,
			Math.min(
				focusedEl.offsetLeft + focusedEl.clientWidth - menuEl.offsetWidth + overScroll,
				menuEl.scrollWidth,
			),
			0,
		);
	} else if (focusedRect.left - overScroll < menuRect.left) {
		scrollTo(menuEl, Math.max(focusedEl.offsetLeft - overScroll, 0), 0);
	}
}

export function getBoundingClientObj(element: HTMLElement) {
	const rect = element.getBoundingClientRect();

	return {
		bottom: rect.bottom,
		height: rect.height,
		left: rect.left,
		right: rect.right,
		top: rect.top,
		width: rect.width,
	};
}
