import styled from 'styled-components';
import { black,	error, white } from 'design-system/variables/colors';
import { spaceLarge } from 'design-system/variables/spaces';

export const RichTextEditorWrapper = styled.div<{ hasError }>`
	color: ${black};
	border: 1px solid ${props => (props.hasError ? error : white)};
	ul {
		list-style: disc outside none;
		margin: initial;
		padding-left: ${spaceLarge};
	}
`;
