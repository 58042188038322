import React, { useMemo } from 'react';
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';
import root from 'window-or-global';
import { MapProps } from './MapContainer.types';
import { AspectRatio } from '../AspectRatio';
import { MapDetails, MapWrapper } from './MapContainer.styles';

const MapComponent: React.FC<MapProps> = ({
	latitude,
	longitude,
	name,
	id,
	mapCopy,
	mapTitle,
	mapZoomLevel,
	google,
}) => {
	const lat = parseFloat(latitude);
	const lng = parseFloat(longitude);

	const markerData = useMemo(
		() => ({
			position: new google.maps.LatLng(lat, lng),
			icon: new google.maps.MarkerImage(`${root.STORYBOOK_ENV ? '' : '/react-components'}/images/pin-1x.png`),
		}),
		[latitude, longitude],
	);

	const createDetailsMarkup = () => {
		return { __html: mapCopy };
	};

	return (
		<MapWrapper>
			<AspectRatio ratio={4 / 3}>
				<Map
					title={mapTitle}
					google={google}
					initialCenter={{ lat, lng }}
					streetViewControl
					zoom={mapZoomLevel || 12}
				>
					<Marker
						map={google.maps}
						id={id}
						name={name}
						position={markerData.position}
						icon={markerData.icon}
					/>
				</Map>
			</AspectRatio>
			{mapCopy && <MapDetails dangerouslySetInnerHTML={createDetailsMarkup()}></MapDetails>}
		</MapWrapper>
	);
};

export const MapContainer = GoogleApiWrapper({
	apiKey: 'AIzaSyCDG0R_OcTxjaioXCebaHGwgoBOraezj34',
})(MapComponent);
