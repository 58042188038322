import React from 'react';
import styled from 'styled-components';

import { StaticContentPageProps } from './StaticContentPageProps';

import { ComparisonSection } from './ComparisonSection';

const Wrapper = styled.div`
	margin-top: -1px;
`;

export const StaticContentPage: React.FC<StaticContentPageProps> = () => {
	return (
		<Wrapper>
			<ComparisonSection />
		</Wrapper>
	);
};
