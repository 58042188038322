import React, { FC, memo } from 'react';

import { AccessibleSelectedOption } from 'components/KeyboardAccessibility';
import { DestinationPill, DestinationPillWrapper } from './DestinationAutocomplete.styles';
import { DestinationTagProps } from './DestinationAutocompleteProps';

export const DestinationTag: FC<DestinationTagProps> = memo(({ onRemove, destination: { label }, isDisabled }) => {
	const handleIconClick = () => {
		if (!isDisabled) {
			onRemove();
		}
	};
	return (
		<AccessibleSelectedOption label={label}>
			<DestinationPillWrapper title={label}>
				<DestinationPill
					withCloseIcon
					size='small'
					color='lightGray'
					onIconClick={handleIconClick}
					isDisabled={isDisabled}
				>
					{label}
				</DestinationPill>
			</DestinationPillWrapper>
		</AccessibleSelectedOption>
	);
});
