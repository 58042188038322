import React from 'react';
import styled from 'styled-components';

import { spaceBig, spaceHuge } from 'design-system/variables/spaces';
import { Headline } from 'components/Headline/Headline';
import { Paragraph } from 'components/Paragraph/Paragraph';

const StyledParagraph = styled(props => <Paragraph {...props} />)`
	margin: 0;
`;

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-bottom: ${spaceHuge};
	padding-top: ${spaceBig};

	${props => props.theme.wrapperStyle}
`;

export const NoResults: React.FC = () => (
	<Wrapper>
		<Headline size='small'>Sorry, no results were found.</Headline>
		<StyledParagraph>Try a less specific search.</StyledParagraph>
	</Wrapper>
);
