import React from 'react';
import styled, { css } from 'styled-components';

// import { Subtext, Icon, Label } from 'components';
import { CallToActionProps } from 'components/ActionLink/CallToActionProps';
import { spaceTiny, spaceBase, spaceMedium, spaceLarge } from 'design-system/variables/spaces';
import { white } from 'design-system/variables/colors';
import { Subtext } from 'components/Subtext';
import { Label } from 'components/Label';
import { Icon } from 'components/Icon';
import { textShadow, svgShadow } from 'design-system/style-mixins/shadows-and-scrims';
import { sm } from 'design-system/media-queries';
import { Link } from 'components/Link';
import { textSmall, fontSans } from 'design-system/style-mixins/text';

export interface ImageCaptionsProps {
	title?: string;
	caption: string;
	url: CallToActionProps;
}

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	position: initial;
	background: transparent;
	z-index: 100000;
	align-items: flex-end;
	margin-top: ${spaceMedium};
	margin-right: -${spaceBase};
	margin-bottom: -${spaceBase};

	${sm`
		position: absolute;
		right: ${spaceLarge};
		bottom: 22px;
		margin: 0;
	`}
`;

const InnerWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	width: 50%;
	text-align: right;

	${sm`
		text-align: initial;
		width: auto;
	`}

	a:before {
		content: '';
		position: absolute;
		bottom: 0;
		right: 0;
		width: 100%;
		height: 100%;
	}
`;

const whiteTextStyles = css`
	color: ${white};
	${textShadow}
`;

const Caption = styled(props => <Subtext {...props} />)`
	${whiteTextStyles}
	margin-bottom: ${spaceTiny};
`;

const Action = styled.div`
	display: flex;
	align-items: center;
	display: flex;
	margin-right: -2px;
	position: relative;

	${sm`
		position: initial;
	`}
`;

const CaptionLink = styled(Link)`
	${textShadow}
	${textSmall}
	${fontSans}
	line-height: 15px;
	margin-right: ${spaceTiny};
`;
const ShadowIcon = styled(props => <Icon {...props} />)`
	${svgShadow};
	fill: ${white};
	z-index: -1;
`;

export const ImageCaptions: React.FC<ImageCaptionsProps> = ({ caption, url }) => (
	<Wrapper>
		<InnerWrapper>
			<Caption>{caption}</Caption>
			{url && url.url && url.linkTitle && (
				<Action>
					<CaptionLink color='white' href={url.url} target={url.newWindow ? '_blank' : '_self'}>
						{url.linkTitle}
					</CaptionLink>
					<ShadowIcon iconName='arrow-long' iconSize='sm' />
				</Action>
			)}
		</InnerWrapper>
	</Wrapper>
);
