import Cookies from 'js-cookie';
import axios from 'axios';
import { AUTH_ERROR_SET, REQUEST_IN_PROGRESS, REQUEST_COMPLETE } from './redux/constants';
import { UnitDetails } from '../interfaces';

export const getErrorMessages = (error, prop) => {
	const errorResponse = error.response.data;
	let errorMessage = errorResponse.friendlyMessage || '';

	if (errorResponse.errors && prop in errorResponse.errors) {
		errorMessage = errorResponse.errors[prop].join(' ');
	}

	return errorMessage;
};

export const setupAxiosInterceptors = (dispatch) => {
	axios.interceptors.request.use(req => {
		dispatch({ type: REQUEST_IN_PROGRESS });

		const accessToken = Cookies.get('ServiceAPIAccessToken');
		req.headers.authorization = `Bearer ${accessToken}`

		return req;
	}, error => {
		dispatch({type: REQUEST_COMPLETE});
		return Promise.reject(error);
	});

	/** Intercept any unauthorized request.**/
	const UNAUTHORIZED = 401;
	axios.interceptors.response.use(
		response => {
			dispatch({type: REQUEST_COMPLETE});
			return response;
		},
		error => {
			if (error.response) {
				const { status } = error.response;

				if (status === UNAUTHORIZED) {
					dispatch({ type: AUTH_ERROR_SET });
				}
			}

			dispatch({type: REQUEST_COMPLETE});

			return Promise.reject(error);
		}
	);
}


export function MapNpoToUnitDetails(bathrooms: number, bedrooms: number, destination: string,
	guests: number, nonPortfolioAccommodationName: string, propertyName: string,
	propertyUrl: string, squareFeet: number, note: string)
	{
		let unitDetails: UnitDetails =
		{
			bathrooms: bathrooms == 0 ? "N/A" : String(bathrooms),
			bedrooms: bedrooms == 0 ? "N/A" : String(bedrooms),
			locationName: destination,
			propertyName: propertyName,
			occupancy: guests == 0 ? "N/A" : String(guests),
			unitName: nonPortfolioAccommodationName,
			unitUrl: propertyUrl,
			squareFeet: squareFeet == 0 ?  "N/A" : String(squareFeet),
			isNew: false,
			rid: "",
			unitId: 0,
			note: note
		};
		return unitDetails;
	}
