import { WizardProvider, WizardStep, WizardContextInit, useWizard } from 'components/hooks/useWizard';
import { FC, ReactNode } from 'react';

export const Wizard: FC<{ steps: WizardStep[]; children: ReactNode }> = ({ steps, children }) => {
	const [totalSteps, currentStep, changeStep, nextStep, allSteps, wizardComplete] = useWizard(steps);

	return (
		<WizardProvider
			value={WizardContextInit(totalSteps, currentStep, changeStep, nextStep, allSteps, wizardComplete)}
		>
			{children}
		</WizardProvider>
	);
};
