import React, { memo } from 'react';
import styled from 'styled-components';
import { md, xsSmOnly } from '../../../design-system/media-queries';
import { boxShadow3 } from '../../../design-system/style-mixins/shadows-and-scrims';
import { dividerGrayLight, textColorLight, white } from '../../../design-system/variables/colors';
import { spaceBase, spaceHuge, spaceMedium, spaceSmall } from '../../../design-system/variables/spaces';
import { AllCaps } from '../../AllCaps';
import { NavigationLinkProps } from '../NavigationLinkProps';
import { Column } from './Column';

export interface Category {
	title?: string;
	links: NavigationLinkProps[];
}

interface MultiColumnNavigationProps {
	categories: Category[];
}

const Wrapper = styled.ul`
	list-style: none;
	z-index: 9000;
	flex-direction: column;

	${md`
		visibility: hidden;
		background: ${white};
		flex-direction: row;
		justify-content: center;
		left: 0;
		position: absolute;
		right: 0;
		top: 81px;
		${boxShadow3}
		width: 100vw;
	`}
	&:hover {
		cursor: initial;
	}
`;

const ColumnContainer = styled.li`
	display: flex;
	flex-direction: column;

	${xsSmOnly`
		&:first-child {
			padding-top: ${spaceBase};
		}

		padding-bottom: ${spaceSmall};
		padding-top: ${spaceSmall};

		&:last-child {
			padding-bottom: 0;
		}
	`}

	${md`
		&:first-child {
			border-right: ${dividerGrayLight};
			padding-left: 0;
			padding-right: ${spaceHuge};
		}

		margin-bottom: ${spaceHuge};
		margin-top: ${spaceHuge};
		padding-bottom: 0;
		padding-left: ${spaceHuge};
		padding-top: 0;
	`}
`;

const ColumnTitle = styled(props => <AllCaps {...props} />)`
	${textColorLight}
	padding: ${spaceBase} 0;
	white-space: nowrap;

	${md`
		padding: 0 0 ${spaceMedium} 0;
	`}
`;

export const MultiColumnsNavigation: React.FC<MultiColumnNavigationProps> = memo(({ categories }) => {
	const NavigationComponent = () => (
		<Wrapper className='submenu'>
			{categories.map(({ title, links }) => (
				<ColumnContainer key={title}>
					<ColumnTitle size='small'>{title}</ColumnTitle>
					<Column links={links} />
				</ColumnContainer>
			))}
		</Wrapper>
	);

	return <NavigationComponent />;
});
