import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { spaceSmall } from 'design-system/variables/spaces';
import { fontSans, textSmall } from 'design-system/style-mixins/text';
import { xsOnly } from 'design-system/media-queries';
import { Icon, Tooltip } from 'components';
import { getSafeHtml } from '../../../services/Helpers';
import { grayDark, grayMedium } from '../../../design-system/variables/colors';

const HelpIcon = styled.div<{displace}>`
	vertical-align: bottom;
	margin-left: ${spaceSmall};
	margin-top: 2px;
	display: inline-flex;
	${props => (props.displace ? 'left: 155px': '')};
	letter-spacing: 0;
	line-height: 18px;
	position: absolute;


	svg {
		fill: ${grayMedium};
	}
`;

const IconWrapper = styled(props => <Icon iconName='help' iconSize='sm' {...props} />)`
	position: absolute;
`;

const HelpTip = styled(props => <Tooltip {...props} />)`
	${textSmall}
	${fontSans}
	width: 320px;
	font-weight: 500;
	position: absolute;
	top: 25px;
	margin-top: 0 !important;
	margin-left: -8px;
	white-space: pre-line;
	pointer-events: none;
	color: ${grayDark} !important;

	${xsOnly`
		right: 0;
	`};
`;

export const InputTooltip: FC<{ message: string, displace?: boolean }> = ({ message, displace }) => {
	const [display, setDisplay] = useState(false);
	return (
		<HelpIcon displace={displace}>
			<IconWrapper
				onMouseEnter={() => setDisplay(true)}
				onMouseLeave={() => setDisplay(false)}
				onClick={() => setDisplay(!display)}
			/>
			<HelpTip theme='light' arrowPosition='topLeft' isOpen={display} dangerouslySetInnerHTML={getSafeHtml(message)}>
			</HelpTip>
		</HelpIcon>
	);
};
