import React, { FC, ReactElement } from 'react';
import styled from 'styled-components';
import { Link, Title, Paragraph } from 'components';
import { spaceBase, spaceSmall } from 'design-system/variables/spaces';
import { textColorMedium, white } from 'design-system/variables/colors';
import { textSmall } from 'design-system/style-mixins/text';

export const PolicyBlock = styled((props) => <Paragraph {...props} size="small" />)`
	margin: -2px 0;
`;

export const Policy = styled.article`
	background: ${white};
	max-width: 385px;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);

	${textColorMedium}

	align-items: left;
	display: flex;
	flex-direction: column;
	text-align: left;
	width: 100%;
	padding: ${spaceBase};

	p {
		${textSmall};
		margin: ${spaceSmall} 0 ${spaceBase};

		&:last-of-type {
			margin-bottom: 0;
		}
	}

	a {
		margin-top: 3px;
	}
`;

interface CancellationPolicyProps {
	display: boolean;
	children?: ReactElement;
}

export const CancellationPolicy: FC<CancellationPolicyProps> = ({ display, children }) => (
	<Policy>
		<Title size="small">Cancellation Policy</Title>
		{children ?? (
			<PolicyBlock size="small">
				{display ? (
					<>
						If you cancel before your trip is fully reserved, that trip is not eligible for Pass Days
						refunded. If you cancel after your trip is fully reserved, the Pass Days refunded will vary
						depending on proximity to check-in date.{' '}
					</>
				) : (
					<>
						For no-shows or Inspirato Pass Active Reservations canceled within 72 hours of planned arrival,
						a $250 fee will be charged for each reserved day.{' '}
					</>
				)}
				<br />
				<br />
				View{' '}
				<Link
					href="/info/pass-cancellation-policy/#pass"
					color="primary"
					target="_blank"
					rel="noreferer noopener"
				>
					Cancellation policy
				</Link>{' '}
				for Residences and Hotels. <br />
				<br />
				View{' '}
				<Link
					href="/info/pass-cancellation-policy/#otherpass"
					color="primary"
					target="_blank"
					rel="noreferer noopener"
				>
					Cancellation policy
				</Link>{' '}
				for Cruises, Experiences, and other offerings.
				<br />
			</PolicyBlock>
		)}
	</Policy>
);
