import { useState, createContext, useContext, ReactNode } from 'react';

export interface WizardStep {
	stepOrder: number;
	stepName: string;
	stepCompleted: () => boolean;
	stepComponent: ReactNode;
}

export const useWizard = (steps: WizardStep[]) => {
	const [stepIndex, setStepIndex] = useState(1);
	const currentStep = steps.find(step => step.stepOrder === stepIndex);

	const totalSteps = steps.length;
	const changeStep = newStep => newStep <= totalSteps && setStepIndex(newStep);
	const nextStep = () => changeStep(currentStep.stepOrder + 1);
	const allSteps = steps;

	const wizardComplete = steps.reduce((acc, current) => {
		return acc && current.stepCompleted();
	}, true);

	return [totalSteps, currentStep, changeStep, nextStep, allSteps, wizardComplete];
};

interface WizardContextType {
	totalSteps: number;
	currentStep: WizardStep;
	changeStep: () => void;
	nextStep: () => void;
	allSteps: WizardStep[];
	wizardComplete: boolean;
}

export const WizardContextInit = (totalSteps?, currentStep?, changeStep?, nextStep?, allSteps?, wizardComplete?) => {
	return {
		totalSteps,
		currentStep,
		changeStep,
		nextStep,
		allSteps,
		wizardComplete,
	};
};

const WizardContext = createContext<WizardContextType>(WizardContextInit());

export const WizardProvider = WizardContext.Provider;
export const WizardConsumer = WizardContext.Consumer;

export const useWizardContext = () => {
	const context = useContext<WizardContextType>(WizardContext);

	return context;
};
