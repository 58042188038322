export interface ImageViewModel {
	alternativeText: string;
	basePath: string;
	caption: string;
	compression: number;
	fullPath: string;
	fullPathLowQuality: string;
	fullPathTinyOptimized: string;
	hasDimensions: boolean;
	hasImage: boolean;
	hasTitle: boolean;
	height: number;
	imageGenPath: string;
	issvg: boolean;
	title: string;
	width: number;
}

export function createImageViewModel(src: string): ImageViewModel {
	return {
		alternativeText: '',
		basePath: src,
		caption: '',
		compression: 100,
		fullPath: `//cms.inspirato.com/ImageGen.ashx?image=${src}`,
		fullPathLowQuality: `//cms.inspirato.com/ImageGen.ashx?image=${src}`,
		fullPathTinyOptimized: `//cms.inspirato.com/ImageGen.ashx?image=${src}`,
		hasDimensions: true,
		hasImage: true,
		hasTitle: true,
		height: 900,
		imageGenPath: `//cms.inspirato.com/ImageGen.ashx`,
		issvg: false,
		title: '',
		width: 1600,
	};
}
