import axios from 'axios';
import configuration from 'pages/ResponsivePage/environmentConfig';

export interface CarRentalRewardProps {
	carRentalRewardID: number;
	accountUserID: number;
	rewardNumber: string;
	emailAddress: string;
	firstName: string;
	lastName: string;
}

export const fetchAvisWizardNumber = (): Promise<CarRentalRewardProps> => {
	return axios.get(`${configuration.baseApiUrl}/CarRentalReward/GetCarRentalReward/`).then(response => response.data);
};
