import React, { FC } from 'react';
import memoizedPlural from 'utils/memoizedPlural';
import { CheckboxGroup } from 'components/Checkbox';
import { Label } from 'components/Label';
import { SearchSection } from './DestinationAutocompleteProps';
import { DestinationsDropdownHeader } from './DestinationAutocomplete.styles';

interface DestinationsSectionProps extends SearchSection {
	selectResult: Function;
	searchWord: string;
}

export const DestinationsSection: FC<DestinationsSectionProps> = ({
	sectionLabel,
	data = [],
	selectResult,
	searchWord,
}) => {
	return data.length ? (
		<div>
			<DestinationsDropdownHeader>
				<Label size='medium'>
					{data.length} {data.length > 1 ? memoizedPlural(sectionLabel) : sectionLabel}
				</Label>
			</DestinationsDropdownHeader>

			<CheckboxGroup members={data} searchWord={searchWord} onCheckboxClick={selectResult} />
		</div>
	) : null;
};
