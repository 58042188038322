import * as React from 'react';
import styled from 'styled-components';

import { ImageViewModelProps } from 'interfaces/ImageViewModelProps';
import { ResponsiveImage } from '../ResponsiveImage';

const Wrapper = styled.div`
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	bottom: 0;
	left: 0;
	overflow: hidden;
	position: absolute;
	right: 0;
	top: 0;
`;

export const BackgroundImage: React.FunctionComponent<ImageViewModelProps> = (props: ImageViewModelProps) => {
	const jsx =
		(props && props.hasImage && (
			<Wrapper>
				<ResponsiveImage url={props.fullPath} adjustHeightForMobile />
			</Wrapper>
		)) ||
		null;
	return jsx;
};
