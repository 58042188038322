import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { getErrorMessages } from '../../../../shared/utils';
import { ACCOMMODATION_DATE_RANGE, PROPOSAL_ELEMENTS_UPDATED } from '../constants';
import { getTripProposal, deleteAccommodationDateRange } from '../../../../services/tripRecommendationsApiService';

import { State } from '../context';
import { clearFieldError, setFieldError } from './errors';

export const deleteDateRange = (dateRangeId: number) => (
	dispatch: ThunkDispatch<State, undefined, Action>,
	getState: () => State,
) => {
	const { metadata } = getState();
	const fieldName = `${ACCOMMODATION_DATE_RANGE}_${dateRangeId}`;

	dispatch(clearFieldError(fieldName));

	deleteAccommodationDateRange(metadata.id, dateRangeId)
		.then(() => {
			getTripProposal(metadata.id)
				.then(proposal => {
					dispatch({
						type: PROPOSAL_ELEMENTS_UPDATED,
						payload: { proposalElements: proposal.proposalElements },
					});
				})
				.catch(error => {
					const errorMessage = getErrorMessages(error, '');
					dispatch(setFieldError(fieldName, errorMessage));
				});
		})
		.catch(error => {
			const errorMessage = getErrorMessages(error, '');
			dispatch(setFieldError(fieldName, errorMessage));
		});
};
