import styled from 'styled-components';
import React from 'react';
import { textColorDark } from '../../design-system/variables/colors';
import { spaceLarge } from '../../design-system/variables/spaces';
import { IconButton } from '../Icon';
import { fontSans, textMedium } from '../../design-system/style-mixins/text';

const IncrementorContainer = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	max-width: 200px;
	align-items: center;
`;

const Value = styled.div`
	${fontSans}
    ${textMedium}
	${textColorDark}
	flex-grow: 1;
    padding-left: ${spaceLarge};
	padding-right: ${spaceLarge};
	text-align: center;
`;

interface Props {
	min: number;
	max?: number;
	current?: number;
	step?: number;
	handleChange?: Function;
}

const useCounter = ({ min, max, current, step }) => {
	const [count, setCount] = React.useState<number>(current || min);

	if (current !== undefined) {
		React.useEffect(() => {
			setCount(current);
		}, [current]);
	}

	const isUpperLimit = () => count >= max;
	const isLowerLimit = () => count <= min;
	return {
		isUpperLimit,
		isLowerLimit,
		count,
		increment: () => !isUpperLimit() && setCount(currentCount => currentCount + (step || 1)),
		decrement: () => !isLowerLimit() && setCount(currentCount => currentCount - (step || 1)),
	};
};

/**
 * Incrementor:
 * Used for changing quantity or value.
 */
export const Incrementor: React.FunctionComponent<Props> = ({
	min,
	max,
	current,
	step,
	handleChange,
	children,
	...rest
}) => {
	const { count, increment, decrement, isLowerLimit, isUpperLimit } = useCounter({ ...{ min, max, current, step } });

	const handleDecrement = () => {
		if (!isLowerLimit()) {
			decrement();

			if (handleChange) {
				handleChange(count - 1);
			}
		}
	};

	const handleIncrement = () => {
		if (!isUpperLimit()) {
			increment();

			if (handleChange) {
				handleChange(count + 1);
			}
		}
	};

	return (
		<IncrementorContainer {...rest}>
			<IconButton iconName='minus' iconSize='sm' isDisabled={isLowerLimit()} onClick={handleDecrement} />
			<Value>
				{children || count}
				{!children && count > 0 ? '+' : ''}
			</Value>
			<IconButton iconName='plus' iconSize='sm' isDisabled={isUpperLimit()} onClick={handleIncrement} />
		</IncrementorContainer>
	);
};
