import { useState, useEffect, useCallback } from 'react';
import root from 'window-or-global';
import { useClickOff } from 'components/hooks/useClickOff';

export function useDropdown(
	dropElRef,
	actionElRef,
	boundariesSelector?: string,
	additionalBoundaries: string[] = [],
): [boolean, (toggleState?: any) => void] {
	const dropEl = dropElRef['current'];
	const actionEl = actionElRef['current'];

	const [drop, setDrop] = useState(false);

	const toggleDrop = useCallback(
		(toggleState): void => {
			setDrop(toggleState !== undefined ? Boolean(toggleState) : !drop);
		},
		[drop],
	);

	const onWindowClick = ev => {
		const clickOnAction = actionEl && (ev.target === actionEl || actionEl.contains(ev.target));
		const boundaries = [boundariesSelector, ...additionalBoundaries];
		const isInBounds =
			(boundariesSelector || additionalBoundaries) &&
			boundaries.some(selector => {
				const el = root.document.querySelector(selector);
				return Boolean(el && (el === ev.target || el.contains(ev.target)));
			});

		const clickOnDrop = dropEl && (ev.target === dropEl || dropEl.contains(ev.target) || isInBounds);

		if (!clickOnAction && !clickOnDrop && drop) {
			toggleDrop(false);
		}
	};

	const onEsc = useCallback(
		ev => {
			if (ev.keyCode === 27 && drop === true) {
				toggleDrop(false);
			}
		},
		[drop],
	);

	useClickOff(dropElRef, onWindowClick);

	useEffect(() => {
		root.addEventListener('keyup', onEsc);
		return () => root.removeEventListener('keyup', onEsc);
	});

	return [drop, toggleDrop];
}
