import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { ProposalElementOrder, TripProposalElement } from '../../../../interfaces';
import { State } from '../context';
import { MoveDirection } from '../../../../enums';
import { updateProposalElementsOrder, getTripProposal } from '../../../../services/tripRecommendationsApiService';
import { PROPOSAL_ELEMENTS_UPDATED } from '../constants';
import { move } from '../../utils';

export const updateProposalElements = (proposalId: number) => (dispatch: ThunkDispatch<State, undefined, Action>) => {
	getTripProposal(proposalId)
		.then(proposal => {
			dispatch({
				type: PROPOSAL_ELEMENTS_UPDATED,
				payload: { proposalElements: proposal.proposalElements },
			});
		})
		.catch(error => {
			// TODO: Handle errors
		});
};

const getOrderedProposalElements = (
	proposalElements: TripProposalElement[],
	proposalElementId: number,
	moveDirection: MoveDirection,
) => {
	const elementIndex = proposalElements.findIndex(element => element.id === proposalElementId);
	const updatedElements = move([].concat(proposalElements), elementIndex, moveDirection);

	updatedElements
		.map((element, index) => {
			element.order = index + 1;
			return element;
		})
		.sort((a, b) => {
			return a.order > b.order ? 1 : -1;
		});

	return updatedElements;
};

export const updateProposalOrder = (proposalElementId: number, moveDirection: MoveDirection) => (
	dispatch: ThunkDispatch<State, undefined, Action>,
	getState: () => State,
) => {
	const { proposalElements, metadata } = getState();
	const proposalId = metadata.id;
	const orderedElements = getOrderedProposalElements(proposalElements, proposalElementId, moveDirection);

	const proposalOrderObj: ProposalElementOrder = orderedElements.reduce((acc, element) => {
		const { id, order } = element;
		return { ...acc, [id]: order };
	}, {});

	const proposalOrderPromise = updateProposalElementsOrder(proposalId, proposalOrderObj);
	proposalOrderPromise.then(
		() => {
			dispatch({
				type: PROPOSAL_ELEMENTS_UPDATED,
				payload: { proposalElements: orderedElements },
			});
		},
		reason => {},
	);
	return proposalOrderPromise;
};
