import styled, { css } from 'styled-components';
import { AspectRatio } from 'components/AspectRatio';
import { white, black } from 'design-system/variables/colors';
import { spaceBase } from 'design-system/variables/spaces';
import { ImageCarouselItemCaptionProps } from './ImageCarouselItem.types';

export const StyledImageCarouselItem = styled(AspectRatio).attrs(props => props.loaded && {
	style: {opacity: 1}
})<{ loaded: boolean }>`
	position: relative;
	flex: 0 0 100%;
	background: transparent;
	opacity: 0;
	transition: opacity 400ms cubic-bezier(0.23, 1, 0.32, 1);
`;

export const ImageCarouselItemFigure = styled.figure`
	margin: 0;

	> * {
		width: 100%;
		vertical-align: middle;
	}
`;

export const ImageCarouselItemCaption = styled.figcaption<ImageCarouselItemCaptionProps>`
	position: absolute;
	top: ${spaceBase};
	left: ${spaceBase};
	color: ${props => (props.theme === 'dark' ? `${black}` : `${white}`)};
	text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.4);
	opacity: 0;
	transition: opacity 400ms 400ms cubic-bezier(0.23, 1, 0.32, 1);

	${props =>
		props.showCaption &&
		css`
			opacity: 1;
		`}
`;
