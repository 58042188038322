import styled from 'styled-components';
import { spaceSmall, spaceBase, spaceTiny } from 'design-system/variables/spaces';
import { TextButton } from 'components/TextButton';
import { textSmall, bold, textBase } from 'design-system/style-mixins/text';
import { boxShadow3 } from 'design-system/style-mixins/shadows-and-scrims';
import { white } from 'design-system/variables/colors';
import { md } from 'design-system/media-queries';
import { GridWrap } from 'components/GridWrap';

export const FilterContainer = styled.div`
	padding: ${spaceSmall} ${spaceSmall} ${spaceBase} ${spaceSmall};
	width: 300px;
`;

export const FilterLabel = styled.h4`
	${bold}
	${textBase}
	margin-bottom: ${spaceSmall};
`;

export const FilterControls = styled.div`
	flex: 1;
	display: flex;
	margin-right: ${spaceBase};

	> * {
		max-width: 200px;
		margin-right: ${spaceSmall};
	}
`;

export const CloseIcon = styled.div`
	position: absolute;
	top: ${spaceBase};
	right: ${spaceBase};
`;

export const FooterContainer = styled.div`
	position: fixed;
	bottom: 0;
	width: 100%;
	left: 0;
	z-index: 1;
`;

export const FilterButtonClear = styled(props => <TextButton {...props} />)`
	${textSmall}
	display: flex;
	visibility: ${props => (props.isDisabled ? 'hidden' : 'visible')};
	align-items: center;
	padding: ${spaceTiny} 0;
	margin-left: ${spaceBase};
`;

export const FilterWrapper = styled.div`
    ${boxShadow3}
	background-color: ${white};
	display: flex;
	height: 70px;
    left: 0;
    position: sticky;
    right: 0;
    top: 0;
	z-index: 14;

	${md`
height: 104px;
	`}
`;

export const FilterGridWrap = styled(GridWrap)`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;

	> * > * {
		margin-right: ${spaceSmall};
	}

	${md`
		justify-content: flex-start;
	`}
`;
