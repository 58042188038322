import axios from 'axios';
import { FormStateProps } from 'components/Registration/shared/FormContext';

export interface RegistrationResponseProps {
	AccountId: number;
	Errors: any[];
	Success: boolean;
}

// TODO: check if URL is correct
export class RegistrationRepository {
	public async submit(model: FormStateProps): Promise<RegistrationResponseProps> {
		const response = await axios
			.post('/Public/RegisterUser/', model)
			.then(resp => JSON.parse(resp.data) as RegistrationResponseProps)
			.catch(resp => {
				throw JSON.parse(resp.data);
			});
		return response;
	}
}
