import { createSelector } from 'reselect';
import { TripsResponse, PricingAvailabilityChange, ProposalAccommodationElement } from '../../../../interfaces';

import { accommodationElementSelector } from './proposalElements';
import { changedTripsSelector, areDateRangesEqual } from './proposalValidation';

export const pricingAvailabilityChangesSelector = createSelector(
	[accommodationElementSelector, changedTripsSelector],
	(
		accommodationElements: ProposalAccommodationElement[],
		changedTrips: TripsResponse,
	): PricingAvailabilityChange[] => {
		const accommodations: ProposalAccommodationElement[] = [...accommodationElements];
		let pricingAvailabilityChanges: PricingAvailabilityChange[] = [];

		changedTrips.responses.forEach(response => {
			const accommodation = accommodations.find(a => Number(a.residenceId) === response.unitId);

			const accommodationPricingAvailabilityChanges = response.trips.map(trip => {
				const { pricingAvailabilityRatePeriod } = trip;
				const accommodationDateRange = accommodation.accommodationDateRanges.find(d =>
					areDateRangesEqual(d, pricingAvailabilityRatePeriod.dateRange),
				);
				return {
					accommodationDateRangeId: accommodationDateRange.id,
					subtotal: pricingAvailabilityRatePeriod.subtotal,
					taxes: pricingAvailabilityRatePeriod.taxes,
					isAvailable: pricingAvailabilityRatePeriod.isAvailable,
				};
			});

			pricingAvailabilityChanges = pricingAvailabilityChanges.concat(accommodationPricingAvailabilityChanges);
		});

		return pricingAvailabilityChanges;
	},
);
