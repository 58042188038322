import root from 'window-or-global';
import { ChatModel } from '../Chat.Interfaces';

root._laq = root._laq || [];

export const liveAgentChatProvider = ((chatClient: any) => {
	let contact;

	const initialize = (model: ChatModel, callback: (isOnline: boolean) => void) => {
		if (model.enableDebugging) {
			chatClient.enableLogging();
		}

		chatClient.addButtonEventHandler(model.buttonId, (e: any) => {
			const isOnline = chatClient.BUTTON_EVENT.BUTTON_AVAILABLE === e || chatClient.BUTTON_EVENT.BUTTON_ACCEPTED === e;

			if (model.enableChat) {
				setTimeout(() => callback(isOnline), model.invitationPopupDelayInSeconds * 1000);
			}
		});

		if (!model.isPublic) {
			contact = chatClient.findOrCreate('Contact');
			contact.map('Dry_Fly_ID__c', 'AccountUserID', true, true, false);
			contact
				.saveToTranscript('ContactId')
				.showOnCreate()
				.linkToEntity('ContactId');

			chatClient.setName(model.firstName);
			chatClient.addCustomDetail('AccountID', model.accountId).saveToTranscript('AccountID__c');
			chatClient.addCustomDetail('AccountUserID', model.accountUserId).saveToTranscript('AccountUserID__c');
			chatClient.addCustomDetail('FirstName', model.firstName).saveToTranscript('FirstName__c');
			chatClient.addCustomDetail('LastName', model.lastName).saveToTranscript('LastName__c');
			chatClient.addCustomDetail('Email', model.emailAddress).saveToTranscript('Email__c');
			chatClient.findOrCreate('Lead').map('Dry_Fly_ID__c', 'AccountUserID', true, true, false);
			chatClient
				.findOrCreate('Lead')
				.saveToTranscript('LeadId')
				.showOnCreate()
				.linkToEntity('LeadId');
		}

		chatClient.init(model.chatUrl, model.deploymentId, model.orgId);
	};

	const showWhenOnline = (buttonId: string, element: any) => {
		root._laq.push(() => {
			chatClient.showWhenOnline(buttonId, element);
		});
	};

	const startChat = (buttonId: string) => {
		chatClient.startChat(buttonId);
	};

	return {
		initialize,
		showWhenOnline,
		startChat,
	};
})(root.liveagent);
