import React, { FC, memo } from 'react';
import styled, { css } from 'styled-components';

import { Icon } from 'components/Icon';
import { grayMedium, white, grayDark } from 'design-system/variables/colors';
import { transition100ms } from 'design-system/style-mixins/animations';
import { spaceLarge, spaceTiny } from 'design-system/variables/spaces';
import { ArrowProps } from './ScrollDragContainer.types';

interface Theme {
	isRightArrowVisible: boolean;
	isLeftArrowVisible: boolean;
}

interface Props {
	direction: string;
	theme: Theme;
}

const ArrowWrapper = styled.div<Props>`
	opacity: 0;
	pointer-events: none;
	${props =>
		props.direction === 'left' &&
		props.theme.isLeftArrowVisible &&
		css`
			opacity: 1;
			pointer-events: all;
		`}
	${props =>
		props.direction === 'right' &&
		props.theme.isRightArrowVisible &&
		css`
			opacity: 1;
			pointer-events: all;
		`}
	${transition100ms}
	position: absolute;
	top: 0;
	left: auto;
	right: -${spaceTiny};
	height: 30px;
	z-index: 10000;
	display: flex;
	align-items: center;

	svg {
		cursor: pointer;
		z-index: 1;
	}

	${props =>
		props.direction === 'left' &&
		css`
			left: -${spaceLarge};
			right: auto;
			transform: rotateY(180deg);
		`}
`;

const ArrowIcon = styled(Icon)`
	fill: ${grayMedium};

	&:hover {
		fill: ${grayDark};
	}
`;

const ShadowOverlay = styled.div`
	width: 50px;
	height: 100%;
	background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.06) 32.97%, #ffffff 100%);

	:before {
		content: '';
		background: ${white};
		position: absolute;
		right: 0;
		height: 100%;
		width: 30px;
		display: inline-block;
	}
`;

export const Arrow: FC<ArrowProps> = memo(({ direction, onClick }) => {
	return (
		<ArrowWrapper direction={direction}>
			<ShadowOverlay />
			<ArrowIcon iconName='arrow' iconSize='md' onClick={onClick} />
		</ArrowWrapper>
	);
});
