import { ProposalElementType } from '../../enums';
import { TripProposalElement } from '../../interfaces';

export const getProposalElementType = (proposalElement: TripProposalElement) => {
	if (proposalElement.divider) {
		return ProposalElementType.Divider;
	}

	if (proposalElement.accommodation) {
		return ProposalElementType.Accommodation;
	}

	return ProposalElementType.NonPortfolio;
};

export const getProposalAccommodationIds = (elements: TripProposalElement[]) => {
	return elements
		.filter(e => e.accommodation !== null)
		.map(element => {
			return element.accommodation.residenceId;
		});
};

export const move = (array, index: number, delta: number) => {
	const newIndex = index + delta;
	if (newIndex < 0 || newIndex >= array.length) {
		return array;
	} // Already at the top or bottom.

	const indexes = [index, newIndex].sort((a, b) => a - b); // Sort the indexes
	array.splice(indexes[0], 2, array[indexes[1]], array[indexes[0]]); // Replace from lowest index, two elements, reverting the order

	return array;
};
