import { css } from 'styled-components';

/* Grid & Layout
	~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
export const containerWidthTinyPx = 370;
export const containerWidthSmallPx = 500;
export const containerWidthMediumPx = 768;
export const containerWidthPx = 1024;
export const containerWidthLargePx = 1200;

/**
 *Global container widths:
 *Depending on design, you might need the max expansion of your container to be 1024px.
 *Other times you will want it to extend to the full 1200px.
 */
export const containerWidth = css`
	max-width: ${containerWidthPx}px;
`;

export const containerWidthLarge = css`
	max-width: ${containerWidthLargePx}px;
`;
