import React, { useState, memo, useEffect, useRef, useCallback } from 'react';
import { KeyboardAccessibility, AccessibleInput } from 'components/KeyboardAccessibility';
import { CustomizedDropdown } from '../Dropdown';
import { useIsDesktop, useVisibilityToggle } from '../hooks';
import { QuickSearchProps } from './QuickSearchProps';
import { handleKeyDownUtil, scrollToSelectedOption } from './utils';
import {
	Wrapper,
	DropdownWrapper,
	SearchInput,
	ClearButton,
	SearchWrapper,
	modalStyles,
	SearchIcon,
} from './QuickSearch.styles';
import { SearchResults } from './components/SearchResults';
import { renderLoaderOrContent } from '../Loader/LoaderSkeleton';

export const QuickSearch: React.FC<QuickSearchProps> = memo(
	({ searchResults, onSearch, onClearSearch, onSelectResult, isLoading, isResultsShown, onToggleVisibility }) => {
		const [isDesktop] = useIsDesktop();
		const [value, changeValue] = useState('');
		const inputRef = useRef(null);
		const [isVisible, show, hide] = useVisibilityToggle();

		const handleInput = useCallback(
			e => {
				changeValue(e.target['value']);
				onSearch(e.target['value']);
			},
			[onSearch],
		);

		const resetKeyword = useCallback(() => {
			changeValue('');
			onClearSearch();
		}, [onClearSearch]);

		const selectResult = useCallback(
			(name, selected) => {
				changeValue(name);
				onSelectResult && onSelectResult(selected);
			},
			[onSelectResult],
		);

		const handleKeyDown = useCallback(
			({ setActiveId, activeId, e }) => {
				handleKeyDownUtil({
					isResultsVisible: isVisible,
					showResults: show,
					selectResult,
					searchResults,
					setActiveId,
					activeId,
					e,
				});
			},
			[isVisible, selectResult, searchResults],
		);

		const renderTrigger = useCallback(
			() => (
				<SearchIcon
					buttonType='containerless'
					iconName='search'
					iconSize={isDesktop ? 'sm' : 'md'}
					isActive={isVisible}
					data-node='search'
				/>
			),
			[isDesktop, isVisible],
		);

		useEffect(() => {
			inputRef.current && inputRef.current.focus();
		}, [isVisible, value]);

		useEffect(() => {
			onToggleVisibility(isVisible);
		}, [isVisible]);

		return (
			<KeyboardAccessibility
				menuId='search-results'
				isMenuOpen={isVisible}
				scrollToSelectedOption={scrollToSelectedOption}
			>
				<Wrapper>
					<CustomizedDropdown
						overflowAuto
						title=''
						align='right'
						parentSelector={`[data-node="header"]`}
						boundariesSelector="[data-node='search-dropdown']"
						modal={!isDesktop}
						modalStyles={modalStyles}
						TriggerComponent={renderTrigger}
						onOpen={show}
						onClose={hide}
					>
						<DropdownWrapper data-node='search-dropdown'>
							<SearchWrapper>
								{renderLoaderOrContent(
									isLoading,
									<AccessibleInput handleKeyDown={handleKeyDown}>
										<SearchInput
											autoFocus
											placeholder='Search destinations...'
											value={value}
											ref={inputRef}
											onInput={handleInput}
										/>
									</AccessibleInput>,
									{ width: '100%' },
								)}
								<ClearButton
									buttonType='grayRounded'
									iconName='close'
									iconSize='sm'
									buttonSize='small'
									value={value}
									onClick={resetKeyword}
								/>
								{isResultsShown && (
									<SearchResults
										searchResults={searchResults}
										searchWord={value}
										handleClick={selectResult}
									/>
								)}
							</SearchWrapper>
						</DropdownWrapper>
					</CustomizedDropdown>
				</Wrapper>
			</KeyboardAccessibility>
		);
	},
);
