import React from 'react';
import styled, { css } from 'styled-components';

import { sm, md } from '../../design-system/media-queries';
import { white, black, focusOutlineColor } from '../../design-system/variables/colors';

import { Link } from '../Link';
import { Icon } from '../Icon';
import { transition250ms } from '../../design-system/style-mixins/animations';

const Wrapper = styled(props => <Link {...props} />)<{ theme: { color: string } }>`
	display: flex;
	order: 3;

	${md`
		order: 0;
		line-height: 1;
	`}

	&[data-focus-visible-added] {
		outline: 2px solid ${focusOutlineColor};
		outline-offset: 2px;
	}
`;

const StyledIcon = styled(props => <Icon {...props} />)<{ theme: { color: string } }>`
	height: 28px;
	width: 176px;
	${transition250ms}
	fill: ${white};

	${props =>
		props.theme.color === 'white' &&
		css`
			fill: ${black};
		`}

	${sm`
        width: 211px;
    `}
`;

export const Logo = () => (
	<Wrapper color='primary' href='/'>
		<StyledIcon iconName='logo-inspirato' />
	</Wrapper>
);
