import { createSelector } from 'reselect';
import { TripProposalElement, ProposalAccommodationElement, ProposalNpoAccommodationElement } from '../../../../interfaces';
import { proposalElements } from '../reducers/proposalElements';

export const getProposalElements = state => state.proposalElements;
const getUnitId = (state, unitId) => unitId;
const getProposalElementId = (state, proposalElementId) => proposalElementId;
const getNpoId = (state, npoId) => npoId;

export const dividerElementsSelector = createSelector(
	getProposalElements,
	proposalElements => {
		const elements = [...proposalElements];
		return elements.filter((el: TripProposalElement) => el.divider !== null).map(({ divider }) => divider);
	},
);

export const accommodationElementSelector = createSelector(
	getProposalElements,
	proposalElements => {
		const elements: TripProposalElement[] = [...proposalElements];
		return elements
			.filter((el: TripProposalElement) => el.accommodation !== null)
			.map(({ accommodation }) => accommodation);
	},
);

export const npoAccommodationElementSelector = createSelector(
	getProposalElements,
	proposalElements => {
		const elements: TripProposalElement[] = [...proposalElements];
		return elements
			.filter((el: TripProposalElement) => el.nonPortfolioAccommodation !== null)
			.map(({ nonPortfolioAccommodation }) => nonPortfolioAccommodation);
	},
);

export const proposalElementSelector = createSelector(
	[getProposalElements, dividerElementsSelector, accommodationElementSelector],
	(proposalElements, dividers, accommodations) => {
		const elements = [...proposalElements];
		if (dividers.length !== accommodations.length || accommodations.length === 0) {
			const defaultProposalElementDivider: TripProposalElement = {
				accommodation: null,
				divider: {
					id: 0,
					label: '',
				},
				id: 0,
			};

			if (dividers.length > 0) {
				elements.push(defaultProposalElementDivider);
			} else {
				elements.unshift(defaultProposalElementDivider);
			}
		}

		return elements;
	},
);

export const accommodationDateRangesSelector = createSelector(
	[accommodationElementSelector, getUnitId],
	(accommodations, unitId) => {
		const accommodation: ProposalAccommodationElement = accommodations.filter(
			(acc: ProposalAccommodationElement) => acc.residenceId === unitId,
		)[0];
		return accommodation.accommodationDateRanges;
	},
);

export const nonPortfolioAccommodationDateRangesSelector = createSelector(
	[npoAccommodationElementSelector, getNpoId],
	(npoAccommodations, npoId) => {
		const npoAccommodation: ProposalNpoAccommodationElement = npoAccommodations.filter(
			(acc: ProposalNpoAccommodationElement) => acc.id === npoId,
		)[0];
		return npoAccommodation.nonPortfolioAccommodationDateRanges;
	},
);

export const isFirstProposalElementSelector = createSelector(
	[getProposalElements, getProposalElementId],
	(proposalElements, proposalElementId) => {
		return proposalElements.findIndex(element => element.id === proposalElementId) === 0;
	},
);

export const isLastProposalElementSelector = createSelector(
	[getProposalElements, getProposalElementId],
	(proposalElements, proposalElementId) => {
		return proposalElements.findIndex(element => element.id === proposalElementId) === proposalElements.length - 1;
	},
);
