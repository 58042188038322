import styled from 'styled-components';
import { IconButton } from 'components/Icon/IconButton';
import { spaceSmall } from '../../../design-system/variables/spaces';

export const StyledFullScreenButton = styled(IconButton)`
	position: absolute;
	right: ${spaceSmall};
	top: ${spaceSmall};
	z-index: 10;
`;
