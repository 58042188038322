import React from 'react';
import styled from 'styled-components';
import { FeatureListProps } from './FeatureListProps';
import { xsOnly, xsSmOnly } from '../../design-system/media-queries';
import { spaceBase, spaceHuge, spaceMassive } from '../../design-system/variables/spaces';
import { sabon } from '../../design-system/variables/fonts';
import { textBaseSerif } from '../../design-system/style-mixins/text';
import { grayDark } from '../../design-system/variables/colors';

const Container = styled.div`
	display: flex;
`;

const ListItemWrapper = styled.div`
	display: flex;
	background-position: left center;
	background-repeat: no-repeat;
	background-size: 60px 60px;
	flex-direction: column;
	justify-content: center;
	margin-bottom: 75px;
	min-height: 100px;
	text-align: left;
	line-height: 27px;
	padding-left: ${spaceMassive};
	font-family: ${sabon};
	color: ${grayDark};
	${textBaseSerif}

	&:last-of-type {
		margin-bottom: 0;
	}

	${xsSmOnly`
		background-size: 50px 50px;
		padding-left: ${spaceHuge};
		padding-right: ${spaceBase};
	`}

	${xsOnly`
		padding-right: 0;
	`}
`;

export const FeatureList = ({ listItemText, icon }: FeatureListProps) => {
	const iconStyle = icon ? { backgroundImage: `url("${icon.fullPath}")` } : {};

	return (
		<Container>
			<ListItemWrapper style={iconStyle}>{listItemText}</ListItemWrapper>
		</Container>
	);
};
