import React, { FC, memo } from 'react';
import { Button } from 'components/Button';
import { UnitParentCategory } from 'pages/CuratedTrips/enums';
import { Icon } from 'components/Icon';
import { CardFooterInner, StyledR2BCta, ButtonCustomized } from '../ListCard.styles';

interface Props {
	isListAndIsDesktop: boolean;
	isPlaceholder: boolean;
	configuration: any;
	rid: any;
	onPassUnitClick: any;
	tripDetails: any;
	requestQueryParams: string;
	overrideUrl: string;
	viewHomeProps: Function;
}
export const BookButton: FC<Props> = memo(
	({
		isListAndIsDesktop,
		isPlaceholder,
		configuration,
		rid,
		tripDetails,
		requestQueryParams,
		overrideUrl,
		viewHomeProps,
	}) => {
		if (isPlaceholder) {
			return null;
		}
		const listingTypeString = tripDetails.unitParentCategoryID === UnitParentCategory.Hotel ? 'hotel' : 'home';
		const requestToBookUrl = `https://travel.inspirato.com/pass/${listingTypeString}/request-to-book/${requestQueryParams}`;

		const viewButtonText = () => (
			<>
				<span>
					{tripDetails.unitParentCategoryID === UnitParentCategory.Hotel ? 'View Hotel' : 'View Home'}
				</span>
				{overrideUrl ? (
					<Icon iconName='link-external' iconSize='sm' />
				) : null}
			</>
		);

		return (
			<CardFooterInner isList={isListAndIsDesktop} isPlaceholder={isPlaceholder}>
				<Button {...viewHomeProps()}>{viewButtonText()}</Button>
				{configuration.enableRequestToBook ? (
					<StyledR2BCta
						userType={configuration.currentUserType}
						rid={rid}
						checkInDate={tripDetails.checkInDate}
						checkOutDate={tripDetails.checkOutDate}
						useModal
					/>
				) : (
					<ButtonCustomized
						buttonType='primary'
						as='a'
						size='base'
						target='_blank'
						href={!isPlaceholder ? requestToBookUrl : null}
					>
						Request to book
					</ButtonCustomized>
				)}
			</CardFooterInner>
		);
	},
);
