import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { State } from '../context';
import { UPDATE_EXPERIENCES_FILTER, CLEAR_EXPERIENCE_TYPES } from '../constants';

export const updateExperiencesFilter = (id: string) => (
	dispatch: ThunkDispatch<State, undefined, Action>,
	getState: () => State,
) => {
	const experiencesFilter = getState().experienceTypes.map(experience => ({
		...experience,
		checked: id === experience.id && !experience.disabled ? !experience.checked : experience.checked,
	}));
	dispatch({ type: UPDATE_EXPERIENCES_FILTER, payload: experiencesFilter });
};

export const clearExperiencesFilter = () => (
	dispatch: ThunkDispatch<State, undefined, Action>,
	getState: () => State,
) => {
	const clearedExperiences = getState().experienceTypes.map(experienceType => ({
		...experienceType,
		checked: false,
	}));
	dispatch({ type: CLEAR_EXPERIENCE_TYPES, payload: clearedExperiences });
};
