import styled, { css } from 'styled-components';

import { md, sm, smOnly } from 'design-system/media-queries';
import { fadeInAnimation, transition250ms } from 'design-system/style-mixins/animations';
import { boxShadow3 } from 'design-system/style-mixins/shadows-and-scrims';
import { fontSans, textBase } from 'design-system/style-mixins/text';
import { dividerGrayLight, grayDark, textColorDark, white, black } from 'design-system/variables/colors';
import { spaceBase, spaceLarge, spaceSmall, spaceHuge } from 'design-system/variables/spaces';
import { IconButton } from 'components/Icon';

export const Wrapper = styled.div`
	${md`
    border-right: ${dividerGrayLight};
    padding-bottom: ${spaceSmall};
    padding-right: 10px;
    padding-top: ${spaceSmall};
    position: relative;
`}
`;

export const DropdownWrapper = styled.div`
	background-color: ${white};
	display: flex;
	position: absolute;
	width: 100%;
	z-index: 99;
	top: 0px;

	${md`
		${fadeInAnimation}
		${boxShadow3}
		width: 375px;
		right: -116px;
		top: 20px;

		&:before {
			border-bottom: ${spaceSmall} solid ${white};
			border-left: ${spaceSmall} solid transparent;
			border-right: ${spaceSmall} solid transparent;
			border-top: ${spaceSmall} solid transparent;
			content: '';
			height: 0;
			position: absolute;
			right: 125px;
			top: -${spaceBase};
			width: 0;
			z-index: 2;
		}
	`}
`;

export const SearchInput = styled.input`
	${fontSans}
	${textBase}
	${textColorDark}
	background-clip: padding-box;
	border: none;
	box-sizing: border-box;
	height: 40px;
	outline: none;
	width: 100%;
	margin: 0;
	padding: 0 0 0 ${spaceBase};

	${smOnly`
		padding-left: ${spaceLarge};
	`}

	${md`
		max-width: 317px;
		overflow: hidden;
	`}
`;

export const ClearButton = styled(props => <IconButton {...props} />)<{ value: string }>`
	display: none;
	position: absolute;
	right: ${spaceBase};
	top: 10px;

	${sm`
		right: ${spaceLarge};
	`}

	${md`
		right: ${spaceSmall};
	`}

	${props =>
		props.value &&
		css`
			display: flex;
		`}
`;

export const SearchWrapper = styled.div`
	position: relative;
	width: 100%;
	height: 100%;
`;

export const modalStyles = css`
	top: 100%;
	left: 0;
	bottom: 0;
	right: 0;
	width: 100%;
	position: absolute;
	height: calc(100vh - ${spaceHuge});

	${md`
		${boxShadow3}
	`}
`;

export const SearchIcon = styled(props => <IconButton {...props} />)<{ theme: { color: string }; isActive: boolean }>`
	display: flex;
	width: 28px;
	height: 28px;
	margin-left: -6px;

	svg {
		${transition250ms}
		fill: ${white};
	}

	${props =>
		props.isActive &&
		css`
			svg {
				fill: ${black} !important;
			}
		`}

	${props =>
		props.theme.color === 'white' &&
		css`
			svg {
				fill: ${grayDark};
			}
		`}
`;
