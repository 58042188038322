import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { getErrorMessages } from '../../../../shared/utils';
import { updateProposalAccommodation, deleteProposalElement } from '../../../../services/tripRecommendationsApiService';
import { UpdateProposalAccommodationRequest, TripProposalElement } from '../../../../interfaces';
import { ACCOMMODATION_UPDATED, ACCOMMODATION_NOTE, ACCOMMODATION, PROPOSAL_ELEMENTS_UPDATED } from '../constants';
import { State } from '../context';
import { setFieldError, clearFieldError } from './errors';

export const updateAccommodationNote = (id: number, note: string, modifiedBy: string) => (
	dispatch: ThunkDispatch<State, undefined, Action>,
	getState: () => State,
) => {
	const { metadata, proposalElements } = getState();
	const fieldName = `${ACCOMMODATION_NOTE}_${id}`;
	const { accommodation } = proposalElements.find(
		(element: TripProposalElement) => element.accommodation !== null && element.accommodation.id === id,
	);
	accommodation.note = note;

	const request: UpdateProposalAccommodationRequest = {
		note,
		coverImageUrl: accommodation.coverImageUrl,
	};

	dispatch(clearFieldError(fieldName));

	updateProposalAccommodation(metadata.id, id, request)
		.then(() => {
			dispatch({ type: ACCOMMODATION_UPDATED, payload: { id, accommodation } });
		})
		.catch(error => {
			const errorMessage = getErrorMessages(error, 'Note');
			dispatch(setFieldError(fieldName, errorMessage));
		});
};

export const deleteAccommodation = (accommodationId: number, proposalElementId: number) => (
	dispatch: ThunkDispatch<State, undefined, Action>,
	getState: () => State,
) => {
	const { metadata, proposalElements } = getState();
	const fieldName = `${ACCOMMODATION}_${accommodationId}`;
	const updatedElements = proposalElements.filter(element => element.id !== proposalElementId);

	dispatch(clearFieldError(fieldName));

	deleteProposalElement(metadata.id, proposalElementId)
		.then(() => {
			dispatch({ type: PROPOSAL_ELEMENTS_UPDATED, payload: { proposalElements: updatedElements } });
		})
		.catch(error => {
			const errorMessage = getErrorMessages(error, 'Label');
			dispatch(setFieldError(fieldName, errorMessage));
		});
};
