import React from 'react';
import { Picture, Img } from './ResponsiveImage.styles';

export interface ResponsiveImageProps {
	adjustHeightForMobile?: boolean;
	url: string;
}

const widths: number[] = [2560, 1920, 1600, 1280, 1024, 768];
export const ResponsiveImage = ({ url, adjustHeightForMobile = true }: ResponsiveImageProps) => {
	const fallbackSrcSet: string[] = [];
	const sources: JSX.Element[] = [];

	widths.forEach(width => {
		const imgUrl = `${url}&width=${width}&align=center ${width}w`;
		const source = { width, url: imgUrl };
		fallbackSrcSet.push(imgUrl);
		sources.push(<source key={width} media={`(min-width: ${source.width}px)`} srcSet={source.url} />);
	});

	if (adjustHeightForMobile) {
		sources.push(
			<source
				key={767}
				media='(max-width:767px)'
				srcSet={`${url}&width=768&height=1400&crop=resize&align=center`}
			/>,
		);
	}

	return (
		<React.Fragment>
			<Picture>
				{sources}
				<Img alt='Responsive' src={fallbackSrcSet[fallbackSrcSet.length - 1]} />
			</Picture>
		</React.Fragment>
	);
};
