import styled, { css } from 'styled-components';

import { dividerGrayLight, itemHover, textColorDark, textColorLight } from 'design-system/variables/colors';
import { spaceSmall, spaceBase, spaceMedium, spaceLarge } from 'design-system/variables/spaces';
import { brokenImgHoriz, truncateText } from 'design-system/utils';
import { bold } from 'design-system/style-mixins/text';
import { smOnly, xsOnly, md, sm } from 'design-system/media-queries';
import { Subtext } from 'components/Subtext/Subtext';

export const ListItem = styled.li<{ isActive?: boolean }>`
	${textColorDark}
	display: flex;
	line-height: 18px;
	margin: 0;
	overflow: hidden;
	padding: ${spaceSmall} ${spaceBase};
	text-align: left;
	text-overflow: ellipsis;
	text-transform: none;
	white-space: nowrap;

	${props =>
		props.isActive &&
		css`
			background-color: ${itemHover};
		`}

	&:hover {
		background-color: ${itemHover};
		cursor: pointer;
	}

	${smOnly`
		padding-left: ${spaceLarge};
		padding-right: ${spaceLarge};
	`}

	${props =>
		props.isActive &&
		css`
			background-color: ${itemHover};
		`}

	${props => props.theme.resultStyle}
`;

export const Wrapper = styled.ul`
	list-style: none;
	overflow-x: hidden;
	padding: 0;
	width: 100%;
	${props => props.theme.wrapperStyle}
	height: ${props => (props.theme.height ? `calc(100vh - ${props.theme.height}px)` : '100%')};

	${props =>
		props.theme.isModal &&
		css`
			height: '100%';
		`}
`;

export const Header = styled(props => <ListItem {...props} />)`
	${textColorLight}
	border-bottom: ${dividerGrayLight};
	margin-bottom: ${spaceSmall};
	margin-left: ${spaceBase};
	margin-right: ${spaceBase};
	padding: ${spaceMedium} 0 ${spaceSmall} 0;

	&:hover {
		background-color: initial;
		cursor: auto;
	}

	${smOnly`
		margin-left: ${spaceLarge};
		margin-right: ${spaceLarge};
		padding-left: 0;
		padding-right: 0;
	`}

	${props => props.theme.headerStyle}
`;

export const DestinationInfo = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;

	.highlight {
		background-color: transparent;
		${bold}
	}
`;

export const DestinationImage = styled.div`
	${xsOnly`
		display: none;
	`}
	background: url(${props => props.src || brokenImgHoriz});
    width: 88px;
    height: 50px;
    background-size: cover;
    background-repeat: no-repeat;
	margin-right: 23px;
	flex-shrink:0;
`;

export const recommendedInformationBlock = css`
	width: 100%;
	${truncateText}

	${sm`
		max-width: 232px;
	`}

	${md`
max-width: 372px;
	`}
`;

export const Name = styled.span`
	${recommendedInformationBlock}
`;

export const AdditionalBlock = styled(props => <Subtext {...props} />)`
	${recommendedInformationBlock}
`;
