import moment from 'moment';
import Numeral from 'numeral';
import React, { useState } from 'react';
import axios from 'axios';
import { UnitParentCategory } from '../../../pages/CuratedTrips/enums';
import { CuratedTripSet } from '../../../pages/CuratedTrips/interfaces';
import { getHeroImage } from '../../../pages/CuratedTrips/services/curatedTripsService';
import { useIsDesktop } from '../../hooks';
import { CardFooter, CardMain, FeaturedLabel, ListCardContainer, MainContainer } from './ListCard.styles';
import { Gallery, Header, Stats, Value, DateContent } from './components';
import { BookButton } from './components/BookButton';

const { CancelToken } = axios;

export type ListCardProps = Omit<CuratedTripSet, 'bathrooms' | 'coverImage'> & {
	isList?: boolean;
	configuration: any;
	isPlaceholder?: boolean;
	onPassUnitClick: (
		rid: string,
		tripDetails: { checkInDate: string; checkOutDate: string; value: number },
		unitURL: string,
	) => void;
};

export const ListCard: React.FunctionComponent<ListCardProps> = ({
	bedrooms,
	configuration,
	checkInDate,
	checkOutDate,
	heroImage,
	locationName,
	numberOfDays,
	occupancy,
	overrideUrl,
	propertyName,
	rid,
	unitName,
	unitURL,
	unitID,
	unitParentCategoryID,
	value,
	featuredOrder,
	isList,
	isNew,
	onPassUnitClick,
	showTripValue,
}) => {
	const [requestQueryParams] = useState(
		`?destination=${locationName}` +
			`&unit=${unitName}` +
			`&property=${propertyName}` +
			`&checkIn=${moment.utc(checkInDate).format('MM/DD/YY')}` +
			`&checkOut=${moment.utc(checkOutDate).format('MM/DD/YY')}` +
			`${unitParentCategoryID === UnitParentCategory.Home ? `&est_value=${Numeral(value).format('$0,0')}` : ''}`,
	);

	const [galleryImages, setGalleryImages] = React.useState([]);

	const isPlaceholder = !unitID;
	const heroImgRequest = CancelToken.source();
	const [prevHeroImgRequest, setPrevHeroImgRequest] = React.useState(null);
	const heroImageEndpoint = `${configuration.baseApiUrl}heroimage/unit`;
	const { imageServer } = configuration;

	React.useEffect(() => {
		if (galleryImages.length > 0 || galleryImages[0] === false || isPlaceholder) {
			setGalleryImages([]);
			prevHeroImgRequest && prevHeroImgRequest.cancel('Request Canceled by new Tripset Request');
		}

		if (unitID) {
			setPrevHeroImgRequest(heroImgRequest);

			getHeroImage(heroImageEndpoint, unitID, heroImgRequest.token).then((responseData) => {
				if (responseData?.heroImages?.length > 0) {
					setGalleryImages(responseData.heroImages);
				}
			});
		}
	}, [unitID]);

	const [isDesktop] = useIsDesktop();

	const isListAndIsDesktop = (isList && isDesktop) as boolean;

	const tripDetails = {
		checkInDate: moment.utc(checkInDate).format('MM/DD/YY'),
		checkOutDate: moment.utc(checkOutDate).format('MM/DD/YY'),
		value,
		unitName,
		locationName,
		propertyName,
		unitParentCategoryID,
	};

	const viewHomeBaseProps = {
		buttonType: 'minimal',
		size: 'base',
	};

	const viewHomeProps = () => {
		let urlOption;
		if (overrideUrl) {
			urlOption = {
				as: 'a',
				target: '_blank',
				href: !isPlaceholder ? overrideUrl : null,
			};
		} else {
			urlOption = {
				onClick: () => onPassUnitClick(rid, tripDetails, unitURL),
			};
		}
		return {
			...viewHomeBaseProps,
			...urlOption,
		};
	};

	return (
		<ListCardContainer isList={isListAndIsDesktop}>
			<Gallery
				isList={isList}
				isListAndIsDesktop={isListAndIsDesktop}
				galleryImages={galleryImages}
				heroImage={heroImage}
				isPlaceholder={isPlaceholder}
				imageServer={imageServer}
			/>
			<MainContainer isList={isListAndIsDesktop}>
				<CardMain>
					<Header
						isListAndIsDesktop={isListAndIsDesktop}
						isNew={isNew}
						isPlaceholder={isPlaceholder}
						locationName={locationName}
						propertyName={propertyName}
						unitName={unitName}
						viewHomeProps={viewHomeProps}
					/>
					<Stats
						bedrooms={bedrooms}
						isListAndIsDesktop={isListAndIsDesktop}
						isPlaceholder={isPlaceholder}
						numberOfDays={numberOfDays}
						occupancy={occupancy}
					/>
					<Value
						isListAndIsDesktop={isListAndIsDesktop}
						isPlaceholder={isPlaceholder}
						unitParentCategoryID={unitParentCategoryID}
						value={value}
						showTripValue={showTripValue}
					/>
					{featuredOrder && !isPlaceholder ? <FeaturedLabel>Recommended</FeaturedLabel> : null}
				</CardMain>
				<CardFooter isList={isListAndIsDesktop}>
					<DateContent
						checkInDate={checkInDate}
						checkOutDate={checkOutDate}
						isListAndIsDesktop={isListAndIsDesktop}
						isPlaceholder={isPlaceholder}
					/>
					<BookButton
						isPlaceholder={isPlaceholder}
						isListAndIsDesktop={isListAndIsDesktop}
						rid={rid}
						configuration={configuration}
						tripDetails={tripDetails}
						onPassUnitClick={onPassUnitClick}
						requestQueryParams={requestQueryParams}
						overrideUrl={overrideUrl}
						viewHomeProps={viewHomeProps}
					/>
				</CardFooter>
			</MainContainer>
		</ListCardContainer>
	);
};
