import styled from 'styled-components';

export const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	position: relative;
	overflow: hidden;
	display: flex;
	align-items: center;
`;

export const ScrollContainer = styled.div`
	overflow-y: hidden;
	overflow-x: auto;
	display: flex;
	flex-grow: 2;
	overflow: -moz-scrollbars-none;
	-ms-overflow-style: none;
	scrollbar-width: none;
	&::-webkit-scrollbar {
		display: none !important;
		height: 0 !important;
		width: 0 !important;
		background: transparent !important;
		-webkit-appearance: none !important;
	}
`;
