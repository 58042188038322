import styled, { css } from 'styled-components';
import { textColorDark } from '../../design-system/variables/colors';
import {
	bold,
	lightWeight,
	textHuge,
	textBig,
	textBase,
	fontSans,
	textMassive,
} from '../../design-system/style-mixins/text';
import { sm } from '../../design-system/media-queries';

const big = css`
	${textHuge}
	line-height: 42px;
	${lightWeight}
	letter-spacing: 1px;

	${sm`
		${textMassive}
		line-height: 52px;
	`}
`;

const large = css`
	${textBig}
	line-height: 32px;
	${lightWeight}
	letter-spacing: 1px;

	${sm`
		${textHuge}
		line-height: 42px;
	`}
`;

const medium = css`
	${textBig}
	line-height: 32px;
	${lightWeight}
	letter-spacing: 1px;
`;

const small = css`
	${textBase}
	${bold}
	letter-spacing: initial;
	line-height: 24px;
`;

const sizeStyles = {
	big,
	large,
	medium,
	small,
};

/**
 * Headlines:
 * Used for page, section, and occasionally component headlines.
 * Do not mistake this as a Title or a Label.
 * NOTE: "huge" size is reserved for very large headlines at top of pages, in "hero" for example.
 */
export const Headline = styled.span<{ size: 'small' | 'medium' | 'large' | 'big' }>`
	${fontSans}
	${textColorDark}
	display: block;
	${props => sizeStyles[props.size]}
`;
