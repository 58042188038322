import styled, { css } from 'styled-components';
import { spaceBase, spaceLarge } from '../../design-system/variables/spaces';
import { sm } from '../../design-system/media-queries';
import { containerWidth, containerWidthLarge } from '../../design-system/style-mixins/grid-and-layout';

const fullScreen = css`
	max-width: 100%;
`;

const large = css`
	${containerWidthLarge}
`;

const base = css`
	${containerWidth}
`;

const widthStyles = {
	fullScreen,
	large,
	base,
};

/**
 *Grid Wrap:
 *Used to get correct global container padding across all devices.
 *Mobile devices are 1rem from edge of screen. Tablets and up are 2rems.
 */
export const GridWrap = styled.div<{ width?: 'base' | 'large' | 'fullScreen' }>`
	margin: 0 auto;
	padding-left: ${spaceBase};
	padding-right: ${spaceBase};
	width: 100%;
	${props => widthStyles[props.width] || widthStyles.base}

	${sm`
		padding-left: ${spaceLarge};
		padding-right: ${spaceLarge};
	`}
`;
