import React, { FC } from 'react';
import styled from 'styled-components';
import { ActionLink } from 'components/ActionLink';
import { Button } from 'components/Button';

const ButtonLink = styled(props => <Button {...props} />)`
	display: inline-block;
	margin-left: auto;
	margin-right: auto;
	position: relative;
`;

interface Props {
	callToAction: any;
	isRegistrationCTA: boolean;
}

export const Action: FC<Props> = ({ callToAction, isRegistrationCTA }) => {
	return callToAction && callToAction.canDisplayAsTextLink ? (
		<div className='row center-xs'>
			<div className='col-xs-12'>
				{isRegistrationCTA ? (
					<ButtonLink className='btn-register' buttonType='primary'>
						{callToAction.linkTitle}
					</ButtonLink>
				) : (
					<ActionLink {...callToAction} />
				)}
			</div>
		</div>
	) : null;
};
