import React, { FC } from 'react';
import styled from 'styled-components';
import { Paragraph } from 'components';
import { spaceBase } from 'design-system/variables/spaces';

export const HourBlock = styled((props) => <Paragraph {...props} size='small' />)`
	margin: -2px 0;
`;

export const PVAHours = styled.section`
	margin: ${spaceBase};
`;

export const PvaHours: FC = () => (
	<PVAHours>
		<HourBlock size='small'>Monday–Friday: 7 a.m. – 10 p.m. MST</HourBlock>
		<HourBlock size='small'>Saturday and Sunday: 9 a.m. – 5 p.m. MST</HourBlock>
	</PVAHours>
);
