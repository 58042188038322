import styled from 'styled-components';
import { AspectRatioProps } from './AspectRatioProps';

export const AspectRatio = styled.div<AspectRatioProps>`
	position: relative;

	> img {
		height: auto;
		object-fit: cover;
	}

	&::before {
		content: '';
		display: block;
		padding-bottom: calc(100% / ${props => props.ratio || 16 / 9});
	}

	> :first-child {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
`;
