import { SELECTED_RECIPIENT, SELECTED_RECIPIENT_UPDATED } from '../constants';
import { MemberOption } from '../../../../models/MemberOption';
import { getAccountUserSearchInfo } from '../../../../services/dryflyApiService';
import { setFieldError } from './errors';

export const fetchMemberDetails = (accountUserId: string) => dispatch => {
	const accountUserInfoPromise = getAccountUserSearchInfo(Number(accountUserId));
	accountUserInfoPromise.then(
		accountUserInfo => {
			if (accountUserInfo.data) {
				const selectedRecipient = new MemberOption(accountUserInfo.data);
				return dispatch({ type: SELECTED_RECIPIENT_UPDATED, payload: { selectedRecipient } });
			}

			return dispatch(setFieldError(SELECTED_RECIPIENT, 'There was an error retrieving member details.'));
		},
		reason => dispatch(setFieldError(SELECTED_RECIPIENT, reason.message)),
	);

	return accountUserInfoPromise;
};
