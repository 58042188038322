import React, { FC } from 'react';
import { getSafeHtml } from 'services/Helpers';

import { HelpText } from './Form.styles';

interface Props {
	hasError?: boolean;
	helpText: string;
}

export const HelpBlock: FC<Props> = ({ hasError, helpText }) => (
	<HelpText hasError={hasError} dangerouslySetInnerHTML={getSafeHtml(helpText)} />
);
