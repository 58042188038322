import React, { useEffect } from 'react';
import { useFullscreenView } from 'components/hooks/useFullscreenView';
import { StyledFullScreenButton } from './FullScreenButton.styles';
import { FullScreenButtonProps } from './FullScreenButton.types';

export const FullScreenButton: React.FC<FullScreenButtonProps> = ({ containerRef, theme, onToggle }) => {
	const [isFullscreen, toggleFullscreen] = useFullscreenView(containerRef);

	useEffect(() => {
		onToggle(isFullscreen);
	}, [isFullscreen]);

	return (
		<StyledFullScreenButton
			iconSize='lg'
			buttonType={theme === 'dark' ? 'containerlessSecondary' : 'containerlessPrimary'}
			iconName={!isFullscreen ? 'enter' : 'exit'}
			onClick={toggleFullscreen}
		/>
	);
};
