import React, { useState } from 'react';
import Numeral from 'numeral';
import { Input } from 'components/Form';
import { PaginationProps } from './Pagination.types';
import {
	Nav,
	Items,
	Item,
	Previous,
	Next,
	PageNumber,
	PageJump,
	CurrentRangeLabel,
	SimpleWrapper,
	SimplePageNumber,
	SimpleTextDivider,
} from './Pagination.styles';

const PREVIOUS_JUMP = 'PREVIOUS';
const NEXT_JUMP = 'NEXT';

const range = (from, to, step = 1) => {
	let i = from;
	const arr = [];

	while (i <= to) {
		arr.push(i);
		i += step;
	}

	return arr;
};

const fetchPageNumbers = (pageNeighbors, totalPages, currentPage) => {
	const totalNumbers = pageNeighbors * 2 + 3;
	const totalBlocks = totalNumbers + 2;

	if (totalPages > totalBlocks) {
		const startPage = Math.max(2, currentPage - pageNeighbors);
		const endPage = Math.min(totalPages - 1, currentPage + pageNeighbors);

		let pagesRange = range(startPage, endPage);

		const hasPreviousPages = startPage > 2;
		const hasNextPages = totalPages - endPage > 1;
		const pageOffset = totalNumbers - (pagesRange.length + 1);

		if (hasPreviousPages && !hasNextPages) {
			const extraPages = range(startPage - pageOffset, startPage - 1);
			pagesRange = [PREVIOUS_JUMP, ...extraPages, ...pagesRange];
		} else if (!hasPreviousPages && hasNextPages) {
			const extraPages = range(endPage + 1, endPage + pageOffset);
			pagesRange = [...pagesRange, ...extraPages, NEXT_JUMP];
		} else {
			pagesRange = [PREVIOUS_JUMP, ...pagesRange, NEXT_JUMP];
		}

		return [1, ...pagesRange, totalPages];
	}

	return range(1, totalPages);
};

export const Pagination: React.FC<PaginationProps> = ({
	hideRangeLabel,
	offset,
	onPageUpdate,
	pageNeighbors,
	pageSize,
	simple,
	title,
	total,
	...rest
}) => {
	const initialPage = Math.floor(offset / pageSize + 1);
	const currentPage = offset / pageSize + 1;
	const [simpleInputValue, setSimpleInputValue] = useState(initialPage);
	const totalPages = Math.ceil(total / pageSize);
	const currentRangeStart = (currentPage - 1) * pageSize + 1;
	const currentRangeEnd = Math.min(currentRangeStart + pageSize - 1, total);
	const pages = fetchPageNumbers(pageNeighbors, totalPages, currentPage);

	const handlePageUpdate = page => {
		if (currentPage === page) {
			return;
		}
		onPageUpdate((page - 1) * pageSize, page);

		if (simpleInputValue !== page) {
			setSimpleInputValue(page);
		}
	};

	const submitSimpleInputValue = event => {
		if (event.key !== 'Enter') {
			return;
		}

		if (simpleInputValue > totalPages) {
			event.target.value = totalPages;
		} else if (simpleInputValue < 1) {
			event.target.value = 1;
		}

		handlePageUpdate(Number(event.target.value));
	};

	return (
		<Nav aria-label={title} {...rest}>
			{!hideRangeLabel && (
				<CurrentRangeLabel>
					Showing {Numeral(currentRangeStart).format('0,0')} - {Numeral(currentRangeEnd).format('0,0')}
				</CurrentRangeLabel>
			)}

			<Items simple={simple}>
				<Item>
					<Previous
						title={currentPage > 1 && `Go to previous page (${currentPage - 1})`}
						isDisabled={currentPage <= 1}
						buttonType='containerless'
						iconName='arrow'
						iconSize='md'
						data-arrow-control='previous'
						aria-label='Previous page'
						onClick={() => handlePageUpdate(currentPage - 1)}
					/>
				</Item>

				{simple ? (
					<SimpleWrapper>
						<Input
							type='number'
							id='SimpleInputValue'
							labelText='Enter a page number'
							hideLabel
							min={1}
							max={totalPages}
							value={simpleInputValue}
							onChange={event => setSimpleInputValue(event.target.value)}
							onKeyUp={event => submitSimpleInputValue(event)}
						/>
						<SimpleTextDivider>/</SimpleTextDivider>
						<SimplePageNumber
							buttonType='minimal'
							title='Go to last page'
							onClick={() => handlePageUpdate(totalPages)}
						>
							{totalPages}
						</SimplePageNumber>
					</SimpleWrapper>
				) : (
					pages.map(page => {
						if (page === PREVIOUS_JUMP || page === NEXT_JUMP) {
							return (
								<Item key={page}>
									<PageJump></PageJump>
								</Item>
							);
						}

						return (
							<Item key={page}>
								<PageNumber
									as={currentPage === page && 'span'}
									buttonType='minimal'
									title={`Go to page ${page}`}
									isActive={currentPage === page}
									isDisabled={currentPage === page}
									onClick={() => handlePageUpdate(page)}
								>
									{page}
								</PageNumber>
							</Item>
						);
					})
				)}

				<Item>
					<Next
						title={currentPage < totalPages && `Go to next page (${currentPage + 1})`}
						isDisabled={currentPage >= totalPages}
						buttonType='containerless'
						iconName='arrow'
						iconSize='md'
						data-arrow-control='next'
						aria-label='Next page'
						onClick={() => handlePageUpdate(currentPage + 1)}
					/>
				</Item>
			</Items>
		</Nav>
	);
};
