import React from 'react';

import { Carousel } from 'components/Carousel';
import { RecommendedForYouProps } from './RecommendedForYouProps';
import { RecommendedForYouCard } from './RecommendedForYouCard';

export const RecommendedForYou: React.FC<RecommendedForYouProps> = React.memo(({ residenceCards }) => (
	<Carousel>
		{residenceCards.map(card => (
			<RecommendedForYouCard {...card} key={card.residenceName} />
		))}
	</Carousel>
));
