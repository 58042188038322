import styled, { css } from 'styled-components';

import { textBase } from 'design-system/style-mixins/text';
import { white, textColorMedium, textColorDark } from 'design-system/variables/colors';
import { boxShadow1 } from 'design-system/style-mixins/shadows-and-scrims';
import {
	spaceTiny,
	spaceLarge,
	spaceBase,
	spaceSmall,
	spaceBig,
	spaceMedium,
	spaceHuge,
} from 'design-system/variables/spaces';
import { md } from 'design-system/media-queries';

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const SearchBar = styled.div`
	${textBase};
	${boxShadow1}
	position: relative;
	background-color: ${white};
	border-radius: 28px;
	box-sizing: border-box;
	${textColorMedium}
	display: flex;
	align-items: center;
	height: 56px;
	outline: none;
	width: 100%;
	padding: ${spaceTiny} ${spaceLarge} ${spaceTiny} ${spaceSmall};

	${md`
		padding-left: ${spaceBase};
	`}

	${props =>
		props.theme.isDropdownOpen &&
		css`
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
		`}
`;

export const dropdownStyle = css`
	${boxShadow1}
	background-color: ${white};
    overflow-x: hidden;
    width: 100%;
    z-index: 1000;
	max-height: 400px;
	overflow-y: scroll;
	border-radius: 0 0 28px 28px;

	-ms-overflow-style: none;
    	scrollbar-width: none;
	::-webkit-scrollbar {
    	display: none;
	}

	li:last-of-type {
		margin-bottom: ${spaceBig};
	}
`;

export const headerStyle = css`
	border-bottom: none;
	margin-left: ${spaceMedium};

	&& span {
		${textColorDark}
	}
`;

export const resultStyle = css`
	${textColorMedium}
	padding-left: ${spaceBig};

	:hover {
		${textColorDark}
	}
`;

export const SearchResultsWrapper = styled.div`
	position: relative;
	width: 100%;
	height: auto;
	border-radius: 0 0 28px 28px;

	:after {
		content: '';
		position: absolute;
		display: block;
		bottom: 0;
		left: 0;
		height: ${spaceHuge};
		pointer-events: none;
		width: 100%;
		z-index: 1000;
		background: linear-gradient(0deg, #fff, #fff 24%, hsla(0, 0%, 100%, 0));
		border-radius: 0 0 28px 28px;
	}
`;
