import { useState, useEffect } from 'react';
import root from 'window-or-global';

export const useIsTabletOrDesktop = () => {
	const [isTabletOrDesktop, setIsTabletOrDesktop] = useState(root.innerWidth >= 768);

	const handleResize = () => {
		setIsTabletOrDesktop(root.innerWidth >= 768);
	};

	useEffect(() => {
		handleResize();
		root.addEventListener('resize', handleResize);

		return () => root.removeEventListener('resize', handleResize);
	}, []);

	return [isTabletOrDesktop, setIsTabletOrDesktop];
};
