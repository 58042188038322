import { SortOptions, InspiratoOnlySortOptions } from '../../Sort/SortOptions';
import { ExperienceCard } from '../../types';
import { defaultState as globalDefaultState, State } from '../context';
import {
	SET_INITIAL_DATA,
	UPDATE_DATE_FILTER,
	UPDATE_EXPERIENCES_FILTER,
	SET_RESULTS,
	CLEAR_DATES,
	CLEAR_EXPERIENCE_TYPES,
	RESET_RESULTS,
	UPDATE_SORT,
	CLEAR_SORT,
	UPDATE_LOCATION_FILTER,
	CLEAR_LOCATION_FILTER,
	SET_OFFSET,
} from '../constants';

const defaultState = globalDefaultState;
const isInspiratoOnly = (card: ExperienceCard) => card.inspiratoOnly;
const getInitialData = (action: any, state: State) => {
	const showInspiratoOnlySort =
		action.payload.data.some(isInspiratoOnly) && !action.payload.data.every(isInspiratoOnly);

	const sortOptions = showInspiratoOnlySort ? InspiratoOnlySortOptions : SortOptions;

	return {
		...state,
		dates: action.payload.dates,
		experiences: action.payload.data,
		experienceTypes: action.payload.experienceTypes,
		locations: action.payload.locations,
		results: action.payload.data,
		sort: {
			...sortOptions[0],
			direction: sortOptions[0].direction !== null ? sortOptions[0].direction.default : 1,
		},
		sortOptions,
	};
};

export const experiencesReducer = (state: State = defaultState, action) => {
	switch (action.type) {
		case SET_INITIAL_DATA:
			return getInitialData(action, state);
		case UPDATE_DATE_FILTER:
		case CLEAR_DATES:
			return {
				...state,
				dates: action.payload,
			};
		case UPDATE_EXPERIENCES_FILTER:
		case CLEAR_EXPERIENCE_TYPES:
			return {
				...state,
				experienceTypes: action.payload,
			};
		case UPDATE_LOCATION_FILTER:
		case CLEAR_LOCATION_FILTER:
			return {
				...state,
				locations: action.payload,
			};
		case UPDATE_SORT:
			return {
				...state,
				sort: action.payload,
			};
		case CLEAR_SORT:
			return {
				...state,
				sort: {
					...state.sortOptions[0],
					direction: state.sortOptions[0].direction ? state.sortOptions[0].direction.default : 1,
				},
			};
		case SET_RESULTS:
			return {
				...state,
				results: action.payload.newResults,
				dates: action.payload.newDates,
				experienceTypes: action.payload.newExperienceTypes,
				locations: action.payload.newLocations,
				offset: 0,
			};
		case RESET_RESULTS:
			return {
				...state,
				results: [],
			};
		case SET_OFFSET:
			return {
				...state,
				offset: action.payload,
			};
		default:
			return state;
	}
};
