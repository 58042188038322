import styled from 'styled-components';

export const Img = styled.img``;

export const Picture = styled.picture`
	${Img} {
		left: 50%;
		top: 50%;
		min-height: 100%;
		min-width: 100%;
		width: auto;
		height: auto;
		max-width: 100%;
		object-fit: cover;
		position: absolute;
		transform: translateX(-50%) translateY(-50%);
	}
`;
