import React, { FC } from 'react';
import Highlight from 'react-highlighter';

import { AccessibleMenuOption } from 'components/KeyboardAccessibility/components/AccessibleMenuOption';
import { ListItem, DestinationInfo, Name, AdditionalBlock } from '../SearchResultsSet.styles';
import { ResultProps } from '../SearchResultsSetProps';

export const Result: FC<ResultProps> = ({ item, identificator, handleClick, searchWord }) => {
	const onClick = () => {
		handleClick && handleClick(item.name, item);
	};

	return (
		<AccessibleMenuOption label={item[identificator]}>
			<ListItem data-url={item.url} data-text={item.name} onClick={onClick}>
				<DestinationInfo>
					<Name>
						<Highlight search={searchWord}>{item.name}</Highlight>
					</Name>
					{item.resort && (
						<AdditionalBlock>
							<Highlight search={searchWord}>{item.resort}</Highlight>
						</AdditionalBlock>
					)}
					{item.destination && (
						<AdditionalBlock>
							<Highlight search={searchWord}>{item.destination}</Highlight>
						</AdditionalBlock>
					)}
				</DestinationInfo>
			</ListItem>
		</AccessibleMenuOption>
	);
};
