import { useState, useEffect } from 'react';
import root from 'window-or-global';
import { useIsDesktop } from 'components/hooks/useIsDesktop';

export const useDisabledScroll = (isDisabled: boolean = false) => {
	const [disabledScroll, setDisabledScroll] = useState(isDisabled);
	const [isDesktop] = useIsDesktop(768);

	useEffect(() => {
		if (disabledScroll && !isDesktop) {
			root.document.documentElement.style.overflow = 'hidden';
			root.document.body.style.overflow = 'hidden';
		} else {
			root.document.documentElement.style.overflow = 'unset';
			root.document.body.style.overflow = 'unset';
		}
	}, [disabledScroll, isDesktop]);

	return [disabledScroll, setDisabledScroll];
};
