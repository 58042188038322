import React, { memo, useState } from 'react';
import styled from 'styled-components';

import { NavigationProps } from './NavigationProps';
import { NavigationTab } from './NavigationTab';
import { md } from '../../design-system/media-queries';
import { spaceBase, spaceMassive } from '../../design-system/variables/spaces';
import { PrimaryNavigationContext } from './PrimaryNavigationContext';

const Wrapper = styled.nav`
	${md`
		order: 2;
		display: flex;
		max-width: 640px;
		width: 100%;
	`}
`;
const Menu = styled.ul`
	list-style: none;

	${md`
		justify-content: space-around;
		display: flex;
		flex-direction: row;
		height: ${spaceMassive};
		justify-content: space-around;
		min-height: ${spaceMassive};
		padding: 0 ${spaceBase};
		position: static;
		width: 100%;
	`}
`;

export const PrimaryNavigation: React.FC<NavigationProps> = memo(({ links }) => {
	const [openedTab, setOpenedTab] = useState(null);

	return (
		<PrimaryNavigationContext.Provider value={{ openedTab, setOpenedTab }}>
			<Wrapper>
				<Menu>
					{links.map(link => (
						<NavigationTab key={link.linkTitle} {...link} />
					))}
				</Menu>
			</Wrapper>
		</PrimaryNavigationContext.Provider>
	);
});
