import * as React from 'react';
import { getSafeHtml } from 'services/Helpers';
import styled from 'styled-components';
import { Headline } from '../../components/Headline';
import { Subhead } from '../../components/Subhead';
import { containerWidth } from '../../design-system/style-mixins/grid-and-layout';
import { richText } from '../../design-system/utils';
import { textColorMedium, textColorDark } from '../../design-system/variables/colors';
import { spaceLarge } from '../../design-system/variables/spaces';

const Wrapper = styled.header`
	margin-left: auto;
	margin-right: auto;
	position: relative;
	${containerWidth};
`;

const SectionHeadline = styled(props => <Headline {...props} />)`
	&& {
		margin: 0 auto ${spaceLarge};
	}
	text-align: center;
`;

const SectionSubhead = styled(props => <Subhead {...props} />)`
	display: block;
	margin: auto;
	text-align: center;
	margin-bottom: ${spaceLarge};
	${textColorDark}

	p {
		color: inherit;
		margin: 0;
	}
`;

const Body = styled.div`
	${textColorMedium}
	${richText}
	text-align: center;
	margin: ${spaceLarge} auto ${spaceLarge} auto;
`;

export const Header = props => {
	const { animationSettings = {}, headline, textColor, subhead, body } = props;
	return headline || subhead ? (
		<Wrapper
			data-aos={animationSettings.animation}
			data-aos-once={animationSettings.once}
			data-aos-delay={animationSettings.delay}
		>
			{headline && headline.length && (
				<SectionHeadline
					as='h2'
					size='large'
					style={{ color: textColor }}
					className='row center-xs col-xs-12 col-lg-10'
					dangerouslySetInnerHTML={getSafeHtml(headline)}
				/>
			)}
			{subhead && subhead.length && (
				<SectionSubhead
					style={{ color: textColor }}
					className='col-xs-12 col-sm-8'
					dangerouslySetInnerHTML={getSafeHtml(subhead)}
				/>
			)}
			{body && body.length && (
				<Body
					style={{ color: textColor }}
					className='col-xs-12 col-sm-8'
					dangerouslySetInnerHTML={getSafeHtml(body)}
				/>
			)}
		</Wrapper>
	) : null;
};
