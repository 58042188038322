import styled from 'styled-components';
import { spaceBase, spaceLarge, spaceTiny } from 'design-system/variables/spaces';
import { boxShadow3 } from 'design-system/style-mixins/shadows-and-scrims';
import { IconButton } from 'components/';
import { fadeInAnimation } from 'design-system/style-mixins/animations';
import { focusOutlineColor, textColorMedium } from 'design-system/variables/colors';

export const PopUp = styled.div<{ showChatButton: boolean }>`
	--space: ${spaceBase};
	--image-size: 50px;
	--border-width: 2px;
	--position-offset: ${spaceBase};
	--bg-color: hsl(0, 0%, 100%);
	--bg-color-accent: hsl(0, 0%, 90%);
	--duration: 500ms;
	--delay: calc(var(--duration) / 1.5);
	--bounce-out: cubic-bezier(0.68, -0.6, 0.32, 1.6);
	--ease-out: cubic-bezier(0.22, 1, 0.36, 1);

	position: fixed;
	right: var(--position-offset);
	bottom: ${spaceLarge};
	display: ${(props) => (props.showChatButton ? 'flex' : 'none')};
	border-radius: 360px;
	opacity: 0;
	font-size: 1rem;
	line-height: 1.05;
	z-index: 1000000;
	transform-origin: bottom right;
	transform: translateY(75%);
	transition: var(--duration) var(--delay) var(--bounce-out);
	transition-property: transform, opacity;

	&[data-focus-visible-added] {
		outline: 2px solid ${focusOutlineColor};
		outline-offset: 2px;
	}

	&.is-visible {
		opacity: 1;
		transform: translateY(0);
		transition-delay: 0s;
		transition-timing-function: var(--ease-out);
		cursor: pointer;
	}

	&.hide {
		display: none;
	}
`;

export const ChatWrapper = styled.div`
	display: flex;
	overflow: hidden;
	border-radius: 360px 0 0 360px;
	box-shadow: 0 0 0 0.15;
	transition: box-shadow var(--duration) var(--ease-out);

	.chat.is-visible & {
		${boxShadow3}
		transition-delay: calc(var(--delay) * 2);
	}
`;

export const ChatContent = styled.div`
	display: flex;
	align-items: center;
	position: relative;
	height: var(--image-size);
	padding-right: calc(var(--image-size) / 2);
	background-color: var(--bg-color);
	border-radius: inherit;
	transform-origin: center right;
	transform: translateX(100%);
	transition: transform var(--duration) calc(var(--delay) / 2) var(--ease-out);

	.chat.is-visible & {
		transform: translateX(0);
		transition-delay: var(--delay);
	}

	> * {
		opacity: 0;
		transition: opacity calc(var(--duration) / 2) var(--ease-out);

		.chat.is-visible & {
			opacity: 1;
			transition-duration: var(--duration);
			transition-delay: calc(var(--delay) * 1.5)
		}
	}
`;

export const ChatText = styled.div`
	${textColorMedium};
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	overflow: hidden;
	margin-left: ${spaceTiny};
	margin-right: calc(var(--space) - var(--border-width));
`;

export const ChatCloseButton = styled((props) => <IconButton {...props} />)`
	cursor: pointer;
	position: relative;
	left: calc(var(--space) / 2);
	padding: 0;
	border: none;
	background-color: transparent;
	font-size: inherit;
	font-family: inherit;
	line-height: 0;
	z-index: 1;
`;

export const ChatImage = styled.div`
	margin: 0px;
	padding: 0px;
	outline: none;
	text-decoration: none;
	background-image: url(https://cms.inspirato.com/media/9438648/gettyimages-1180926773.jpg?width=180);
	background-position: -53px -10px;
	background-size: 180px;
	position: relative;
	margin-left: calc(var(--image-size) / -2);
	width: var(--image-size);
	height: var(--image-size);
	border-radius: 360px;
	border: var(--border-width) solid var(--bg-color);
	background-color: var(--bg-color-accent);
`;

export const ChatButtonStyled = styled((props) => <IconButton {...props} />)<{
	showChatButton: boolean;
}>`
	${boxShadow3}
	${fadeInAnimation}
	bottom: ${spaceLarge};
	display: ${(props) => (props.showChatButton ? 'flex' : 'none')};
	position: fixed;
	right: ${spaceBase};
    z-index: 1000000;
`;
