import { useState, useEffect } from 'react';
import root from 'window-or-global';
import debounce from 'lodash.debounce';

const MOBILE_MAX_WIDTH = 767;

export const useStateIsMobile = (): boolean => {
	const [isMobile, setIsMobile] = useState(root.innerWidth <= MOBILE_MAX_WIDTH);

	const handleResize = debounce(() => {
		setIsMobile(root.innerWidth <= MOBILE_MAX_WIDTH);
	}, 100);

	const update = () => {
		handleResize();
		root.addEventListener('resize', handleResize);
		return () => root.removeEventListener('resize', handleResize);
	};

	useEffect(update, []);

	return isMobile;
};
