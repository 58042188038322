import { useEffect, useState } from 'react';
import { TripTypeIR } from '../enums/TripType.enum';

export const useTripIRParam = (): { inspiratoRewards: number; isNonPassRewards: (ir: number) => boolean; isInvalidRewards: (ir: number) => boolean } => {
	const [inspiratoRewards, setInspiratoRewards] = useState(null);

	useEffect(() => {
		const searchParams = new URLSearchParams(document.location.search);
		const ir = searchParams.get('ir');
		const irParsed = parseInt(ir);
		setInspiratoRewards(isNaN(irParsed) || isInvalidRewards(irParsed) ? 0 : irParsed);
	}, []);

	const isNonPassRewards = (ir: number) => ir === TripTypeIR.Comp || ir === TripTypeIR.IFB || ir === TripTypeIR.IFG;

	const isInvalidRewards = (ir: number) => !(ir in TripTypeIR);

	return { inspiratoRewards, isNonPassRewards, isInvalidRewards };
};
