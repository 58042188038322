import React, { FC, memo } from 'react';
import { Link } from 'components/Link';
import { renderLoaderOrContent } from 'components/Loader/LoaderSkeleton';
import { Tag } from 'components/Tag';
import { TitleContainer, CardTitleWrapper, CardTitle, Subtitle } from '../ListCard.styles';

interface Props {
	isListAndIsDesktop: boolean;
	isPlaceholder: boolean;
	propertyName: string;
	unitName: string;
	locationName: string;
	isNew: boolean;
	viewHomeProps: Function;
}
export const Header: FC<Props> = memo(
	({ propertyName, isListAndIsDesktop, isPlaceholder, unitName, locationName, isNew, viewHomeProps }) => {
		return (
			<TitleContainer hasPropertyName={!!propertyName} isList={isListAndIsDesktop}>
				<CardTitleWrapper>
					{renderLoaderOrContent(
						isPlaceholder,
						<CardTitle title={unitName} {...viewHomeProps()}>
							{unitName}
						</CardTitle>,
						{ width: '10em' },
					)}
					{!isPlaceholder && isNew ? <Tag>New</Tag> : ''}
				</CardTitleWrapper>
				<Subtitle title={locationName}>
					{renderLoaderOrContent(isPlaceholder, locationName, { width: '7em' })}
				</Subtitle>
				<Subtitle title={propertyName}>
					{renderLoaderOrContent(isPlaceholder, propertyName || 'Inspirato Residence', {
						width: '5em',
					})}
				</Subtitle>
			</TitleContainer>
		);
	},
);
