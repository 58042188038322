import React, { useRef, useEffect, useContext } from 'react';
import styled, { css } from 'styled-components';
import { LoaderSpinner } from 'components/Loader';
import { CSSTransition } from 'react-transition-group';
import {
	grayDark,
	tapTransparent,
	primaryLinkColor,
	gray,
	dividerGrayLight,
	blue,
} from '../../design-system/variables/colors';
import { spaceTiny, spaceBase, spaceSmall } from '../../design-system/variables/spaces';
import { Icon } from '../Icon';
import { AccordionContext } from './AccordionContext';

export interface AccordionProps {
	accordionId?: any;
	containerStyles?: any;
	currentAccordion?: any;
	isLoading?: boolean;
	title: string | JSX.Element;
	titleStyles?: any;
	width?: string;
	onToggle?: (openState) => void;
	filterAccordion?: boolean;
}

export const Accordion: React.FunctionComponent<AccordionProps> = ({
	accordionId,
	children,
	currentAccordion,
	isLoading,
	title,
	titleStyles,
	onToggle,
	width,
	...rest
}) => {
	const [expanded, setExpanded] = React.useState(false);
	const { expandedId, setExpandedId } = useContext(AccordionContext);
	const accordionRef = useRef(null);
	const contentRef = useRef(null);

	const toggle = () => {
		if (!isLoading) {
			const newState = !expanded;

			if (!newState) {
				setExpandedId(null);
			} else {
				setExpandedId(accordionId);
			}

			!newState && setExpanded(newState);
		}
	};

	useEffect(() => {
		if (expanded && expandedId !== accordionId) {
			setExpanded(false);
		}

		if (expandedId === accordionId) {
			setExpanded(true);
			accordionRef.current.scrollIntoView({ alignToTop: true, behavior: 'smooth' });
		}
	}, [expandedId]);

	useEffect(() => {
		if (onToggle) {
			onToggle(expanded);
		}
	}, [expanded]);

	return (
		<Wrapper {...{ width }} {...rest} ref={accordionRef}>
			<Title
				onClick={toggle}
				styles={titleStyles}
				{...{ expanded }}
				isLoading={isLoading}
				currentAccordion={expandedId}
				accordionId={accordionId}
			>
				{title}
				{isLoading && currentAccordion === accordionId && <Spinner />}
				<Icon iconName='arrow' iconSize='md' />
			</Title>
			<CSSTransition
				in={expanded}
				timeout={{
					appear: 200,
					enter: 200,
					exit: 0,
				}}
				classNames='accordion-toggle'
			>
				<Content
					ref={contentRef}
					height={contentRef.current && contentRef.current.scrollHeight}
					expanded={expanded}
				>
					{children}
				</Content>
			</CSSTransition>
		</Wrapper>
	);
};

type WrapperProps = Pick<AccordionProps, 'width' | 'containerStyles' | 'filterAccordion'>;

const Wrapper = styled.div<WrapperProps>`
	background-color: transparent;
	padding: ${spaceTiny} 0 ${spaceBase};
	width: ${(props: WrapperProps) => props.width};
	${props => props.containerStyles};
	${props =>
		props.filterAccordion &&
		css`
			& > div:first-of-type {
				border-bottom: ${dividerGrayLight};
				padding-bottom: ${spaceSmall};
			}
		`}
`;

interface TitleProps {
	expanded: boolean;
	styles?: any;
	isLoading?: boolean;
	currentAccordion: any;
	accordionId: string;
}

const Title = styled.div<TitleProps>`
	display: flex;
	align-items: center;
	${tapTransparent}
	cursor: pointer;
	position: relative;
	user-select: none;
	z-index: 1;

	${props =>
		props.isLoading &&
		props.currentAccordion !== props.accordionId &&
		css`
			color: ${gray};
		`}

	${props =>
		props.styles &&
		css`
			${props.styles}
		`}

	${props =>
		props.expanded &&
		css`
			color: ${primaryLinkColor};
		`}

	${props => props.theme && props.theme.accordionTitleStyles}

	svg {
		transform: ${(props: TitleProps) => (props.expanded ? 'rotate(-90deg)' : 'rotate(90deg)')};
		transition: transform 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
		display: inline-block;
		position: absolute;
		right: 0;
		top: 0;
		fill: ${grayDark};
	}
`;

const Content = styled.div<{ height?: number; expanded: boolean }>`
	background-color: transparent;
	overflow: hidden;
	height: ${props => (props.expanded ? props.height : 0)};
	transition: height 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
	position: relative;
	z-index: 0;

	&.accordion-toggle-exit-active {
		height: 0;
	}

	&.accordion-toggle-enter,
	&.accordion-toggle-exit {
		height: ${props => props.height}px;
	}

	&.accordion-toggle-enter-done {
		height: auto;
	}
`;

const Spinner = styled(props => <LoaderSpinner {...props} />)`
	height: auto;
	display: inline-flex;
	margin-left: 20px;

	svg {
		position: relative !important;
		height: 20px;
		width: 20px;
	}
`;

export const FilterAccordion = styled(props => <Accordion {...props} />)`
	& > div:first-of-type {
		border-bottom: ${dividerGrayLight};
		padding-bottom: ${spaceSmall};
	}

	em {
		align-items: center;
		background: ${blue};
		border-radius: 10px;
		display: inline-flex;
		justify-content: center;
		line-height: 0;
		left: 10px;
		position: relative;
		text-align: center;
		width: 25px;
		font-size: 12px;
		height: 15px;
		font-style: normal;
		font-weight: bold;
	}
`;
