import React, { FC, useState } from 'react';
import { Modal } from 'components';
import { Formik } from 'formik';
import { addProposalNPO } from 'pages/TripProposals/services/tripRecommendationsApiService';
import { CreateNPORequest } from 'pages/TripProposals/interfaces';
import { useDispatch } from 'react-redux';
import { refreshProposal } from 'pages/TripProposals/views/ProposalManagementView/redux/actions';
import { NPOForm } from '../NpoForm/NPOForm';
import { CancelButton, Container, FormContainer, modalStyles, Overlay, Spinner, Wrapper } from '../NpoForm/NPOStyles';
import { NpoValidationSchema } from '../NpoForm/NPOValidation';
import { ErrorBanner, BannerErrorWrapper } from '../ErrorBanner/ErrorBanner';
import { updateDirty } from 'pages/TripProposals/views/ProposalManagementView/redux/actions/proposal';

export const AddNPO: FC<{ isOpen: boolean; onClose: Function; proposalId: number }> = ({
	isOpen,
	onClose,
	proposalId,
}) => {
	const dispatch = useDispatch();
	const [spinner, setSpinner] = useState(false);
	const [npoErrorMessage, setNpoErrorMessage] = useState('');

	const formikSubmit = (values, actions) => {
		setSpinner(true);
		const dateRangesToSend = values.dateRanges.filter((element) => {
			return element.checkInDate !== '' && element.checkOutDate !== '' && element.reservationTotal !== '';
		});
		const hasDateRangesToSend = dateRangesToSend && dateRangesToSend.length && (dateRangesToSend.length > 1 || dateRangesToSend[0].reservationTotal !== undefined);
		const request: CreateNPORequest = {
			propertyName: values.propertyName,
			propertyUrl: values.propertyUrl !== '' ? values.propertyUrl : null,
			NonPortfolioAccommodationName: values.NonPortfolioAccommodationName,
			destination: values.destination,
			photoUrl: 'https://cms.inspirato.com/ImageGen.ashx?image=/media/9416838/broken-img-16x9.png',
			guests: values.guests,
			bathrooms: values.bathrooms,
			bedrooms: values.bedrooms,
			squareFeet: values.squareFeet,
			Note: values.note,
			DateRanges: hasDateRangesToSend ? dateRangesToSend : null,
		};

		addProposalNPO(proposalId, request)
			.then(async (value) => {
				if (value) {
					await new Promise((res) => res(dispatch(refreshProposal())));
					onClose();
				}
				setSpinner(false);
				dispatch(updateDirty());
			})
			.catch(() => {
				setNpoErrorMessage('Uh oh!  There was an error trying to save this accommodation. Please try again.');
				setSpinner(false);
			});
	};
	const handleBannerClose = () =>
	{
		setNpoErrorMessage('');
	}

	return (
		<Modal isOpen={isOpen} contentStyles={modalStyles} zIndex={9999}>
			<Wrapper>
			<BannerErrorWrapper>
			{npoErrorMessage && (
				<ErrorBanner isOpen={npoErrorMessage !== ''} message={npoErrorMessage} handleClose={handleBannerClose} />
			)}
			</BannerErrorWrapper>
				<Formik
					onSubmit={formikSubmit}
					initialValues={{
						NonPortfolioAccommodationName: '',
						propertyName: '',
						destination: '',
						url: '',
						note: '',
						dateRanges: [
							{
								renderId: `${Math.random()}`,
								checkInDate: '',
								checkOutDate: '',
								reservationTotal: '',
								selectedDays: '',
								reservationTotalNeeded: false,
							},
						],
					}}
					validationSchema={NpoValidationSchema}
					validateOnChange={false}
					validateOnBlur={false}
				>
					<Container className='col-lg-7'>
						<CancelButton onClick={() => {handleBannerClose(); onClose();}}>Cancel</CancelButton>
						<FormContainer>
							<NPOForm proposalId={proposalId} edit={false} />
						</FormContainer>
					</Container>
				</Formik>
			</Wrapper>
			{spinner && (
				<Overlay>
					<Spinner />
				</Overlay>
			)}
		</Modal>
	);
};
