import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { SortOption } from '../../types';
import { UPDATE_SORT, CLEAR_SORT } from '../constants';
import { State } from '../context';

export const updateSort = (option: SortOption) => (dispatch: ThunkDispatch<State, undefined, Action>) => {
	dispatch({ type: UPDATE_SORT, payload: option });
};

export const clearSort = () => (dispatch: ThunkDispatch<State, undefined, Action>) => {
	dispatch({
		type: CLEAR_SORT,
		payload: {},
	});
};
