import { FilterCheckbox, ExperienceCard, SortOption } from '../types';
import { SortOptions } from '../Sort/SortOptions';
import { PAGE_SIZE } from './constants';

type Date = FilterCheckbox & { relatedExperiences: string[]; relatedLocations: string[] };
type Experience = FilterCheckbox & { relatedDates: string[]; relatedLocations: string[] };
type Location = FilterCheckbox & { relatedDates: string[]; relatedExperiences: string[] };

export interface State {
	dates: Date[];
	experiences: ExperienceCard[];
	experienceTypes: Experience[];
	locations: Location[];
	results: ExperienceCard[];
	sort: SortOption;
	sortOptions: SortOption[];
	offset: number;
	pageSize: number;
}

export const defaultState: State = {
	dates: [],
	experiences: [],
	experienceTypes: [],
	locations: [],
	offset: 0,
	pageSize: PAGE_SIZE,
	results: [],
	sort: SortOptions[0],
	sortOptions: SortOptions,
};
