import { ComponentProps } from 'interfaces';
import { CallToActionProps } from 'components/ActionLink/CallToActionProps';

export interface TripFinderProps extends ComponentProps {
	callToAction: CallToActionProps;
	dryFlyApiBaseURL: string;
}

export enum SearchType {
	None = -1,
	Destinations = 1,
	Regions = 2,
	VacationStyles = 3,
	Countries = 4,
	StatesProvinces = 5,
	HotelsResorts = 6,
	Accommodations = 7,
	Experiences = 8,
}

export interface SearchCriteria {
	text: string;
	id: number;
	type: SearchType;
}

export interface SearchCategory {
	type: number;
	header: string;
	sortOrder: number;
	searchCriteria: SearchCriteria[];
}

export interface AutocompleteOption extends SearchCriteria {
	key: string;
	value: string;
}

export interface SearchResultItem extends AutocompleteOption {
	name: string;
}

export interface SearchResult {
	category: string;
	items: SearchResultItem[];
}

export interface SearchResultsProps {
	searchWord: string;
	searchResults: SearchResult[];
	handleClick: Function;
	isLoading: boolean;
}
