import React, { FC, Fragment, memo } from 'react';
import { containerWidthMediumPx } from 'design-system/style-mixins/grid-and-layout';
import { useIsDesktop } from 'components/hooks';
import { Button } from 'components/Button';
import { ActionsClearButton, ActionsApplyButton } from './FilterButtonActions.styles';

interface Props {
	onClearClick: () => void;
	onApplyClick: () => void;
	isDisabled: boolean;
}
export const FilterButtonActions: FC<Props> = memo(({ onClearClick, onApplyClick, isDisabled }) => {
	const [isDesktop] = useIsDesktop(containerWidthMediumPx);

	const handleClearClick = () => {
		if (!isDisabled) {
			onClearClick();
		}
	};

	return (
		<Fragment>
			{onClearClick && (
				<ActionsClearButton onClick={handleClearClick} isDisabled={isDisabled} disabled={isDisabled}>
					Clear
				</ActionsClearButton>
			)}
			{onApplyClick &&
				(isDesktop ? (
					<ActionsApplyButton onClick={() => onApplyClick()}>Apply</ActionsApplyButton>
				) : (
					<Button buttonType='primary' size='large' onClick={() => onApplyClick()}>
						View Trips
					</Button>
				))}
		</Fragment>
	);
});
