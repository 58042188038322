import { gray, black } from 'design-system/variables/colors';
import styled, { css } from 'styled-components';
import { spaceLarge } from 'design-system/variables/spaces';

const thumbBorder = 1;

const thumbBaseStyles = css<{ thumbWidthPx: number; left: number }>`
	border: ${thumbBorder}px solid #000000;
	height: ${({ thumbWidthPx }) => thumbWidthPx}px;
	width: ${({ thumbWidthPx }) => thumbWidthPx}px;
	border-radius: ${({ thumbWidthPx }) => thumbWidthPx}px;
	background: #ffffff;
	cursor: pointer;
	top: 4px;
	position: absolute;
	left: ${({ left }) => left - 1}%;
	@-moz-document url-prefix() {
		top: 10px;
	}
`;

const rangeTrackStyles = css`
	width: 100%;
	height: 3px;
	cursor: pointer;
	background-color: ${gray};
	border-radius: 1px;
`;

export const StyledInputRange = styled.div<{ thumbWidthPx: number; left: number }>`
	display: inline-block;
	width: 100%;
	position: relative;
	margin-top: 30px;
	&& {
		input[type='range'] {
			-webkit-appearance: none;
			margin: 12px 0px;
			width: 100%;
			&:focus {
				outline: none;
				&::-webkit-slider-runnable-track {
					background: ${gray};
				}
			}
			&::-webkit-slider-runnable-track {
				${rangeTrackStyles}
			}
			&::-webkit-slider-thumb {
				appearance: none;
				-webkit-appearance: none;
				${thumbBaseStyles}
			}
			&::-moz-range-track {
				${rangeTrackStyles}
			}
			&::-moz-range-thumb {
				appearance: none;
				${thumbBaseStyles}
				height: 8px;
				width: 8px;
			}
		}
	}
`;

export const StyledThumb = styled.div<{ thumbWidthPx: number; left: number }>`
	${thumbBaseStyles}
	pointer-events: none;
	@-moz-document url-prefix() {
		left: ${({ left }) => left - 2}%;
	}
`;
export const StyledTrack = styled.div<{ left: number; isDisabled: boolean }>`
	pointer-events: none;
	width: ${({ left }) => left}%;
	position: absolute;
	height: 3px;
	background-color: ${({ isDisabled }) => isDisabled ? gray : black};
	top: 13px;
	@-moz-document url-prefix() {
		top: 21px;
		width: ${({ left }) => left - 2}%;
	}
	`;

export const Balloon = styled.div<{ left: number }>`
	left: ${({ left }) => left - 1}%;
	top: -${spaceLarge};
	text-align: center;
	position: absolute;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	@-moz-document url-prefix() {
		left: ${({ left }) => left - 2}%;
	}
`;

export const BalloonLegend = styled.div`
	text-align: center;
`;
