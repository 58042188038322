import { FC, useState, useEffect } from 'react';
import root from 'window-or-global';

import { Props } from './KeyboardAccessibility.types';
import { Context } from './Context';
import { scrollIntoViewHorizontal, scrollIntoViewVertical } from './utils';

export const KeyboardAccessibility: FC<Props> = ({
	scrollToSelectedOption,
	selectedOptionsMenuId,
	isMenuOpen,
	children,
	isMulti,
	menuId,
}) => {
	const [activeId, setActiveId] = useState(null);
	const scrollToSelected =
		typeof scrollToSelectedOption === 'function' ? scrollToSelectedOption : scrollIntoViewVertical;

	useEffect(() => {
		if (activeId) {
			const activeElement = root.document.getElementById(activeId);
			const isHorizontalMenu = activeId.startsWith('selected-');

			if (isHorizontalMenu) {
				const selectedOptionsMenu = root.document.getElementById(selectedOptionsMenuId);
				scrollIntoViewHorizontal(selectedOptionsMenu, activeElement);
			} else {
				const menuElement = root.document.getElementById(menuId);
				scrollToSelected(menuElement, activeElement);
			}
		}
	}, [activeId]);

	return (
		<Context.Provider value={{ activeId, setActiveId, isMenuOpen, menuId, isMulti }}>{children}</Context.Provider>
	);
};
