import React, { FC, memo } from 'react';
import styled, { css } from 'styled-components';

import { Button } from 'components/Button';

import { xsOnly } from 'design-system/media-queries';
import { spaceBase, spaceLarge, spaceBig, spaceSmall, spaceMedium } from 'design-system/variables/spaces';
import { Headline } from 'components/Headline';
import { Paragraph } from 'components/Paragraph';

const Wrapper = styled.div`
	padding: ${spaceBig} ${spaceLarge};
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;

	${props =>
		props.theme.isModal &&
		css`
			flex-grow: 1;
		`}

	${xsOnly`
		padding: ${spaceBase} ${spaceLarge};
	`}
`;

const ClearButton = styled(props => <Button {...props} />)`
	width: 180px;
	height: 40px;
`;

const Header = styled(props => <Headline {...props} />)`
	margin-bottom: ${spaceSmall};
`;

const Message = styled(props => <Paragraph {...props} />)`
	margin: 0 0 ${spaceMedium} 0;
`;

interface Props {
	clearSearch: Function;
}

export const ErrorNothingMatched: FC<Props> = memo(({ clearSearch }) => (
	<Wrapper>
		<Header size='small'>Sorry, Nothing Matched Your Search</Header>
		<Message>Please try something else or clear to view all options.</Message>
		<ClearButton buttonType='secondary' size='small' onClick={clearSearch}>
			Clear search
		</ClearButton>
	</Wrapper>
));
