import styled, { keyframes } from 'styled-components';
import { IconButton } from 'components/Icon/IconButton';
import { boxShadow3 } from '../../design-system/style-mixins/shadows-and-scrims';
import { spaceSmall, spaceTiny, spaceLarge, spaceBase } from '../../design-system/variables/spaces';

export const toggle = ({ isOpen }) => {
	return keyframes`
		from {
			display: ${isOpen ? 'none' : 'flex'};
			opacity: ${isOpen ? 0 : 1};
			top: ${isOpen ? '-32px' : 0};
		}

		to {
			display: ${isOpen ? 'flex' : 'none'};
			opacity: ${isOpen ? 1 : 0};
			top: ${isOpen ? 0 : '-32px'};
		}
	`;
};

export const NotificationContainer = styled.div<{
	backgroundColor: string;
	color: string;
	isOpen: boolean;
	hasIcon: boolean;
}>`
	${boxShadow3}

	animation: ${toggle} 100ms ease-out forwards;
	background-color: ${props => props.backgroundColor};
	color: ${props => props.color};
	display: flex;
	flex-direction: row;
	opacity: 0;
    padding: ${spaceSmall} ${spaceLarge} ${spaceSmall} ${spaceSmall};
    padding-left: ${props => (props.hasIcon ? spaceSmall : spaceBase)};
	position: relative;
	width: 100%;

	svg {
		flex-shrink: 0;
		margin-right: ${spaceTiny};
	}
`;

export const CloseButton = styled(props => <IconButton {...props} />)<{ color: string }>`
	position: absolute;
	right: 0;
	top: 0;

	svg {
		margin: 0;
		fill: ${props => props.color};
	}
`;

export const Content = styled.span<{ centerContent: boolean }>`
	display: flex;
	justify-content: ${props => (props.centerContent ? 'center' : 'flex-start')};
	width: 100%;
`;
