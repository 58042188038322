import styled, { css } from 'styled-components';
import { bold, fontSans, textSmall, textTiny } from '../../design-system/style-mixins/text';
import { textColorDark } from '../../design-system/variables/colors';

const large = css`
	${textSmall}
`;

const small = css`
	${textTiny}
`;

const sizeStyles = {
	large,
	small,
};

export const allCapsStyle = css`
	${fontSans}
	${textColorDark}
	${bold}
	letter-spacing: 2px;
	line-height: 13px;
	text-transform: uppercase;
`;

/**
 * All caps:
 * Used for buttons, main nav items, page divider elements, etc.
 */
export const AllCaps = styled.span<{ size?: 'large' | 'small' }>`
	${allCapsStyle}
	${props => sizeStyles[props.size] || sizeStyles.small}
`;
