import React, { FC } from 'react';
import { getSafeHtml } from 'services/Helpers';
import { useDispatch } from 'react-redux';
import { getFilteredResults } from 'pages/CuratedTrips/redux/actions';
import { ActionLink } from 'components/ActionLink';
import { TravelCardProps } from './TravelCard.types';
import { BodyWrapper, BodyCopy, Content, StyledCard, Icon, HeadingWrapper, Heading } from './TravelCard.styles';
import { useClickProps } from '../useClickProps';

export const TravelCard: FC<TravelCardProps> = ({ backgroundImage = {}, bodyCopy, callToAction, heading, icon }) => {
	const dispatch = useDispatch();

	const handleClick = () => {
		const newParam = callToAction.url.replace('?filter=', '');
		dispatch(getFilteredResults(newParam));
	};
	const initialProps = {
		buttonType: callToAction && callToAction.secondary ? 'secondary' : 'primary',
	};

	const ctaUrl = callToAction && callToAction.url;
	const ctaNewWindow = callToAction && callToAction.newWindow;
	const clickProps = useClickProps(initialProps, ctaUrl, ctaNewWindow, handleClick);

	return (
		<StyledCard backgroundImage={backgroundImage}>
			<Content>
				<HeadingWrapper>
					{icon && icon.fullPath && <Icon src={icon.fullPath} />}
					<Heading as='h3' size='small'>
						{heading}
					</Heading>
				</HeadingWrapper>
				<BodyWrapper>
					<BodyCopy dangerouslySetInnerHTML={getSafeHtml(bodyCopy)} />
					{callToAction && (
						<ActionLink {...clickProps} {...callToAction}>
							{callToAction.linkTitle}
						</ActionLink>
					)}
				</BodyWrapper>
			</Content>
		</StyledCard>
	);
};
