import React from 'react';
import styled, { css } from 'styled-components';

import { textShadow } from 'design-system/style-mixins/shadows-and-scrims';
import { spaceBase, spaceSmall, spaceTiny } from 'design-system/variables/spaces';
import { white, black } from 'design-system/variables/colors';
import { Subtext } from 'components/Subtext';
import { Label } from 'components/Label';

export interface ImageCaptionsProps {
	title: string;
	caption: string;
	darkTheme: boolean;
}

const Wrapper = styled.div<{ darkTheme: boolean }>`
	--theme-color: ${white};

	${props =>
		props.darkTheme &&
		css`
			--theme-color: ${black};
		`}

	display: flex;
	flex-direction: column;
	position: absolute;
	left: ${spaceBase};
	bottom: ${spaceSmall};
	background: transparent;
	z-index: 100000;
	align-items: flex-start;
`;

const textStyles = css`
	color: var(--theme-color);
	${textShadow}
`;

const Title = styled(props => <Label {...props} />)`
	${textStyles}
	margin-bottom: ${spaceTiny};
`;

const Caption = styled(props => <Subtext {...props} />)`
	${textStyles}
	margin-bottom: ${spaceTiny};
`;

export const ImageCaptions: React.FC<ImageCaptionsProps> = ({ title, caption, darkTheme }) => (
	<Wrapper darkTheme={darkTheme}>
		{title && <Title size='medium'>{title}</Title>}
		{caption && <Caption>{caption}</Caption>}
	</Wrapper>
);
