import React from 'react';
import styled, { css } from 'styled-components';

import { spaceSmall } from 'design-system/variables/spaces';
import { TourPreviewProps } from './TourPreviewProps';

import { IconButton } from '../../Icon';

const Wrapper = styled.div<{ width: string; height: string }>`
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background: #ffffff;

	${props =>
		css`
			width: ${props.width || '100%'};
			height: ${props.height || '100%'};
		`}
`;

const StyledIframe = styled.iframe<{ id: string; src: string; frameborder: string; allowfullscreen: boolean }>`
	width: 100%;
	height: 100%;
	margin: 0 auto;
	min-height: 187px;
	border: none;
`;

const CloseButton = styled(props => <IconButton {...props} />)`
	position: absolute;
	right: ${spaceSmall};
	top: ${spaceSmall};
	z-index: 11;
`;

export const TourPreview: React.FunctionComponent<TourPreviewProps> = ({ image, hideTourPreview, width, height }) => (
	<Wrapper width={width} height={height}>
		<StyledIframe id='showcase-player' src={image.tourSrc} frameborder='0' allowfullscreen />
		<CloseButton iconName='close' iconSize='lg' buttonType='containerlessPrimary' onClick={hideTourPreview} />
	</Wrapper>
);
