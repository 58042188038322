import { AccountUserSearchInfo } from '../interfaces';

export class MemberOption implements AccountUserSearchInfo {
	public id: string;

	public accountID: string;

	public accountUserID: string;

	public emailAddress: string;

	public firstName: string;

	public lastName: string;

	public constructor(member: AccountUserSearchInfo) {
		this.id = member.accountUserID;
		this.accountID = member.accountID;
		this.accountUserID = member.accountUserID;
		this.emailAddress = member.emailAddress;
		this.firstName = member.firstName;
		this.lastName = member.lastName;
	}

	public get displayNameOption(): string {
		// Commenting this out to be revisited later
		// return `${this.lastName}, ${this.firstName} <span class="option-details">- ${this.emailAddress}</span>`;
		return `${this.lastName}, ${this.firstName} - ${this.emailAddress}`;
	}

	public get displayName(): string {
		return `${this.lastName}, ${this.firstName} - ${this.emailAddress}`;
	}
}
