import styled, { css } from 'styled-components';
import { textBase } from 'design-system/style-mixins/text';
import { paleBlue, white, black, primaryLinkColor, dividerGrayLight } from 'design-system/variables/colors';
import { spaceSmall, spaceBase } from 'design-system/variables/spaces';
import { sm, md } from 'design-system/media-queries';
import { boxShadow4, transparentDark15 } from 'design-system/style-mixins/shadows-and-scrims';
import { Menu } from '../Menu';
import { TextButton } from '../TextButton';

export const DropdownHeader = styled.header`
	padding: ${spaceBase} 0;
	border-bottom: ${dividerGrayLight};
	text-align: center;
`;

export const DropdownMain = styled.main`
	padding: ${spaceSmall};
`;

export const DropdownFooter = styled.footer`
	border-top: ${dividerGrayLight};
	display: flex;
	justify-content: space-between;
	padding: 0 ${spaceBase};
`;

export const CustomizedModalMenu = styled.div<{
	ref: any;
	hidden: boolean;
	modalStyles?: string;
	overflowAuto: boolean;
}>`
	background-color: ${white};
	bottom: 0;
	left: 0;
	right: 0;
	top: 0;
	display: flex;
	overflow-y: auto;
	position: fixed;
	z-index: 10000;

	${props =>
		props.modalStyles &&
		css`
			${props.modalStyles}
		`}
`;

interface DropdownInnerWrapperTheme {
	dropdownInnerWrapperStyles: any;
}

interface DropdownInnerWrapperProps {
	theme: DropdownInnerWrapperTheme;
}

export const DropdownInnerWrapper = styled.section<DropdownInnerWrapperProps>`
	display: flex;
	flex-direction: column;
	overflow-x: hidden;
	overflow-y: auto;
	height: 100%;
	max-height: 480px;
	${props => props.theme && props.theme.dropdownInnerWrapperStyles}
`;

interface FilterDropdownMenuProps {
	align: string;
	width: string;
	overflowY?: string;
	shadow: string;
	ref?: any;
}

export const FilterDropdownMenu = styled(Menu)<FilterDropdownMenuProps>`
	display: flex;
	flex-direction: column;
	position: absolute;
	top: 48px;
	left: 0;
	width: 100%;
	max-height: calc(345px - 1rem);
	max-width: calc(100vw - 3.5rem);
	background: ${white};
	box-shadow: 0 24px 24px ${transparentDark15};
	border-top: ${dividerGrayLight};
	overflow-y: ${props => (props.overflowY ? props.overflowY : 'auto')};

	${sm`
		${boxShadow4}
		border-top-width: 0;

		left: ${props => (props.align !== 'right' ? 0 : 'initial')};
		right: ${props => (props.align === 'right' ? 0 : 'initial')};
		width: ${props => (props.width ? props.width : 'auto')};
	`};

	${md`
		/* max-height subtracts search filter container height from viewport height */
		max-height: calc(100vh - 106px);
	`};

`;

export const CustomizedDropdownMenu = styled(Menu)<{
	align: string;
	width?: string;
	shadow: string;
	ref: any;
	hidden: boolean;
}>`
	background: ${white};
	left: ${props => (props.align !== 'right' ? 0 : 'initial')};
	right: ${props => (props.align === 'right' ? 0 : 'initial')};
	width: ${props => (props.width ? props.width : 'auto')};
	position: absolute;
	top: 47px;
	z-index: 99999;
`;

export const DropdownContainer = styled.div`
	position: relative;
`;

export const SelectContainer = styled.div`
	display: flex;
	align-items: flex-start;
	flex-direction: column;
`;

export const SelectItem = styled(props => <TextButton {...props} />)<{ enableToggle: boolean }>`
	&& {
		${textBase}

		color: ${props => (props.enableToggle ? primaryLinkColor : black)};
		line-height: 1rem;
		padding: ${spaceSmall};
		text-align: left;
		width: 100%;

		&:hover {
			background-color: ${paleBlue};
			color: ${props => (props.enableToggle ? primaryLinkColor : black)};
		}
	}
`;
