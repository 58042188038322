/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-restricted-globals */
import React, { useState, useRef, useEffect, useContext } from 'react';
import styled, { css, ThemeProvider } from 'styled-components';
import root from 'window-or-global';

import { CmsComposer } from 'components/CmsComposer';
import { CopyBlock } from 'components/CopyBlock';
import { InquiryCard } from 'components/InquiryCard/InquiryCard';
import { determineLayoutStyle, getComponentsPerRowCssClass } from 'components/SectionComponent/Helpers';
import { ExpandableCardProps } from './ExpandableCardProps';
import { spaceBase, spaceLarge, spaceBig } from '../../../design-system/variables/spaces';
import { sm, xsOnly } from '../../../design-system/media-queries';
import { shadowHover } from '../../../design-system/style-mixins/animations';
import { containerWidth } from '../../../design-system/style-mixins/grid-and-layout';
import { boxShadow3 } from '../../../design-system/style-mixins/shadows-and-scrims';
import { fontSans, textBig, textBase } from '../../../design-system/style-mixins/text';
import { black, white } from '../../../design-system/variables/colors';

import { useDisabledScroll } from '../../hooks';

import { ExpandedCardContext } from './ExpandedCardContext';
import Title from '../../Title/Title';
import { IconButton } from '../../Icon/IconButton';

interface Theme {
	isExpanded: boolean;
	backgroundColor?: string;
}

interface Props {
	theme?: Theme;
	onClick?: Function;
}

const ExpandableCardWrapper = styled.div<Props>`
	margin-top: ${spaceBase};
	${props =>
		props.theme.isExpanded &&
		css`
			${xsOnly`
                bottom: 0;
                height: 100%;
                left: 0;
                margin-top: 0;
                position: fixed;
                top: 0;
                transition: 0.5s;
                width: 0;
                z-index: 10000;
            `}
		`}
`;

const ScrollableContent = styled.div<Props>`
	${props =>
		props.theme.isExpanded &&
		css`
			${xsOnly`
                background: ${white};
                height: 100%;
                overflow-y: scroll;
                position: absolute;

                &:after {
                    display: none;
                }
            `}
		`}
`;

const Content = styled.div<Props>`
	${shadowHover};
	cursor: pointer;
	position: relative;

	&:after {
		border-bottom-color: inherit;
		border-bottom-style: solid;
		border-bottom-width: ${spaceBase};
		border-left: ${spaceBase} solid transparent;
		border-right: ${spaceBase} solid transparent;
		content: '';
		display: none;
		left: calc(50% - ${spaceBase});
		margin-top: ${spaceBase};
		position: absolute;
	}

	${sm`
		margin-bottom: ${spaceBase};
	`}

	${props =>
		props.theme.backgroundColor &&
		css`
			border-bottom-color: ${props.theme.backgroundColor};
		`}

	${props =>
		props.theme.isExpanded &&
		css`
			box-shadow: none;
			${xsOnly`
                display: flex;
                flex-direction: column;

                &:after {
                    display: none;
                }

                a:hover {
					text-decoration: none;
				}
            `}

			${sm`
                ${boxShadow3};

                &:after {
                    display: block;
                }
            `}
		`}
`;

const Drawer = styled.div<Props>`
	border-color: inherit;
	display: none;
	width: 100vw;

	${props =>
		props.theme.backgroundColor &&
		css`
			background: ${props.theme.backgroundColor};
		`}

	${props =>
		props.theme.isExpanded &&
		css`
			&& {
				display: block !important;
				${xsOnly`
					&& {
						margin-left: 0;
						margin-top: 0;
					}
           		`}
			}
		`}
	${sm`
		margin-top: ${spaceLarge};
	`}

	.copy-block {
		max-width: 100%;
		padding: 0;

		${sm`
            max-width: 550px;
        `}

		&__heading {
			${fontSans}
			${textBase}
			color: ${black};
			font-weight: 700;
			letter-spacing: normal;
			line-height: 24px;

			margin-bottom: ${spaceBase};
			text-align: left;
		}

		&.copy-block--is-travel-section,
		&.copy-block--has-background {
			padding: ${spaceBase};

			${sm`
                max-width: 750px;
                padding: ${spaceLarge};
            `}
		}
	}
`;

const DrawerWrapper = styled.div<Props>`
	display: flex;
	flex-direction: row !important;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	${containerWidth};
	padding: ${spaceLarge} ${spaceBase};

	${sm`
        padding-left: ${spaceBig};
        padding-right: ${spaceBig};
    `}

	${props =>
		props.theme.isExpanded &&
		css`
			${xsOnly`
                align-items: center;
                flex-direction: column;
                flex-wrap: wrap;
            `}
		`}
`;

const Heading = styled(props => <Title {...props} />)<Props>`
	padding: 12px ${spaceBase};
	display: block;

	${props =>
		props.theme.isExpanded &&
		css`
			${xsOnly`
                ${textBig}
                order: 2;
                padding: ${spaceLarge} ${spaceBase} ${spaceLarge};
                text-align: center;
            `}
		`}
`;

const Close = styled(props => <IconButton {...props} />)<Props>`
	display: none;

	${props =>
		props.theme.isExpanded &&
		css`
			${xsOnly`
                display: flex;
				position: absolute;
				right: 0;
            `}
		`}
`;

const IconWrapper = styled.div<Props>`
	height: 0;
	padding-bottom: 56.25%;
	position: relative;

	${props =>
		props.theme.isExpanded &&
		css`
			${xsOnly`
                order: 3;
            `}
		`}
`;

const Icon = styled.div<{ icon: { fullPath: string } }>`
	background-position: center;
	background-size: cover;
	bottom: 0;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
	width: 100%;

	${props =>
		props.icon &&
		props.icon.fullPath &&
		css`
			background-image: url(${props.icon.fullPath}&width=700);
		`}
`;

const NestedComponentsWrapper = styled.div<Props>`
	display: flex;

	${props =>
		props.theme.isExpanded &&
		css`
			${xsOnly`
                width: 100%;
            `}
		`}
`;

const ContainerOfSomeSort = styled.div`
	&& {
		margin-left: auto;
		margin-right: auto;
		position: relative;
	}
	${containerWidth};
`;

const Header = styled(props => <Heading {...props} />)<Props>`
	${props =>
		props.theme.isExpanded &&
		css`
			${xsOnly`
				padding: ${spaceLarge} ${spaceBase} ${spaceLarge};
			`}
		`}
`;

const expandableCardComponentMap = {
	'copy-block': CopyBlock,
	'inquiry-card': InquiryCard,
};

export const ExpandableCard: React.FC<ExpandableCardProps> = ({ heading, backgroundColor, nestedComponents, icon }) => {
	const [isExpanded, toggleExpandability] = useState(false);
	const { expandedCardId, setExpandedCardId } = useContext(ExpandedCardContext);
	const expandableCardIdentifier = useRef(null);
	const [, setDisabledScroll] = useDisabledScroll(false);
	const expandableCardAnchor = `${heading
		.toLowerCase()
		.trim()
		.replace(/\s/g, '-')}-anchor`;

	useEffect(() => {
		if (isExpanded && expandedCardId !== heading) {
			toggleExpandability(false);
		}
	}, [expandedCardId]);

	useEffect(() => {
		if (locationHashFound()) {
			const anchor = `${location.hash.replace(/#/g, '')}-anchor`;
			const anchorElem = root.document.getElementById(anchor);
			scrollToCard(anchorElem);
		}
	}, []);

	function updateLocationHash() {
		const locationHash = expandableCardIdentifier.current.id.replace('-anchor', '');

		location.hash = locationHash;
	}

	const scrollToCard = (element?: HTMLElement) => {
		setTimeout(() => {
			const node = element || expandableCardIdentifier.current;
			const rec = node.getBoundingClientRect();
			const elementLocation = rec.top + root.pageYOffset - 20;

			root.scrollTo({
				behavior: 'smooth',
				left: 0,
				top: elementLocation,
			});

			updateLocationHash();
		}, 250);
	};

	const handleClick = () => {
		if (isExpanded) {
			// @ts-ignore
			setDisabledScroll(false);
			toggleExpandability(false);
			setExpandedCardId(null);
		} else {
			setExpandedCardId(heading);
			// @ts-ignore
			setDisabledScroll(true);
			toggleExpandability(true);
			scrollToCard();
		}
	};

	function locationHashFound() {
		const anchor = `${location.hash.replace(/#/g, '')}-anchor`;

		return location.hash && !!root.document.getElementById(anchor);
	}

	const componentsPerRow = 'mobile: 1, tablet: 1, desktop: 1';
	const verticalAlignment = 0;
	const columnCssClasses = '';

	return (
		<ThemeProvider theme={{ isExpanded, backgroundColor }}>
			<ExpandableCardWrapper>
				<ScrollableContent>
					<Content onClick={handleClick}>
						<Close buttonType='containerless' iconName='close' iconSize='md' />
						<IconWrapper>
							{icon && <Icon icon={icon} id={expandableCardAnchor} ref={expandableCardIdentifier} />}
						</IconWrapper>
						<Header size='medium'>{heading}</Header>
					</Content>
					<Drawer className='expandable-card__drawer'>
						<DrawerWrapper>
							<NestedComponentsWrapper>
								<ContainerOfSomeSort
									className={`row ${determineLayoutStyle({
										nestedComponents,
										verticalAlignment,
									})}`}
								>
									{nestedComponents.map((component, index) => {
										return (
											<div
												className={`${getComponentsPerRowCssClass(
													componentsPerRow,
													index,
												)} ${columnCssClasses}`}
												key={index}
											>
												<CmsComposer {...component} componentMap={expandableCardComponentMap} />
											</div>
										);
									})}
								</ContainerOfSomeSort>
							</NestedComponentsWrapper>
						</DrawerWrapper>
					</Drawer>
				</ScrollableContent>
			</ExpandableCardWrapper>
		</ThemeProvider>
	);
};
