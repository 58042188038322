export enum RegExps {
	MIN_LENGTH_2 = '.{2,}',
	EMAIL = '^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+.)+([a-zA-Z0-9]{2,4})+$',
	PHONE = '^[0-9- ]{10,}',
	ZIP = '^[0-9]{5}$|^[A-Za-z][0-9][A-Za-z] ?[0-9][A-Za-z][0-9]$',
	ZIP_US = '(^\\d{5}$)|(^\\d{5}-\\d{4}$)',
	ZIP_CANADA = '^[A-Za-z]\\d[A-Za-z][ -]?\\d[A-Za-z]\\d$',
}

export enum PasswordRegExps {
	MIN_LENGTH = '^.{8,}$',
	SPECIAL_CHARACTERS = '[~!#%^&*]',
	LOWERCASE_CHARACTER = '(?=.*[a-z])',
	UPPERCASE_CHARACTER = '(?=.*[A-Z])',
	DIGIT = '(?=.*[0-9])',
}
