import { FC, ReactNode, cloneElement } from 'react';

interface Props {
	children: ReactNode;
}

export const AccessibleMenu: FC<Props> = ({ children }) => {
	const attributes = {
		role: 'listbox',
	};

	return cloneElement(children, { ...attributes });
};
