import React, { memo } from 'react';
import styled from 'styled-components';
import Title from 'components/Title/Title';
import { Paragraph } from 'components/Paragraph/Paragraph';
import { md, sm } from '../../../../../design-system/media-queries';
import { transparentDark10 } from '../../../../../design-system/style-mixins/shadows-and-scrims';
import { dividerGrayLight, paleBlue } from '../../../../../design-system/variables/colors';
import { spaceBase, spaceBig, spaceLarge, spaceMedium } from '../../../../../design-system/variables/spaces';
import { PromoCardProps } from './PromoCardProps';

const Wrapper = styled.div`
	align-items: center;
	background-color: ${paleBlue};
	margin-bottom: ${spaceBase};
	margin-left: -${spaceBase};
	margin-right: -${spaceBase};
	padding: ${spaceLarge};

	${sm`
		display: flex;
		margin-left: -${spaceLarge};
		margin-right: -${spaceLarge};
		padding: ${spaceMedium} ${spaceBig};
	`}

	${md`
		background-color: initial;
		border-left: ${dividerGrayLight};
		justify-content: flex-start;
		margin-bottom: initial;
		margin-left: ${spaceMedium};
		margin-right: initial;
		padding: ${spaceBase} 0 ${spaceBase} ${spaceMedium};
	`}
`;

const ImageContainer = styled.div`
	background-color: ${transparentDark10};
	display: block;
	line-height: 0;
	margin-bottom: ${spaceBase};
	position: relative;
	width: 100%;
	background-repeat: no-repeat;

	${sm`
		margin-bottom: 0;
		margin-right: ${spaceMedium};
		max-width: 187px;
	`}

	${md`
		margin-right: ${spaceBase};
		max-width: 160px;
	`}
`;

const Image = styled.img`
	width: 100%;
	border-style: none;
`;

const Content = styled.div`
	max-width: 350px;
	width: 100%;

	${md`
		max-width: 100%;
	`}
`;

export const PromoCard: React.FC<PromoCardProps> = memo(({ imageSrc, title, description, ...rest }) => (
	<Wrapper {...rest}>
		<ImageContainer>
			<Image src={imageSrc} />
		</ImageContainer>
		<Content>
			<Title size='small'>{title}</Title>
			<Paragraph size='small'>{description}</Paragraph>
		</Content>
	</Wrapper>
));
