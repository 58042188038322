import React, { FC, useState } from 'react';
import { GridWrap } from 'components/GridWrap';
import { Button } from 'components/Button';
import { TextButton } from 'components/TextButton';
import { white } from 'design-system/variables/colors';
import { spaceSuperMassive } from 'design-system/variables/spaces';
import {
	ChatInviteWindow,
	ChatInviteHeader,
	ChatInviteIcon,
	ChatInviteTitle,
	ChatInviteBody,
	ChatButtonPanel,
} from './ChatInvite.styles';

interface ChatInviteProps {
	title: string;
	body: string;
	onClose: () => void;
	onStartChat: () => void;
	showInvite: boolean;
	isOnline: boolean;
}

const ChatInvite: FC<ChatInviteProps> = ({ title, body, onClose, onStartChat, showInvite, isOnline }) => {
	const [className, setClassName] = useState('');

	const handleDismissClick = () => {
		setClassName('fade-out');
		onClose();
	};

	return (
		<ChatInviteWindow className={className} showInvite={showInvite}>
			<ChatInviteHeader>
				<GridWrap>
					<ChatInviteIcon iconName='chat' iconSize='lg' fill={white} />
					{isOnline ? (
						<span style={{ paddingLeft: spaceSuperMassive }}>Live Chat</span>
					) : (
						<span style={{ paddingLeft: spaceSuperMassive }}>Contact Us</span>
					)}
				</GridWrap>
			</ChatInviteHeader>

			<GridWrap>
				<ChatInviteTitle>{title}</ChatInviteTitle>
				<ChatInviteBody>{body}</ChatInviteBody>

				<ChatButtonPanel>
					<Button buttonType='primary' onClick={onStartChat}>
						{isOnline ? 'Chat' : 'Contact'}
					</Button>
					<TextButton onClick={handleDismissClick}>Dismiss</TextButton>
				</ChatButtonPanel>
			</GridWrap>
		</ChatInviteWindow>
	);
};

export default ChatInvite;
