import React, { memo, useCallback } from 'react';
import styled, { css } from 'styled-components';

import { UserControlsProps } from './UserControlsProps';

import { md, lg } from '../../../design-system/media-queries';
import { spaceBase } from '../../../design-system/variables/spaces';
import { black, grayDark, itemHover, white, tapTransparent, focusOutlineColor } from '../../../design-system/variables/colors';
import { textTiny } from '../../../design-system/style-mixins/text';
import { truncateText } from '../../../design-system/utils';

import { AuthButtons } from '../../AuthButtons';
import { Link } from '../../Link';
import { CustomizedDropdown } from '../../Dropdown';
import { allCapsStyle } from '../../AllCaps/AllCaps';
import { fadeInAnimation, transition250ms } from '../../../design-system/style-mixins/animations';

const Wrapper = styled.div`
	display: none;
	position: relative;
	margin-left: ${spaceBase};

	${md`
		display: flex;
	`}
	&[data-focus-visible-added] {
		outline: none;

		span {
			outline: 2px solid ${focusOutlineColor};
			outline-offset: 2px;
			transition: none;
		}
	}
`;

const AccountLinks = styled.ul`
	display: flex;
	flex-direction: column;
	min-width: 180px;
	z-index: 9001;
	${fadeInAnimation}
	${tapTransparent}
`;

const StyledLink = styled.li`
	${allCapsStyle}
	${textTiny}
	padding: ${spaceBase};
	text-decoration: none;
	white-space: nowrap;
	cursor: pointer;

	&:hover {
		background: ${itemHover};
	}
	a:hover,
	a:focus {
		text-decoration: none;
	}
	&[data-focus-visible-added] {
		outline: none;

		a {
			outline: 2px solid ${focusOutlineColor};
			outline-offset: 2px;
			transition: none;
			text-decoration: none;
		}
	}
`;

const StyledTrigger = styled.button<{ color: string; href: string }>`
	${allCapsStyle}
	${tapTransparent}
	${textTiny}
	${transition250ms}
	${truncateText}
	background: transparent;
	border: none;
	color: ${white};
	cursor: pointer;
	outline: none;
	padding: 0;
	white-space: nowrap;

	&[data-focus-visible-added] {
		outline: 2px solid ${focusOutlineColor};
		outline-offset: 2px;
		transition: none;
	}

	${md`
		max-width: 160px;
	`}

	${lg`
		max-width: 222px;
	`}

	${props =>
		props.theme.color === 'white' &&
		css`
			color: ${grayDark};
		`}

	&:hover {
		text-decoration: none;
		color: ${black};
		border-color: transparent;
	}
	&:active {
		border-color: transparent;
	}
`;

export const UserControls: React.FC<UserControlsProps> = memo(({ userInfo, isLoggedIn, onToggleVisibility }) => {
	const renderTrigger = () => (
		<StyledTrigger color="gray" href="">
			{userInfo.name}
		</StyledTrigger>
	);

	const handleOpenDropdown = useCallback(() => {
		onToggleVisibility && onToggleVisibility(true);
	}, [onToggleVisibility]);

	const handleCloseDropdown = useCallback(() => {
		onToggleVisibility && onToggleVisibility(false);
	}, [onToggleVisibility]);

	return (
		<Wrapper>
			{isLoggedIn ? (
				<CustomizedDropdown align="right" TriggerComponent={renderTrigger} title="" onOpen={handleOpenDropdown} onClose={handleCloseDropdown}>
					<AccountLinks>
						{userInfo.accountLinks.map(({ url, linkTitle }) => (
							<StyledLink key={linkTitle}>
								<Link color="black" href={url}>
									{linkTitle}
								</Link>
							</StyledLink>
						))}
					</AccountLinks>
				</CustomizedDropdown>
			) : (
				<AuthButtons />
			)}
		</Wrapper>
	);
});
