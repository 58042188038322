import React, { FC } from 'react';
import styled from 'styled-components';

import { xsOnly, md, smOnly } from 'design-system/media-queries';
import { white } from 'design-system/variables/colors';
import { spaceMassive, spaceMedium, spaceBig, spaceBase, spaceLarge } from 'design-system/variables/spaces';
import { Headline } from 'components/Headline';
import { Paragraph } from 'components/Paragraph';

const ImageCard = styled.div`
	background-image: url(//cms.inspirato.com/ImageGen.ashx?image=/media/9403750/turksbay_darker.jpg&width=1024);
	background-position: center center;
	background-size: cover;
	width: auto;
	/* height: 100%; */
	/* max-height: 285px; */

	&& {
		padding: ${spaceBig} ${spaceBase} ${spaceBig} ${spaceMedium};
	}

	${smOnly`
		/* max-height: 350px; */
		&& {
			padding: ${spaceMassive} 0 ${spaceMassive} ${spaceBase};
		}
	`}
	${md`
		height: 100%;
		max-height: 100%;
	`}
`;

const Wrapper = styled.div`
	${xsOnly`
		max-width: 342px;
		margin: auto;
	`}
	${smOnly`
		max-width: 366px;
		margin: auto;
	`}
`;

const Header = styled(Headline)`
	color: ${white};
	margin-bottom: ${spaceLarge};
`;

const Text = styled(Paragraph)`
	color: ${white};
	margin: 0;

	${md`
		max-width: 300px;
	`}
`;

export const GetStarted: FC<{}> = () => (
	<ImageCard className='col-xs-12 col-md-5'>
		<Wrapper>
			<Header size='large'>Get started</Header>
			<Text>Experience the world’s most luxurious vacations. Create a free account to view availability.</Text>
		</Wrapper>
	</ImageCard>
);
