import React, { FC, useState } from 'react';
import { Formik, FormikBag } from 'formik';
import { UserValues } from 'pages/Chat/Chat.Interfaces';
import mock from 'xhr-mock';
import wretch from 'wretch';
import { RegUserForm } from './RegUserForm';
import { BuildPostObject } from '../../../helper/ConvertFormValuesToPostModel';
import SuccessMessage from '../../Messages/SuccessMessage';
import ErrorMessage from '../../Messages/ErrorMessage';

const SubmitRegUser = (setSuccess: Function) => async (
	values: UserValues,
	{ setSubmitting, ...rest }: FormikBag<null, UserValues>,
) => {
	mock.teardown();
	const postValues = BuildPostObject(values);

	try {
		const response = await wretch('/LiveChat/PostChatData/')
			.post(postValues)
			.json();

		if (response) {
			setSuccess(true);
		} else {
			setSuccess(false);
		}
	} catch (e) {
		setSuccess(false);
	}
};

export const RegUserFormik: FC<UserValues> = props => {
	const initialValues: UserValues = { ...props, description: '' };
	const [success, setSuccess] = useState(null);
	const renderFormik = () => {
		let whatToReturn = null;
		if (success === null) {
			whatToReturn = (
				<Formik initialValues={initialValues} onSubmit={SubmitRegUser(setSuccess)} component={RegUserForm} />
			);
		} else if (success) {
			whatToReturn = <SuccessMessage></SuccessMessage>;
		} else {
			whatToReturn = <ErrorMessage></ErrorMessage>;
		}
		return whatToReturn;
	};

	return renderFormik();
};
