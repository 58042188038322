import React from 'react';
import styled from 'styled-components';

import { Images } from '../StaticContentPageEnums';

import { sm } from '../../../design-system/media-queries';

const ImageContainer = styled.div`
	bottom: 0;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
	z-index: -1;
`;

const BackgroundImagePicture = styled.picture`
	padding: 0;
`;

const Image = styled.img`
	height: 100%;
	object-fit: cover;
	object-position: 37% top;
	width: 100%;

	${sm`
        object-position: 100% top;
    `}
`;

export const BackgroundImage = () => {
	// TODO: remove ts-ignore
	// @ts-ignore
	const fallbackSrcSet = Images.widths.map(
		width => `${Images.BackgroundImage['fullPath']}&compression=100&width=${width}&align=center ${width}w`,
	);
	const imageQueryString = '&width=768&align=center';

	return (
		<ImageContainer>
			<BackgroundImagePicture>
				<source
					media="(max-width:767px)"
					srcSet={`${Images.BackgroundImage['fullPath']}+${imageQueryString}`}
				/>
				<Image
					src={`${Images.BackgroundImage['fullPath']}&compression=100&width=768`}
					srcSet={fallbackSrcSet.join(',')}
				/>
			</BackgroundImagePicture>
		</ImageContainer>
	);
};
