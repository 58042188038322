import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import moment from 'moment';
import { sortWith, descend, ascend, prop } from 'ramda';
import { ExperienceCard } from '../../types';
import { State } from '../context';
import { SET_INITIAL_DATA } from '../constants';
import { monthYearRegex, sortDate } from './util';

export const initializeExperiences = (data: ExperienceCard[]) => (
	dispatch: ThunkDispatch<State, undefined, Action>,
) => {
	let uniqueData = data.filter((experience, index) => {
		return (
			index ===
			data.findIndex(obj => {
				return JSON.stringify(obj) === JSON.stringify(experience);
			})
		);
	});

	uniqueData = uniqueData.map(node => ({
		...node,
		intValue: parseInt(node.value, 10),
		sortDate: moment.utc(node.filterDate).unix(),
	}));

	const initialFilters = (filterName, opts: { isDate?: boolean } = {}) =>
		[
			...new Set(
				uniqueData.reduce((acc, curr) => {
					acc.push(opts.isDate ? curr[filterName].replace(monthYearRegex, '/01/') : curr[filterName]);
					return acc;
				}, []),
			),
		].reduce((acc, curr) => {
			const filter = {
				disabled: false,
				checked: false,
				id: curr,
				label: opts.isDate ? moment.utc(curr).format('MMMM YYYY') : curr,
			};
			acc.push(filter);
			return acc;
		}, []);

	const dates = initialFilters('filterDate', { isDate: true });
	const experienceTypes = initialFilters('experienceType');
	const locations = initialFilters('filterLocation');

	sortDate(dates);

	const newSort = sortWith([descend(prop('inspiratoOnly')), ascend(prop('sortDate')), descend(prop('intValue'))]);
	uniqueData = newSort(uniqueData);

	locations.sort((a, b) => (a.id > b.id ? 1 : -1));
	dispatch({ type: SET_INITIAL_DATA, payload: { data: uniqueData, experienceTypes, dates, locations } });
};
