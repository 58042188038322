import React, { useRef, useState, useEffect } from 'react';
import LazyLoad from 'react-lazyload';
import styled, { css } from 'styled-components';
import { Label } from 'components/Label';
import { useDispatch } from 'react-redux';
import { getFilteredResults } from 'pages/CuratedTrips/redux/actions';
import { xsOnly } from '../../../design-system/media-queries';
import { shadowHover } from '../../../design-system/style-mixins/animations';
import { transparentDark10, boxShadow1 } from '../../../design-system/style-mixins/shadows-and-scrims';
import { fontSans, textBase, textMedium, textSmall } from '../../../design-system/style-mixins/text';
import { brokenImgHoriz, truncateText } from '../../../design-system/utils';
import { textColorDark } from '../../../design-system/variables/colors';
import { spaceBase, spaceSmall, spaceTiny } from '../../../design-system/variables/spaces';
import { Banner } from '../../Banner';
import { Subtext } from '../../Subtext';
import { Title } from '../../Title';
import { ExperiencesCardProps } from './ExperiencesCardProps';
import { useClickProps } from '../useClickProps';

const Wrapper = styled.a<{ redirectionUrl: string }>`
	${textColorDark}
	${textBase}
	${fontSans}
	display: block;
	margin-bottom: ${spaceBase};

	&:hover {
		text-decoration: none;
	}

	${props => (props.redirectionUrl ? shadowHover : boxShadow1)}
`;

const Image = styled.div<{ src?: string }>`
	background-color: ${transparentDark10};
	background-image: url(${props => props.src}&height=275), url(${brokenImgHoriz});
	background-position: 50%;
	background-size: cover;
	padding-bottom: 56.25%;
`;

const PlaceholderImage = styled(props => <Image {...props} />)`
	background-image: url(${brokenImgHoriz});
`;

const InformationContainer = styled.div`
	position: relative;
	line-height: initial;
	padding: ${spaceSmall} ${spaceBase};
	width: 100%;
`;

const InformationContainerRow = styled.div`
	align-items: center;
	display: flex;
	justify-content: space-between;

	&:last-child {
		align-items: baseline;
	}
`;

const IconNewExperience = styled.span`
	margin-right: auto;
	display: inline-block;
`;

const ExperienceTitle = styled(props => <Title {...props} />)`
	${truncateText}
	line-height: 1.4;

	${xsOnly`
		${textMedium}
	`}
`;

const ExperienceSubtitle = styled(props => <Subtext {...props} />)`
	${truncateText}
`;

const ExperienceTitleContainer = styled.span<{ width?: number }>`
	flex-wrap: nowrap;
	display: flex;
	align-items: center;
	max-width: ${props => (props.width ? `calc(100% - ${props.width}px)` : '100%')};
`;

const informationContainerCol2Common = css`
	margin-left: ${spaceTiny};
	white-space: nowrap;
	text-align: right;
`;

const ExperienceLength = styled.span`
	${informationContainerCol2Common}
	${fontSans}
	${textSmall}
	${textColorDark}
`;

const ExperiencesDate = styled(props => <Label {...props} />)`
	${informationContainerCol2Common}
	${xsOnly`
		${textSmall}
	`}
`;

const CalloutBox = styled.div`
	position: absolute;
	left: 0;
	bottom: 100%;
	color: white;
	background-color: rgba(0, 0, 0, 0.6);
	text-align: center;
	padding: 10px;
	width: 100%;
`;

export const ExperiencesCard: React.FunctionComponent<ExperiencesCardProps> = ({
	calloutBox,
	imageUrl,
	title,
	subTitle,
	isNew,
	lengthOfItinerary,
	startDate,
	redirectionUrl,
	redirectToNewWindow,
}) => {
	const dispatch = useDispatch();
	const lengthRef = useRef(null);
	const defineTitleWidth = () => lengthRef && lengthRef.current && lengthRef.current.offsetWidth;
	const [titleWidth, setTitleWidth] = useState(defineTitleWidth());

	useEffect(() => {
		setTitleWidth(defineTitleWidth());
	}, [lengthRef.current]);

	const handleClick = () => {
		const newParam = redirectionUrl.replace('?filter=', '');
		dispatch(getFilteredResults(newParam));
	};
	const cardProps = useClickProps({}, redirectionUrl, redirectToNewWindow, handleClick);

	return (
		<Wrapper redirectionUrl={redirectionUrl} as={!redirectionUrl && 'div'} {...cardProps}>
			<LazyLoad placeholder={<PlaceholderImage />}>
				<Image src={imageUrl} />
			</LazyLoad>
			<InformationContainer>
				<InformationContainerRow>
					{title && (
						<ExperienceTitleContainer width={titleWidth}>
							<ExperienceTitle size='medium'>{title} </ExperienceTitle>
							{isNew && (
								<IconNewExperience>
									<Banner />
								</IconNewExperience>
							)}
						</ExperienceTitleContainer>
					)}
					{lengthOfItinerary && <ExperienceLength ref={lengthRef}>{lengthOfItinerary}</ExperienceLength>}
				</InformationContainerRow>
				<InformationContainerRow>
					{subTitle && <ExperienceSubtitle>{subTitle}</ExperienceSubtitle>}
					{startDate && <ExperiencesDate size='medium'>{startDate}</ExperiencesDate>}
				</InformationContainerRow>
				{calloutBox && <CalloutBox>{calloutBox}</CalloutBox>}
			</InformationContainer>
		</Wrapper>
	);
};
