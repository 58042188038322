import root from 'window-or-global';
import { useEffect, useState } from 'react';

export const useKeyPress = (targetKey, isPreventDefault = false) => {
	const [keyPressed, setKeyPressed] = useState(false);

	function downHandler(e) {
		if (e.key === targetKey) {
			setKeyPressed(true);
			isPreventDefault && e.preventDefault();
		}
	}

	const upHandler = (e) => {
		if (e.key === targetKey) {
			setKeyPressed(false);
			isPreventDefault && e.preventDefault();
		}
	};

	useEffect(() => {
		root.addEventListener('keydown', downHandler);
		root.addEventListener('keyup', upHandler);

		return () => {
			root.removeEventListener('keydown', downHandler);
			root.removeEventListener('keyup', upHandler);
		};
	}, []);

	return keyPressed;
};
