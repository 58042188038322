import React, { useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';

import { ImagePreviewProps } from './ImagePreviewProps';

import { Bullets } from '../NavigationButtons/Bullets';
import { Arrows } from '../NavigationButtons/Arrows';
import { FullscreenSwitch } from '../FullscreenSwitch';
import { TourPreviewButton } from '../TourPreviewButton';
import { Images } from '../Images';

import { useFullscreenView, useVisibilityToggle } from '../../hooks';

const Wrapper = styled.div<{
	width?: string;
	height?: string;
	onMouseOut: any;
	onMouseOver: any;
	onBlur: any;
	onFocus: any;
	autoplay: boolean;
}>`
	overflow: hidden;
	position: relative;
	top: 0px;
	left: 0px;
	${props =>
		css`
			width: ${props.width};
			height: ${props.height};
		`} /* z-index: 1; */
`;

export const ImagePreview: React.FunctionComponent<ImagePreviewProps> = ({
	isShownTourPreviewButton,
	isFullscreenAvailable,
	toggleTourPreview,
	navigateForward,
	navigateToIndex,
	timeout = 5000,
	navigateBack,
	activeIndex,
	autoplay,
	height,
	images,
	width,
}) => {
	const [counter, setCounter] = React.useState(null);
	const countRef = useRef(counter);
	countRef.current = counter;
	const galleryRef = useRef(null);
	const [isFullscreen, toggleFullscreen] = useFullscreenView(galleryRef);
	const [isArrowNavigationShown, showArrows, hideArrows] = useVisibilityToggle();
	const isShownTourButton = !isFullscreen && isShownTourPreviewButton;

	useEffect(() => {
		if (autoplay) {
			const timerId = setTimeout(navigateForward, timeout);
			setCounter(timerId);

			return function() {
				setCounter(null);
				clearTimeout(timerId);
			};
		}
	}, [activeIndex]);

	return (
		<Wrapper
			width={width}
			height={height}
			onMouseOut={hideArrows}
			onMouseOver={showArrows}
			onBlur={hideArrows}
			onFocus={showArrows}
			ref={galleryRef}
			autoplay={autoplay}
		>
			<Images images={images} activeIndex={activeIndex} />
			{isShownTourButton && <TourPreviewButton showTourPreview={toggleTourPreview as Function} />}
			{images.length > 1 && (
				<Arrows
					navigateBack={navigateBack}
					navigateForward={navigateForward}
					isShown={isArrowNavigationShown}
				/>
			)}
			{isFullscreenAvailable && (
				<FullscreenSwitch toggleFullscreen={toggleFullscreen as Function} isFullscreen={isFullscreen} />
			)}
			{images.length > 1 && (
				<Bullets total={images.length} activeIndex={activeIndex} navigate={navigateToIndex} />
			)}
		</Wrapper>
	);
};
