import { createSelector } from 'reselect';
import moment from 'moment';
import {
	TripsResponse,
	DateRangeResponse,
	ProposalAccommodationDateRange,
	ProposalAccommodationElement,
	PricingAvailabilityRatePeriod,
} from '../../../../interfaces';
import { getAllUnitDetails } from './unitDetails';
import { accommodationElementSelector, npoAccommodationElementSelector } from './proposalElements';
import { getMessage } from './message';
import { getProposalErrors } from './errors';
import { getSelectedRecipient } from './selectedRecipient';
import { State } from '../context';

export const proposalValidationModelSelector = createSelector(
	[getSelectedRecipient, accommodationElementSelector, npoAccommodationElementSelector, getMessage, getProposalErrors],
	(selectedRecipient, accommodations, nonPortfolioAccommodations, message, errors) => {
		return {
			selectedRecipient,
			accommodations,
			allAccommodations: [...accommodations, ...nonPortfolioAccommodations],
			message,
			errors,
		};
	},
);

export const pricingAvailabilitySelector = (state: State) => state.pricingAvailabilityResponses;

export const areDateRangesEqual = (
	accommodationDateRange: ProposalAccommodationDateRange,
	ratePeriodDateRange: DateRangeResponse,
) => {
	return (
		moment(accommodationDateRange.checkInDate)
			.startOf('day')
			.isSame(moment(ratePeriodDateRange.start).startOf('day')) &&
		moment(accommodationDateRange.checkOutDate)
			.startOf('day')
			.isSame(moment(ratePeriodDateRange.end).startOf('day'))
	);
};

const getChangedTrips = (
	accommodation: ProposalAccommodationElement,
	pricingAvailabilityRatePeriods: PricingAvailabilityRatePeriod[],
) => {
	return pricingAvailabilityRatePeriods.filter(ratePeriod => {
		if (accommodation) {
			const dateRangeToCompare = accommodation.accommodationDateRanges.find(d =>
				areDateRangesEqual(d, ratePeriod.dateRange),
			);
			const hasRateChange =
				dateRangeToCompare &&
				(dateRangeToCompare.lastSyncedSubtotal !== ratePeriod.subtotal ||
					dateRangeToCompare.lastSyncedTaxes !== ratePeriod.taxes);

			return (dateRangeToCompare && !ratePeriod.isAvailable) || (ratePeriod.isAvailable && hasRateChange);
		}
		return false;
	});
};

export const changedTripsSelector = createSelector(
	[pricingAvailabilitySelector, accommodationElementSelector, getAllUnitDetails],
	(pricingAvailabilityResponses, accommodationElements, unitDetails): TripsResponse => {
		let hasUnavailableDates = false;
		let hasPriceChangedTrips = false;

		const responses = pricingAvailabilityResponses.map(response => {
			const accommodation: ProposalAccommodationElement = accommodationElements.find(
				a => Number(a.residenceId) === response.unitId,
			);
			const changedTrips = getChangedTrips(accommodation, response.pricingAvailabilityRatePeriods);

			return {
				unitId: response.unitId,
				trips: changedTrips.map((ratePeriod, index) => {
					const dateRangeToCompare = accommodation.accommodationDateRanges.find(d =>
						areDateRangesEqual(d, ratePeriod.dateRange),
					);
					const tripGrandTotal = ratePeriod.subtotal + ratePeriod.taxes;
					const previousGrandTotal =
						dateRangeToCompare.lastSyncedSubtotal + dateRangeToCompare.lastSyncedTaxes;

					hasUnavailableDates = hasUnavailableDates || !ratePeriod.isAvailable;
					hasPriceChangedTrips =
						hasPriceChangedTrips ||
						(ratePeriod.isAvailable &&
							(dateRangeToCompare.lastSyncedSubtotal !== ratePeriod.subtotal ||
								dateRangeToCompare.lastSyncedTaxes !== ratePeriod.taxes));

					return {
						displayUnitName: index === 0,
						pricingAvailabilityRatePeriod: ratePeriod,
						tripGrandTotal,
						previousGrandTotal,
						hasRateIncrease: previousGrandTotal < tripGrandTotal,
						unitName: unitDetails[response.unitId].unitName,
					};
				}),
			};
		});

		return {
			hasUnavailableDates,
			hasPriceChangedTrips,
			responses,
		};
	},
);
