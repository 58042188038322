import React from 'react';
import styled from 'styled-components';

import { BulletProps } from './BulletsProps';

import { white } from '../../../../design-system/variables/colors';

const StyledBullet = styled.div<{ onClick: any }>`
	cursor: pointer;
	margin-right: 3px;
	width: 8px;
	height: 8px;
	background-color: transparent;
	border-radius: 50%;
	border: 1px solid ${white};
`;

export const Bullet: React.FunctionComponent<BulletProps> = ({ onClick }) => <StyledBullet onClick={onClick} />;
