import React, { FC, memo } from 'react';
import { LoaderSkeleton } from 'components/Loader/LoaderSkeleton';
import * as pluralize from 'pluralize';
import { SpecsContainer, ItemContainer, StatsLabel, Specs, SpecsLabel, SpecsIcon } from '../ListCard.styles';

interface Props {
	isListAndIsDesktop: boolean;
	isPlaceholder: boolean;
	occupancy: any;
	bedrooms: any;
	numberOfDays: any;
}
export const Stats: FC<Props> = memo(({ isListAndIsDesktop, isPlaceholder, occupancy, bedrooms, numberOfDays }) => {
	const bedroom = pluralize('Bedroom', bedrooms);
	if (isListAndIsDesktop) {
		return (
			<SpecsContainer isList={isListAndIsDesktop}>
				{isPlaceholder ? (
					<>
						<ItemContainer>
							<LoaderSkeleton width='3.5em' />
						</ItemContainer>
						<ItemContainer>
							<LoaderSkeleton width='3.5em' />
						</ItemContainer>
						<ItemContainer>
							<LoaderSkeleton width='3.5em' />
						</ItemContainer>
					</>
				) : (
					<>
						<ItemContainer>
							{occupancy}
							<StatsLabel>Guests</StatsLabel>
						</ItemContainer>
						<ItemContainer>
							{bedrooms}
							<StatsLabel>{bedroom}</StatsLabel>
						</ItemContainer>
						<ItemContainer>
							{numberOfDays}
							<StatsLabel>Nights</StatsLabel>
						</ItemContainer>
					</>
				)}
			</SpecsContainer>
		);
	}
	return (
		<SpecsContainer isList={isListAndIsDesktop}>
			{isPlaceholder ? (
				<>
					<Specs>
						<LoaderSkeleton width='5em' />
						<LoaderSkeleton width='5em' />
						<LoaderSkeleton width='5em' />
					</Specs>
				</>
			) : (
				<>
					<Specs>
						{occupancy}
						<SpecsLabel>Guests</SpecsLabel>
					</Specs>
					<SpecsIcon iconName='bullet-dot' iconSize='md' />
					<Specs>
						{bedrooms}
						<SpecsLabel>{bedroom}</SpecsLabel>
					</Specs>
					<SpecsIcon iconName='bullet-dot' iconSize='md' />
					<Specs>
						{numberOfDays}
						<SpecsLabel>Nights</SpecsLabel>
					</Specs>
				</>
			)}
		</SpecsContainer>
	);
});
