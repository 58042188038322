import { SearchResultsCategory } from './SearchResultsSetProps';

export const transformSearchResultsToMenuOptionsIds = (
	searchResults: SearchResultsCategory[],
	identificator: string,
) => {
	const menuOptionsId = [];

	searchResults.forEach(category => {
		const values = category.items.map(child => child[identificator]);
		menuOptionsId.push(...values);
	});

	return menuOptionsId;
};
