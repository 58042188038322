import React, { FC } from 'react';
import styled from 'styled-components';

import { spaceBase } from 'design-system/variables/spaces';
import { xsSmOnly } from 'design-system/media-queries';
import { Paragraph } from 'components/Paragraph';
import { Link } from 'components/Link';

const Wrapper = styled.div`
	text-align: center;
	${xsSmOnly`
		margin-bottom: ${spaceBase};
	`}
`;

export const PrivacyPolicy: FC<{}> = () => (
	<Wrapper>
		<Paragraph size='small'>
			We use your information to personalize your experience. By continuing, you are agreeing to Inspirato’s&nbsp;
			<Link color='primary' href='/privacy-policy'>
				Privacy Policy
			</Link>
			.
		</Paragraph>
	</Wrapper>
);
