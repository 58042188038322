import equals from 'ramda/es/equals';
import { getPassResults, getPassFilters, getPassRefinedFilters } from 'pages/CuratedTrips/services/curatedTripsService';
import { State, lastRequestedParams as defaultLastRequestedParams } from '../context';
import {
	SET_SEARCH_RESULTS,
	SET_IS_LOADING,
	SET_DONE_LOADING,
	SET_NUMBER_OF_RECORDS_TO_SKIP,
	SET_DESTINATION_COUNT,
	CLEAR_RESULTS,
	UPDATE_SORT_OPTION,
	SET_FILTER_DATA,
	SET_SSR_INITIALIZED,
	CLEAR_SEARCH_RESULTS_REQUEST,
	CLEAR_SEARCH_FILTERS_REQUEST,
	SEARCH_FILTERS_REQUEST_FAILED,
	SET_V2_SEARCH_RESULTS,
	SET_TRIPS_AVAILABLE_COUNT,
	SET_V2_RESULTS_SET,
	SET_V2_SHAREABLE_URL_PARAMS,
	SET_V2_LAST_REQUEST_PARAMS,
	SET_NO_RESULTS,
	PAGE_SIZE,
} from '../constants';
import { updateQueryParams } from '../../services';
import {
	transformResults,
	transformRefinedFilters,
	transformRequestParams,
	compareParams,
	calculateDestinationCount,
	countLocationsInRegions,
} from './resultsUtils';

export const getTripFilters = () => (dispatch, getState) => {
	dispatch({ type: CLEAR_SEARCH_FILTERS_REQUEST });
	const {
		initialData: { configuration },
	} = getState();

	dispatch(setFiltersLoading());

	return getPassFilters(configuration.passOfferingsUrl)
		.then((res) => {
			dispatch({ type: SET_FILTER_DATA, data: res });
			dispatch({ type: SET_SSR_INITIALIZED });
		})
		.catch(() => {
			dispatch({ type: SEARCH_FILTERS_REQUEST_FAILED });
		});
};

/**
 * Adds new refined filter data into the store
 * @param response Filters API response
 * @param v2Params Current parameters
 */
const refineFilters = (response, v2Params) => (dispatch, getState: () => State) => {
	const { filterData } = getState();

	const newFilterData = transformRefinedFilters(filterData, v2Params, response);

	dispatch({
		type: SET_SEARCH_RESULTS,
		payload: { filterData: newFilterData },
	});

	dispatch({
		type: SET_V2_LAST_REQUEST_PARAMS,
		payload: v2Params,
	});

	const destinationCount = calculateDestinationCount(filterData, v2Params, response, newFilterData);

	dispatch({ type: SET_DESTINATION_COUNT, payload: destinationCount });
};

/**
 * Resets all filter data back to it's default state.
 */
const resetToDefaultParams = () => (dispatch, getState: () => State) => {
	dispatch({
		type: SET_V2_RESULTS_SET,
		payload: [],
	});

	const {
		initialData: { configuration },
	} = getState();

	getPassFilters(configuration.passOfferingsUrl).then((response) => {
		dispatch(refineFilters(response, defaultLastRequestedParams));
	});
	updateQueryParams();
};

/**
 * Sets the pass results to their loading state.
 */
export const setFiltersLoading = () => (dispatch) => {
	dispatch({ type: CLEAR_SEARCH_FILTERS_REQUEST });
	dispatch({ type: CLEAR_SEARCH_RESULTS_REQUEST });
	dispatch({ type: CLEAR_RESULTS });
	dispatch({ type: SET_IS_LOADING });
	dispatch({
		type: SET_V2_RESULTS_SET,
		payload: [...Array(PAGE_SIZE).fill(undefined)],
	});
};

/**
 * Set results for pass list.
 */
const setResults = (response) => (dispatch) => {
	dispatch({
		type: SET_V2_SEARCH_RESULTS,
		payload: transformResults(response),
	});
	dispatch({
		type: SET_V2_RESULTS_SET,
		payload: response.totalResult ? response.results.reduce((arr, result) => [...arr, result.id], []) : [],
	});
	dispatch({
		type: SET_NO_RESULTS,
		payload: !response.totalResult,
	});
};

/**
 * Encodes filter parameters into the proper query string.
 * @param v2Params
 */
const setEncodedQuery = (v2Params) => {
	const objJsonStr = JSON.stringify(v2Params);
	updateQueryParams(encodeURIComponent(btoa(objJsonStr)));
};

/**
 * Retrieves v2 formatted params from the store.
 */
const getV2Params = (getState: () => State) => {
	const { filters, filterParams } = getState();
	return transformRequestParams(filterParams, filters);
};

/**
 * Makes a request for results given the current filter params and sets the results.
 */
const fetchPassData = (v2Params) => (dispatch, getState: () => State) => {
	/*
	 If there are no filters there could still be changes to sort.
	 lastRequestedParams would never get set if this is the case which is why it gets set here.
	 */
	if (!Object.keys(v2Params.filters).length) {
		dispatch({
			type: SET_V2_LAST_REQUEST_PARAMS,
			payload: v2Params,
		});
	}

	const {
		initialData: { configuration },
	} = getState();

	return getPassResults(configuration.passOfferingsUrl, v2Params)
		.then((res) => {
			dispatch(setResults(res));
			dispatch({ type: SET_TRIPS_AVAILABLE_COUNT, payload: res.totalResult });
			if (!res.totalResult) {
				dispatch({ type: SET_DONE_LOADING });
				dispatch({
					type: SET_V2_LAST_REQUEST_PARAMS,
					payload: v2Params,
				});
			}
		})
		.catch(() => {
			dispatch({ type: SEARCH_FILTERS_REQUEST_FAILED });
		});
};

const fetchPassFilters = (v2Params) => async (dispatch, getState: () => State) => {
	const {
		initialData: { configuration },
		filterData,
	} = getState();

	/*
	 Only make a filter request if there are changed filters.
	 Sorting never causes refinement so it just needs to support setting the destination count
	 */
	if (Object.keys(v2Params.filters).length) {
		if ('ParentCategories' in v2Params.filters) {
			v2Params.filters.ParentCategories = [...new Set(v2Params.filters.ParentCategories)];
		}

		await getPassRefinedFilters(configuration.passOfferingsUrl, {
			filters: v2Params.filters,
		})
			.then((response) => {
				dispatch(refineFilters(response, v2Params));
			})
			.catch(() => {
				dispatch({ type: SEARCH_FILTERS_REQUEST_FAILED });
			});
	} else {
		dispatch({ type: SET_DESTINATION_COUNT, payload: countLocationsInRegions(filterData.regions) });
	}
};

const fetchResultsAndFilters = (v2Params) => (dispatch) => {
	dispatch(fetchPassData(v2Params)).then(() => {
		dispatch(fetchPassFilters(v2Params)).then(() => {
			dispatch({ type: SET_DONE_LOADING });
			setEncodedQuery(v2Params);
		});
	});
};

/**
 * Gets filtered results from encoded query string
 * @param filterString base64 uri encoded string of query params.
 */
export const getFilteredResults = (filterString) => async (dispatch, getState) => {
	const queryParams = JSON.parse(atob(decodeURIComponent(filterString)));
	if (!queryParams) {
		return;
	}
	dispatch(setFiltersLoading());
	dispatch({ type: SET_NUMBER_OF_RECORDS_TO_SKIP, payload: (queryParams.page - 1) * PAGE_SIZE });

	dispatch(fetchPassData(queryParams)).then(() => {
		dispatch(fetchPassFilters(queryParams)).then(() => {
			dispatch({ type: SET_V2_SHAREABLE_URL_PARAMS, payload: queryParams });
			dispatch({ type: SET_DONE_LOADING });
			setEncodedQuery(queryParams);
		});
	});
};

export const getUnfilteredResults = () => async (dispatch) => {
	const queryParams = {
		sort: 1,
		sortDirection: 2,
		page: 1,
		pageSize: 60,
		filters: {},
	};

	dispatch(fetchPassData(queryParams)).then(() => {
		dispatch(fetchPassFilters(queryParams)).then(() => {
			dispatch({ type: SET_V2_SHAREABLE_URL_PARAMS, payload: queryParams });
			dispatch({ type: SET_DONE_LOADING });
		});
	});
};

/**
 * Get a results for a new page in the paginated results.
 */
export const getPagedResults = () => (dispatch, getState: () => State) => {
	const v2Params = getV2Params(getState);
	dispatch(setFiltersLoading());
	dispatch(fetchResultsAndFilters(v2Params));
};

/**
 * Gets results sorted by a new direction.
 */
export const getSortedData = (sort, sortDirection) => (dispatch, getState: () => State) => {
	const { lastRequestedParams } = getState();
	dispatch({ type: SET_NUMBER_OF_RECORDS_TO_SKIP, payload: 0 });
	const v2Params = getV2Params(getState);

	if (compareParams(v2Params, lastRequestedParams, ['page'])) {
		return;
	}

	dispatch(setFiltersLoading());
	dispatch(fetchPassData(v2Params)).then(() => {
		dispatch({ type: UPDATE_SORT_OPTION, sort, sortDirection });
		dispatch(fetchPassFilters(v2Params)).then(() => {
			dispatch({ type: SET_DONE_LOADING });
			setEncodedQuery(v2Params);
		});
	});
};

/**
 * Gets search results assuming filters may have changed.
 */
export const getSearchResults = () => async (dispatch, getState: () => State) => {
	const { lastRequestedParams } = getState();
	dispatch({ type: SET_NUMBER_OF_RECORDS_TO_SKIP, payload: 0 });
	const v2Params = getV2Params(getState);

	if (equals(v2Params, defaultLastRequestedParams)) {
		if (equals(v2Params, lastRequestedParams)) {
			return;
		}
		dispatch(resetToDefaultParams());
		dispatch(getUnfilteredResults());
		return;
	}

	if (compareParams(v2Params, lastRequestedParams, ['page'])) {
		return;
	}

	dispatch(setFiltersLoading());
	dispatch(fetchResultsAndFilters(v2Params));
};

export const getMobileFilters = () => (dispatch, getState: () => State) => {
	const v2Params = getV2Params(getState);

	dispatch(setFiltersLoading());
	dispatch({ type: CLEAR_SEARCH_FILTERS_REQUEST });

	const {
		initialData: { configuration },
	} = getState();

	/*
	 Only make a filter request if there are changed filters.
	 */
	if (Object.keys(v2Params.filters).length) {
		getPassRefinedFilters(configuration.passOfferingsUrl, {
			filters: v2Params.filters,
		}).then((response) => {
			const { filterData } = getState();
			const newFilterData = transformRefinedFilters(filterData, v2Params, response);

			dispatch({
				type: SET_SEARCH_RESULTS,
				payload: { filterData: newFilterData },
			});
			dispatch({ type: SET_DONE_LOADING });
			setEncodedQuery(v2Params);
		});
	} else {
		dispatch({ type: SET_DONE_LOADING });
		setEncodedQuery(v2Params);
	}
};
