import React, { forwardRef, MutableRefObject, useEffect, ChangeEvent } from 'react';
import { Icon } from 'components/Icon';
import { validateValueByRules, ValidateValueByRulesType } from 'services/ValidationRules';
import { InputField, InputWrapper } from './Input.styles';
import { InputProps } from './Input.types';
import { InputLabel, RequiredContainer } from '../Form.styles';
import { HelpBlock } from '../HelpBlock';
import { InputTooltip } from './InputTooltip';

export const Input = forwardRef(
	(
		{
			disabled,
			hasError,
			helpText,
			hideLabel,
			id,
			labelSize,
			labelText,
			onFocus,
			onBlur,
			onChange,
			placeholder,
			required,
			type,
			min,
			max,
			value,
			validationRules,
			validate,
			onInput,
			tooltip,
			isDesktop,
			onValidation = () => {},
			...rest
		}: InputProps,
		ref,
	) => {
		const innerRef: MutableRefObject<any> = React.useRef(ref);

		const validateFn: ValidateValueByRulesType = validate || validateValueByRules;
		const validateBeforeProceed = val => onValidation(innerRef.current, ...validateFn(val, validationRules));

		const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
			if (typeof onChange === 'function') {
				validateBeforeProceed(e.target.value);
				onChange(e);
			}
		};
		const handleFocus = (e: React.ChangeEvent<HTMLInputElement>) => {
			if (typeof onFocus === 'function') {
				validateBeforeProceed(e.target.value);
				onFocus(e);
			}
		};
		const handleBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
			if (typeof onBlur === 'function') {
				validateBeforeProceed(e.target.value);
				onBlur(e);
			}
		};

		const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
			if (typeof onInput === 'function') {
				validateBeforeProceed(e.target.value);
				onInput(e);
			}
		};

		useEffect(() => {
			validateBeforeProceed(value);
		}, [innerRef]);

		return (
			<InputWrapper>
				{labelText && (
					<InputLabel size={labelSize} as='label' htmlFor={id} hideLabel={hideLabel}>
						{labelText}
						{required && (
							<RequiredContainer>
								<Icon iconName='required' iconSize='md' />
							</RequiredContainer>
						)}
						{tooltip && isDesktop && (
							<InputTooltip message={tooltip}/>
						)}
					</InputLabel>
				)}
				<InputField
					type={type}
					id={id}
					disabled={disabled}
					placeholder={placeholder}
					value={value}
					hasError={hasError}
					ref={innerRef}
					required={required}
					minLength={min}
					maxLength={max}
					onFocus={handleFocus}
					onBlur={handleBlur}
					onChange={handleChange}
					onInput={handleInput}
					{...rest}
				/>
				{helpText && <HelpBlock hasError={hasError} helpText={helpText} />}
			</InputWrapper>
		);
	},
);
