export const CLEAR_CHECK_IN_DATES = 'CLEAR_CHECK_IN_DATES';
export const CLEAR_LENGTH_OF_STAY = 'CLEAR_LENGTH_OF_STAY';
export const CLEAR_MIN_OCCUPANCY = 'CLEAR_MIN_OCCUPANCY';
export const CLEAR_REGIONS = 'CLEAR_REGIONS';
export const CLEAR_DESTINATIONS = 'CLEAR_DESTINATIONS';
export const CLEAR_VACATION_STYLES = 'CLEAR_VACATION_STYLES';
export const CLEAR_SKIP = 'CLEAR_SKIP';
export const SET_CARD_LAYOUT = 'SET_CARD_LAYOUT';
export const SET_DESTINATION_COUNT = 'SET_DESTINATION_COUNT';
export const SET_DONE_LOADING = 'SET_DONE_LOADING';
export const SET_INITIAL_DATA = 'SET_INITIAL_DATA';
export const SET_FILTER_DATA = 'SET_FILTER_DATA';
export const SET_INITIAL_SEARCH_RESULTS = 'SET_INITIAL_SEARCH_RESULTS';
export const SET_IS_LOADING = 'SET_IS_LOADING';
export const SET_LAST_REQUEST_PARAMS = 'SET_LAST_REQUEST_PARAMS';
export const CLEAR_LAST_REQUEST_PARAMS = 'CLEAR_LAST_REQUEST_PARAMS';
export const SET_LAST_APPLIED_FILTER = 'SET_LAST_APPLIED_FILTER';
export const SET_NUMBER_OF_RECORDS_TO_SKIP = 'SET_NUMBER_OF_RECORDS_TO_SKIP';
export const SET_SEARCH_RESULTS = 'SET_SEARCH_RESULTS';
export const SET_TRIPS_RETURNED_COUNT = 'SET_TRIPS_RETURNED_COUNT';
export const TOGGLE_ENTIRE_MONTH = 'TOGGLE_ENTIRE_MONTH';
export const UPDATE_CHECK_IN_DATES = 'UPDATE_CHECK_IN_DATES';
export const UPDATE_DESTINATIONS = 'UPDATE_DESTINATIONS';
export const UPDATE_FILTER_DATA = 'UPDATE_FILTER_DATA';
export const UPDATE_MIN_LENGTH_OF_STAY = 'UPDATE_MIN_LENGTH_OF_STAY';
export const UPDATE_MIN_OCCUPANCY = 'UPDATE_MIN_OCCUPANCY';
export const UPDATE_REGION_CHILDREN = 'UPDATE_REGION_CHILDREN';
export const UPDATE_REGIONS = 'UPDATE_REGIONS';
export const UPDATE_SELECTED_UNIT_TYPE_ID = 'UPDATE_SELECTED_UNIT_TYPE_ID';
export const UPDATE_SORT_OPTION = 'UPDATE_SORT_OPTION';
export const UPDATE_VACATION_STYLES = 'UPDATE_VACATION_STYLES';
export const SET_DESTINATION_IDS = 'SET_DESTINATION_IDS';
export const CLEAR_RESULTS = 'CLEAR_RESULTS';
export const SET_BOOKMARKED_FILTER_PARAMS = 'SET_BOOKMARKED_FILTER_PARAMS';
export const SET_BOOKMARKED_FILTER_DATA = 'SET_BOOKMARKED_FILTER_DATA';
export const CLEAR_SORT = 'CLEAR_SORT';
export const CLEAR_SORT_DIRECTION = 'CLEAR_SORT_DIRECTION';
export const UPDATE_LAST_REQUESTED_MIN_LENGTH_OF_STAY = 'UPDATE_LAST_REQUESTED_MIN_LENGTH_OF_STAY';
export const SET_SSR_INITIALIZED = 'SET_SSR_INITIALIZED';
export const UPDATE_UNITS = 'UPDATE_UNITS';
export const UPDATE_PROPERTIES = 'UPDATE_PROPERTIES';
export const CLEAR_UNITS = 'CLEAR_UNITS';
export const CLEAR_PROPERTIES = 'CLEAR_PROPERTIES';

// Pass Unit Page Constants
export const SET_UNIT_PAGE = 'SET_UNIT_PAGE';
export const OPEN_UNIT_MODAL = 'OPEN_UNIT_MODAL';
export const CLOSE_UNIT_MODAL = 'CLOSE_UNIT_MODAL';
export const PASS_UNIT_LOADING = 'PASS_UNIT_LOADING';
export const PASS_UNIT_DONE_LOADING = 'PASS_UNIT_DONE_LOADING';
export const SET_UNIT_PAGE_DETAILS = 'SET_UNIT_PAGE_DETAILS';
export const CLEAR_UNIT_PAGE_DETAILS = 'CLEAR_UNIT_PAGE_DETAILS';
export const SET_CURRENT_UNIT = 'SET_CURRENT_UNIT';
export const SET_UNIT_PAGE_CONTENT = 'SET_UNIT_PAGE_CONTENT';

export const CLEAR_SEARCH_RESULTS_REQUEST = 'CLEAR_SEARCH_RESULTS_REQUEST';
export const CLEAR_SEARCH_FILTERS_REQUEST = 'CLEAR_SEARCH_FILTERS_REQUEST';
export const SEARCH_FILTERS_REQUEST_FAILED = 'SEARCH_FILTERS_REQUEST_FAILED';
export const SEARCH_RESULTS_REQUEST_FAILED = 'SEARCH_RESULTS_REQUEST_FAILED';

export const SET_PASS_DEFAULT_PAGE_CONTENT = 'SET_PASS_DEFAULT_PAGE_CONTENT';

export const CLEAR_CONTAINS_FEATURED = 'CLEAR_CONTAINS_FEATURED';
export const PAGE_SIZE = 60;

// v2 API constants
export const UPDATE_V2_FILTER_STYLES = 'UPDATE_V2_FILTER_STYLES';
export const UPDATE_V2_FILTER_PARENT_CATEGORY = 'UPDATE_V2_FILTER_PARENT_CATEGORY';
export const UPDATE_V2_FILTER_NUMBER_OF_DAYS = 'UPDATE_V2_FILTER_NUMBER_OF_DAYS';
export const UPDATE_V2_FILTER_OCCUPANCY = 'UPDATE_V2_FILTER_OCCUPANCY';
export const UPDATE_V2_FILTER_CHECK_IN_DATES = 'UPDATE_V2_FILTER_CHECK_IN_DATES';
export const SET_V2_SEARCH_RESULTS = 'SET_V2_SEARCH_RESULTS';
export const SET_TRIPS_AVAILABLE_COUNT = 'SET_TRIPS_AVAILABLE_COUNT';
export const SET_V2_RESULTS_SET = 'SET_V2_RESULTS_SET';
export const SET_NO_RESULTS = 'SET_NO_RESULTS';
export const CLEAR_V2_RESULTS_SET = 'CLEAR_V2_RESULTS_SET';
export const SET_V2_SHAREABLE_URL_PARAMS = 'SET_V2_SHAREABLE_URL_PARAMS';
export const SET_V2_LAST_REQUEST_PARAMS = 'SET_V2_LAST_REQUEST_PARAMS';
