import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import root from 'window-or-global';
import styled from 'styled-components';
import { xsOnly } from 'design-system/media-queries';
import { spaceSmall } from 'design-system/variables/spaces';
import { Subtext } from '../../Subtext';
import { pluralize } from '../../../design-system/utils';
import { getTotalExperienceCount } from '../redux/selectors';

const Results = styled(props => <Subtext {...props} />)`
	${xsOnly`
		margin-top: ${spaceSmall};
	`}
`;

export const ResultsCount: FC = () => {
	const totalCount = useSelector(state => getTotalExperienceCount(state));

	const renderContent = () => {
		if (root.location && root.location.pathname.includes('cruise')) {
			return pluralize(totalCount, 'cruise', 'cruises');
		}
		return pluralize(totalCount, 'experience', 'experiences');
	};
	return <Results>{renderContent()}</Results>;
};
