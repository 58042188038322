import React, { forwardRef, RefObject, memo, useContext, useEffect } from 'react';

import { FilterButtonActions } from 'components/FilterButtonActions';
import { AccessibleMenu, KeyboardAccessibilityContext } from 'components/KeyboardAccessibility';
import { DestinationsAccordion } from './DestinationsAccordion';
import { ErrorNothingMatched } from './ErrorMessages';
import { AutocompleteDropdownProps } from './DestinationAutocompleteProps';
import { DestinationsSection } from './DestinationsSection';
import {
	DestinationsDropdownWrapper,
	FilterButtonActionsContainer,
	DestinationsDropdownResults,
} from './DestinationAutocomplete.styles';
import { getFirstRegionInExpandedParent } from './utils';

export const AutocompleteDropdown = memo(
	forwardRef(
		(
			{
				regions,
				clearSearch,
				clearAll,
				onApply,
				additionalSections,
				destinations,
				...rest
			}: AutocompleteDropdownProps,
			ref: RefObject<HTMLInputElement>,
		) => {
			const { setActiveId } = useContext(KeyboardAccessibilityContext);
			const hasRegions = (regions && regions.length) || null;
			const hasAdditionalSections =
				(additionalSections.length && additionalSections.some((section) => section.data.length)) || null;

			useEffect(() => {
				const firstRegion = getFirstRegionInExpandedParent({ regions }) || regions[0];
				setActiveId(firstRegion.label);

				return () => {
					setActiveId(null);
				};
			}, []);

			return (
				<AccessibleMenu>
					<DestinationsDropdownWrapper ref={ref}>
						{hasRegions || hasAdditionalSections ? (
							<DestinationsDropdownResults id='dropdown-results'>
								{hasRegions && (
									<DestinationsAccordion regions={regions} destinations={destinations} {...rest} />
								)}
								{hasAdditionalSections &&
									additionalSections.map((section) => (
										<DestinationsSection key={section.sectionLabel} {...section} {...rest} />
									))}
							</DestinationsDropdownResults>
						) : (
							<ErrorNothingMatched clearSearch={clearSearch} />
						)}

						<FilterButtonActionsContainer>
							<FilterButtonActions
								onClearClick={clearAll}
								onApplyClick={onApply}
								isDisabled={destinations.length === 0}
							/>
						</FilterButtonActionsContainer>
					</DestinationsDropdownWrapper>
				</AccessibleMenu>
			);
		},
	),
);
