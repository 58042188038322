import { useState } from 'react';

function* loopedRange(start, end, total) {
	for (let i = start; i <= end; i++) {
		if (i >= total) {
			yield i - total;
		} else if (i < 0) {
			yield total + i;
		} else {
			yield i;
		}
	}
}

export const useLazyImageList = (loadBatch: number, listLength: number) => {
	const [loadedIndices, setLoadedIndices] = useState([]);

	const handleInView = (i: number): void => {
		const rangePadding = (loadBatch - 1) / 2;

		setLoadedIndices(
			Array.from(new Set([...loadedIndices, ...loopedRange(i - rangePadding, i + rangePadding, listLength + 1)])),
		);
	};

	const shouldRequestImg = (i: number): boolean => loadedIndices.includes(i);

	const getImageProps = (i: number, props?: any) => ({
		key: `lazy-img-${i}`,
		index: i,
		shouldRequest: shouldRequestImg(i),
		onInView: () => handleInView(i),
		...props,
	});

	return { handleInView, shouldRequestImg, getImageProps };
};
