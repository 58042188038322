import styled from 'styled-components';
import { boxShadow2 } from 'design-system/style-mixins/shadows-and-scrims';
import { fadeInAnimation, fadeOutAnimation } from 'design-system/style-mixins/animations';
import { white, grayMedium, black } from 'design-system/variables/colors';
import { spaceBase } from 'design-system/variables/spaces';
import { textBaseSerif, bold } from 'design-system/style-mixins/text';
import { Icon } from 'components/Icon';
import { Title } from 'components/Title';
import { Paragraph } from 'components/Paragraph';

export const ChatInviteWindow = styled.div<{ showInvite: boolean }>`
    ${boxShadow2}
	${fadeInAnimation}

    background-color: ${white};
	bottom: ${spaceBase};
	display: ${props => (props.showInvite ? 'block' : 'none')};
	min-width: 250px;
	position: fixed;
	right: ${spaceBase};
	z-index: 1000000;
	max-width: 350px;

	&.fade-out {
		${fadeOutAnimation}
		display: none;
	}
`;

export const ChatInviteHeader = styled.header`
	background-color: ${black};
	box-sizing: unset;
	color: ${white};
	height: 35px;
	margin-bottom: ${spaceBase};
	padding: 10px 0px 3px 0px;
	width: 100%;

	span {
		${textBaseSerif}
	}
`;

export const ChatInviteIcon = styled(props => <Icon {...props} />)`
	float: left;
`;

export const ChatInviteTitle = styled(props => <Title size='small' {...props} />)`
	${bold}

	color: ${black};
	text-transform: capitalize;
`;

export const ChatInviteBody = styled(props => <Paragraph size='medium' {...props} />)`
	color: ${grayMedium};
	word-break: normal;
`;

export const ChatButtonPanel = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: ${spaceBase};
	margin-top: ${spaceBase};
`;
