import { FC, useContext, ReactNode, cloneElement } from 'react';

import { Context } from '../Context';

interface Props {
	children: ReactNode;
	label: string;
	role?: string;
	disabled?: boolean;
}

export const AccessibleMenuOption: FC<Props> = ({ children, label, role, disabled }) => {
	const { activeId } = useContext(Context);
	const attributes = {
		role: role || 'option',
		'aria-label': label,
		'aria-disabled': disabled,
		id: label,
		isActive: activeId === label,
	};

	return cloneElement(children, { ...attributes });
};
