import React, { useCallback, useState } from 'react';
import styled, { createGlobalStyle, css } from 'styled-components';
import root from 'window-or-global';
import { ComponentProps } from '../../interfaces/ComponentProps';
import { useScript } from '../../services/Helpers';
import { white } from '../../design-system/variables/colors';

export interface VideoPlayerProps extends ComponentProps {
	vidyardEmbedCode: string;
	playerType: string;
}

export const videoPlayerType = {
	inline: 'inline',
	lightbox: 'lightbox',
};

const vidyardCommonStyles = css`
	height: 0 !important; /* using important since these values get set in js by vidyard */
	max-height: calc(100vw * 0.5625); /* constrain the aspect ratio, 9:16 = 0.5625 */
	max-width: 100vw;
	padding-bottom: 56.25%;
	width: 100% !important;
`;

const Container = styled.div`
	background-color: ${white};

	&:after {
		clear: both;
		content: '';
		display: block;
		height: 0;
		visibility: hidden;
	}

	.vidyard_player > span {
		${vidyardCommonStyles}
	}
`;

// TODO: replace .video-gallery with relevant component when it's introduced
const Styles = createGlobalStyle`
	.video-gallery ${Container} {
		background-color: rgba(0, 0, 0, .075)
	}
`;

const PlayBtn = styled.div<{ isVisible: boolean }>`
	visibility: ${props => (props.isVisible ? 'visible' : 'hidden')};
`;

const LightboxContainer = styled.div`
	${vidyardCommonStyles}
`;

const VIDYARD_SERVER_NAME = 'play.vidyard.com';
const VIDYARD_IMG_EXTENSION = 'jpg';
const getImageSrc = (vidyardEmbedCode: string) => {
	return `//${VIDYARD_SERVER_NAME}/${vidyardEmbedCode}.${VIDYARD_IMG_EXTENSION}`;
};

const getVidyardSrc = (vidyardEmbedCode: string, type: string) => {
	return `//play.vidyard.com/${vidyardEmbedCode}.js?v=3.1&type=${type}&width=960&height=540`;
};

export const VideoPlayer = (props: VideoPlayerProps) => {
	const { vidyardEmbedCode, playerType } = props;

	const [playerElement, setPlayerElement] = useState(null);

	const elementRef = useCallback(node => {
		setPlayerElement(node);
	}, []);

	const type = playerType || videoPlayerType.inline;
	const id = `vidyard_embed_code_${vidyardEmbedCode}`;
	const src = getVidyardSrc(vidyardEmbedCode, type);

	const [vidyardScriptLoaded] = useScript(src, id, type === videoPlayerType.inline ? playerElement : null);

	const openLightboxPlayer = () => {
		vidyardScriptLoaded && root[`fn_vidyard_${vidyardEmbedCode}`]();
		return false;
	};

	return (
		<React.Fragment>
			<Styles />
			<Container ref={elementRef}>
				{playerType === 'lightbox' && (
					<LightboxContainer className='vidyard_wrapper' onClick={openLightboxPlayer}>
						<img width='100%' src={getImageSrc(vidyardEmbedCode)} alt='' />
						<PlayBtn className='vidyard_play_button' isVisible={vidyardScriptLoaded}>
							{/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-has-content */}
							<a href='javascript:void(0);' />
						</PlayBtn>
					</LightboxContainer>
				)}
			</Container>
		</React.Fragment>
	);
};
