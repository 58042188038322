import React, { FC, useRef, useEffect, useCallback, memo } from 'react';
import { clone } from 'ramda';
import smoothscroll from 'smoothscroll-polyfill';
import { ScrollDragContainer } from 'components/ScrollDragContainer';
import { AccessibleInput } from 'components/KeyboardAccessibility';
import { DestinationTag } from './DestinationTag';
import {
	SearchInput,
	SearchInputWrapper,
	SearchInputTrigger,
	DestinationsTagCarouselWrapper,
} from './DestinationAutocomplete.styles';
import { DestinationsTagCarouselProps } from './DestinationAutocompleteProps';
import { usePrevProps, handleKeyDownUtil } from './utils';

export const DestinationsTagCarousel: FC<DestinationsTagCarouselProps> = memo(
	({
		updateRegionsExpandedState,
		removeDestination,
		searchResults,
		additionalResults,
		destinations,
		openDropdown,
		selectResult,
		changeValue,
		placeholder,
		isVisible,
		isMobile,
		value,
		isLoading,
	}) => {
		const inputRef = useRef(null);
		const scrollContainerRef = useRef(null);
		const previousDestinations = usePrevProps(clone(destinations)) || [];

		const scrollRight = useCallback(() => {
			if (!scrollContainerRef.current) {
				return;
			}
			const { scrollWidth } = scrollContainerRef.current;

			scrollContainerRef.current.scrollTo({
				top: 0,
				left: scrollWidth,
				behavior: 'smooth',
			});
		}, [scrollContainerRef]);

		const handleFocus = useCallback(() => {
			openDropdown(true);
			scrollRight();
			setTimeout(() => inputRef.current.focus(), 0);
		}, []);

		const handleKeyDown = useCallback(
			({ e, activeId, setActiveId }) => {
				handleKeyDownUtil({
					updateRegionsExpandedState,
					removeDestination,
					searchResults,
					additionalResults,
					openDropdown,
					selectResult,
					destinations,
					setActiveId,
					isVisible,
					activeId,
					value,
					e,
				});
			},
			[searchResults, destinations, isVisible, additionalResults, value],
		);

		useEffect(() => {
			smoothscroll.polyfill();
		}, []);

		useEffect(() => {
			if (isVisible && scrollContainerRef.current) {
				scrollContainerRef.current.scrollLeft = scrollContainerRef.current.scrollWidth;
			}
		}, [isVisible]);

		useEffect(() => {
			const isDestinationAdded = destinations.length > previousDestinations.length;

			if (isDestinationAdded) {
				if (inputRef.current) {
					inputRef.current.style.position = 'sticky';
				}
				scrollRight();

				setTimeout(() => {
					if (inputRef.current) {
						inputRef.current.style.position = 'static';
					}
				}, 350);
			}
		}, [destinations]);

		return (
			<DestinationsTagCarouselWrapper>
				<ScrollDragContainer ref={scrollContainerRef}>
					{destinations.map((destination, index) => (
						<DestinationTag
							key={destination.id}
							destination={destination}
							onRemove={() => removeDestination(index)}
							isDisabled={isLoading}
						/>
					))}
					<SearchInputWrapper>
						<AccessibleInput handleKeyDown={handleKeyDown}>
							<SearchInput
								data-type='search-input'
								ref={inputRef}
								placeholder={placeholder}
								value={value}
								onChange={changeValue}
								onFocus={handleFocus}
								disabled={isLoading}
							/>
						</AccessibleInput>
						{isMobile && !isVisible && <SearchInputTrigger onClick={handleFocus}>Open</SearchInputTrigger>}
					</SearchInputWrapper>
				</ScrollDragContainer>
			</DestinationsTagCarouselWrapper>
		);
	},
);
