import styled from 'styled-components';
import { black } from 'design-system/variables/colors';
import { textTiny } from 'design-system/style-mixins/text';
import { spaceTiny, spaceSmall } from '../../design-system/variables/spaces';

export const TagGroup = styled.div`
	display: flex;
	flex-wrap: wrap;
	margin-top: calc(${spaceTiny} / -1);

	> * {
		margin-top: ${spaceTiny};
	}

	> *:not(:last-child) {
		margin-right: ${spaceTiny};
	}
`;

export const Tag = styled.span`
	${textTiny};
	display: inline-block;
	padding: 0 ${spaceSmall};
	border: 1px solid ${black};
	border-radius: 360px;
`;
