import styled from 'styled-components';
import React from 'react';
import { black } from '../../design-system/variables/colors';
import { rotate, dash } from '../../design-system/style-mixins/animations';

const LoaderContainer = styled.div`
	display: flex;
	width: ${props => props.spinnerSize};
	height: ${props => props.spinnerSize};
	justify-content: center;
`;

const SpinnerSVG = styled.svg`
	animation: ${rotate} 1s linear infinite;
	height: 100%;
	width: 100%;
	overflow: visible;

	circle {
		animation: ${dash} 1.5s ease-in-out infinite;
		stroke: ${black};
		stroke-dasharray: 1, 200;
		stroke-dashoffset: 0;
		stroke-linecap: round;
	}
`;

/**
 * Loaders:
 * Used for progress & loading indicators.
 */
export const LoaderSpinner = ({ spinnerSize = '40px', ...rest }) => (
	<LoaderContainer spinnerSize={spinnerSize} {...rest}>
		<SpinnerSVG viewBox='25 25 50 50'>
			<circle cx='50' cy='50' r='20' fill='none' strokeWidth='7' strokeMiterlimit='10' />
		</SpinnerSVG>
	</LoaderContainer>
);
