import React from 'react';
import styled from 'styled-components';
import { Headline } from 'components/Headline';
import { Link } from 'components/Link';
import { Paragraph } from 'components/Paragraph';

import { spaceTiny, spaceBase } from 'design-system/variables/spaces';
import { ContactWidgetWrapper, ContactWidgetTeamTitle, CopyWrapper } from '../ContactWidget.styles';
import { ContactWidgetDisplayOptions, CustomerSupportInfoProps } from '../ContactWidgetProps';

const Subtext = styled(props => <Paragraph size='small' {...props} />)`
	margin: ${spaceTiny} auto ${spaceBase};
`;

export const MemberContactWidget: React.FC<{
	info: CustomerSupportInfoProps;
	displayOpts: ContactWidgetDisplayOptions;
	imageServerUrl: string;
}> = ({ info, displayOpts, imageServerUrl }) => (
	<ContactWidgetWrapper
		card={displayOpts.card}
		headline={displayOpts.headline}
		imgSize={displayOpts.imgSize || 'full'}
		row={displayOpts.row}
	>
		{displayOpts.headline && !displayOpts.row && <Headline size='small'>Questions? We're here to help.</Headline>}
		{info.photoSrc && (
			<img src={`${imageServerUrl}${info.photoSrc}&width=384`} alt={`${info.name}; ${info.subtext}`} />
		)}
		<CopyWrapper>
			<div>
				<ContactWidgetTeamTitle>{info.name}</ContactWidgetTeamTitle>
				<Subtext>{info.subtext}</Subtext>
			</div>
			<div>
				<Link href={`tel:${info.phone}`} color='primary'>
					{info.phone}
				</Link>
				<Link href={`mailto:${info.email}`} color='primary'>
					{info.email.toLowerCase()}
				</Link>

				{displayOpts.details && displayOpts.details}
			</div>
		</CopyWrapper>
	</ContactWidgetWrapper>
);
