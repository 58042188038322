import { Country } from 'enums/Country';

export enum Constants {
	id = 'country',
	name = 'CountryID',
	DefaultCountry = 240,
}

export enum PostalCode {
	id = 'postal_code',
	name = 'PostalCode',
}

export enum CountriesWithPostalCode {
	Canada = 254,
	UnitedStates = 240,
}

export const Countries = Object.keys(Country).map(countryName => {
	const c = Country[countryName];
	return {
		value: c.name,
		key: c.code,
	};
});
