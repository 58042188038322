import React from 'react';
import styled, { css, createGlobalStyle } from 'styled-components';
import { getSafeHtml } from 'services/Helpers';
import { Picture, Img } from 'components/ResponsiveImage/ResponsiveImage.styles';
import { TestimonialProps } from './TestimonialProps';
import { useLoadVidyardVideo, watchVidyardVideo } from '../../../services/VidyardService';
import { spaceLarge } from '../../../design-system/variables/spaces';
import { black, white } from '../../../design-system/variables/colors';
import { containerWidth } from '../../../design-system/style-mixins/grid-and-layout';
import { boxShadow2 } from '../../../design-system/style-mixins/shadows-and-scrims';
import { Blockquote } from '../../Blockquote';
import { AllCaps } from '../../AllCaps';
import { ResponsiveImage } from '../../ResponsiveImage';

export const MemberTestimonialContainer = styled.div<{ isSelected?: boolean }>`
	display: block;

	${props =>
		!props.isSelected &&
		css`
			display: none;
		`}
`;

const Styles = createGlobalStyle`
	${MemberTestimonialContainer} ${Picture} ${Img} {
		z-index: -2
	}
`;

const Header = styled.header`
	text-align: center;
`;

const Headline = styled.h2`
	color: ${white};
	margin-bottom: ${spaceLarge};
	margin-left: 0;
	margin-right: 0;
	margin-top: 0;
	position: relative;
	text-align: center;
`;

const TestimonialContainer = styled.div`
	&& {
		margin-left: auto;
		margin-right: auto;
	}
	position: relative;
	${containerWidth};
`;

const Quote = styled.div`
	background: none;
	color: ${white};
	display: inline-block;
	margin: 0 auto;
	padding: 0;
	p {
		color: ${white};
		margin: 0;
		padding: 0;
		&:before {
			content: open-quote;
		}
		&:after {
			content: close-quote;
		}
	}
`;

const Attribution = styled.div`
	color: ${white};
	margin-top: ${spaceLarge};
`;

const PlayVideo = styled.button`
	background-color: ${black};
	border: none;
	border-radius: 500px;
	bottom: calc(0% + 115px);
	cursor: pointer;
	filter: alpha(opacity=75);
	font-size: 0;
	height: auto;
	left: 50%;
	margin-left: -25px;
	margin-top: -25px;
	opacity: 0.75;
	overflow: visible;
	padding: 0;
	position: absolute;
	transition: opacity 0.2s;
	width: 50px;
	&:hover {
		${boxShadow2}
		opacity: 1;
		zoom: 1;
	}
`;

const Size = styled.div`
	padding-top: 100%;
	width: 100%;
`;

const ArrowSize = styled.div`
	height: auto;
	left: 50%;
	margin: -25% 0 0 -12%;
	overflow: hidden;
	position: absolute;
	top: 50%;
	width: 35%;
`;

const SizeRatio = styled.div`
	padding-top: 150%;
	width: 100%;
`;
const Arrow = styled.div`
	border: 200px solid transparent;
	border-left: 300px solid ${white};
	border-right: none;
	height: 0;
	margin: -200px 0 -200px -300px;
	position: absolute;
	right: 0;
	top: 50%;
	width: 0;
`;

const MemberTestimonial: React.FunctionComponent<TestimonialProps> = ({
	attribution,
	backgroundImageUrl,
	isSelected,
	quote,
	vidyardEmbedCode,
	headline,
}) => {
	const [vidyardScriptLoaded] = useLoadVidyardVideo(vidyardEmbedCode);

	return (
		<MemberTestimonialContainer isSelected={isSelected}>
			<Styles />
			<ResponsiveImage url={backgroundImageUrl} adjustHeightForMobile />

			<Header>
				<div className='row center-xs'>
					<div className='col-xs-12 col-md-10'>
						<Headline>{headline}</Headline>
					</div>
				</div>
			</Header>

			<TestimonialContainer className='row center-xs'>
				<div className='col-xs-12 col-md-10'>
					<Blockquote size='large'>
						<Quote dangerouslySetInnerHTML={getSafeHtml(quote)} />
					</Blockquote>
					<AllCaps size='small'>
						<Attribution>{attribution}</Attribution>
					</AllCaps>
				</div>
			</TestimonialContainer>

			{vidyardEmbedCode && vidyardScriptLoaded && (
				<PlayVideo
					onClick={() => watchVidyardVideo(vidyardEmbedCode)}
					type='button'
					title='Play Video'
					aria-label='Play Video'
				>
					<Size />
					<ArrowSize>
						<SizeRatio />
						<Arrow />
					</ArrowSize>
				</PlayVideo>
			)}
		</MemberTestimonialContainer>
	);
};

export default MemberTestimonial;
