import { useState, useEffect } from 'react';
import root from 'window-or-global';

export const useFullscreenView = galleryRef => {
	const [isFullscreen, toggleFullScreen] = useState(false);
	const gallery = galleryRef && galleryRef.current;

	function exitFullscreen() {
		if (isFullscreen) {
			if (root.document.exitFullscreen) {
				root.document.exitFullscreen();
			} else if (root.document.webkitExitFullscreen) {
				root.document.webkitExitFullscreen();
			} else if (root.document.mozCancelFullScreen) {
				root.document.mozCancelFullScreen();
			} else if (root.document.msExitFullscreen) {
				root.document.msExitFullscreen();
			}
			toggleFullScreen(false);
		}
	}

	function enterFullscreen() {
		if (gallery.requestFullscreen) {
			gallery.requestFullscreen();
		} else if (gallery.msRequestFullscreen) {
			gallery.msRequestFullscreen();
		} else if (gallery.mozRequestFullScreen) {
			gallery.mozRequestFullScreen();
		} else if (gallery.webkitRequestFullscreen) {
			gallery.webkitRequestFullscreen();
		}
		toggleFullScreen(true);
	}

	const toggle = () => {
		if (isFullscreen) {
			exitFullscreen();
		} else {
			enterFullscreen();
		}
	};

	const onFullScreenChange = () => {
		toggleFullScreen(Boolean(root.document.fullscreenElement));
	};

	useEffect(() => {
		root.addEventListener('fullscreenchange', onFullScreenChange, false);
		root.addEventListener('webkitfullscreenchange', onFullScreenChange, false);
		root.addEventListener('mozfullscreenchange', onFullScreenChange, false);

		return () => {
			root.removeEventListener('fullscreenchange', onFullScreenChange, false);
			root.removeEventListener('webkitfullscreenchange', onFullScreenChange, false);
			root.removeEventListener('mozfullscreenchange', onFullScreenChange, false);
		};
	}, []);

	return [isFullscreen, toggle];
};
