import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { getErrorMessages } from '../../../../shared/utils';
import { getTripProposal, deleteNonPortfolioAccommodationDateRange, editProposalNPO, deleteProposalElement } from '../../../../services/tripRecommendationsApiService';
import { NONPORTFOLIO_ACCOMODATION_UPDATED, NPO_ACCOMMODATION_NOTE, PROPOSAL_ELEMENTS_UPDATED, ACCOMMODATION_DATE_RANGE, NPO_ACCOMMODATION } from '../constants';
import { EditNPORequest, TripProposalElement } from '../../../../interfaces';
import { State } from '../context';
import { clearFieldError, setFieldError } from './errors';
import { updateDirty } from './proposal';

export const updateNpoNote = (proposalElementId: number, npoId: number, request: EditNPORequest, modifiedBy: string) => (
	dispatch: ThunkDispatch<State, undefined, Action>,
	getState: () => State,
) => {
	const { metadata, proposalElements } = getState();
	const fieldName = `${NPO_ACCOMMODATION_NOTE}_${proposalElementId}`;
	const { nonPortfolioAccommodation } = proposalElements.find(
		(element: TripProposalElement) => element.nonPortfolioAccommodation !== null && element.nonPortfolioAccommodation.id === npoId,
	);
	nonPortfolioAccommodation.note = request.Note;
	dispatch(clearFieldError(fieldName));

	editProposalNPO(metadata.id, proposalElementId, request)
		.then(() => {
			dispatch({ type: NONPORTFOLIO_ACCOMODATION_UPDATED, payload: { proposalElementId, nonPortfolioAccommodation } });
			dispatch(updateDirty());
		})
		.catch(error => {
			const errorMessage = getErrorMessages(error, 'Note');
			dispatch(setFieldError(fieldName, errorMessage));
		});
};

export const deleteNonPortfolioDateRange = (dateRangeId: number) => (
	dispatch: ThunkDispatch<State, undefined, Action>,
	getState: () => State,
) => {
	const { metadata } = getState();
	const fieldName = `${ACCOMMODATION_DATE_RANGE}_${dateRangeId}`;

	dispatch(clearFieldError(fieldName));

	deleteNonPortfolioAccommodationDateRange(metadata.id, dateRangeId)
		.then(() => {
			getTripProposal(metadata.id)
				.then(proposal => {
					dispatch({
						type: PROPOSAL_ELEMENTS_UPDATED,
						payload: { proposalElements: proposal.proposalElements },
					});
				})
				.catch(error => {
					const errorMessage = getErrorMessages(error, '');
					dispatch(setFieldError(fieldName, errorMessage));
				});
		})
		.catch(error => {
			const errorMessage = getErrorMessages(error, '');
			dispatch(setFieldError(fieldName, errorMessage));
		});
};

export const deleteNpoAccommodation = (accommodationId: number, proposalElementId: number) => (
	dispatch: ThunkDispatch<State, undefined, Action>,
	getState: () => State,
) => {
	const { metadata, proposalElements } = getState();
	const fieldName = `${NPO_ACCOMMODATION}_${accommodationId}`;
	const updatedElements = proposalElements.filter(element => element.id !== proposalElementId);

	dispatch(clearFieldError(fieldName));

	deleteProposalElement(metadata.id, proposalElementId)
		.then(() => {
			dispatch({ type: PROPOSAL_ELEMENTS_UPDATED, payload: { proposalElements: updatedElements } });
			dispatch(updateDirty());
		})
		.catch(error => {
			const errorMessage = 'Unable to delete element. Please try again.';
			dispatch(setFieldError(fieldName, errorMessage));
		});
};

