import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { DateRangeInput } from 'pages/TripProposals/views/ProposalManagementView/components/DateRangeInput';
import { IconButton } from 'components';
import { Icon } from 'components/Icon';
import { useIsTyping, useDebounce } from 'components/hooks';
import { useDispatch } from 'react-redux';
import CurrencyInput from 'react-currency-input-field';
import { InputWrapper } from '../../../../../components/Form/Input/Input.styles';
import { InputLabel, RequiredContainer } from '../../../../../components/Form/Form.styles';
import { DateRangeGrid, UnitCell, Error, FirstUnitCell } from './NPOStyles';
import { error, gray } from 'design-system/variables/colors';
import { InputTooltip } from '../../../../../components/Form/Input/InputTooltip';
import { useIsDesktop } from '../../../../../components/hooks/useIsDesktop';

export const CustomNpoDateRangesInput = ({
	renderId,
	index,
	handleChange,
	handleDelete,
	handleTotalChange,
	range,
	errors,
	handleBlur,
}) => {
	const dispatch = useDispatch();
	const hideLabel = index != 0;
	const [disableReservationTotal, setDisableReservationTotal] = useState(!range.reservationTotalNeeded);
	const [disableDeleteButton, setDisableDeleteButton] = useState(range.selectedDays == '');
	const [reservationTotal, setReservationTotal] = useState(range.reservationTotal);
	const [isDeleted, setIsDeleted] = useState(false);
	const [isDesktop] = useIsDesktop();

	const newReservationTotal = useDebounce(reservationTotal, 500); // Value becomes a new message if a user hasn't typed for half a second
	const isTyping = useIsTyping(reservationTotal, 1000); // We assume the user is done typing after 1 second

	useEffect(() => {
		if (!isTyping) {
			dispatch(handleChange(range, renderId));
		}
	}, [newReservationTotal, isTyping]);

	const onReservationTotalChange = (value, name) => {
		setReservationTotal(value);
		range.reservationTotal = value;
		dispatch(handleChange(range, renderId));
	};

	const deleteRow = () =>
	{
		setIsDeleted(!isDeleted);
		handleDelete();
	}

	const onDayChange = (changedRange) => {
		if (!changedRange.startDate) {
			return;
		}
		const stringifiedDateRange = {
			to: moment(changedRange.endDate).format('MM/DD/YYYY'),
			from: moment(changedRange.startDate).format('MM/DD/YYYY'),
		};
		range.checkInDate = stringifiedDateRange.from;
		range.checkOutDate = stringifiedDateRange.to;
		setDisableReservationTotal(range.checkOutDate == '' || range.checkOutDate == 'Invalid date');
		range.reservationTotalNeeded = disableReservationTotal;
		setDisableDeleteButton(false);
	};
	return (
		<DateRangeGrid>
			<UnitCell>
				<DateRangeInput
					fromDate={new Date(range.checkInDate)}
					toDate={new Date(range.checkOutDate)}
					placeholder={'Select Dates'}
					onDaysSelected={onDayChange}
					hideLabel={hideLabel}
					name={`dateRanges[${renderId}].selectedDays`}
					onChange={handleTotalChange}
					range={range}
					isDeleted={isDeleted}
				/>
			</UnitCell>
			<UnitCell>
				<InputWrapper>
					<InputLabel as='label' hideLabel={hideLabel}>
						Reservation Total
						{!disableReservationTotal && isDesktop && (
							<InputTooltip displace={true}
								message={
									'Enter the Reservation Total for the respective dates, including all taxes and fees. Members will see this as “Grand Total” in the proposal email.<br><br>To include nightly rates, add them to the Notes field below.'
								}
							/>
						)}
						{!disableReservationTotal && (
							<RequiredContainer>
								<Icon iconName='required' iconSize='md' />
							</RequiredContainer>
						)}
						{disableReservationTotal && isDesktop && (
							<InputTooltip displace={true}
								message={
									'A Reservation Total can only be included if Dates have already been entered. A Reservation Total is required if Dates have been entered.<br><br>Enter the Reservation Total for the respective dates, including all taxes and fees. Members will see this as “Grand Total” in the proposal email.<br><br>To include nightly rates, add them to the Notes field below.'
								}
							/>
						)}
					</InputLabel>
					<CurrencyInput
						style={{
							display: 'flex',
							border: `1px solid ${
								errors.dateRanges &&
								errors.dateRanges[index] &&
								errors.dateRanges[index].reservationTotal
									? error
									: gray
							}`,
						}}
						placeholder='$0.00'
						id={`dateRanges[${renderId}].reservationTotal`}
						onValueChange={onReservationTotalChange}
						disabled={disableReservationTotal}
						prefix='$'
						decimalScale={2}
						value={range.reservationTotal}
						onBlur={handleBlur}
						allowNegativeValue={false}
					/>
					{errors.dateRanges && errors.dateRanges[index] && errors.dateRanges[index].reservationTotal && (
						<Error>{errors.dateRanges[index].reservationTotal}</Error>
					)}
				</InputWrapper>
			</UnitCell>
			{!hideLabel ? (
				<FirstUnitCell>
					<IconButton
						iconName='delete'
						iconSize='md'
						buttonType='containerless'
						title='Delete'
						onClick={deleteRow}
						isDisabled={disableDeleteButton}
					/>
				</FirstUnitCell>
			) : (
				<UnitCell>
					<IconButton
						iconName='delete'
						iconSize='md'
						buttonType='containerless'
						title='Delete'
						onClick={deleteRow}
						isDisabled={disableDeleteButton}
					/>
				</UnitCell>
			)}
		</DateRangeGrid>
	);
};
