import React from 'react';
import { TFunction } from 'i18next';

export interface FormStateProps {
	CountryID?: number;
	Email?: string;
	FirstName?: string;
	LastName?: string;
	Password?: string;
	Phone?: string;
	PostalCode?: string;
	leadSourceOriginal?: string;
	pardotFormUrl?: string;
	salesforceId?: string;
	visitorType?: number;
	isInternalRegistration?: boolean;
}

interface Props {
	validationRules: any;
	setFieldValue: Function;
	isErrorShown: boolean;
	t: TFunction;
	state?: FormStateProps;
}

export const FormContext = React.createContext<Props>({
	validationRules: {},
	setFieldValue: () => {},
	isErrorShown: false,
	t: () => {},
	state: {},
});
