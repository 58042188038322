import React, { memo } from 'react';
import styled from 'styled-components';

import { AccordionGroup } from 'components/Accordion/AccordionGroup';
import { sm } from '../../design-system/media-queries';
import {
	spaceBase,
	spaceBig,
	spaceHuge,
	spaceLarge,
	spaceMassive,
	spaceMedium,
} from '../../design-system/variables/spaces';
import { AuthButtons } from '../AuthButtons';
import { MobileNavigationProps } from './NavigationProps';
import { MobileNavigationTab } from './MobileNavigationTab';

const Wrapper = styled.nav`
	display: flex;
`;
const Menu = styled.ul`
	list-style: none;
	display: flex;
	flex-direction: column;
	position: absolute;
	overflow-y: auto;
	width: 100%;
	height: 100%;
	top: ${spaceHuge};
	padding: 0 ${spaceBase} ${spaceBig};

	${sm`
		top: ${spaceMassive};
		padding: 0 ${spaceLarge} ${spaceLarge};
	`}
`;

const AuthButtonsWrapper = styled.div`
	align-items: center;
	display: flex;
	flex-grow: 1;
	flex-shrink: 0;
	justify-content: center;
	margin-top: ${spaceMedium};
`;

export const MobileNavigation: React.FC<MobileNavigationProps> = memo(({ links, isLoggedIn }) => (
	<AccordionGroup>
		<Wrapper>
			<Menu>
				{links.map(link => (
					<MobileNavigationTab {...link} key={link.url} />
				))}
				{isLoggedIn ? (
					<React.Fragment>
						<MobileNavigationTab linkTitle='My Inspirato' url='/myinspirato' key='/myinspirato' />
						<MobileNavigationTab linkTitle='Sign Out' url='/account/signout' key='/account/signout' />
					</React.Fragment>
				) : (
					<AuthButtonsWrapper>
						<AuthButtons />
					</AuthButtonsWrapper>
				)}
			</Menu>
		</Wrapper>
	</AccordionGroup>
));
