import DayPickerInput from 'react-day-picker/DayPickerInput';
import React, { LegacyRef, Fragment } from 'react';
import moment from 'moment';
import { DayModifiers } from 'react-day-picker';
import { parseDate } from 'react-day-picker/moment';
import { Input } from 'components';
import { GlobalStyle, InputContainer } from './DateRangeInput.styles';

export interface SelectedDateRange {
	startDate: Date;
	endDate: Date;
}

interface DateRangeInputProps {
	monthsToDisplay?: number;
	onDaysSelected?: (dateRange: SelectedDateRange) => void;
	enabledRange?: { from: Date; to: Date };
	placeholder?: string;
	ref: LegacyRef<DayPickerInput>;
	initSelectedDays?: { checkInDate: Date; checkOutDate: Date };
	hideLabel: boolean;
	name: string;
	range?: any;
	isDeleted?: boolean;
}

export const DateRangeInput: React.ForwardRefExoticComponent<Pick<DateRangeInputProps, any>> = React.forwardRef(
	(
		{ monthsToDisplay = 1, onDaysSelected, enabledRange, initSelectedDays, placeholder, hideLabel, name, range, isDeleted, ...rest },
		ref: LegacyRef<DayPickerInput>,
	) => {
		const [from, setFrom] = React.useState<Date>(initSelectedDays ? initSelectedDays.checkInDate : null);
		const [to, setTo] = React.useState<Date>(initSelectedDays ? initSelectedDays.checkOutDate : null);
		const [enteredTo, setEnteredTo] = React.useState<Date>(initSelectedDays ? initSelectedDays.checkOutDate : null);
		const modifiers = { start: from, end: to };

		const today = new Date();
		const oneYearOut = moment()
			.utc()
			.add(1, 'year')
			.toDate();
		const disabledDays = {
			before: enabledRange ? enabledRange.from : today,
			after: enabledRange ? enabledRange.to : oneYearOut,
		};
		const selectedDays = { from, to: enteredTo };
		const fromMonth = enabledRange ? enabledRange.from : today;
		const initialMonth = initSelectedDays ? initSelectedDays.checkInDate : fromMonth;
		const toMonth = enabledRange ? enabledRange.to : oneYearOut;
		const containerRef = React.useRef(null);

		const isSelectingFirstDay = day => {
			const isBeforeFirstDay = from && moment.utc(day).isBefore(from);
			const isRangeSelected = from && to;

			return !from || isBeforeFirstDay || isRangeSelected;
		};

		React.useEffect(() => {
			if (!onDaysSelected) {
				return;
			}

			const dateRange: SelectedDateRange = { startDate: selectedDays.from, endDate: selectedDays.to };
			onDaysSelected(dateRange);
		}, [from, to]);

		React.useEffect(() => {
			reset();
		}, [isDeleted]);

		const reset = () => {
			setFrom(null);
			setTo(null);
			setEnteredTo(null);
		};

		const handleDayClick = (day: Date, { disabled }: DayModifiers) => {
			if (disabled) {
				return;
			}

			if (from && to && day >= from && day <= to) {
				reset();
			}

			if (isSelectingFirstDay(day)) {
				setFrom(day);
				setTo(null);
				setEnteredTo(null);
			} else {
				setTo(day);

				if (containerRef && containerRef.current) {
					const input = containerRef.current.querySelector('input');

					input.focus();
					input.blur();
					range.selectedDays = input.value;
				}
			}
		};

		const handleDayMouseEnter = day => {
			if (!isSelectingFirstDay(day)) {
				setEnteredTo(day);
			}
		};

		const formatDate = selectedRange => {
			const start = moment(selectedRange.from).format('MM/DD/YYYY');
			const end = selectedRange.to ? moment(selectedRange.to).format('MM/DD/YYYY') : '';

			return `${start} - ${end}`;
		};

		return (
			<Fragment>
				<GlobalStyle />
				<DayPickerInput
					format={null}
					component={props =>(
						<InputContainer ref={containerRef} htmlFor={name} onClick={props.onClick} >
							<Input {...props} {...rest} name={name} id={name} placeholder={placeholder} labelText='Dates' hideLabel={hideLabel} />
						</InputContainer>
					)}
					formatDate={() => formatDate(selectedDays)}
					parseDate={parseDate}
					dayPickerProps={{
						numberOfMonths: monthsToDisplay,
						initialMonth: initialMonth,
						fromMonth:fromMonth,
						toMonth: toMonth,
						selectedDays: selectedDays,
						disabledDays: disabledDays,
						modifiers: modifiers,
						onDayMouseEnter: handleDayMouseEnter,
					}}
					hideOnDayClick={false}
					onDayChange={handleDayClick}
					value={range.selectedDays}
				/>
				<input type='hidden' ref={() => ref} onClick={reset} />
			</Fragment>
		);
	},
);
