import React from 'react';
import styled from 'styled-components';

import { GridWrap } from 'components/GridWrap/GridWrap';
import { Headline } from 'components/Headline/Headline';
import { ComparisonCard } from './ComparisonCard';
import { BackgroundImage } from './BackgroundImage';

import {
	spaceLarge,
	spaceMassive,
	spaceSuperMassive,
	spaceMedium,
	spaceSmall,
} from '../../../design-system/variables/spaces';
import { white } from '../../../design-system/variables/colors';
import { sm, md, xsOnly } from '../../../design-system/media-queries';

const Wrapper = styled.div`
	padding: ${spaceMassive} 0 ${spaceSuperMassive} 0;
	overflow: hidden;
	position: relative;

	${xsOnly`
        padding: ${spaceLarge} 0;
    `}
`;
const CardWrapper = styled.div`
	display: flex;

	${sm`
        flex: 1;
    `}
`;

const Header = styled(props => <Headline {...props} />)`
	margin-bottom: ${spaceMedium};
	color: ${white};
`;

const LeftComparisonCard = styled(props => <ComparisonCard {...props} />)`
	${sm`
        margin-left: auto;
    `}
	${md`
		margin-right: ${spaceSmall};
	`}
`;

const RightComparisonCard = styled(props => <ComparisonCard {...props} />)`
	${sm`
    	margin-right: auto;
	`}

	${md`
		margin-left: ${spaceSmall};
	`}
`;

export const ComparisonSection: React.FC = () => (
	<Wrapper>
		<BackgroundImage />
		<GridWrap width='large'>
			<div className='row center-xs'>
				<div className='col-xs-12 col-sm-10 col-md-7'>
					<Header size='large'>Two ways to travel</Header>
				</div>
			</div>
			<div className='row center-xs'>
				<CardWrapper className='col-xs-12 col-sm-6'>
					<LeftComparisonCard
						title='Inspirato Pass'
						subTitle="The world's first luxury travel subscription"
						// @ts-ignore
						actionLink={{
							url: '/pass/overview/',
							newWindow: false,
							canDisplayAsTextLink: true,
							linkTitle: 'Explore Pass',
						}}
						content={[
							'Endless travel with no nightly rates, taxes, or fees',
							'Choose from more than 100,000 trips, updated daily',
							'Reserve a trip, enjoy your stay, book your next trip the day you check out',
						]}
					/>
				</CardWrapper>
				<CardWrapper className='col-xs-12 col-sm-6'>
					<RightComparisonCard
						title='The Inspirato Club'
						subTitle='The premier luxury destination club'
						// @ts-ignore
						actionLink={{
							url: '/the-club/',
							newWindow: false,
							canDisplayAsTextLink: true,
							linkTitle: 'Explore the Club',
						}}
						content={[
							'Access to the full Inspirato Collection of homes, hotels, and experiences',
							'Choose your dates and accommodations and pay nightly rates',
							'Receive a complimentary seven-night vacation when you join',
						]}
					/>
				</CardWrapper>
			</div>
		</GridWrap>
	</Wrapper>
);
