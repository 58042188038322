import { Button, Headline, Paragraph, TextButton, Title } from 'components';
import { LoaderSpinner } from '../../../../../components/Loader/LoaderSpinner';
import { fadeInAnimation } from 'design-system/style-mixins/animations';
import styled, { createGlobalStyle, css } from 'styled-components';
import { md, sm, xsSmOnly } from 'design-system/media-queries';
import { white, textColorDark, grayMedium, error, black } from 'design-system/variables/colors';
import { spaceHuge, spaceSmall, spaceBase, spaceBig, spaceMedium, spaceMassive } from 'design-system/variables/spaces';
import { transparentDark80 } from 'design-system/style-mixins/shadows-and-scrims';
import { gtAmerica } from 'design-system/variables/fonts';
import React from 'react';
import { RichTextEditor } from '../../../../../components/Form/RichTextEditor/RichTextEditor';
import { gray } from '../../../../../design-system/variables/colors';

export const Overlay = styled.div`
	display: flex;
	justify-content: center;
	padding: ${spaceBig};
	position: absolute;
	background-color: rgba(255, 255, 255, 0.9);
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	${fadeInAnimation}
`;

export const Spinner = styled((props) => <LoaderSpinner {...props} />)`
	height: auto;
	display: inline-flex;
	margin-left: 20px;
	z-index: 99999999;
`;

export const MobileSpacing = styled.div`
	${xsSmOnly`
		&& {
			margin: 0;
			padding: 0;
		}
	`}
`;

export const Wrapper = styled.div`
	height: 100%;
	position: relative;

	${md`
		display: flex;
		min-height: 567px;
		width: 950px;
		flex-direction: row;
	`}
`;

export const Container = styled.div`
	position: relative;
	background-color: ${white};
	display: flex;
	justify-content: center;
	margin: auto;
	width: 100%;
	align-items: center;
	flex-shrink: 1;
	flex-direction: column;
	height: auto;

	&& {
		padding-top: ${spaceSmall};
		padding-left: ${spaceBase};
		padding-right: ${spaceBase};

		${sm`
			padding-top: ${spaceHuge};
			padding-left: initial;
			padding-right: initial;
		`}

		${md`
			padding-top: ${spaceBase};
			padding-bottom: ${spaceSmall};
		`}
	}
`;

export const ValidationMessage = styled.div`
	font-size: 11px;
	color: ${black};
	margin-top: 0;
	line-height: 14px;
	margin-block-end: 1em;
`;

export const FormContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	max-width: 425px;
	width: 100%;
	padding-bottom: ${spaceBase};
`;

export const modalStyles = css`
	display: block;
	height: 100vh;

	${md`
		display: flex;
		background: ${transparentDark80};
		align-items: center;
		justify-content: center;
	`}
`;
export const CancelButton = styled((props) => <TextButton {...props} />)`
	align-self: flex-end;
	margin-bottom: ${spaceBase};
	margin-right: ${spaceMedium};
`;

export const Error = styled((props) => <Paragraph {...props} size='small' />)`
	color: ${error} !important;
	margin-top: 0;
`;

export const Form = styled.form`
	display: flex;
	flex-direction: column;
`;
export const MultiRow = styled.div`
	display: flex;
	flex-direction: row;
`;
export const UnitCell = styled.div`
	padding-left: 5px;
	padding-right: 5px;
`;

export const DateRangeGrid = styled.div`
	display: grid;
	grid-template-columns: 1fr 35% 50px;
`;

export const FirstUnitCell = styled.div`
	padding-left: 5px;
	padding-right: 5px;
	padding-top: 25px;
`;

export const NPOButton = styled((props) => <Button {...props} />)`
	margin-top: ${spaceBase};
`;

export const NPOHeadline = styled((props) => <Headline {...props} />)`
	font-weight: 700 !important;
	${textColorDark}
	padding-left: ${spaceMassive};
	font-family: ${gtAmerica} !important;
	font-size: 24px !important;
`;

export const NPOSectionTitle = styled((props) => <Title {...props} />)`
	font-weight: 700 !important;
	${textColorDark}
	padding-bottom:  ${spaceSmall};
	padding-left: 7px;
	padding-top: ${spaceMedium};
	font-size: 20px !important;
`;

export const NPOSectionOptional = styled((props) => <Title {...props} />)`
	color: ${grayMedium} !important;
	padding-bottom: ${spaceSmall};
	padding-left: 7px;
	padding-top: ${spaceMedium};
	font-size: 20px !important;
`;

export const RequiredText = styled.div`
	color: ${grayMedium};
`;

export const SetBalloonToTop = createGlobalStyle`
	.ck.ck-balloon-panel {
		z-index: 99999 !important;
	}
`;

export const NpoRichTextEditor = styled((props) => <RichTextEditor hasError {...props} />)`
	border: 1px solid ${props => (props.hasError ? error : gray)};
`;
