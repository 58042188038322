import * as React from 'react';
import styled from 'styled-components';
import { Pill } from './Pill';
import { spaceSmall } from '../../design-system/variables/spaces';

interface Member {
	id: number;
	label: string;
	isSelected?: boolean;
}

interface Props {
	members: Member[];
	onPillClick: Function;
	selected: number;
}

const PillItem = styled(props => <Pill {...props} />)`
	margin-right: ${spaceSmall};

	&:last-child {
		margin-right: 0;
	}
`;

export const PillGroup: React.FunctionComponent<Props> = ({ members, selected, onPillClick, ...rest }) => {
	const handleClick = pillVal => {
		if (pillVal !== selected) {
			onPillClick(pillVal);
		} else if (pillVal === selected) {
			onPillClick();
		}
	};

	const isSelected = pillVal => pillVal === selected;

	return (
		<div {...rest}>
			{members.map(member => (
				<PillItem
					key={member.id}
					size='large'
					onClick={() => handleClick(member.id)}
					isSelected={isSelected(member.id)}
				>
					{member.label}
				</PillItem>
			))}
		</div>
	);
};
