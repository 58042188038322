import { useEffect } from 'react';
import root from 'window-or-global';

export const usePageReload = (reloadInMin: number = 1) => {
	let internalReloadTime = reloadInMin;
	if (reloadInMin < 1 || !reloadInMin || !Number(reloadInMin)) {
		internalReloadTime = 1;
	}

	internalReloadTime = internalReloadTime * 60 * 1000;
	return useEffect(() => {
		const t = setTimeout(() => {
			root.location.reload(true);
		}, internalReloadTime);
		return () => clearTimeout(t);
	}, []);
};
