import styled, { css } from 'styled-components';
import { Title } from 'components';
import { spaceBase, spaceLarge } from 'design-system/variables/spaces';
import { white } from 'design-system/variables/colors';
import { transparentEaseGradient } from 'design-system/style-mixins/shadows-and-scrims';
import { richText } from 'design-system/utils';
import { sm } from 'design-system/media-queries';
import { shadowHover } from 'design-system/style-mixins/animations';
import { StyledCardProps } from './TravelCard.types';

export const BodyWrapper = styled.div`
	> * + * {
		margin-top: ${spaceBase};
	}
`;

export const BodyCopy = styled.div`
	&:not(:empty) {
		${richText}
		text-align: center;
		color: ${white};
		margin-left: auto;
		margin-right: auto;
		padding: 0 ${spaceBase};

		p {
			color: ${white};
			margin: 0;
		}
	}
`;

export const Icon = styled.img`
	height: 50px;
`;

export const HeadingWrapper = styled.header`
	position: relative;
`;

export const Heading = styled(props => <Title {...props} />)`
	color: ${white};
	margin: 0;
	font-size: 20px;
	line-height: 23px;
	letter-spacing: normal;
`;

export const Content = styled.div`
	position: relative;
	padding: ${spaceLarge} ${spaceBase};
	width: 100%;
	transition: transform var(--duration) var(--ease);
	z-index: 1;

	${sm`
    transform: translateY(calc(100% - ${spaceLarge} * 2));
  `}

	> * + * {
		margin-top: ${spaceBase};
	}
`;

export const StyledCard = styled.div<StyledCardProps>`
	--duration: 700ms;
	--ease: cubic-bezier(0.19, 1, 0.22, 1);

	${shadowHover}
	display: flex;
	align-items: flex-end;
	justify-content: center;
	margin-bottom: ${spaceBase};
	position: relative;
	width: 100%;
	overflow: hidden;
	text-align: center;
	transition: transform var(--duration) var(--ease);

	${sm`
	  height: 345px;
  `}

	&:before,
	&:after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		pointer-events: none;
	}

	&:after {
		${transparentEaseGradient}
		height: 200%;
		transition: transform calc(var(--duration) * 2) var(--ease);
	}

	${props =>
		props.backgroundImage.hasImage &&
		css`
			background-image: url(${props.backgroundImage.fullPath}&width=530);
			background-color: transparent;
			background-position: 50%;
			background-repeat: no-repeat;
			background-size: cover;
		`}

	@media (hover: hover) {
		${sm`
      ${BodyWrapper} {
        opacity: 0;
        transform: translateY(${spaceBase});
        transition: opacity var(--duration) var(--ease), transform var(--duration) var(--ease);
      }

      ${HeadingWrapper} {
        transform: translateY(-100%);
	      transition: transform calc(var(--duration) / 2) var(--ease);
      }

      &:hover,
      &:focus-within {
        &:after {
          transform: translateY(-50%);
        }

        ${Content} {
          transform: translateY(0);
        }

        ${Icon} {
          position: relative;
        }

        ${HeadingWrapper} {
          transform: translateY(0);
        }

        ${BodyWrapper} {
          opacity: 1;
          transform: translateY(0);
          transition-delay: calc(var(--duration) / 5);
        }
      }
    `}
	}
`;
