import React, { MouseEvent } from 'react';
import styled from 'styled-components';

import { spaceSmall } from 'design-system/variables/spaces';
import { IconButton } from '../../Icon';

import { FullscreenSwitchProps } from './FullscreenSwitchProps';

export interface EventHandlerProps {
	onClick: (e: React.MouseEvent<HTMLElement>) => void;
}

const StyledButton = styled(props => <IconButton {...props} />)<EventHandlerProps>`
	position: absolute;
	right: ${spaceSmall};
	top: ${spaceSmall};
	z-index: 10;
`;

export const FullscreenSwitch: React.FunctionComponent<FullscreenSwitchProps> = ({
	isFullscreen,
	toggleFullscreen,
	darkTheme,
}) => (
	<StyledButton
		iconSize='lg'
		buttonType={darkTheme ? 'containerlessSecondary' : 'containerlessPrimary'}
		iconName={!isFullscreen ? 'enter' : 'exit'}
		onClick={toggleFullscreen}
	/>
);
