import styled from 'styled-components';
import { Icon } from 'components/Icon';
import { spaceSmall } from 'design-system/variables/spaces';
import { grayMedium } from 'design-system/variables/colors';
import { InputStyles } from '../Form.styles';

export const SelectWrapper = styled.div``;

export const SelectFieldContainer = styled.div`
	position: relative;
	display: flex;
`;
export const SelectIcon = styled(Icon)`
	fill: ${grayMedium};
	pointer-events: none;
	position: absolute;
	right: ${spaceSmall};
	top: ${spaceSmall};
	transform: rotate(90deg);
	z-index: 1;
`;

export const SelectField = styled.select<{ hasError, isGuestModal }>`
	${InputStyles}
	appearance: none;
	&:-ms-expand {
		display: none;
	}
	option {
		font-weight: normal;
	}
`;
