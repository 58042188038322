import root from 'window-or-global';
import { getHighestNumber } from './Helpers';
import { brokenImg16x9 } from '../design-system/utils';

export const landscapeImageWidths = [375, 768, 1024].map(width => width * root.devicePixelRatio);

// these assume an aspect ratio of 4:5. these were derived based off of the landscape widths
// which were used to calculate as follows: width / 4 * 5 = height
export const portraitImageHeights = [468.75, 960, 1280].map(height => height * root.devicePixelRatio);

export function getNormalizedImageDimension(ImageDimensions: number[], length: number): number {
	let closest: number = getHighestNumber(ImageDimensions);

	// Check if it's higher than your number, but lower than your closest value
	ImageDimensions.forEach((dimension: number) => {
		if (dimension >= length && dimension < closest) {
			closest = dimension;
		}
	});

	return closest;
}

// Returns object with styles that will be used later as `style` attr in JSX.
export function getNormalizedImageStyle(imageUrl: string, containerWidth: number, containerHeight: number): object {
	const imgStrDimensions =
		containerWidth >= containerHeight
			? `width=${getNormalizedImageDimension(landscapeImageWidths, containerWidth)}`
			: `height=${getNormalizedImageDimension(portraitImageHeights, containerHeight)}`;

	const backgroundImage = `url("${imageUrl}&align=center&${imgStrDimensions}"),url(${brokenImg16x9})`;

	return { backgroundImage };
}
