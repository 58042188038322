import styled from 'styled-components';
import { textBig, bold } from 'design-system/style-mixins/text';
import { black } from 'design-system/variables/colors';
import { spaceBig } from 'design-system/variables/spaces';

export const PostMessageStyled = styled.div`
	text-align: center;
	${bold};
	${textBig};
	padding: 0 ${spaceBig} 0 ${spaceBig};
	& > p {
		color: ${black};
	}
`;
