import * as React from 'react';
import styled from 'styled-components';

import { sm, xsOnly } from '../../design-system/media-queries';
import { grayMedium, grayDark } from '../../design-system/variables/colors';
import { spaceBase, spaceSmall, spaceLarge } from '../../design-system/variables/spaces';

import { RouteProps } from './RouteProps';
import { BreadcrumbProps } from './BreadcrumbProps';

const StyledItem = styled.li`
	line-height: 21px;

	span {
		${xsOnly`
				max-width: 190px;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			`}
	}
`;

const Container = styled.ul`
	${sm`
		padding: ${spaceBase} ${spaceLarge};
	`}
	padding-left: ${spaceBase};
	display: flex;
	flex-basis: 100%;
	align-items: center;
	height: 38px;
	font-size: 13px;

	${StyledItem}:first-of-type {
		${sm`
			&:before {
				margin: 0 ${spaceBase};
			}
		`}
		:before {
			content: '/';
			margin: 0 ${spaceSmall};
		}
	}
	${StyledItem}:not(:last-child) {
		color: ${grayMedium};
		span {
			cursor: pointer;
			:hover {
				color: ${grayDark};
			}
		}
		${sm`
			&:after {
				margin: 0 ${spaceBase};
			}
		`}
		:after {
			content: '/';
			margin: 0 ${spaceSmall};
		}
	}
`;

const Link: React.FC<RouteProps> = ({ name, uri }) => (
	<StyledItem key={name} title={uri}>
		<span>{name}</span>
	</StyledItem>
);

const HomeLink = styled.a`
	background-image: url('./images/logo-32x32.png');
	background-size: cover;
	display: inline-block;
	height: 22px;
	width: 22px;
`;

export const Breadcrumbs: React.FC<BreadcrumbProps> = ({ routes = null, handleClick = () => {} }) => (
	<Container>
		{routes && (
			<React.Fragment>
				<HomeLink href='/' />
				{routes.map(route => (
					<Link {...route} key={route.name} />
				))}
			</React.Fragment>
		)}
	</Container>
);
