import React, { useLayoutEffect, useEffect } from 'react';
import { useInView } from 'components/hooks/useInView';
import { useWorkerImage } from 'components/hooks/useWorkerImage';
import { LazyImageProps } from './LazyImage.types';

export const LazyImage: React.FC<LazyImageProps> = ({
	root,
	src,
	index,
	shouldRequest,
	onInView,
	onLoad,
	imageComponent = <img />,
	useWebWorker = false,
}) => {
	if (!root) {
		return React.cloneElement(imageComponent, { src: '' });
	}

	const [imageRef, isInView] = useInView({ threshold: [0.05, 0.95], root });
	const [imageUrl, requestImageSrc] = useWorkerImage({ disabled: !useWebWorker });

	useLayoutEffect(() => {
		if (isInView) {
			onInView(index);
		}
	}, [isInView]);

	useEffect(() => {
		if (shouldRequest) {
			requestImageSrc(src);
		}
	}, [shouldRequest]);

	return React.cloneElement(imageComponent, {
		ref: imageRef,
		src: imageUrl,
		key: `lazy-image-${index}-${src}`,
		onLoad,
	});
};
