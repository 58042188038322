import styled, { css } from 'styled-components';
import { loaderSkeletonGradient } from '../../design-system/variables/colors';

interface LoaderSkeletonConfig {
	fillContainer?: boolean;
	height?: string;
	width?: string;
}

export const LoaderSkeleton = styled.div<LoaderSkeletonConfig>`
	display: inline-block;
	position: relative;
	height: ${props => props.height || 'auto'};
	width: ${props => props.width || '100%'};

	&::before {
		content: '\\a0';
	}

	&:after {
		animation: gradient 5s linear infinite;
		background: linear-gradient(to left, ${loaderSkeletonGradient});
		background-size: 400% 400%;
		content: '';
		position: absolute;
		top: 2px;
		left: 2px;
		width: calc(100% - 2px);
		height: calc(100% - 2px);
	}

	${props =>
		props.fillContainer &&
		css`
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;

			&:before {
				display: none;
			}

			&:after {
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
		`}

	@keyframes gradient {
		to {
			background-position: -400% 0%;
		}
	}
`;

export const renderLoaderOrContent = (
	loading: boolean,
	content: string | JSX.Element,
	config: LoaderSkeletonConfig,
) => {
	if (loading) {
		return <LoaderSkeleton width={config.width} height={config.height} fillContainer={config.fillContainer} />;
	}
	return content;
};
