import React, { FC } from 'react';
import { UserValues } from 'pages/Chat/Chat.Interfaces';
import { Input } from 'components/';
import { FormikProps } from 'formik';
import { BoldLabel } from './RegUserForm.styles';
import { Form } from '../OfflineForm.styles';
import { SubmitButton } from '../../modal/ChatModal.styles';

export const RegUserForm: FC<FormikProps<UserValues>> = ({
	errors,
	values,
	touched,
	handleChange,
	handleBlur,
	handleSubmit,
	isSubmitting,
}) => {
	return (
		<Form onSubmit={handleSubmit}>
			<Input
				labelText={<BoldLabel>{`${values.invitationTitle}.`}</BoldLabel>}
				placeholder='How can we help you?*'
				name='description'
				onChange={handleChange}
				onBlur={handleBlur}
			/>
			<SubmitButton buttonType='primary' type='submit' disabled={isSubmitting}>
				Continue
			</SubmitButton>
		</Form>
	);
};
