import { ImageViewModelProps } from 'interfaces/ImageViewModelProps';
import { ComponentProps } from '../../interfaces/ComponentProps';
import { CallToActionProps } from '../ActionLink/CallToActionProps';

export enum SectionComponentBottomPadding {
	Default = 'Default',
	ExtraPadding = 'Extra Padding',
	Collapsed = 'Collapsed',
}

export enum SectionComponentTopPadding {
	Default = 'Default',
	ExtraPadding = 'Extra Padding',
	Collapsed = 'Collapsed',
}

export const SectionComponentsPerRow = {
	fullScreen: 'fullscreen',
	mobile1Tablet1Desktop3: 'mobile: 1, tablet: 1, desktop: 3',
	mobile1Tablet2Desktop2: 'mobile: 1, tablet: 2, desktop: 2',
	mobile1Tablet2Desktop4: 'mobile: 1, tablet: 2, desktop: 4',
	mobile1Tablet3Desktop3: 'mobile: 1, tablet: 3, desktop: 3',
};

export enum VerticalAlignment {
	top = 'top',
	middle = 'middle',
	bottom = 'bottom',
}

export interface SectionComponentProps extends ComponentProps {
	animationSettings: AnimationSettingsProps;
	backgroundColor: string;
	backgroundImage: ImageViewModelProps;
	body: string;
	bottomPadding: SectionComponentBottomPadding;
	callToAction: CallToActionProps;
	componentsPerRow: string;
	headline: string;
	modifier: string;
	nestedComponents: ComponentProps[];
	rowReverse: boolean;
	subhead: string;
	textColor: string;
	topPadding: SectionComponentTopPadding;
	verticalAlignment: VerticalAlignment;
}

export interface NestedComponentsProps {
	callToAction?: CallToActionProps;
	columnCssClasses: string;
	componentsPerRow: string;
	nestedComponents: ComponentProps[];
	rowReverse: boolean;
	verticalAlignment: VerticalAlignment;
}

export interface AnimationSettingsProps {
	animation: string;
	delay: number;
	once: string;
}
