import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { getErrorMessages } from '../../../../shared/utils';
import { UpdateTripProposalRequest } from '../../../../interfaces';
import { updateTripProposal } from '../../../../services/tripRecommendationsApiService';
import { MESSAGE_UPDATED, PROPOSAL_MESSAGE } from '../constants';
import { State } from '../context';
import { setFieldError } from './errors';
import { updateDirty } from './proposal';

export const updateMessage = (message: string, modifiedBy: string) => (
	dispatch: ThunkDispatch<State, undefined, Action>,
	getState: () => State,
) => {
	const { selectedRecipient, metadata, proposalName } = getState();
	const request: UpdateTripProposalRequest = {
		name: proposalName,
		message,
		recipientId: selectedRecipient ? selectedRecipient.accountUserID : null,
		ownerId: metadata.ownerId,
		pvaTeamId: metadata.pvaTeamId,
		modifiedBy,
	};

	dispatch(updateDirty());
	updateTripProposal(metadata.id, request)
		.then(() => {
			dispatch({ type: MESSAGE_UPDATED, payload: { message } });
		})
		.catch(error => {
			const errorMessage = getErrorMessages(error, 'Message');
			dispatch(setFieldError(PROPOSAL_MESSAGE, errorMessage));
		});
};
