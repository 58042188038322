import React, { FC, Fragment } from 'react';
import { Title, Notification, Link, Button, ContactWidget, PvaHours } from 'components';
import { ReactConfigurationViewModel } from 'interfaces/ResponsivePageViewModel';
import { ErrorContainer, NotificationContainer, Spacer, PvaSpacer } from './PassBookingError.styles';
import { ErrorModel, PVATeamModel } from './PassBookingError.types';

export const PassBookingError: FC<{
	errorResult: ErrorModel[];
	pvaTeam: PVATeamModel;
	configuration: ReactConfigurationViewModel;
	isImpersonated: boolean;
	returnUrl: string;
}> = ({ errorResult, pvaTeam, configuration, isImpersonated, returnUrl }) => {
	return (
		<Fragment>
			{errorResult &&
				errorResult.length &&
				errorResult.map((x) => (
					<ErrorContainer>
						{isImpersonated && x.internalMessage && x.internalMessage.length && (
							<NotificationContainer>
								<Notification isOpen type='error' centerContent canClose={false}>
									{x.internalMessage}
								</Notification>
							</NotificationContainer>
						)}

						{x.showAlternative && !isImpersonated && (
							<NotificationContainer>
								<Notification isOpen type='error' centerContent canClose={false}>
									{x.externalMessage}
								</Notification>
							</NotificationContainer>
						)}

						{!x.showAlternative && (<Spacer>
							<Title size='large'>{x.headerMessage}</Title>
						</Spacer>)}

						{!x.showAlternative && (<Spacer>
							<Title size='small'>{x.externalMessage}</Title>
						</Spacer>)}

						<Spacer>
							{x.hasCta ? (!x.cta ? (
								<Link color='black' href={returnUrl}>
									<Button buttonType='primary' size='large' type='button'>
										Back To Inspirato Pass
									</Button>
								</Link>
							) : (
								<Link color='black' href={x.cta.action}>
									<Button buttonType='primary' size='large' type='button'>
										{x.cta.label}
									</Button>
								</Link>
							)) : ''}
						</Spacer>

						{pvaTeam && (
							<PvaSpacer>
								<ContactWidget
									type='member'
									imageServerUrl={configuration.imageServer}
									info={{
										name: pvaTeam.teamName,
										subtext: pvaTeam.title,
										photoSrc: pvaTeam.photoUrl,
										...pvaTeam,
									}}
									displayOpts={{
										card: true,
										details: <PvaHours />,
										imgSize: 'lg',
										headline: true,
										row: true,
									}}
								/>
							</PvaSpacer>
						)}
					</ErrorContainer>
				))}
		</Fragment>
	);
};
