/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-restricted-globals */
import React, { useState, ReactNode } from 'react';

import { AccordionContext } from './AccordionContext';

interface AccordionGroupProps {
	children: ReactNode;
	scrollableContainer?: string;
}

export const AccordionGroup: React.FC<AccordionGroupProps> = ({ children }) => {
	const [expandedId, setExpandedId] = useState(null);

	// @ts-ignore
	return <AccordionContext.Provider value={{ expandedId, setExpandedId }} >{children}</AccordionContext.Provider>;
};
