import styled, { css } from 'styled-components';
import { lightWeight, textLarge, fontSans, textBase, textSmall } from '../../design-system/style-mixins/text';
import { textColorMedium } from '../../design-system/variables/colors';

const large = css`
	${textLarge}
	line-height: 27px;
	${lightWeight}
`;

const medium = css`
	${textBase}
	line-height: 18px;
`;

const small = css`
	${textSmall};
	line-height: 15px;
`;

const sizeStyles = {
	large,
	medium,
	small,
};

/**
 * Labels:
 * Used for section labels, forms, stats, etc.
 */
export const Label = styled.span<{ size: 'small' | 'medium' | 'large' }>`
	${fontSans}
	${textColorMedium}
	font-weight: normal;
	letter-spacing: 0;
	${props => sizeStyles[props.size] || sizeStyles.medium}
`;
