import React, { FC, useState, useEffect, Fragment, useContext } from 'react';
import styled from 'styled-components';

import { spaceTiny } from 'design-system/variables/spaces';
import { sm } from 'design-system/media-queries';

import { Select } from 'components/Form/Select';
import { EnhancedInput } from '../../shared/Input';
import { MemberFirstName, MemberLastName, ReferralSourcesTypes, Constants } from './enums';
import { FormContext } from '../../shared/FormContext';

const FirstNameWrapper = styled.div`
	&& {
		${sm`
			padding-right: ${spaceTiny}
		`}
	}
`;

const LastNameWrapper = styled.div`
	&& {
		${sm`
			padding-left: ${spaceTiny}
		`}
	}
`;

export const ReferralSources: FC<{}> = () => {
	const { validationRules, setFieldValue } = useContext(FormContext);
	const [source, selectSource] = useState(ReferralSourcesTypes[0]);
	const [memberFirstName, setMemberFirstName] = useState('');
	const [memberLastName, setMemberLastName] = useState('');
	const handleClick = selected => {
		selectSource(selected);
	};

	useEffect(() => {
		setFieldValue({
			[Constants.name]: source.key,
			[MemberFirstName.name]: memberFirstName,
			[MemberLastName.name]: memberLastName,
		});
	}, [source.key, memberFirstName, memberLastName]);

	return (
		<Fragment>
			<div className='row' data-node='select-referral-source'>
				<div className='col-xs-12'>
					<Select
						hideLabel
						labelText='Referred By'
						onChange={handleClick}
						options={ReferralSourcesTypes}
						value={source}
					/>
				</div>
			</div>
			{source.key === ReferralSourcesTypes[1].key && (
				<div className='row'>
					<FirstNameWrapper className='col-xs-12 col-sm-6'>
						<EnhancedInput
							type='text'
							id={MemberFirstName.id}
							name={MemberFirstName.name}
							label={MemberFirstName.label}
							labelText="Member's First Name"
							value={memberFirstName}
							validationRules={validationRules.REFERRAL_FIRST_NAME}
							setValue={setMemberFirstName}
						/>
					</FirstNameWrapper>
					<LastNameWrapper className='col-xs-12 col-sm-6'>
						<EnhancedInput
							type='text'
							id={MemberLastName.id}
							name={MemberLastName.name}
							label={MemberLastName.label}
							labelText="Member's First Name"
							value={memberLastName}
							validationRules={validationRules.REFERRAL_LAST_NAME}
							setValue={setMemberLastName}
						/>
					</LastNameWrapper>
				</div>
			)}
		</Fragment>
	);
};
