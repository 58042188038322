import styled, { css } from 'styled-components';
import { fontSerif, italic, textBig, textMedium } from '../../design-system/style-mixins/text';
import { textColorDark } from '../../design-system/variables/colors';
import { sm } from '../../design-system/media-queries';

const large = css`
	${sm`
        ${textBig}
        line-height: 32px;
    `}
`;

const sizeStyles = {
	large,
};

/**
 * Blockquotes:
 * Base is standard blockquote format.
 * Large is used for hero sections or large callouts.
 */
export const Blockquote = styled.div<{ size?: 'large' }>`
	${fontSerif}
	${textMedium}
    ${textColorDark}
	${italic}
	line-height: 27px;
	text-align: center;
	${props => sizeStyles[props.size]}
`;
