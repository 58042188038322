import { CallToActionProps } from 'components/ActionLink/CallToActionProps';
import React from 'react';
import styled, { css } from 'styled-components';
import { Headline } from 'components/Headline/Headline';
import { ActionLink } from 'components/ActionLink/ActionLink';
import { useAnimateOnView } from 'components/hooks/useAnimateOnView';
import { Blockquote } from 'components/Blockquote/Blockquote';
import { md, sm } from '../../../design-system/media-queries';
import { transparentLight80 } from '../../../design-system/style-mixins/shadows-and-scrims';
import { richText } from '../../../design-system/utils';
import { textColorMedium } from '../../../design-system/variables/colors';
import { spaceBase, spaceBig, spaceLarge, spaceMedium } from '../../../design-system/variables/spaces';

const Card = styled.div<{ isAnimated: any; ref?: any }>`
	background: ${transparentLight80};
	margin-left: auto;
	margin-right: auto;
	margin-top: ${spaceBase};
	max-width: 408px;
	padding-top: ${spaceLarge};
	position: relative;
	width: 100%;
	transform: translate3d(0, 100px, 0);
	opacity: 0;
    transition-property: opacity, transform;

	${sm`
        margin-top: ${spaceLarge};
    `}

	${md`
		margin-top: ${spaceBig};
        padding-top: ${spaceBig};
	`}
	${props =>
		props.isAnimated &&
		css`
			transform: translate3d(0, 0px, 0);
			transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
			transition-duration: 600ms;
			transition-delay: 200ms;
			opacity: 1;
		`}
`;

const Header = styled(props => <Headline {...props} />)`
	margin-bottom: ${spaceBase};
`;

const SubHeader = styled(props => <Blockquote {...props} />)`
	max-width: 195px;
	${textColorMedium}
	margin: 0 auto ${spaceBase};

	${md`
		margin-bottom: ${spaceMedium};
	`}
`;

const Link = styled(props => <ActionLink {...props} />)`
	bottom: ${spaceLarge};
	left: ${spaceMedium};
	position: absolute;
	right: ${spaceMedium};
	word-break: keep-all;
	white-space: pre;

	${sm`
        bottom: ${spaceLarge};
        left: ${spaceLarge};
        right: ${spaceLarge};
    `}

	${md`
		bottom: ${spaceBig};
        left: ${spaceBig};
		right: ${spaceBig};
	`}
`;

const ContentContainer = styled.div`
	padding: 0 ${spaceMedium} 5.5rem;

	${md`
		padding: 0 ${spaceBig} 6.5rem;
	`}
`;

const Content = styled.div`
	${richText}
`;

interface ComparisonCardProps {
	title: string;
	subTitle: string;
	actionLink: CallToActionProps;
	content: string[];
}

export const ComparisonCard: React.FC<ComparisonCardProps> = ({ title, subTitle, actionLink, content, ...rest }) => {
	const [ref, isAnimated] = useAnimateOnView();

	return (
		<Card ref={ref} {...rest} isAnimated={isAnimated}>
			<Header size='medium'>{title}</Header>
			<SubHeader>{subTitle}</SubHeader>
			<ContentContainer>
				<div className='row'>
					<div className='col-xs-12'>
						<Content>
							<ul>
								{content.map(item => (
									<li key={item}>{item}</li>
								))}
							</ul>
						</Content>
					</div>
				</div>
			</ContentContainer>
			<Link secondary {...actionLink} size='base' />
		</Card>
	);
};
