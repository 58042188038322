import React, { Children, useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { FullScreenButton } from '../CarouselControls/FullScreenButton';
import {
	StyledEmblaCarousel,
	ImageCarouselContainer,
	ImageCarouselPrevButton,
	ImageCarouselNextButton,
	ImageCarouselDots,
} from './ImageCarousel.styles';
import { ImageCarouselProps } from './ImageCarousel.types';
import { ImageCarouselItem } from './ImageCarouselItem';

export const ImageCarousel: React.FC<ImageCarouselProps> = ({
	theme,
	children,
	captions,
	setCarouselRef,
	additionalControls,
	showArrowsOnHover = true,
	fullScreenImages,
}) => {
	const emblaRef = useRef(null);
	const [embla, setEmbla] = useState(null);
	const [selectedIndex, setSelectedIndex] = useState(0);
	const [isFullScreen, setIsFullScreen] = useState(false);
	const scrollPrev = useCallback(() => embla.scrollPrev(), [embla]);
	const scrollNext = useCallback(() => embla.scrollNext(), [embla]);
	const childrenToRender = useMemo(() => (fullScreenImages && isFullScreen ? fullScreenImages : children), [
		isFullScreen,
		embla,
		children,
		fullScreenImages,
	]);
	const lastChildLength = useRef(Children.count(childrenToRender));
	const opts = { speed: 20, loop: true };

	useEffect(() => {
		if (embla) {
			embla.on('select', () => {
				setSelectedIndex(embla.selectedScrollSnap());
			});

			setCarouselRef && setCarouselRef(emblaRef.current.container.current);
		}
	}, [embla]);

	useEffect(() => {
		if (embla) {
			const currentCount = Children.count(childrenToRender);

			if (lastChildLength.current === currentCount && currentCount !== 1) {
				return;
			}

			lastChildLength.current = Children.count(childrenToRender);
			embla.changeOptions({ ...opts, draggable: currentCount > 1 });
		}
	}, [children, embla]);

	const handleNavClick = (i: number) => {
		embla.scrollTo(i);
	};

	return (
		<StyledEmblaCarousel ref={emblaRef} emblaRef={setEmbla} options={opts} theme={theme}>
			<ImageCarouselContainer>
				{Children.map(childrenToRender, (child, i) => {
					return (
						<ImageCarouselItem
							key={`carousel-item-${i}-${child.props.src}`}
							item={child}
							caption={captions && captions.length && captions[i]}
							index={i}
							selectedIndex={selectedIndex}
							theme={theme}
						/>
					);
				})}
			</ImageCarouselContainer>

			{Children.count(childrenToRender) > 1 && (
				<>
					<ImageCarouselPrevButton
						buttonType='primary'
						buttonShape='square'
						iconName='arrow'
						iconSize='lg'
						onClick={scrollPrev}
						theme={theme}
						showHideArrows={showArrowsOnHover}
					/>
					<ImageCarouselNextButton
						buttonType='primary'
						buttonShape='square'
						iconName='arrow'
						iconSize='lg'
						onClick={scrollNext}
						theme={theme}
						showHideArrows={showArrowsOnHover}
					/>
					<ImageCarouselDots
						count={Children.count(children)}
						onClick={handleNavClick}
						selectedIndex={selectedIndex}
						theme={theme}
					/>
					{fullScreenImages && (
						<FullScreenButton
							containerRef={embla && emblaRef.current.container}
							theme={theme}
							onToggle={isFullscreen => setIsFullScreen(isFullscreen)}
						/>
					)}
					{additionalControls && additionalControls(embla)}
				</>
			)}
		</StyledEmblaCarousel>
	);
};
