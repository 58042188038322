import React from 'react';
import styled from 'styled-components';

import { fontSans, textTiny, bold } from '../../design-system/style-mixins/text';
import { black, white } from '../../design-system/variables/colors';
import { spaceTiny, spaceSmall } from '../../design-system/variables/spaces';

export const Wrapper = styled.span`
    ${fontSans}
    ${textTiny}
    ${bold}

	background-color: ${black};
	color: ${white};
    height: 100%;
    line-height: 16px;
    margin-left: ${spaceSmall};
	padding: ${spaceTiny} ${spaceSmall};
	text-transform: uppercase;
`;

export const Banner = () => <Wrapper>New</Wrapper>;
