import { useState, useEffect } from 'react';
import root from 'window-or-global';

export const useTouchScreenDevice = () => {
	const [isTouchScreenDevice, setIsTouchScreenDevice] = useState();
	const checkIfTouchDevice = () => Boolean('ontouchstart' in root || root.navigator.maxTouchPoints);

	const handleResize = () => {
		setIsTouchScreenDevice(checkIfTouchDevice);
	};

	useEffect(() => {
		handleResize();
		root.addEventListener('resize', handleResize);

		return () => root.removeEventListener('resize', handleResize);
	}, []);

	return [isTouchScreenDevice, checkIfTouchDevice];
};
