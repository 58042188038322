import React, { memo } from 'react';
import styled, { css } from 'styled-components';

import { NavigationLinkProps } from './NavigationLinkProps';

import { textTiny } from '../../design-system/style-mixins/text';
import { dividerGrayLight, tapTransparent } from '../../design-system/variables/colors';

import { Accordion } from '../Accordion';
import { AllCaps } from '../AllCaps';
import { Link } from '../Link';
import { SubNavigation } from './SubNavigation';
import { allCapsStyle } from '../AllCaps/AllCaps';

const titleStyles = css`
	${allCapsStyle}
	${textTiny}

	display: flex;
	align-items: center;
	min-height: 24px;
`;

const containerStyles = css`
	padding: 0;
`;

const MenuItem = styled.li`
	padding: 27px 0;
	border-bottom: ${dividerGrayLight};
	cursor: pointer;
	${tapTransparent}

	&:last-child {
		border-bottom: none;
	}

	:hover * {
		text-decoration: none;
	}
`;

export const MobileNavigationTab: React.FC<NavigationLinkProps> = memo(({ linkTitle, url, childNavigation }) => {
	return childNavigation ? (
		<MenuItem>
			<Accordion
				accordionId={linkTitle}
				title={linkTitle}
				titleStyles={titleStyles}
				containerStyles={containerStyles}
			>
				<SubNavigation {...childNavigation} />
			</Accordion>
		</MenuItem>
	) : (
		<MenuItem>
			<Link color='black' href={url}>
				<AllCaps size='small'>{linkTitle}</AllCaps>
			</Link>
		</MenuItem>
	);
});
