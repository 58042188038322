import { SortFilters, SortDirection } from '../enums';

export const SortOptions = [
	{
		key: SortFilters.CheckInDate,
		value: 'Check-in',
		direction: { low: 'Near', high: 'Far', default: SortDirection.Ascending },
	},
	{
		key: SortFilters.Price,
		value: 'Value',
		direction: { low: 'Low', high: 'High', default: SortDirection.Descending },
	},
];

const InspiratoOnlySort = {
	key: SortFilters.InspiratoOnly,
	value: 'Inspirato Only',
	direction: null,
};

export const InspiratoOnlySortOptions = [InspiratoOnlySort].concat(SortOptions);
