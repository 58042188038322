import styled from 'styled-components';
import { spaceSmall } from 'design-system/variables/spaces';
import { white, black } from 'design-system/variables/colors';

interface CarouselDotsContainerProps {
	dotsToShow: number;
	theme: string;
}

export const CarouselDotsContainer = styled.div<CarouselDotsContainerProps>`
	--dotSize: 10px;
	--dotPadding: ${spaceSmall};
	--itemSize: calc(var(--dotSize) + var(--dotPadding));
	--containerSize: ${props => `calc(var(--itemSize) * ${props.dotsToShow + 1})`};
	--themeColor: ${props => (props.theme === 'dark' ? `${black}` : `${white}`)};

	overflow: hidden;
	width: var(--containerSize);
	z-index: 1;
	mask-image: linear-gradient(
		to right,
		rgba(255, 255, 255, 0) calc(var(--dotSize) / 3),
		rgba(255, 255, 255, 1) var(--dotSize),
		rgba(255, 255, 255, 1) calc(var(--containerSize) - var(--dotSize)),
		rgba(255, 255, 255, 0) calc(var(--containerSize) - var(--dotSize) / 3)
	);
`;

export const CarouselDotButton = styled.button`
	appearance: none;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0;
	width: var(--itemSize);
	height: var(--itemSize);
	background-color: transparent;
	border: none;

	&:after {
		content: '';
		width: var(--dotSize);
		height: var(--dotSize);
		border-radius: 100%;
		border: 2px solid var(--themeColor);
		background-color: transparent;
		transform-origin: 50% 100%;
		transform: scale(0.64);
		transition: transform 200ms ease-out, background-color 200ms ease-out;
	}
`;

export const CarouselDotListItem = styled.li`
	position: relative;

	&.neighbor ${CarouselDotButton}:after {
		transform: scale(0.82);
	}

	&.selected ${CarouselDotButton}:after {
		background-color: var(--themeColor);
		transform: scale(1.001);
	}

	&:hover ${CarouselDotButton}:after {
		background-color: var(--themeColor);
	}
`;

export const CarouselDotsList = styled.ol.attrs<{ pull: number }>(props => props.pull && {
	style: { transform: `translateX(calc(var(--itemSize) * ${props.pull}))` }
})`
	display: flex;
	align-items: flex-end;
	margin: 0 0 0 calc(var(--itemSize) / 2);
	padding: 0;
	list-style: none;
	transition: transform 300ms ease-out;

	&.detect-loop {
		transition: transform 500ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
	}
`;
