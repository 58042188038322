import { locales } from 'services/i18n/i18n';

export const Country = {
	Afghanistan: { code: 1, name: 'Afghanistan' },
	Akrotiri: { code: 2, name: 'Akrotiri' },
	Albania: { code: 3, name: 'Albania' },
	Algeria: { code: 4, name: 'Algeria' },
	AmericanSamoa: { code: 5, name: 'American Samoa' },
	Andorra: { code: 6, name: 'Andorra' },
	Angola: { code: 7, name: 'Angola' },
	Anguilla: { code: 8, name: 'Anguilla' },
	Antarctica: { code: 9, name: 'Antarctica' },
	AntiguaAndBarbuda: { code: 10, name: 'Antigua and Barbuda' },
	Argentina: { code: 11, name: 'Argentina' },
	Armenia: { code: 12, name: 'Armenia' },
	Aruba: { code: 13, name: 'Aruba' },
	AshmoreAndCartierIslands: { code: 14, name: 'Ashmore and Cartier Islands' },
	Australia: { code: 15, name: 'Australia' },
	Austria: { code: 16, name: 'Austria' },
	Azerbaijan: { code: 17, name: 'Azerbaijan' },
	Bahamas: { code: 18, name: 'Bahamas' },
	Bahrain: { code: 19, name: 'Bahrain' },
	Bangladesh: { code: 20, name: 'Bangladesh' },
	Barbados: { code: 21, name: 'Barbados' },
	BassasdaIndia: { code: 22, name: 'Bassas da India' },
	Belarus: { code: 23, name: 'Belarus' },
	Belgium: { code: 24, name: 'Belgium' },
	Belize: { code: 25, name: 'Belize' },
	Benin: { code: 26, name: 'Benin' },
	Bermuda: { code: 27, name: 'Bermuda' },
	Bhutan: { code: 28, name: 'Bhutan' },
	Bolivia: { code: 29, name: 'Bolivia' },
	BosniaAndHerzegovina: { code: 30, name: 'Bosnia and Herzegovina' },
	Botswana: { code: 31, name: 'Botswana' },
	BouvetIsland: { code: 32, name: 'Bouvet Island' },
	Brazil: { code: 33, name: 'Brazil' },
	BritishIndianOceanTerritory: { code: 34, name: 'British Indian Ocean Territory' },
	BritishVirginIslands: { code: 35, name: 'British Virgin Islands' },
	Brunei: { code: 36, name: 'Brunei' },
	Bulgaria: { code: 37, name: 'Bulgaria' },
	BurkinaFaso: { code: 38, name: 'Burkina Faso' },
	Burma: { code: 39, name: 'Burma' },
	Burundi: { code: 40, name: 'Burundi' },
	Cameroon: { code: 41, name: 'Cameroon' },
	Canada: { code: 254, name: 'Canada', locale: locales.CanadianEnglish },
	CapeVerde: { code: 42, name: 'Cape Verde' },
	CaymanIslands: { code: 43, name: 'Cayman Islands' },
	CentralAfricanRepublic: { code: 44, name: 'Central African Republic' },
	Chad: { code: 45, name: 'Chad' },
	Chile: { code: 46, name: 'Chile' },
	China: { code: 255, name: 'China' },
	ChristmasIsland: { code: 47, name: 'Christmas Island' },
	CocosKeelingIslands: { code: 48, name: 'Cocos (Keeling) Islands' },
	Colombia: { code: 49, name: 'Colombia' },
	Comoros: { code: 50, name: 'Comoros' },
	CongoDemocraticRepublicOfThe: { code: 51, name: 'Congo, Democratic Republic of the' },
	CongoRepublicOfThe: { code: 52, name: 'Congo, Republic of the' },
	CookIslands: { code: 53, name: 'Cook Islands' },
	CoralSeaIslands: { code: 54, name: 'Coral Sea Islands' },
	CostaRica: { code: 55, name: 'Costa Rica' },
	CotedIvoire: { code: 56, name: 'Cote d&#39;Ivoire' },
	Croatia: { code: 57, name: 'Croatia' },
	Cuba: { code: 58, name: 'Cuba' },
	Cyprus: { code: 59, name: 'Cyprus' },
	CzechRepublic: { code: 60, name: 'Czech Republic' },
	Denmark: { code: 61, name: 'Denmark' },
	Dhekelia: { code: 62, name: 'Dhekelia' },
	Djibouti: { code: 63, name: 'Djibouti' },
	Dominica: { code: 64, name: 'Dominica' },
	DominicanRepublic: { code: 65, name: 'Dominican Republic' },
	Ecuador: { code: 66, name: 'Ecuador' },
	Egypt: { code: 67, name: 'Egypt' },
	ElSalvador: { code: 68, name: 'El Salvador' },
	EquatorialGuinea: { code: 69, name: 'Equatorial Guinea' },
	Eritrea: { code: 70, name: 'Eritrea' },
	Estonia: { code: 71, name: 'Estonia' },
	Ethiopia: { code: 72, name: 'Ethiopia' },
	EuropaIsland: { code: 73, name: 'Europa Island' },
	FalklandIslandsIslasMalvinas: { code: 74, name: 'Falkland Islands (Islas Malvinas)' },
	FaroeIslands: { code: 75, name: 'Faroe Islands' },
	Fiji: { code: 76, name: 'Fiji' },
	Finland: { code: 77, name: 'Finland' },
	France: { code: 78, name: 'France' },
	FrenchGuiana: { code: 79, name: 'French Guiana' },
	FrenchPolynesia: { code: 80, name: 'French Polynesia' },
	FrenchSouthernAndAntarcticLands: { code: 81, name: 'French Southern and Antarctic Lands' },
	Gabon: { code: 82, name: 'Gabon' },
	GambiaThe: { code: 83, name: 'Gambia, The' },
	GazaStrip: { code: 84, name: 'Gaza Strip' },
	Georgia: { code: 85, name: 'Georgia' },
	Germany: { code: 86, name: 'Germany' },
	Ghana: { code: 87, name: 'Ghana' },
	Gibraltar: { code: 88, name: 'Gibraltar' },
	GloriosoIslands: { code: 89, name: 'Glorioso Islands' },
	Greece: { code: 90, name: 'Greece' },
	Greenland: { code: 91, name: 'Greenland' },
	Grenada: { code: 92, name: 'Grenada' },
	Guadeloupe: { code: 93, name: 'Guadeloupe' },
	Guam: { code: 94, name: 'Guam' },
	Guatemala: { code: 95, name: 'Guatemala' },
	Guernsey: { code: 96, name: 'Guernsey' },
	Guinea: { code: 97, name: 'Guinea' },
	GuineaBissau: { code: 98, name: 'Guinea-Bissau' },
	Guyana: { code: 99, name: 'Guyana' },
	Haiti: { code: 100, name: 'Haiti' },
	HeardIslandAndMcDonaldIslands: { code: 101, name: 'Heard Island and McDonald Islands' },
	HolySeeVaticanCity: { code: 102, name: 'Holy See (Vatican City)' },
	Honduras: { code: 103, name: 'Honduras' },
	HongKong: { code: 104, name: 'Hong Kong' },
	Hungary: { code: 105, name: 'Hungary' },
	Iceland: { code: 106, name: 'Iceland' },
	India: { code: 107, name: 'India' },
	Indonesia: { code: 108, name: 'Indonesia' },
	Iran: { code: 109, name: 'Iran' },
	Iraq: { code: 110, name: 'Iraq' },
	Ireland: { code: 111, name: 'Ireland' },
	IsleOfMan: { code: 112, name: 'Isle of Man' },
	Israel: { code: 113, name: 'Israel' },
	Italy: { code: 114, name: 'Italy' },
	Jamaica: { code: 115, name: 'Jamaica' },
	JanMayen: { code: 116, name: 'Jan Mayen' },
	Japan: { code: 117, name: 'Japan' },
	Jersey: { code: 118, name: 'Jersey' },
	Jordan: { code: 119, name: 'Jordan' },
	JuandeNovaIsland: { code: 120, name: 'Juan de Nova Island' },
	Kazakhstan: { code: 121, name: 'Kazakhstan' },
	Kenya: { code: 122, name: 'Kenya' },
	Kiribati: { code: 123, name: 'Kiribati' },
	KoreaNorth: { code: 124, name: 'Korea, North' },
	KoreaSouth: { code: 125, name: 'Korea, South' },
	Kuwait: { code: 126, name: 'Kuwait' },
	Kyrgyzstan: { code: 127, name: 'Kyrgyzstan' },
	Laos: { code: 128, name: 'Laos' },
	Latvia: { code: 129, name: 'Latvia' },
	Lebanon: { code: 130, name: 'Lebanon' },
	Lesotho: { code: 131, name: 'Lesotho' },
	Liberia: { code: 132, name: 'Liberia' },
	Libya: { code: 133, name: 'Libya' },
	Liechtenstein: { code: 134, name: 'Liechtenstein' },
	Lithuania: { code: 135, name: 'Lithuania' },
	Luxembourg: { code: 136, name: 'Luxembourg' },
	Macau: { code: 137, name: 'Macau' },
	Macedonia: { code: 138, name: 'Macedonia' },
	Madagascar: { code: 139, name: 'Madagascar' },
	Malawi: { code: 140, name: 'Malawi' },
	Malaysia: { code: 141, name: 'Malaysia' },
	Maldives: { code: 142, name: 'Maldives' },
	Mali: { code: 143, name: 'Mali' },
	Malta: { code: 144, name: 'Malta' },
	MarshallIslands: { code: 145, name: 'Marshall Islands' },
	Martinique: { code: 146, name: 'Martinique' },
	Mauritania: { code: 147, name: 'Mauritania' },
	Mauritius: { code: 148, name: 'Mauritius' },
	Mayotte: { code: 149, name: 'Mayotte' },
	Mexico: { code: 150, name: 'Mexico' },
	MicronesiaFederatedStatesof: { code: 151, name: 'Micronesia, Federated States of' },
	Moldova: { code: 152, name: 'Moldova' },
	Monaco: { code: 153, name: 'Monaco' },
	Mongolia: { code: 154, name: 'Mongolia' },
	Montserrat: { code: 155, name: 'Montserrat' },
	Morocco: { code: 156, name: 'Morocco' },
	Mozambique: { code: 157, name: 'Mozambique' },
	Namibia: { code: 158, name: 'Namibia' },
	Nauru: { code: 159, name: 'Nauru' },
	NavassaIsland: { code: 160, name: 'Navassa Island' },
	Nepal: { code: 161, name: 'Nepal' },
	Netherlands: { code: 162, name: 'Netherlands' },
	NetherlandsAntilles: { code: 163, name: 'Netherlands Antilles' },
	NewCaledonia: { code: 164, name: 'New Caledonia' },
	NewZealand: { code: 165, name: 'New Zealand' },
	Nicaragua: { code: 166, name: 'Nicaragua' },
	Niger: { code: 167, name: 'Niger' },
	Nigeria: { code: 168, name: 'Nigeria' },
	Niue: { code: 169, name: 'Niue' },
	NorfolkIsland: { code: 170, name: 'Norfolk Island' },
	NorthernMarianaIslands: { code: 171, name: 'Northern Mariana Islands' },
	Norway: { code: 172, name: 'Norway' },
	Oman: { code: 173, name: 'Oman' },
	Pakistan: { code: 174, name: 'Pakistan' },
	Palau: { code: 175, name: 'Palau' },
	Panama: { code: 176, name: 'Panama' },
	PapuaNewGuinea: { code: 177, name: 'Papua New Guinea' },
	ParacelIslands: { code: 178, name: 'Paracel Islands' },
	Paraguay: { code: 179, name: 'Paraguay' },
	Peru: { code: 180, name: 'Peru' },
	Philippines: { code: 181, name: 'Philippines' },
	PitcairnIslands: { code: 182, name: 'Pitcairn Islands' },
	Poland: { code: 183, name: 'Poland' },
	Portugal: { code: 184, name: 'Portugal' },
	PuertoRico: { code: 185, name: 'Puerto Rico' },
	Qatar: { code: 186, name: 'Qatar' },
	Reunion: { code: 187, name: 'Reunion' },
	Romania: { code: 188, name: 'Romania' },
	Russia: { code: 189, name: 'Russia' },
	Rwanda: { code: 190, name: 'Rwanda' },
	SaintHelena: { code: 191, name: 'Saint Helena' },
	SaintKittsAndNevis: { code: 192, name: 'Saint Kitts and Nevis' },
	SaintLucia: { code: 193, name: 'Saint Lucia' },
	SaintPierreAndMiquelon: { code: 194, name: 'Saint Pierre and Miquelon' },
	SaintVincentAndtheGrenadines: { code: 195, name: 'Saint Vincent and the Grenadines' },
	Samoa: { code: 196, name: 'Samoa' },
	SanMarino: { code: 197, name: 'San Marino' },
	SaoTomeAandPrincipe: { code: 198, name: 'Sao Tome and Principe' },
	SaudiArabia: { code: 199, name: 'Saudi Arabia' },
	Senegal: { code: 200, name: 'Senegal' },
	SerbiaAndMontenegro: { code: 201, name: 'Serbia and Montenegro' },
	Seychelles: { code: 202, name: 'Seychelles' },
	SierraLeone: { code: 203, name: 'Sierra Leone' },
	Singapore: { code: 204, name: 'Singapore' },
	Slovakia: { code: 205, name: 'Slovakia' },
	Slovenia: { code: 206, name: 'Slovenia' },
	SolomonIslands: { code: 207, name: 'Solomon Islands' },
	Somalia: { code: 208, name: 'Somalia' },
	SouthAfrica: { code: 209, name: 'South Africa' },
	Spain: { code: 211, name: 'Spain', locale: locales.Spanish },
	SpratlyIslands: { code: 212, name: 'Spratly Islands' },
	SriLanka: { code: 213, name: 'Sri Lanka' },
	Sudan: { code: 214, name: 'Sudan' },
	Suriname: { code: 215, name: 'Suriname' },
	Svalbard: { code: 216, name: 'Svalbard' },
	Swaziland: { code: 217, name: 'Swaziland' },
	Sweden: { code: 218, name: 'Sweden' },
	Switzerland: { code: 219, name: 'Switzerland' },
	Syria: { code: 220, name: 'Syria' },
	Taiwan: { code: 221, name: 'Taiwan' },
	Tajikistan: { code: 222, name: 'Tajikistan' },
	Tanzania: { code: 223, name: 'Tanzania' },
	Thailand: { code: 224, name: 'Thailand' },
	TimorLeste: { code: 225, name: 'Timor-Leste' },
	Togo: { code: 226, name: 'Togo' },
	Tokelau: { code: 227, name: 'Tokelau' },
	Tonga: { code: 228, name: 'Tonga' },
	TrinidadAndTobago: { code: 229, name: 'Trinidad and Tobago' },
	TromelinIsland: { code: 230, name: 'Tromelin Island' },
	Tunisia: { code: 231, name: 'Tunisia' },
	Turkey: { code: 232, name: 'Turkey' },
	Turkmenistan: { code: 233, name: 'Turkmenistan' },
	TurksAndCaicosIslands: { code: 234, name: 'Turks and Caicos Islands' },
	Tuvalu: { code: 235, name: 'Tuvalu' },
	Uganda: { code: 236, name: 'Uganda' },
	Ukraine: { code: 237, name: 'Ukraine' },
	UnitedArabEmirates: { code: 238, name: 'United Arab Emirates' },
	UnitedKingdom: { code: 239, name: 'United Kingdom' },
	UnitedStates: { code: 240, name: 'United States', locale: locales.English },
	Uruguay: { code: 241, name: 'Uruguay' },
	Uzbekistan: { code: 242, name: 'Uzbekistan' },
	Vanuatu: { code: 243, name: 'Vanuatu' },
	Venezuela: { code: 244, name: 'Venezuela' },
	Vietnam: { code: 245, name: 'Vietnam' },
	VirginIslands: { code: 246, name: 'Virgin Islands' },
	WakeIsland: { code: 247, name: 'Wake Island' },
	WallisAndFutuna: { code: 248, name: 'Wallis and Futuna' },
	WestBank: { code: 249, name: 'West Bank' },
	WesternSahara: { code: 250, name: 'Western Sahara' },
	Yemen: { code: 251, name: 'Yemen' },
	Zambia: { code: 252, name: 'Zambia' },
	Zimbabwe: { code: 253, name: 'Zimbabwe' },
};
