import React, { FC } from 'react';
import styled, { css } from 'styled-components';

import { md, sm, smOnly, xsSmOnly } from 'design-system/media-queries';
import { white } from 'design-system/variables/colors';
import { spaceHuge, spaceSmall, spaceBase, spaceBig, spaceTiny, spaceMedium } from 'design-system/variables/spaces';
import { transparentDark80 } from 'design-system/style-mixins/shadows-and-scrims';

import { IconButton } from 'components/Icon';
import { Modal } from 'components/Modal';
import { SignIn } from '../shared/SignIn';
import { PrivacyPolicy } from '../shared/PrivacyPolicy';
import { GetStarted } from './GetStarted';
import { RegistrationForm } from './RegistrationForm';

const MobileSpacing = styled.div`
	${xsSmOnly`
		&& {
			margin: 0;
			padding: 0;
		}
	`}
`;

const Wrapper = styled.div`
	height: 100%;
	position: relative;

	${md`
		display: flex;
		min-height: 567px;
		width: 850px;
		flex-direction: row;
	`}
`;

const Container = styled.div`
	background-color: ${white};
	display: flex;
	justify-content: center;
	margin: auto;
	width: 100%;
	align-items: center;
	flex-shrink: 1;
	flex-direction: column;
	height: auto;

	&& {
		padding-top: ${spaceMedium};
		padding-left: ${spaceBase};
		padding-right: ${spaceBase};

		${sm`
			padding-top: ${spaceHuge};
			padding-left: initial;
			padding-right: initial;
		`}

		${md`
			padding-top: ${spaceBig};
			padding-bottom: ${spaceSmall};
			height: 100%;
		`}
	}
`;

const FormContainer = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: flex-start;
	max-width: 350px;
	width: 100%;
`;

const modalStyles = css`
	display: block;

	${md`
		display: flex;
		background: ${transparentDark80};
		align-items: center;
		justify-content: center;
	`}
`;

const CloseButton = styled(IconButton)`
	position: absolute;
	right: ${spaceTiny};
	top: ${spaceTiny};

	${xsSmOnly`
		&& {
			svg {
				fill: ${white};
			}
		}
	`}
	${smOnly`
		right: ${spaceBase};
		top: ${spaceBase};
	`}
`;

export const RegistrationModal: FC<{ onClose: Function }> = ({ onClose }) => (
	<Modal isOpen contentStyles={modalStyles}>
		<MobileSpacing className='row'>
			<MobileSpacing className='col-xs-12'>
				<Wrapper>
					<GetStarted />
					<Container className='col-xs-12 col-sm-8 col-md-7'>
						<FormContainer>
							<RegistrationForm />
							<SignIn />
							<PrivacyPolicy />
						</FormContainer>
					</Container>
					<CloseButton buttonType='containerless' iconName='close' iconSize='md' onClick={onClose} />
				</Wrapper>
			</MobileSpacing>
		</MobileSpacing>
	</Modal>
);
