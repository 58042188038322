import React, { FC, memo, useState } from 'react';
import { LoaderSkeleton } from 'components/Loader/LoaderSkeleton';
import { ImageCarousel } from 'components/ImageCarousel';
import { useLazyImageList } from 'components/hooks/useLazyImageList';
import { LazyImage } from 'components/LazyImage';
import { ImageContainer } from '../ListCard.styles';

interface Props {
	isListAndIsDesktop: boolean;
	isList: boolean;
	galleryImages: any;
	heroImage: any;
	imageServer: any;
	isPlaceholder: boolean;
}
export const Gallery: FC<Props> = memo(
	({ isListAndIsDesktop, galleryImages, heroImage, imageServer, isPlaceholder }) => {
		const defaultImages = heroImage ? [{ imagePath: `${imageServer}${heroImage}` }] : null;
		const getJoinedImages = () => (defaultImages ? [...defaultImages, ...galleryImages.slice(1)] : galleryImages);

		const images = galleryImages.length ? getJoinedImages() : defaultImages;

		const { getImageProps } = useLazyImageList(3, images && images.length);
		const [carouselRef, setCarouselRef] = useState(null);

		return (
			<ImageContainer isList={isListAndIsDesktop}>
				{images ? (
					<ImageCarousel setCarouselRef={setCarouselRef}>
						{images.map((imageData, i) => (
							 imageData.imagePath && <LazyImage
								{...getImageProps(i)}
								root={carouselRef}
								src={`${imageData.imagePath}&width=768`}
								key={`list-img-${i}-${imageData.imagePath}`}
							/>
						))}
					</ImageCarousel>
				) : (
					<LoaderSkeleton fillContainer />
				)}
			</ImageContainer>
		);
	},
);
