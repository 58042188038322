import React, { useState, useRef, useEffect } from 'react';
import ImageLoaderWorker from 'worker-loader!../webWorkers/ImageLoader.worker.ts';

export const useWorkerImage = ({ disabled = false }) => {
	const loaderWorker = useRef(null);
	const [imageUrl, setImageUrl] = useState('');

	useEffect(() => {
		if (disabled) {
			return;
		}
		if (typeof Worker !== 'undefined') {
			loaderWorker.current = new ImageLoaderWorker();
		}

		const loaderHandler = event => {
			const objectUrl = URL.createObjectURL(event.data.blob);
			setImageUrl(objectUrl);
		};

		loaderWorker.current.addEventListener('message', loaderHandler);

		return () => {
			loaderWorker.current.removeEventListener('message', loaderHandler);
		};
	}, []);

	const requestImageSrc = src => {
		if (disabled) {
			setImageUrl(src);
			return;
		}

		loaderWorker.current.postMessage({
			type: 'lazy-image-list',
			src,
		});
	};

	return [imageUrl, requestImageSrc];
};
