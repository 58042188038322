import React, { FC, useState } from 'react';
import { Icon, TextArea, TextButton, Input } from 'components';
import { useFormikContext, FieldArray, Field } from 'formik';
import { CustomNpoDateRangesInput } from './CustomNpoDateRangesInput';
import {
	Form,
	MultiRow,
	NPOButton,
	NPOHeadline,
	NPOSectionOptional,
	NPOSectionTitle,
	RequiredText,
	UnitCell,
	Error,
	ValidationMessage,
	SetBalloonToTop,
	NpoRichTextEditor,
} from './NPOStyles';
import { InputTooltip } from '../../../../../components/Form/Input/InputTooltip';
import { useIsDesktop } from '../../../../../components/hooks/useIsDesktop';

export const NPOForm: FC<{ proposalId; edit }> = ({ proposalId, edit }) => {
	const {
		handleBlur,
		handleChange,
		values,
		errors,
		handleSubmit,
		setFieldValue,
		setFieldTouched,
	} = useFormikContext();
	const [disableDateButton, setDisableDateButton] = useState(true);
	const noteMaxLength = 999;
	const [isDesktop] = useIsDesktop();

	const handleChangeTextArea = (name) => (value) => {
		setFieldValue(name, value);
	};
	const handleBlurTextArea = (name) => (value) => {
		setFieldTouched(name);
	};

	const handleDateRangeChange = (dateRange, index) => (value) => {
		if (!values.dateRanges) {
			values.dateRanges = [{ dateRange }];
			setDisableDateButton(true);
		} else {
			values.dateRanges[index] = dateRange;
			setDisableDateButton(
				dateRange.reservationTotal == undefined ||
					dateRange.reservationTotal == '' ||
					values.dateRanges.length > 4,
			);
		}
	};

	return (
		<div>
			<NPOHeadline>{edit ? 'Edit' : 'Add'} nonportfolio option</NPOHeadline>
			<Form>
				<div className='row'>
					<NPOSectionTitle>Property Details</NPOSectionTitle>
					<div className='col-xs-12'>
						<Input
							type='text'
							id='add-npo-accomodation-name-input'
							name='NonPortfolioAccommodationName'
							labelText='Accommodation name'
							placeholder='King Suite'
							value={values.NonPortfolioAccommodationName}
							onChange={handleChange}
							onBlur={handleBlur}
							hasError={errors.NonPortfolioAccommodationName}
							required={true}
						/>
						{errors.NonPortfolioAccommodationName && <Error>{errors.NonPortfolioAccommodationName}</Error>}
					</div>
					<div className='col-xs-12'>
						<Input
							placeholder='St. Regis Aspen'
							type='text'
							name='propertyName'
							labelText='Property name'
							id='add-npo-property-name-input'
							value={values.propertyName}
							onChange={handleChange}
							onBlur={handleBlur}
							hasError={errors.propertyName}
							required={true}
						/>
						{errors.propertyName && <Error>{errors.propertyName}</Error>}
					</div>
					<div className='col-xs-12'>
						<Input
							placeholder='Aspen, Colorado'
							type='text'
							name='destination'
							id='add-npo-destination-input'
							labelText='Destination'
							value={values.destination}
							onChange={handleChange}
							onBlur={handleBlur}
							hasError={errors.destination}
							required={true}
						/>
						{errors.destination && <Error>{errors.destination}</Error>}
					</div>
					<div className='col-xs-12'>
						<Input
							placeholder='http://www.hotel.com'
							type='url'
							name='propertyUrl'
							id='add-npo-property-url-input'
							labelText='Property URL (optional)'
							tooltip='Enter a URL for the property or unit (make sure to include http://).<br><br>Members can access this link by clicking on the property name in the proposal email.'
							isDesktop={isDesktop}
							value={values.propertyUrl}
							onChange={handleChange}
							onBlur={handleBlur}
							hasError={errors.propertyUrl}
						/>
						{errors.propertyUrl && <Error>{errors.propertyUrl}</Error>}
					</div>
				</div>
				{/* 				<div className='row'>
				<NPOSectionTitle>Add a Photo </NPOSectionTitle><NPOSectionOptional>(optional)</NPOSectionOptional>
                 <div className='col-xs-12'>
						<Input
							placeholder='http://www.hotel.com/photo.jpg'
							type='url'
							name='photoUrl'
							id='add-npo-photo-url-input'
							name='photoUrl'
							labelText='Photo URL'
							value={values.photoUrl}
							onChange = {handleChange}
							onBlur = {handleBlur}
						/>
					</div>
				</div> */}
				<div className='row'>
					<NPOSectionTitle>Unit Details </NPOSectionTitle>
					<NPOSectionOptional>(optional)</NPOSectionOptional>
				</div>
				<MultiRow>
					<Input
						placeholder='8'
						type='number'
						id='add-npo-unit-guests-input'
						labelText='Guests'
						name='guests'
						value={values.guests}
						onChange={handleChange}
						onBlur={handleBlur}
					/>
					<UnitCell>
						<Input
							placeholder='5'
							type='number'
							id='add-npo-unit-bedrooms-input'
							labelText='Bedrooms'
							name='bedrooms'
							value={values.bedrooms}
							onChange={handleChange}
							onBlur={handleBlur}
						/>
					</UnitCell>
					<UnitCell>
						<Input
							placeholder='5.5'
							type='number'
							id='add-npo-unit-bathrooms-input'
							labelText='Bathrooms'
							name='bathrooms'
							value={values.bathrooms}
							onChange={handleChange}
							onBlur={handleBlur}
						/>
					</UnitCell>
					<UnitCell>
						<Input
							placeholder='2000'
							type='number'
							id='add-npo-unit-square-feet-input'
							labelText='Square feet'
							name='squareFeet'
							value={values.squareFeet}
							onChange={handleChange}
							onBlur={handleBlur}
							hasError={errors.squareFeet}
						/>
						{errors.squareFeet && <Error>{errors.squareFeet}</Error>}
					</UnitCell>
				</MultiRow>
				<div className='row'>
					<NPOSectionTitle>Dates </NPOSectionTitle>
					<NPOSectionOptional>(optional)</NPOSectionOptional>
				</div>
				<FieldArray
					name='dateRanges'
					render={(helpers) => (
						<div>
							{values.dateRanges.length && values.dateRanges.map((dateRange, index) => (
									<Field key={`dateRange[${dateRange.renderId}]`} name={`dateRange[${dateRange.renderId}]`}>
										{() => (
											<CustomNpoDateRangesInput
												renderId={dateRange.renderId}
												index={index}
												handleChange={handleDateRangeChange}
												handleTotalChange={handleChange}
												handleDelete={() => {
													if (dateRange.id) {
														values.deletedDateRanges.push(dateRange.id);
													}
													if (values.dateRanges.length === 1 && index === 0)
													{
														values.dateRanges.push({
															renderId: `${Math.random()}`,
															checkInDate: '',
															checkOutDate: '',
															reservationTotal: '',
															selectedDays: '',
															reservationTotalNeeded: false,
														});
														setDisableDateButton(true);
														helpers.remove(0);
													}
													else
													{
														helpers.remove(index);
														setDisableDateButton(values.dateRanges[values.dateRanges.length - 1].selectedDays === '' && index !== values.dateRanges.length - 1);
													}

												}}
												range={dateRange}
												errors={errors}
												handleBlur={handleBlur}
											/>
										)}
									</Field>
								))}
							<div className='row'>
								<TextButton
									iconName='plus'
									iconSize='md'
									iconPosition='left'
									style={{ textAlign: 'left' }}
									onClick={() => {
										helpers.push({
											renderId: `${Math.random()}`,
											checkInDate: '',
											checkOutDate: '',
											reservationTotal: '',
											selectedDays: '',
											reservationTotalNeeded: false,
										});
										setDisableDateButton(true);
									}}
									disabled={disableDateButton}
								>
									Add another date
								</TextButton>
							</div>
						</div>
					)}
				/>
				<div className='row'>
					<NPOSectionTitle>
						Notes<Icon iconName='required' fill='#dd3310' iconSize='md'></Icon>
						{isDesktop && (
							<InputTooltip
								message={
									'Enter any notes you’d like to share with the member, including additional links to the property or unit, a breakdown of the nightly rates, etc.'
								}
							/>
						)}
					</NPOSectionTitle>
				</div>
				<SetBalloonToTop />
				<NpoRichTextEditor
					value={values.note}
					id='add-npo-reservation-note'
					name='notes'
					placeholder='Enter a note...'
					hideLabel
					editorType='inline'
					toolbar={['bold', 'italic', 'link', 'bulletedList', 'numberedList', 'undo', 'redo']}
					onChange={handleChangeTextArea('note')}
					onBlur={handleBlurTextArea('note')}
					hasError={errors.note}
					onInit={(editor) => {
						editor.ui.view.panel.element.style.zIndex = 99999;
					}}
				/>
				{values.note.length > 0 && values.note.length <= noteMaxLength && (
					<ValidationMessage>{noteMaxLength - values.note.length} characters left.</ValidationMessage>
				)}
				{errors.note && <Error>{errors.note}</Error>}
				<NPOButton type='button' buttonType='primary' size='base' onClick={handleSubmit}>
					{edit ? 'Save Changes' : 'Add to Proposal'}
				</NPOButton>
				<RequiredText className='row'>
					<Icon iconName='required' fill='#dd3310' iconSize='sm'></Icon>Required fields
				</RequiredText>
			</Form>
		</div>
	);
};
