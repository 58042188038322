import styled from 'styled-components';
import { textBase } from 'design-system/style-mixins/text';
import { textColorLight } from 'design-system/variables/colors';
import { sm } from 'design-system/media-queries';
import { spaceBase } from 'design-system/variables/spaces';
import { TextButton } from 'components/TextButton/TextButton';

export const ActionsClearButton = styled(TextButton)`
	${textBase}
	color: ${textColorLight};

  && {
    ${sm`
      padding: ${spaceBase} 0;
    `}
  }
`;

export const ActionsApplyButton = styled(TextButton)`
	${textBase}

	&& {
		${sm`
      padding: ${spaceBase} 0;
    `}
	}
`;
