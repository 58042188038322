import { SET_DONE_LOADING } from 'pages/TripProposals/shared/redux/constants';
import { fetchMemberDetails } from './member';
import { getRecipientId } from '../selectors/metadata';
import { getProposalElements } from '../selectors/proposalElements';
import { State } from '../context';
import { fetchProposal } from './proposal';
import { setUnitDetails } from './unitDetails';
import { getProposalAccommodationIds } from '../../utils';

export const loadEditView = (id: number) => (dispatch, getState: () => State) => {
	return dispatch(fetchProposal(id))
		.then(() => {
			const proposalElements = getProposalElements(getState());
			const accommodationIds = getProposalAccommodationIds(proposalElements);
			const recipientId = getRecipientId(getState());

			if (accommodationIds.length > 0) {
				dispatch(setUnitDetails(accommodationIds));
			}

			if (recipientId) {
				dispatch(fetchMemberDetails(recipientId));
			}
		})
		.then(() => {
			dispatch({ type: SET_DONE_LOADING });
		});
};
