import React from 'react';
import styled from 'styled-components';
import { dividerGrayLight, white, black } from 'design-system/variables/colors';
import { lg } from 'design-system/media-queries';
import { boxShadow1 } from 'design-system/style-mixins/shadows-and-scrims';
import { spaceBig, spaceBetween, spaceHuge, spaceLarge, spaceMassive } from 'design-system/variables/spaces';
import { bold } from 'design-system/style-mixins/text';
import { TextButton } from 'components/TextButton/TextButton';
import { Icon, InspiratoSelectLogo } from 'components/Icon/Icon';
import { useTripIRParam } from 'pages/RequestToBook/hooks/useTripIRParam.hook';
import { TripTypeIR } from 'pages/RequestToBook/enums/TripType.enum';

const Banner = styled.section`
	background: ${white};
	${boxShadow1}
	height: 80px;
	border-bottom: ${dividerGrayLight};
	position: relative;
	width: 100%;

	@media print {
		display: none;
	}
`;
const ContentWrapper = styled.div`
	display: flex;
	height: 100%;
	justify-content: space-between;
	padding: 0 ${spaceLarge};
	margin: 0 auto;
	max-width: 1200px;
	width: 100%;

	button {
		font-weight: ${bold};
	}

	${lg`
		padding: 0 ${spaceMassive} 0 ${spaceBetween(spaceBig, spaceHuge)};
	`}
`;

const PassLogo = styled((props) => <Icon iconName="logo-inspirato-pass" {...props} />)`
	height: 100%;
	max-width: 200px;
	path {
		fill: ${black};
	}
`;
const InspiratoLogo = styled((props) => <Icon iconName="logo-inspirato" {...props} />)`
	height: 100%;
	max-width: 200px;
	path {
		fill: ${black};
	}
`;

interface ReturnBannerProps {
	returnLabel: string;
	returnFunction: Function;
	inspiratoRewards: TripTypeIR;
}

export const ReturnBanner: React.FC<ReturnBannerProps> = ({ returnLabel, returnFunction }) => {
	const { inspiratoRewards, isNonPassRewards } = useTripIRParam();

	let logo = null;
	if (inspiratoRewards === TripTypeIR.Select) {
		logo = <InspiratoSelectLogo />;
	} else if (isNonPassRewards(inspiratoRewards) || inspiratoRewards === TripTypeIR.Invited) {
		logo = <InspiratoLogo />;
	} else {
		logo = <PassLogo />;
	}

	return (
		<Banner>
			<ContentWrapper>
				{logo}
				<TextButton onClick={returnFunction}>{returnLabel}</TextButton>
			</ContentWrapper>
		</Banner>
	);
};
