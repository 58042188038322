import styled from 'styled-components';
import { paleBlue } from 'design-system/variables/colors';
import { spaceBase } from 'design-system/variables/spaces';
import { richText } from 'design-system/utils';
import { textBase } from 'design-system/style-mixins/text';

export const MapWrapper = styled.figure`
	position: relative;
	margin: 0;
`;

export const MapDetails = styled.figcaption`
	${richText}
	${textBase}
	line-height: 1.6;
	background-color: ${paleBlue};
	padding: ${spaceBase};

	> *:first-child {
		margin-top: 0;
	}

	> *:last-child {
		margin-bottom: 0;
	}
`;
