import React, { FC, useMemo, useContext, useEffect, memo } from 'react';
import { clone } from 'ramda';
import memoizedPlural from 'utils/memoizedPlural';
import { CheckboxGroup } from 'components/Checkbox';
import { Label } from 'components/Label';
import { KeyboardAccessibilityContext } from 'components/KeyboardAccessibility';
import { CheckboxGroupWrapper, DestinationsDropdownHeader } from './DestinationAutocomplete.styles';
import { DestinationsAccordionProps } from './DestinationAutocompleteProps';
import { usePrevProps, checkIsMajorRegionsChange, getFirstRegionInExpandedParent } from './utils';

export const DestinationsAccordion: FC<DestinationsAccordionProps> = memo(
	({ updateRegionsExpandedState, selectResult, searchWord, regions }) => {
		const { setActiveId } = useContext(KeyboardAccessibilityContext);
		const previousRegions = usePrevProps(clone(regions));
		const totalDestinationsCount = useMemo(
			() =>
				regions.reduce(
					(acc, current) => acc + current.children.filter(child => child.locationType === 'Location').length,
					0,
				),
			[regions],
		);

		useEffect(() => {
			const firstRegion = getFirstRegionInExpandedParent({ regions }) || regions[0];
			setActiveId(firstRegion.label);

			return () => {
				setActiveId(null);
			};
		}, []);

		useEffect(() => {
			const isMajorRegionsChange = checkIsMajorRegionsChange({ previousRegions, regions });

			if (isMajorRegionsChange) {
				const firstRegion = getFirstRegionInExpandedParent({ regions });

				firstRegion && setActiveId(firstRegion.label);
			}
		}, [regions]);

		return (
			<>
				<DestinationsDropdownHeader>
					<Label size='medium'>
						{totalDestinationsCount}{' '}
						{totalDestinationsCount > 1 ? memoizedPlural('Destination') : 'Destination'}
					</Label>
				</DestinationsDropdownHeader>
				<CheckboxGroupWrapper>
					<CheckboxGroup
						isParentLabelIncluded
						members={regions}
						searchWord={searchWord}
						onCheckboxClick={selectResult}
						onExpand={updateRegionsExpandedState}
					/>
				</CheckboxGroupWrapper>
			</>
		);
	},
);
