import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import styled, { css } from 'styled-components';
import { Carousel } from 'react-responsive-carousel';
import LazyLoad from 'react-lazyload';
import { transition100ms } from 'design-system/style-mixins/animations';
import root from 'window-or-global';
import { ImageViewModel } from 'interfaces/ImageViewModel';
import { AspectRatio } from 'components/AspectRatio';
import { useFullscreenView, useVisibilityToggle } from 'components/hooks';
import { white, primaryLinkColor, black } from '../../design-system/variables/colors';
import { transparentDark70, transparentDark90 } from '../../design-system/style-mixins/shadows-and-scrims';
import { FullscreenSwitch } from './FullscreenSwitch';
import { ImageCaptions } from './ImageCaptions';
import { TourPreview } from './TourPreview';
import { TourPreviewButton } from './TourPreviewButton';

const dotContainerWidth = 80;

const genOffset = (dotControlWidth, selectedItem) => {
	let offset = 0;
	const endOffset = dotControlWidth - dotContainerWidth;

	if (selectedItem > 3) {
		offset = -Math.ceil((dotContainerWidth + 16) / 6) * (selectedItem - 3);

		if ((endOffset > Math.abs(offset) && endOffset < Math.abs(offset)) || endOffset < Math.abs(offset)) {
			offset = -endOffset;
		}
	}

	return `${offset}px`;
};

const genPositionCSS = props => {
	let positionCSS = css`
		justify-content: center;
		min-width: 100%;
	`;

	if (dotContainerWidth - props.dotControlWidth < 0) {
		positionCSS = css`
			transform: translateX(${genOffset(props.dotControlWidth, props.selectedItem)});
		`;
	}

	return positionCSS;
};

const fullScaleDot = css`
	transform: scale(1);
	top: 0;
`;

const smallScaleDot = css`
	top: 1px;
	transform: scale(0.8);
`;

const tinyScaleDot = css`
	top: 2px;
	transform: scale(0.6);
`;

const fullOpacity = css`
	opacity: 1;
`;

const ImageCarouselWrapper = styled.div`
	position: relative;
`;

interface ImageCarouselProps {
	selectedItem: number;
	gallerySize: number;
	showNavigation: boolean;
	backgroundImage: string;
	dotControlWidth: number;
	style?: object;
	darkTheme?: boolean;
}

const ImageCarousel = styled(Carousel)<ImageCarouselProps>`
	--theme-color: ${white};

	${props =>
		props.darkTheme &&
		css`
			--theme-color: ${black};
		`}

	height: 100%;
	max-height: 100%;

	.carousel.carousel-slider {
		background: transparent;
		background-size: auto 100%;
		background-position: center;
		height: 100%;
		width: 100%;
		max-height: 100%;
		max-width: 100vw;
		position: relative;
		overflow: visible;
		user-select: none;

		> div {
			max-height: 100%;
			pointer-events: none;
			transform: translateZ(0);

			ul,
			.slide {
				max-height: 100%;
			}
		}

		.slider-wrapper {
			pointer-events: all;
		}

		.slide {
			background: transparent;
			max-height: 100%;
			position: relative;
		}

		.control-arrow {
			background: ${transparentDark70};
			display: flex;
			height: 42px;
			opacity: 0;
			margin: auto;
			width: 42px;
			align-items: center;
			justify-content: center;
			transition: none;

			&:before {
				content: '';
				border: 2px solid ${white};
				border-left: none;
				border-bottom: none;
				height: 12px;
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				margin: auto;
				transform: rotate(45deg) translate(-15%, 15%);
				width: 12px;
			}

			&:first-of-type {
				&:before {
					transform: rotate(-135deg) translate(-15%, 15%);
				}
			}

			&:hover,
			&:focus {
				background: ${transparentDark90};
			}

			&.focus-visible {
				opacity: 1;
				outline: 2px solid ${primaryLinkColor};
				outline-offset: 2px;
			}

			&:active {
				transform: translateY(1px);
			}
		}

		.dot-container {
			bottom: 0;
			height: 30px;
			left: 0;
			margin: auto;
			overflow: hidden;
			pointer-events: all;
			position: absolute;
			right: 0;
			width: ${props =>
				props.selectedItem > 2 && props.selectedItem < props.gallerySize - 1
					? `${dotContainerWidth + 16}px`
					: `${dotContainerWidth}px`};

			.control-dots {
				${props => props.showNavigation && fullOpacity}
			}
		}

		ul {
			transition: transform 200ms cubic-bezier(0.215, 0.61, 0.355, 1);

			&.control-dots {
				display: flex;
				opacity: 0;
				left: 0;

				${props => genPositionCSS(props)}

				position: absolute;
				width: auto;

				.dot {
					background: transparent;
					border: 1px solid var(--theme-color);
					margin: 0 4px;
					box-shadow: none;
					${transition100ms}
					position: relative;
					${tinyScaleDot}
					outline: none;
					opacity: 1;

					&.selected {
						background: var(--theme-color);
						${fullScaleDot}

						&:not(:nth-child(2)) {
							& + li + li {
								${smallScaleDot}

								& + li {
									${tinyScaleDot}
								}
							}

							&:not(:nth-child(3)) {
								& + li {
									${smallScaleDot}

									& + li {
										${tinyScaleDot}
									}
								}
							}
						}
					}
				}

				.fake-selected {
					&:not(:nth-child(1)) {
						& + .selected {
							background: var(--theme-color);
							${fullScaleDot}

							& + li + li + li {
								${smallScaleDot}

								& + li,
								& + li + li {
									${tinyScaleDot}
								}
							}
						}

						& + li {
							${smallScaleDot}

							& + li,
							& + li + li {
								${fullScaleDot}
							}
						}
					}

					&:nth-child(1) {
						& + li {
							${fullScaleDot}

							& + li {
								${fullScaleDot}
							}
						}

						& + .selected {
							${fullScaleDot}

							& + li {
								${fullScaleDot}

								& + li {
									${fullScaleDot}
								}
							}

							& + li + li + li {
								${smallScaleDot}

								& + li,
								& + li + li {
									${tinyScaleDot}
								}
							}
						}

						& + li + .selected {
							& + li {
								${fullScaleDot}
							}
						}
					}
				}
			}
		}

		&:hover {
			.control-arrow {
				${props => props.showNavigation && fullOpacity}
			}
		}
	}
`;

interface ResponsiveImage {
	defaultSrc: string;
	srcSet: string[];
}

interface Props {
	children?: React.ReactNode;
	imageSet?: string[];
	images?: ImageViewModel[];
	aspectRatio?: number;
	showFullScreenButton?: boolean;
	showCaptions?: boolean;
	showTour?: boolean;
	responsiveImage?: ResponsiveImage;
	fullScreenResponsiveImage?: ResponsiveImage;
	darkTheme?: boolean;
}

export const ImageGallery: React.FunctionComponent<Props> = ({
	children,
	imageSet,
	images,
	showTour,
	aspectRatio,
	showCaptions,
	showFullScreenButton,
	responsiveImage,
	fullScreenResponsiveImage,
	darkTheme,
	...rest
}) => {
	const [selectedImage, setSelectedImage] = React.useState(0);
	const [hasInteraction, setHasInteraction] = React.useState(false);
	const [galleryDCWidth, setGalleryDCWidth] = React.useState(0);
	const gallery = React.useRef(null);
	const containerRef = React.useRef(null);
	const [isFullscreen, toggleFullscreen] = useFullscreenView(containerRef);
	const [isTourPreviewVisible, showTourPreview, hideTourPreview] = useVisibilityToggle(false);
	const isFullscreenShown = showFullScreenButton && !isTourPreviewVisible;
	const isCaptionShown = showCaptions && !isTourPreviewVisible;
	const isTourButtonShown = showTour && !isTourPreviewVisible;
	let galleryImages = imageSet || [];

	React.useEffect(() => {
		gallery.current &&
			gallery.current.carouselWrapperRef &&
			gallery.current.carouselWrapperRef.querySelectorAll('.carousel .dot').forEach(dot => (dot.tabIndex = '-1'));
	});

	React.useEffect(() => {
		if (!gallery.current || !gallery.current.carouselWrapperRef) {
			return;
		}

		gallery.current.carouselWrapperRef.addEventListener('mouseover', () => setHasInteraction(true));
		gallery.current.carouselWrapperRef.addEventListener('touchstart', () => setHasInteraction(true));

		const container = gallery.current.carouselWrapperRef;
		const dotControl = container.querySelector('.control-dots');

		if (dotControl) {
			dotControl.remove();

			const prevDotContainer = container.querySelector('.dot-container');
			let fakeSelected = dotControl.querySelector('.fake-selected');

			prevDotContainer && prevDotContainer.remove();

			const dotContainer = root.document.createElement('div');

			if (!fakeSelected) {
				fakeSelected = root.document.createElement('li');
				fakeSelected.classList.add('fake-selected');
			} else {
				fakeSelected.remove();
			}

			let insertBeforIndex = selectedImage >= 2 ? selectedImage - 2 : 0;

			if (insertBeforIndex > dotControl.childNodes.length - 4) {
				insertBeforIndex = dotControl.childNodes.length - 4;
			}

			dotControl.insertBefore(fakeSelected, dotControl.childNodes[insertBeforIndex]);
			dotContainer.classList.add('dot-container');
			dotContainer.insertAdjacentElement('beforeend', dotControl);
			container.querySelector('.carousel').insertAdjacentElement('beforeend', dotContainer);

			setGalleryDCWidth(dotControl.clientWidth);
		}

		return () => {
			if (!gallery.current || !gallery.current.carouselWrapperRef) {
				return;
			}

			gallery.current.carouselWrapperRef.removeEventListener('mouseover', () => setHasInteraction(true));
			gallery.current.carouselWrapperRef.removeEventListener('touchstart', () => setHasInteraction(true));
		};
	}, [gallery]);

	React.useEffect(() => {
		if (gallery.current && gallery.current.carouselWrapperRef) {
			const container = gallery.current.carouselWrapperRef;
			const dotControl = container.querySelector('.control-dots');

			if (dotControl) {
				let fakeSelected = dotControl.querySelector('.fake-selected');

				if (!fakeSelected) {
					fakeSelected = root.document.createElement('li');
					fakeSelected.classList.add('fake-selected');
				} else {
					fakeSelected.remove();
				}

				let insertBeforIndex = selectedImage >= 2 ? selectedImage - 2 : 0;

				if (insertBeforIndex > dotControl.childNodes.length - 4) {
					insertBeforIndex = dotControl.childNodes.length - 4;
				}

				dotControl.insertBefore(fakeSelected, dotControl.childNodes[insertBeforIndex]);
			}
		}
	}, [selectedImage, imageSet]);

	if (!imageSet && images && images.length) {
		galleryImages = images.map(img => img.fullPath);
	}

	const defaultResponsiveImage: ResponsiveImage = {
		defaultSrc: 'width=768',
		srcSet: ['width=768', 'width=1024 2x'],
	};

	let imageData = isFullscreen ? fullScreenResponsiveImage : responsiveImage;

	if (!imageData) {
		imageData = defaultResponsiveImage;
	}

	const srcSetCreator = srcSet => imageUrl =>
		srcSet.reduce((acc, src) => `${acc}${acc.length ? ', ' : ''}${imageUrl}&${src}`, '');
	const getSrcSet = srcSetCreator(imageData.srcSet);

	const GalleryImages = (galleryImages || []).map((imageUrl, index) => {
		const encodedImageUrl = encodeURI(imageUrl);
		const imageSrcSet = getSrcSet(encodedImageUrl);
		return (
			<LazyLoad
				unmountIfInvisible
				once
				placeholder={<img alt='' src={`${galleryImages[0]}&${imageData.defaultSrc}`} srcSet={imageSrcSet} />}
				key={`${index}-${encodedImageUrl}-${isFullscreen}`}
			>
				<AspectRatio ratio={aspectRatio}>
					<img alt='' key={index + 1} src={`${encodedImageUrl}&${imageData.defaultSrc}`} srcSet={imageSrcSet} />
				</AspectRatio>
			</LazyLoad>
		);
	});

	if (children) {
		GalleryImages.splice(0, 0, children as JSX.Element);
	}

	if (!hasInteraction) {
		GalleryImages.fill(<img key={Math.E} src={`${galleryImages[0]}&${imageData.defaultSrc}`} alt='' />, 1);
	}
	const multipleImageGallery = galleryImages.length > 1;

	return (
		<ImageCarouselWrapper ref={containerRef}>
			<ImageCarousel
				style={{ maxWidth: '100%', width: '300px' }}
				ref={gallery}
				{...rest}
				gallerySize={GalleryImages.length}
				selectedItem={selectedImage}
				dotControlWidth={galleryDCWidth}
				showStatus={false}
				showThumbs={false}
				onChange={image => setSelectedImage(image)}
				infiniteLoop
				emulateTouch={multipleImageGallery}
				backgroundImage={galleryImages[0]}
				showNavigation={multipleImageGallery}
				darkTheme={darkTheme}
			>
				{GalleryImages}
			</ImageCarousel>
			{isFullscreenShown && (
				<FullscreenSwitch
					toggleFullscreen={toggleFullscreen as Function}
					isFullscreen={isFullscreen}
					darkTheme={darkTheme}
				/>
			)}
			{isCaptionShown && <ImageCaptions {...images[selectedImage]} darkTheme={darkTheme} />}
			{isTourButtonShown && <TourPreviewButton showTourPreview={showTourPreview} darkTheme={darkTheme} />}
			{isTourPreviewVisible && (
				<TourPreview image={images[selectedImage]} hideTourPreview={hideTourPreview as Function} />
			)}
		</ImageCarouselWrapper>
	);
};
