import { CuratedTripApiRequest } from '../interfaces';
import { SortDirection, Sort } from '../enums';
import { PAGE_SIZE } from './constants';

export interface State {
	initialData: any;
	isLoading: boolean;
	noResults: boolean;
	filterParams: CuratedTripApiRequest;
	lastRequestedParams: Partial<CuratedTripApiRequest>;
	lastAppliedFilter: string;
	searchResults: Record<string, any>;
	resultsSet: string[];
	tripsAvailableCount: number;
	tripsReturnedCount: number;
	regions: any;
	filterData: any;
	containsFeatured: boolean;
	containsNew: boolean;
	destinationCount: number;
	isList: boolean;
	unitPage: any;
	unitPageDetails: any;
	currentPassUnit: string;
	isUnitPageModalOpen: boolean;
	isUnitPageModalLoading: boolean;
	unitPageContent: any;
	filtersRequestHasError: boolean;
	resultsRequestHasError: boolean;
	defaultPageContent: any[];
	filters: any; // new v2 filterParams
}

const filterParams = {
	unitSourceType: [],
	vacationStyleIds: [],
	destinationIds: [],
	regionIds: [],
	propertyIds: [],
	unitIds: [],
	sortDirection: SortDirection.Descending,
	sort: Sort.CheckInDate,
	curatedTripSetId: 11,
	minOccupancy: 1,
	minLengthOfStay: 2,
	checkInDates: [],
	skip: 0,
	pageSize: PAGE_SIZE,
};

const filters = {
	styles: [],
	parentCategory: [],
	numberOfDays: [],
	occupancy: [],
	checkInDates: [],
	locations: [],
	regions: [],
	homeName: [],
	property: [],
};

export const lastRequestedParams = {
	sort: Sort.CheckInDate,
	sortDirection: SortDirection.Descending,
	pageSize: PAGE_SIZE,
	page: 1,
	filters: {},
};

export const defaultState: State = {
	isList: false,
	initialData: {
		initialData: {
			tripFilters: {
				occupancy: [],
				regions: [],
				homes: [],
				hotels: [],
				vacationStyles: [],
				unitSources: [],
				checkInDatesList: [],
			},
		},
	},
	isLoading: false,
	noResults: false,
	filterData: {},
	filters, // new v2 filterParams
	filterParams,
	lastRequestedParams,
	lastAppliedFilter: '',
	searchResults: {},
	resultsSet: [],
	tripsAvailableCount: 0,
	tripsReturnedCount: 0,
	regions: [],
	containsFeatured: true,
	containsNew: true,
	destinationCount: 0,
	unitPage: {},
	unitPageDetails: {},
	currentPassUnit: null,
	isUnitPageModalOpen: false,
	isUnitPageModalLoading: false,
	unitPageContent: [],
	resultsRequestHasError: false,
	filtersRequestHasError: false,
	defaultPageContent: [],
};
