import React, { FC, memo, useState, useRef, useEffect, useCallback, useMemo } from 'react';
import styled, { css } from 'styled-components';
import Highlight from 'react-highlighter';

import { Subtext } from 'components/Subtext';
import { textTiny, textBase, bold } from '../../../src/design-system/style-mixins/text';
import { white, textColorDark, gray, black, paleBlue, grayDark } from '../../../src/design-system/variables/colors';
import { spaceBase, spaceTiny, spaceSmall } from '../../../src/design-system/variables/spaces';
import { transition100ms } from '../../design-system/style-mixins/animations';
import { CheckboxGroup } from './CheckboxGroup';
import { Icon } from '../Icon';
import { CheckBoxProps } from './Checkbox.props';

const CheckboxContainer = styled.div<{ isActive?: boolean }>`
	width: 100%;
	margin: ${spaceTiny} auto;
	pointer-events: none;
	${props => props.theme.checkboxOuterContainer}
	${props =>
		props.isActive &&
		css`
			${CheckboxControls} {
				background-color: ${paleBlue};
			}
			[data-is-parent] {
				background-color: ${paleBlue} !important;
			}
			[role='option'] {
				> * {
					background-color: white;
				}
			}
		`}
`;

export const CheckboxControls = styled.label`
	align-items: flex-start;
	display: flex;
	pointer-events: all;
	${props => props.theme.checkboxContainer}

	input[type='checkbox'] {
		flex: 1 0 auto;
		display: flex;
		align-items: center;
		justify-content: center;
		height: ${spaceBase};
		width: ${spaceBase};
		margin: 1px ${spaceSmall} 0 0;
		border: 1px solid ${gray};
		vertical-align: baseline;
		background: ${white};

		border-radius: none;
		outline: none;
		-webkit-appearance: none;

		&:indeterminate,
		&:checked {
			background: ${black};
			border-color: ${black};
			color: ${white};
			${textTiny}
			text-align: center;
		}

		&:indeterminate {
			&::after {
				content: '—';
			}
		}

		&:checked {
			&::after {
				content: '✓';
			}
		}

		&:disabled {
			& + div {
				color: ${gray};
				span {
					color: ${gray};
				}

				.highlight {
					color: ${gray};
				}
			}
		}

		&:checked:disabled {
			background: ${gray};
			border-color: ${gray};
		}
	}
`;

const CheckboxIcon = styled(Icon)<{ isOpen: boolean }>`
	transform: rotate(90deg);
	${transition100ms};

	${props =>
		props.isOpen &&
		css`
			transform: rotate(-90deg);
		`}
`;

const CheckboxLabel = styled.div<{ hasSubLabel: boolean, isGuestModal: boolean }>`
	display: inline-flex;
	justify-content: space-between;
	color: ${props => props.isGuestModal ? grayDark : textColorDark}}
	pointer-events: all;
	width: 100%;
	outline: none;

	${CheckboxIcon} {
		align-self: center;
	}
	span {
		line-height: 1.2;
	}

	> span {
		${props =>
			props.hasSubLabel &&
			css`
				margin-top: -${spaceTiny};
			`}
	}

	.highlight {
		${bold}
		background-color: inherit;
	}
	${props => props.theme.checkboxLabel}
`;

const NestedCheckboxGroup = styled(CheckboxGroup)`
	padding: ${spaceSmall} ${spaceBase} 0;

	> ${CheckboxControls} {
		margin: 0.25rem auto;
		width: 100%;
	}

	${props => props.theme.nestedCheckboxGroup}
`;

export const Checkbox: FC<CheckBoxProps> = memo(
	({
		member,
		onChange,
		onExpandedChange,
		isSelected,
		searchWord,
		highlighted,
		isParentLabelIncluded = false,
		...rest
	}) => {
		const [isOpen, setIsOpen] = useState(member.expanded);
		const checkboxRef = useRef(null);
		const isHighlighted = highlighted && highlighted.label === member.label;
		const selectedCount = useMemo(
			() =>
				member.children
					? member.children.reduce((total, child) => {
							if (child.checked && !child.disabled) {
								return total + 1;
							}
							return total;
					  }, 0)
					: 0,
			[member],
		);
		const defaultFormatter = label => (member.children ? `(All ${label})` : label);
		const formattedLabel = member.labelFormatter
			? member.labelFormatter(member.label)
			: defaultFormatter(member.label);
		const handleExpand = useCallback(() => {
			setIsOpen(!isOpen);
			if (onExpandedChange && member.expanded !== undefined) {
				onExpandedChange(member.id, !isOpen);
			}
		}, [isOpen, onExpandedChange, member]);
		const ParentLabel = (
			<CheckboxLabel onClick={handleExpand} data-is-parent tabIndex='0' data-id={member.id}>
				{`${member.label} ${selectedCount > 0 ? `(${selectedCount})` : ``}`}
				<CheckboxIcon iconName='arrow' isOpen={isOpen} iconSize='md' />
			</CheckboxLabel>
		);

		const handleChange = useCallback(() => {
			onChange(member);
			member.checked = !member.checked;
		}, [onChange, member]);

		useEffect(() => {
			if (member.expanded !== isOpen) {
				setIsOpen(member.expanded);
			}
		}, [member.expanded]);

		const checkboxControls = (
			<CheckboxControls htmlFor={member.label} isHighlighted={isHighlighted}>
				<input
					type='checkbox'
					id={member.label}
					ref={checkboxRef}
					disabled={member.disabled}
					value={member.id}
					checked={isSelected || member.checked}
					onChange={handleChange}
				/>
				<CheckboxLabel hasSubLabel={Boolean(member.subLabel)} isGuestModal={member.id === 'acknowledgement'}>
					<span>
						<Highlight search={searchWord}>{formattedLabel}</Highlight>
						{member.subLabel && (
							<Subtext>
								<Highlight search={searchWord}>{member.subLabel}</Highlight>
							</Subtext>
						)}
					</span>
				</CheckboxLabel>
			</CheckboxControls>
		);

		return (
			<CheckboxContainer {...rest}>
				{!member.children ? checkboxControls : ParentLabel}

				{member.children && isOpen && (
					<NestedCheckboxGroup
						onCheckboxClick={onChange}
						members={member.children}
						selected={0}
						searchWord={searchWord}
						highlighted={highlighted}
						isParentLabelIncluded={isParentLabelIncluded}
					>
						{checkboxControls}
					</NestedCheckboxGroup>
				)}
			</CheckboxContainer>
		);
	},
);
