import React from 'react';
import styled from 'styled-components';

import { getSafeHtml } from 'services/Helpers';
import { xsOnly } from 'design-system/media-queries';
import { FAQProps } from './FAQProps';
import { spaceBig, spaceSmall } from '../../design-system/variables/spaces';
import { richText } from '../../design-system/utils';
import { Headline } from '../Headline';
import { textColorMedium } from '../../design-system/variables/colors';

const Question = styled(props => <Headline {...props} />)`
	text-align: left;

	${xsOnly`
    	padding: 0px ${spaceSmall};
	`}
`;
const Answer = styled.div`
	margin-bottom: ${spaceBig};
	${richText}
	${textColorMedium}

	${xsOnly`
		p {
			margin-bottom: 0px;
		}
		padding: 0px ${spaceSmall};
	`}
`;

export const Faq = ({ answer, question }: FAQProps) => {
	return (
		<div className='col-md-offset-1 col-md-10'>
			<Question as='h4' size='small'>
				{question}
			</Question>
			<Answer dangerouslySetInnerHTML={getSafeHtml(answer)} />
		</div>
	);
};
