/* eslint-disable no-case-declarations */
import { useEffect, useRef } from 'react';
import { equals } from 'ramda';
import { Keys } from 'enums/Keyboard';

import { SearchSection } from './DestinationAutocompleteProps';

export const definePlaceholder = ({ destinations, placeholder }) => {
	if (destinations.length) {
		return '+ add';
	}

	return placeholder;
};

export const getInitialSelectedResults = ({ data, additionalSectionData }) => {
	const allData = [
		...(data.length ? data.reduce((acc, region) => [...acc, ...region.children], []) : []),
		...(additionalSectionData.length
			? additionalSectionData.reduce((acc, section) => [...acc, ...section.data], [])
			: []),
	];

	return allData.length ? allData.filter(location => location.checked) : [];
};

export const defineIsUniqueResultsMatch = ({ searchResults }) =>
	searchResults.length === 1 && searchResults[0].children.length <= 1;

export const checkIfItemLabelMatchesKeyword = (item, keyword) =>
	item.label.toLowerCase().includes(keyword.toLowerCase());

const updateCheckedStates = selectedDestinations =>
	function updateResults(results) {
		return results.map(result => ({
			...result,
			checked: Boolean(selectedDestinations.find(destination => destination.label === result.label)),
			children: result.children ? (result.children = updateResults(result.children)) : null,
		}));
	};

export const updateResultsCheckedState = ({ results = [], destinations, additionalResults = [] }) => {
	const allResults = {};

	const updateStates = updateCheckedStates(destinations);

	if (results.length) {
		allResults.updatedDestinations = updateStates(results);
	}

	if (additionalResults.length) {
		allResults.updatedAdditional = additionalResults.map(resultSet => ({
			...resultSet,
			data: updateStates(resultSet.data),
		}));
	}
	return allResults;
};

export const filterSearchResults = ({ keyword, initialSearchResults, destinations }) => {
	const filteredResults = keyword
		? initialSearchResults.reduce((acc, current) => {
				const regionChildrenMatchKeyword = current.children.find(child =>
					checkIfItemLabelMatchesKeyword(child, keyword),
				);
				if (regionChildrenMatchKeyword) {
					const childrenMatchKeyword = current.children.filter(child =>
						checkIfItemLabelMatchesKeyword(child, keyword),
					);
					acc.push({ ...current, children: childrenMatchKeyword, expanded: true });
				}

				return acc;
		  }, [])
		: initialSearchResults;

	const { updatedDestinations } = updateResultsCheckedState({ results: filteredResults, destinations });
	return updatedDestinations;
};

export const getDestinationIndex = ({ selected, destinations }) =>
	destinations.findIndex(destination => destination.label === selected.label);

export const addDestination = ({ destinations, selected, setDestinations }) => {
	let filteredDestinations = [];

	if (selected.childrenRegions) {
		filteredDestinations = destinations.filter(
			destination => !selected.childrenRegions.find(child => destination.label === child.label),
		);
	} else {
		filteredDestinations = destinations.filter(destination => {
			if (destination.childrenRegions) {
				return !destination.childrenRegions.find(child => child.label === selected.label);
			}
			return true;
		});
	}

	setDestinations([...filteredDestinations, selected]);
};

export const usePrevProps = value => {
	const ref = useRef();

	useEffect(() => {
		ref.current = value;
	});

	return ref.current;
};

const transformRegionsToIncludeMajorValues = regions =>
	regions.map(region => ({
		id: region.id,
		children: region.children.map(child => child.id),
	}));

export const checkIsMajorRegionsChange = ({ previousRegions = [], regions }) => {
	const prev = transformRegionsToIncludeMajorValues(previousRegions);
	const current = transformRegionsToIncludeMajorValues(regions);

	return !equals(prev, current);
};

export const getFirstRegionInExpandedParent = ({ regions }) => {
	const firstExpandedParentRegion = regions.find(region => region.expanded);

	return firstExpandedParentRegion && firstExpandedParentRegion.children[0];
};

const onUpDownPressed = ({ searchResults, additionalResults, activeId, isVisible, openDropdown, setActiveId, e }) => {
	e.preventDefault();
	const menuOptionsId = [];

	searchResults.forEach(parentRegion => {
		menuOptionsId.push(parentRegion.label);
		if (parentRegion.expanded) {
			parentRegion.children.forEach(child => {
				const label = child.label === parentRegion.label ? child.labelFormatter(child.label) : child.label;
				menuOptionsId.push(label);
			});
		}
	});
	const allResults = [
		...menuOptionsId,
		...additionalResults.reduce((acc, section) => [...acc, ...section.data.map(item => item.label)], []),
	];
	const activeIndex = allResults.findIndex(id => activeId === id); // TODO: What if it's -1
	const lastIndex = allResults.length - 1;
	let nextIndex;

	if (e.key === Keys.ArrowUp) {
		nextIndex = activeIndex === 0 ? lastIndex : activeIndex - 1;
	} else {
		if (!isVisible) {
			openDropdown(true);
		}
		nextIndex = activeIndex === lastIndex ? 0 : activeIndex + 1;
	}

	setActiveId(allResults[nextIndex]);
};

const onBackspacePressed = ({ value, destinations, activeId, removeDestination, setActiveId }) => {
	if (!value && destinations.length) {
		if (activeId && activeId.startsWith('selected-')) {
			const destinationIndex = destinations.findIndex(
				destination => `selected-${destination.label}` === activeId,
			);

			removeDestination(destinationIndex);
			setActiveId(null);
		} else {
			const lastIndex = destinations.length - 1;
			const lastDestination = destinations[lastIndex];

			setActiveId(`selected-${lastDestination.label}`);
		}
	}
};

const onEnterPressed = ({
	activeId,
	searchResults,
	additionalResults = [],
	updateRegionsExpandedState,
	selectResult,
}) => {
	if (!activeId || activeId.startsWith('selected-')) {
		return;
	}

	if (additionalResults.length) {
		let selected = null;
		additionalResults.forEach(section => {
			const match = section.data.find(item => item.label === activeId);
			if (section.data.length && match) {
				selected = match;
			}
		});

		if (selected) {
			if (selected.disabled) {
				return;
			}
			selectResult(selected);
			return;
		}
	}

	const parentRegion = searchResults.find(region => region.label === activeId);

	if (parentRegion) {
		updateRegionsExpandedState(+parentRegion.id, !parentRegion.expanded);
	} else {
		const flattenRegions = searchResults.map(result => [...result.children]).flat();
		const region = flattenRegions.find(result => {
			const label = result.childrenRegions ? result.labelFormatter(result.label) : result.label;

			return label === activeId;
		});
		if (region.disabled) {
			return;
		}

		selectResult(region);
	}
};

const onLeftRightPressed = ({ e, destinations, activeId, value, setActiveId }) => {
	if (value || !destinations.length) {
		return;
	}
	const pillsId = destinations.map(destination => `selected-${destination.label}`);
	const currentIndex = pillsId.findIndex(id => id === activeId);
	const lastIndex = destinations.length - 1;
	const isPillSelected = activeId && activeId.startsWith('selected-');

	if (e.key === Keys.ArrowLeft) {
		const prevIndex = currentIndex - 1 < 0 ? 0 : currentIndex - 1;
		const nextIndex = isPillSelected ? prevIndex : lastIndex;

		setActiveId(pillsId[nextIndex]);
	} else if (e.key === Keys.ArrowRight && isPillSelected) {
		const nextIndex = currentIndex === lastIndex ? null : pillsId[currentIndex + 1];

		setActiveId(nextIndex);
	}
};

export const handleKeyDownUtil = ({
	updateRegionsExpandedState,
	removeDestination,
	searchResults,
	additionalResults,
	openDropdown,
	selectResult,
	destinations,
	setActiveId,
	isVisible,
	activeId,
	value,
	e,
}) => {
	switch (e.key) {
		case Keys.ArrowUp:
		case Keys.ArrowDown:
			// TODO: add back in once checkbox highlighting can be added back.
			// onUpDownPressed({ searchResults, additionalResults, activeId, isVisible, openDropdown, setActiveId, e });
			break;
		case Keys.Backspace:
			onBackspacePressed({ value, destinations, activeId, removeDestination, setActiveId });
			break;
		case Keys.ArrowLeft:
		case Keys.ArrowRight:
			onLeftRightPressed({ e, destinations, activeId, value, setActiveId });
			break;
		case Keys.Enter:
			// TODO: add back in once checkbox highlighting can be added back.
			// onEnterPressed({ activeId, searchResults, additionalResults, updateRegionsExpandedState, selectResult });
			break;
		default:
			break;
	}
};

export const filterSections = (sections: SearchSection[]) => searchVal => {
	const search = searchVal.toLowerCase();

	return sections.map(section =>
		searchVal.length >= section.minSearchChars
			? {
					...section,
					data: section.data.filter(item =>
						section.searchFields.some(field =>
							item[field] ? item[field].toLowerCase().includes(search) : false,
						),
					),
			  }
			: { ...section, data: [] },
	);
};
