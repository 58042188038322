import { createSelector } from 'reselect';
import { State } from '../context';

export const getAllUnitDetails = (state: State) => state.unitDetails;
const getUnitId = (state, unitId) => unitId;

export const unitDetailsSelector = createSelector(
	[getAllUnitDetails, getUnitId],
	(allUnitDetails, unitId) => {
		return allUnitDetails[unitId];
	},
);
