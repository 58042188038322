/* eslint-disable no-shadow */
import React, { FC, useContext, useState, useEffect } from 'react';
import { InputProps as FormInputProps } from 'components/Form/Input/Input.types';

import { Input } from 'components/Form/Input/Input';
import { FormContext } from '../FormContext';
import { displayNameMapper } from './utils';

export interface InputProps extends FormInputProps {
	type: string;
	label: string;
	setValue: Function;
	onErrorFixed?: Function;
}

export const EnhancedInput: FC<InputProps> = ({ label, setValue, value, helpText, onBlur, onErrorFixed, ...rest }) => {
	const { isErrorShown } = useContext(FormContext);
	const [wasFocused, setWasFocused] = useState(false);
	const [error, setErrorMessage] = useState('');
	const isHelpTextShown = isErrorShown || wasFocused;

	const handleInput = e => {
		setValue(e.target.value);
	};

	const handleBlur = () => {
		setWasFocused(true);
		onBlur && onBlur();
	};

	const onInputValidationHandler = (domElement, isValid, invalidMessage) => {
		if (!domElement) {
			return;
		}
		const customValidity =
			invalidMessage && invalidMessage.length > 0
				? // eslint-disable-next-line no-template-curly-in-string
				  invalidMessage.replace('${displayName}', displayNameMapper[domElement.name])
				: invalidMessage;
		setErrorMessage(customValidity);
		domElement.dataset.isInvalid = isValid ? '' : false;
	};

	useEffect(() => {
		if (!error && value) {
			onErrorFixed && onErrorFixed();
		}
	}, [error]);

	return (
		<Input
			hideLabel
			{...rest}
			value={value}
			placeholder={label}
			hasError={Boolean(isHelpTextShown && error)}
			helpText={helpText || (isHelpTextShown && error)}
			onInput={handleInput}
			onValidation={onInputValidationHandler}
			onBlur={handleBlur}
		/>
	);
};
