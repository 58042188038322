import React from 'react';
import { useSwipeable } from 'react-swipeable';
import styled, { css } from 'styled-components';

import { CarouselProps } from './CarouselProps';
import { CarouselControls } from './CarouselEnums';
import { useCarousel } from './useCarousel';

export const Wrapper = styled.div`
	width: 100%;
	overflow: hidden;
`;

export const CarouselContainer = styled.div<{ sliding: boolean; dir: string }>`
	display: flex;
	width: 100%;
	transition: ${props => (props.sliding ? 'none' : 'transform 1s ease')};
	transform: ${props => {
		if (!props.sliding) {
			return 'translateX(calc(-80% - 20px))';
		}
		if (props.dir === CarouselControls.PREV) {
			return 'translateX(calc(2 * (-80% - 20px)))';
		}
		return 'translateX(0%)';
	}};
`;

export const CarouselSlot = styled.div<{ order: number; position: number; total: number }>`
	flex: 1 0 100%;
	flex-basis: 80%;
	margin-right: 20px;
	order: ${props => props.order};
	${props =>
		props.position === props.total &&
		props.order === 1 &&
		css`
			margin-right: 100%;
		`}
`;

const getOrder = ({ index, pos, numItems }) => {
	return index - pos < 0 ? numItems - Math.abs(index - pos) : index - pos;
};

export const Carousel: React.FC<CarouselProps> = ({ children }) => {
	const [state, dispatch] = useCarousel();
	const numItems = React.Children.count(children);
	const slide = dir => {
		dispatch({ type: dir, numItems });
		setTimeout(() => {
			dispatch({ type: CarouselControls.STOP });
		}, 50);
	};
	const handlers = useSwipeable({
		onSwipedLeft: () => slide(CarouselControls.NEXT),
		onSwipedRight: () => slide(CarouselControls.PREV),
		preventDefaultTouchmoveEvent: true,
		trackMouse: true,
	});

	return (
		<div {...handlers}>
			<Wrapper>
				<CarouselContainer dir={state.dir} sliding={state.sliding}>
					{React.Children.map(children, (child, index) => (
						<CarouselSlot
							key={index}
							position={state.pos}
							total={numItems - 1}
							order={getOrder({ index, pos: state.pos, numItems })}
						>
							{child}
						</CarouselSlot>
					))}
				</CarouselContainer>
			</Wrapper>
		</div>
	);
};
