import styled from 'styled-components';
import { Title } from 'components/Title';
import { Subtext } from 'components/Subtext';
import { textBase, textMedium } from '../../../design-system/style-mixins/text';
import { textColorMedium, white, textColorDark, dividerGrayLight } from '../../../design-system/variables/colors';
import { spaceBetween, spaceBase, spaceTiny, spaceSmall } from '../../../design-system/variables/spaces';
import { boxShadow2 } from '../../../design-system/style-mixins/shadows-and-scrims';
import { TripPriceStyleProps } from './DetailCard.types';

export const DetailCardWrapper = styled.article`
	${boxShadow2}

	background: ${white};
	display:flex;
	flex-direction: column;
	justify-content: space-between;
	max-width: 385px;
	width: 100%;

	@media print {
		img {
			display: none;
		}
	}
`;

export const UnitDetails = styled.section`
	padding: ${spaceBase};
`;

export const DetailCardTitle = styled(Title)`
	margin: ${spaceSmall} 0;
	margin-bottom: ${spaceBase};
`;

export const DetailCardSubtitle = styled(Subtext)`
	${textBase}
	overflow: hidden;
	text-overflow: ellipsis;
	margin: ${spaceSmall} 0;
	margin-bottom: ${spaceBase};
	white-space: nowrap;
`;

export const Specs = styled.section`
	${textBase}

	border-bottom: ${dividerGrayLight};
	display: flex;
	justify-content: space-between;
	margin-bottom: ${spaceBase};
	padding-bottom: ${spaceBase};
`;

export const Spec = styled.section``;

export const UnitOfMeasure = styled.abbr`
	${textColorMedium}

    margin-left: ${spaceTiny};
    text-transform: lowercase;
`;

export const TripPrice = styled.div<TripPriceStyleProps>`
	${textMedium}
	${textColorDark}
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	border-top: ${(props) => (props.hasBorder ? dividerGrayLight : 'none')};
	padding-top: ${(props) => (props.hasPadding ? spaceBetween(spaceSmall, spaceBase) : 'none')};

	p {
		font-style: italic;
		font-weight: 100;
		margin: ${spaceSmall} 0 0 0;
	}

	span {
		${textBase}
		${textColorMedium}
	}
`;
