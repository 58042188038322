import { useEffect, useState } from 'react';
import root from 'window-or-global';

// A hook that helps to load script files that is not possible to include as a ES6 module
export const useScript = (
	src: string = '',
	id: string = new Date().getTime().toString(),
	domElement?: HTMLElement,
): boolean[] => {
	const [state, setState] = useState([false, false]);

	const load = () => {
		if (!src || src.length === 0) {
			setState([false, true]);
			return;
		}

		const script = root.document.createElement('script');
		script.async = true;
		script.id = id;
		script.src = src;

		const onScriptLoad = () => {
			setState([true, false]);
		};

		const onScriptError = () => {
			script.remove();

			setState([true, true]);
		};

		script.addEventListener('load', onScriptLoad);
		script.addEventListener('error', onScriptError);

		const parent = domElement || root.document.head;
		parent.appendChild(script);

		// remove event listeners on cleanup
		return () => {
			script.removeEventListener('load', onScriptLoad);
			script.removeEventListener('error', onScriptError);
			script.remove();
		};
	};

	useEffect(load, [src, id, domElement]); // Re-run if change

	return state;
};

/**
 * Workaround in order to open a link via JS based on provided url and newWindow props.
 * see for details https://stackoverflow.com/a/28374344/1061438
 * @param href
 * @param newWindow
 */
export const openLink = (href: string, newWindow: boolean = false): void => {
	const target = newWindow ? '_blank' : '_self';
	Object.assign(root.document.createElement('A'), { target, href }).click();
};

export const getHighestNumber = (arrayOfNumbers: number[]): number => {
	return Math.max.apply(null, arrayOfNumbers);
};

export const getSafeHtml = (dirtyHtmlStr: string): { __html: string } => {
	return {
		__html: dirtyHtmlStr,
	};
};

export const sendGAPageview = () => {
	if (root['ga'] !== undefined) {
		root['ga']('send', {
			hitType: 'pageview',
			page: root.location.pathname + root.location.search,
		});
	}
};
