import styled, { css } from 'styled-components';
import { textTiny } from '../../design-system/style-mixins/text';
import {
	black,
	blackHover,
	disabled,
	focusOutlineColor,
	tapTransparent,
	textColorDark,
	textColorDisabled,
	white,
	whitePressed,
	primaryLinkColor,
	primaryLinkColorHover,
} from '../../design-system/variables/colors';
import { spaceBase, spaceBig, spaceMedium } from '../../design-system/variables/spaces';
import { allCapsStyle } from '../AllCaps/AllCaps';

/* Button Sizes */

const large = css`
	padding: 15px ${spaceBig};
`;

const base = css`
	padding: 11px ${spaceMedium};
`;

const small = css`
	padding: 3px ${spaceBase};
`;

/* Button Styles */

const buttonTextStyles = css`
	${allCapsStyle}
	${textTiny}
	${textColorDark}
	line-height: 16px;
`;

const minimal = css`
	${buttonTextStyles}
	background: transparent;
	border-color: transparent;
	color: ${primaryLinkColor};
	fill: ${primaryLinkColor};

	&:hover {
		color: ${primaryLinkColorHover};
		fill: ${primaryLinkColorHover};
	}
`;

const primary = css`
  ${buttonTextStyles}
	background: ${black};
	border-color: ${black};
	color: ${white};

	&:hover {
		background: ${blackHover};
		border: 1px solid ${blackHover};
		color: ${white};
	}

	&:active {
		background: ${black};
		color: ${white};
	}
`;

const primaryInvert = css`
${buttonTextStyles}
	background: ${white};
	border-color: ${white};

	&:visited {
		${textColorDark}

		&:hover {
			color: ${white};
		}
	}

	&:hover {
		background: ${blackHover};
		border-color: ${blackHover};
		color: ${white};
	}

	&:active {
		background: ${black};
		color: ${white};
	}
`;

const secondary = css`
	${buttonTextStyles}
	background: transparent;
	border-color: ${black};

	&:visited {
		${textColorDark}
	}

	&:hover {
		background: ${blackHover};
		border-color: ${blackHover};
		color: ${white};
	}

	&:active {
		background: ${black};
		color: ${white};
	}
`;

const secondaryInvert = css`
	${buttonTextStyles}
	background: transparent;
	border-color: ${white};
	color: ${white};

	&:visited {
		color: ${white};
	}

	&:hover {
		background-color: ${white};
		${textColorDark}
	}

	&:active {
		background-color: ${whitePressed};
		border-color: ${whitePressed};
		${textColorDark}
	}
`;

const isDisabled = css`
	&,
	&:hover {
		${textColorDisabled}
		background-color: ${disabled};
		border-color: ${disabled};
		cursor: not-allowed;
	}
`;

const sizeStyles = {
	large,
	base,
	small,
};

const typeStyles = {
	minimal,
	primary,
	primaryInvert,
	secondary,
	secondaryInvert,
};

interface ButtonProps {
	buttonType: buttonTypes;
	size?: buttonSizes;
	state?: string;
	isOpen?: boolean;
}

export const Button = styled.button<ButtonProps>`
	${tapTransparent}
	cursor: pointer;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border-style: solid;
	border-width: 1px;
	outline: none;
	text-align: center;
	text-decoration: none;
	line-height: 1.5;
	transition: color 100ms ease-out, border-color 100ms ease-out, background-color 100ms ease-out, fill 100ms ease-out;

	&:hover {
		text-decoration: none;
	}

	&:active:not(:disabled) {
		transform: translateY(1px);
		transition-duration: 0s;
	}

	&:disabled {
		${isDisabled}
	}

	&[data-focus-visible-added] {
		outline: 2px solid ${focusOutlineColor};
		outline-offset: 2px;
	}

	${props => sizeStyles[props.size] || sizeStyles.base}
	${props => typeStyles[props.buttonType]}
`;

type buttonTypes = 'minimal' | 'primary' | 'primaryInvert' | 'secondary' | 'secondaryInvert' | 'dropdown';
export type buttonSizes = 'large' | 'base' | 'small';
