import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './locales/en/translation.json';
import translationES from './locales/es/translation.json';
import translationCA from './locales/en-CA/translation.json';

export const locales = {
	CanadianEnglish: 'en-CA',
	English: 'en',
	Spanish: 'es',
};

i18n.use(initReactI18next).init({
	resources: {
		[locales.English]: { translation: translationEN },
		[locales.Spanish]: { translation: translationES },
		[locales.CanadianEnglish]: { translation: translationCA },
	},
	fallbackLng: locales.English,
});

export { i18n };
