import { createContext } from 'react';

export const Context = createContext({
	activeId: null,
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	setActiveId: (id: string | null): void => {},
	isMenuOpen: false,
	menuId: null,
	isMulti: false,
});
