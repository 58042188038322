import React from 'react';
import { Headline } from 'components/Headline';
import { Link } from 'components/Link';
import { Paragraph } from 'components/Paragraph';
import { ContactWidgetDisplayOptions } from '../ContactWidgetProps';
import { ContactWidgetWrapper } from '../ContactWidget.styles';

export const PublicUserContactWidget: React.FC<ContactWidgetDisplayOptions> = ({ card }) => (
	<ContactWidgetWrapper card={card}>
		<Headline size='small'>Questions? We're here to help.</Headline>

		<Paragraph size='base'>Our expert travel advisors will help you find the perfect trip.</Paragraph>
		<Link href='/contact-us' color='primary'>
			Contact Us
		</Link>
	</ContactWidgetWrapper>
);
