import * as React from 'react';
import styled, { css } from 'styled-components';
import { Icon } from 'components/Icon/Icon';
import { md } from '../../design-system/media-queries';
import { fontSans, textSmall } from '../../design-system/style-mixins/text';
import { blue, gray, itemHover, tapTransparent, textColorDark } from '../../design-system/variables/colors';
import { spaceLarge, spaceMedium, spaceBase } from '../../design-system/variables/spaces';
import { transition100ms } from '../../design-system/style-mixins/animations';
import { truncateText } from '../../design-system/utils';

const colors = {
	blue: {
		default: blue,
		hover: blue,
	},
	lightGray: {
		default: '#f0f4f8',
		hover: 'rgba(187,206,217,.25)',
	},
};

const isActive = css`
	&& {
		background: ${blue};
		border: transparent 1px solid;

		&:hover,
		&:active {
			background: ${blue};
			border: transparent 1px solid;
		}
	}
`;

const statusStyles = {
	isActive,
};

const BasePill = styled.a<{ state?: pillStates }>`
	border-style: initial; /* Override to avoid default button style */
	box-sizing: border-box;
	cursor: pointer;
	display: inline-block;
	line-height: 16px;
	outline: none;
	text-align: center;
	text-decoration: none;
	${transition100ms}
	user-select: none;
    ${textSmall}
    ${fontSans}
    ${textColorDark}
	${props => statusStyles[props.state]}
	${tapTransparent}

    &:visited {
        ${textColorDark}

        &:hover {
            ${textColorDark}
        }
    }

    &:hover,
    &:active {
        text-decoration: none;
        ${textColorDark}
    }
`;

const Large = styled(props => <BasePill {...props} />)`
	padding: 11px ${spaceLarge};
	border-radius: 20px;
	border: ${gray} 1px solid;

	${(props: { isSelected: boolean }) =>
		props.isSelected &&
		css`
			${isActive}
		`}

	${md`
		&:hover {
			background-color: ${itemHover};
			border: transparent 1px solid;
		}
	`}

	&:active {
		background-color: ${itemHover};
		border: transparent 1px solid;
	}
`;

const Small = styled(props => <BasePill {...props} />)`
	padding: 3px ${spaceBase};
	white-space: nowrap;
	max-width: 7rem;
	position: relative;
	${truncateText}
	background: ${props => colors[props.color].default};
	:hover {
		background: ${props => colors[props.color].hover};
	}
`;

const CloseIcon = styled.div`
	position: absolute;
	left: 0;
	top: 0;
	width: ${spaceMedium};
	height: ${spaceMedium};
	display: flex;
	align-items: center;
	justify-content: center;
`;

const Text = styled.span<{ offsetLeft: boolean }>`
	margin-left: ${props => (props.offsetLeft ? '4px' : 0)};
`;

export const Pill = ({ size, color = 'blue', withCloseIcon, onIconClick, children, ...rest }) => {
	const pills = {
		large: Large,
		small: Small,
	};

	const ThemedPill = pills[size || 'large'];

	return (
		<ThemedPill {...rest} color={color}>
			{withCloseIcon && (
				<CloseIcon onClick={onIconClick}>
					<Icon buttonType='containerless' iconName='close' iconSize='sm' width='12px' height='12px' />
				</CloseIcon>
			)}
			<Text offsetLeft={withCloseIcon}>{children}</Text>
		</ThemedPill>
	);
};

type pillStates = 'isActive';
