import React, { FC } from 'react';
import { PostMessageStyled } from './PostMessage.styles';

const SuccessMessage: FC = () => (
	<PostMessageStyled>
		<p>We've received your inquiry!</p>
		<p>Thanks for reaching out. A member of our team will respond to you within one business day.</p>
	</PostMessageStyled>
);

export default SuccessMessage;
