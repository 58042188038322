export enum Constants {
	id = 'password',
	name = 'Password',
}

export const PASSWORD_RULES = [
	'Must be a minimum of 8 characters.',
	'Must include an uppercase letter, a lowercase letter and a number.',
	'Special characters may be used (~, !, #, %, ^, &, *).',
];
