import React, { useState } from 'react';
import styled from 'styled-components';
import { PressReleaseListingProps } from './PressReleaseListingProps';
import { xsOnly } from '../../design-system/media-queries';
import { spaceLarge } from '../../design-system/variables/spaces';
import { PressReleaseProps } from './PressRelease/PressReleaseProps';
import { PressRelease } from './PressRelease';
import { TextButton } from '../TextButton';

const Table = styled.table`
	width: 100%;
	border-collapse: collapse;
	margin-bottom: ${spaceLarge};
	text-align: left;

	${xsOnly`
		tbody {
			width: auto;
			display: block;
		}
	`};
`;

const ViewMore = styled(props => <TextButton {...props} />)`
	margin: auto;
`;

const viewMoreItemsCount = 5;
export const PressReleaseListing: React.FunctionComponent<PressReleaseListingProps> = ({
	pressReleases,
	componentName,
	...rest
}) => {
	const [visibleItems, setVisibleItems] = useState(Math.min(viewMoreItemsCount, pressReleases.length));
	const viewMoreClicked = (): void => {
		const newEndIndex = Math.min(pressReleases.length, visibleItems + viewMoreItemsCount);
		setVisibleItems(newEndIndex);
	};

	return (
		<React.Fragment>
			<Table>
				<tbody>
					{pressReleases.slice(0, visibleItems).map((pressRelease: PressReleaseProps, index) => (
						<PressRelease {...pressRelease} key={index} {...rest} />
					))}
				</tbody>
			</Table>
			{visibleItems < pressReleases.length && (
				<ViewMore iconName='arrow' iconSize='md' onClick={viewMoreClicked}>
					View more
				</ViewMore>
			)}
		</React.Fragment>
	);
};
