/* eslint-disable no-shadow */
import React, { FC, useState, useEffect, Fragment, useContext } from 'react';
import styled, { css } from 'styled-components';
import { spaceTiny } from 'design-system/variables/spaces';
import { sm } from 'design-system/media-queries';
import { Select } from 'components/Form/Select';
import { Phone } from '../Phone';
import { EnhancedInput } from '../Input';
import { Countries, Constants, CountriesWithPostalCode, PostalCode } from './enums';
import { FormContext } from '../FormContext';

const PostalCodeWrapper = styled.div`
	&& {
		${sm`
            padding-right: ${spaceTiny}
        `}
	}
`;
const PhoneWrapper = styled.div<{ isPostalCodeShown: boolean }>`
	&& {
		${sm`
            ${props =>
				props.isPostalCodeShown &&
				css`
					padding-left: ${spaceTiny};
				`}
        `}
	}
`;

export const Country: FC<{}> = () => {
	const { validationRules, setFieldValue, t, state } = useContext(FormContext);
	const [country, selectCountry] = useState({
		key: null,
		value: '',
	});
	const [code, setCode] = useState('');
	const isPostalCodeShown = Boolean(CountriesWithPostalCode[country.key]);

	useEffect(() => {
		setFieldValue({
			[Constants.name]: country.key,
			[PostalCode.name]: code,
		});
	}, [country.key, code]);

	useEffect(() => {
		if (state.CountryID) {
			const newCountry = Countries.find(item => item.key === state.CountryID);
			selectCountry(newCountry);
		}
	}, [state.CountryID]);

	return (
		<Fragment>
			<div className='row' data-node='select-country'>
				<div className='col-xs-12'>
					<Select
						onChange={selectCountry}
						options={Countries}
						value={country}
						hideLabel
						labelText='Country'
					/>
				</div>
			</div>
			<div className='row'>
				{isPostalCodeShown && (
					<PostalCodeWrapper className='col-xs-12 col-sm-6'>
						<EnhancedInput
							type='text'
							id={PostalCode.id}
							name={PostalCode.name}
							label={t('zip')}
							value={code}
							validationRules={validationRules.POSTAL_CODE}
							setValue={setCode}
						/>
					</PostalCodeWrapper>
				)}
				<PhoneWrapper
					isPostalCodeShown={isPostalCodeShown}
					className={`col-xs-12 ${isPostalCodeShown ? 'col-sm-6' : 'col-sm-12'}`}
				>
					<Phone />
				</PhoneWrapper>
			</div>
		</Fragment>
	);
};
