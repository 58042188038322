import React from 'react';
import { Button } from 'components/Button';
import styled from 'styled-components';
import { CallToActionProps } from './CallToActionProps';

const ButtonCustomized = styled(Button)<{ href: string; target: string }>``;

export const ActionLink: React.FunctionComponent<CallToActionProps> = (props: CallToActionProps) => {
	const { url, newWindow, linkTitle, canDisplayAsTextLink, secondary, ...rest } = props;
	
	return (
		canDisplayAsTextLink && (
			<ButtonCustomized
				as={!url.startsWith('?filter=') && 'a'}
				href={url}
				target={newWindow ? '_blank' : '_self'}
				buttonType={secondary ? 'secondary' : 'primary'}
				{...rest}
			>
				{linkTitle}
			</ButtonCustomized>
		)
	);
};
