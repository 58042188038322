import { Keys } from 'enums/Keyboard';
import { transformSearchResultsToMenuOptionsIds } from 'components/SearchResultsSet/utils';
import { UnitOverviewJSON } from './services/TreadstoneApi';
import { UnitParentCategory, AccentMap, SortedCategories, RegExp } from './enums';
import { SearchType } from './TripFinder.types';

const keyMap = {
	[SearchType.VacationStyles]: 'type',
	[SearchType.Destinations]: 'destinations',
	[SearchType.Regions]: 'regions',
};

const sortByTextAsc = (a, b) => (a.text < b.text ? -1 : 1);

export const getMappedOptionsServerSupports = options =>
	options.map(c => ({
		...c,
		key: keyMap[c.type],
		value: c.id.toString(),
	}));

const isExperience = (unit: UnitOverviewJSON) =>
	unit.UnitCategory.UnitParentCategory.UnitParentCategoryID === UnitParentCategory.Experiences;

export const isUnitThatTripFinderCanDisplay = (unit: UnitOverviewJSON) => unit.Country && !isExperience(unit);

const getStates = unitsThatTripFinderCanDisplay =>
	unitsThatTripFinderCanDisplay
		.filter(u => !!u.State)
		.reduce((set, unit) => {
			set.add(unit.State);
			return set;
		}, new Set<string>());

const getCountries = unitsThatTripFinderCanDisplay =>
	unitsThatTripFinderCanDisplay
		.filter(u => !!u.Country)
		.reduce((set, unit) => {
			set.add(unit.Country);
			return set;
		}, new Set<string>());

export const getStatesOptions = unitsThatTripFinderCanDisplay => {
	const states = getStates(unitsThatTripFinderCanDisplay);

	return Array.from(states)
		.map(state => ({
			id: -1,
			key: 'states',
			value: state.replace(RegExp.NOT_LETTERS, '').toLocaleLowerCase(),
			text: state,
			type: SearchType.StatesProvinces,
		}))
		.sort(sortByTextAsc);
};

export const getCountriesOptions = unitsThatTripFinderCanDisplay => {
	const countries = getCountries(unitsThatTripFinderCanDisplay);

	return Array.from(countries)
		.map(country => ({
			id: -1,
			key: 'countries',
			value: country.replace(RegExp.NOT_LETTERS, '').toLocaleLowerCase(),
			text: country,
			type: SearchType.Countries,
		}))
		.sort(sortByTextAsc);
};

export const getUnitsThatTripFinderCanDisplay = productData => productData.Units.filter(isUnitThatTripFinderCanDisplay);

export const sanitizeString = (inputString: string) => {
	let str = inputString
		.toLocaleLowerCase()
		.replace(RegExp.MULTIPLE_SPACES, '')
		.trim()
		.replace(RegExp.SPECIAL_SYMBOLS, '');

	let normalizedString = '';
	for (const character of str) {
		normalizedString += AccentMap[character] || character;
	}
	str = normalizedString;

	return str;
};

const transformDataForSearchResultsSet = ({ header, searchCriteria }) => {
	const items = searchCriteria.map(item => ({
		...item,
		name: item.text,
	}));

	return {
		category: header,
		items,
	};
};

export const getOptionsMatchingSearch = (allResults, searchQuery) =>
	SortedCategories.map(sort => {
		sort.searchCriteria = allResults.filter(i => {
			const sanitizedOption = sanitizeString(i.text);
			const sanitizedSearch = sanitizeString(searchQuery);

			return i.type === sort.type && sanitizedOption.indexOf(sanitizedSearch) >= 0;
		});

		return sort;
	})
		.filter(category => category.searchCriteria.length > 0)
		.sort((l, r) => l.sortOrder - r.sortOrder)
		.map(transformDataForSearchResultsSet);

const onUpDownPressed = ({ toggleResultsVisibility, isResultsVisible, searchResults, setActiveId, activeId, e }) => {
	const menuOptionsId = transformSearchResultsToMenuOptionsIds(searchResults, 'value');
	const activeIndex = menuOptionsId.findIndex(id => activeId === id);
	const lastIndex = menuOptionsId.length - 1;
	let nextIndex;

	if (e.key === Keys.ArrowUp) {
		nextIndex = activeIndex === 0 ? lastIndex : activeIndex - 1;
	} else {
		if (!isResultsVisible) {
			toggleResultsVisibility(true);
		}
		nextIndex = activeIndex === lastIndex ? 0 : activeIndex + 1;
	}

	setActiveId(menuOptionsId[nextIndex]);
};

const onEnterPressed = ({ activeId, selectDestination, searchResults }) => {
	if (activeId) {
		const selected = searchResults
			.map(category => category.items)
			.flat()
			.find(item => item.value === activeId);

		selectDestination(selected.text, selected);
	}
};

export const handleKeyDownUtil = ({
	toggleResultsVisibility,
	selectDestination,
	isResultsVisible,
	searchResults,
	setActiveId,
	activeId,
	e,
}) => {
	switch (e.key) {
		case Keys.ArrowUp:
		case Keys.ArrowDown:
			onUpDownPressed({ toggleResultsVisibility, isResultsVisible, searchResults, setActiveId, activeId, e });
			break;
		case Keys.Enter:
			onEnterPressed({ activeId, selectDestination, searchResults });
			break;
		default:
			break;
	}
};
