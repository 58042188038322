import { useState, useEffect } from 'react';
import { useInView } from './useInView';

export const useAnimateOnView = () => {
	const [ref, isVisible] = useInView();
	const [isAnimated, setAnimated] = useState(false);

	useEffect(() => {
		if (!isAnimated && isVisible) {
			setAnimated(true);
		}
	}, [isVisible]);

	return [ref, isAnimated];
};
