import root from 'window-or-global';
import qs from 'qs';

/**
 * Sets query params for sharing search results
 *
 * @param paramsHash - hash given from the server representing filterParams
 */
export const updateQueryParams = (paramsHash = '') => {
	const parsedQuery = qs.parse(root.location.search, { ignoreQueryPrefix: true });
	delete parsedQuery.filter;
	const stringQuery = qs.stringify(parsedQuery);

	const search = `${stringQuery ? `?${stringQuery}` : ''}${stringQuery && paramsHash && '&'}${
		paramsHash && !stringQuery ? '?' : ''
	}${paramsHash ? `filter=${paramsHash}` : ''}`;
	const newQuery = `
			${root.location.pathname}
			${search}
		`;

	root.history.pushState(null, '', newQuery);
};

/**
 * Sets query params for sharing unit page
 *
 * @param rid - rid of residence
 * @param tripDetails - object containing checkin, checkout, and value
 * @param unitURL - url path of unit in cms
 */
export const setUnitPageQueryParams = (rid: string, tripDetails, unitURL: string) => () => {
	const parsedQuery = qs.parse(root.location.search, { ignoreQueryPrefix: true });
	const { filter } = parsedQuery;

	const tripDetailsQuery = Object.entries(tripDetails).reduce((acc, entry) => `${acc}&${entry[0]}=${entry[1]}`, '');

	const newQuery = `
			${root.location.pathname}
			${filter ? `?${qs.stringify({ filter })}&` : `?`}
			${`rid=${rid}&unitURL=${unitURL}${tripDetailsQuery}`}
	`;

	if (root.STORYBOOK_ENV) {
		root.parent.history.pushState(null, '', newQuery);
	} else {
		root.history.pushState(null, '', newQuery);
	}
};

/**
 * Removes query params for sharing unit page
 */
export const removeUnitPageQueryParams = () => () => {
	const parsedQuery = qs.parse(root.location.search, { ignoreQueryPrefix: true });
	const { filter } = parsedQuery;
	const newQuery = `
		${root.location.pathname}
		${filter ? `?${qs.stringify({ filter })}` : ''}
	`;

	if (root.STORYBOOK_ENV) {
		root.parent.history.pushState(null, '', newQuery);
	} else {
		root.history.pushState(null, '', newQuery);
	}
};
