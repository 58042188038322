import React, { FC, useState } from 'react';
import root from 'window-or-global';
import { Button } from 'components/Button';
import { Modal } from 'components/Modal';
import { Title } from 'components/Title';
import { Link } from 'components/Link';
import { CloseButton, Copy, SignInSection, SecondarySection, modalStyles } from './RequestToBookCta.styles';
import { UserSpecificContent } from '../../enums/UserSpecificContent';

interface R2BCtaProps {
	userType: number;
	rid: string;
	checkInDate: string;
	checkOutDate: string;
	useModal?: boolean;
	buttonText?: string;
}

export const RequestToBookCta: FC<R2BCtaProps> = ({
	userType,
	rid,
	checkInDate,
	checkOutDate,
	useModal,
	buttonText,
	...rest
}) => {
	const [displayModal, setDisplayModal] = React.useState(false);
	const [signInUrl, setSignInUrl] = useState('');
	const toggleModal = () => setDisplayModal(!displayModal);
	if (!rid) {
		return null;
	}

	const handleClick = () => {
		const filterHash = encodeURIComponent(new URLSearchParams(root.location.search).get('filter'));
		const requestToBookUrl = `/request-to-book/?rid=${rid}&checkindate=${checkInDate}&checkoutDate=${checkOutDate}&filters=${filterHash}`;
		const signInPath = `/signin/?ReturnUrl=${encodeURIComponent(requestToBookUrl)}`;
		setSignInUrl(signInPath);

		if (useModal && userType === UserSpecificContent.Public) {
			toggleModal();
		} else if (userType === UserSpecificContent.Public) {
			root.open(signInPath, '_blank');
		} else {
			root.open(requestToBookUrl, '_blank');
		}
	};

	let learnMoreUrl = '/pass/booking-request/';

	if (userType === UserSpecificContent.RegisteredUser) {
		learnMoreUrl = '/pass/reg/booking-request/';
	}

	return (
		<>
			<Button buttonType='primary' size='base' onClick={handleClick} {...rest}>
				{buttonText || 'Request to book'}
			</Button>

			{signInUrl && (
				<Modal maxWidth='640px' contentStyles={modalStyles} isOpen={displayModal} onRequestClose={handleClick}>
					<CloseButton onClick={() => toggleModal()} />
					<SignInSection>
						<Title size='large'>Sign in to book with Inspirato Pass</Title>
						<Copy>In order to request to reserve this trip, please sign in below.</Copy>
						<Link color='white' href={signInUrl}>
							<Button buttonType='primary' size='large'>
								Sign In
							</Button>
						</Link>
					</SignInSection>
					<SecondarySection>
						<Title size='medium'>Interested in Pass?</Title>
						<Copy>
							Not a passholder, but still interested in this trip? Click below to learn more about
							Inspirato Pass.
						</Copy>
						<Link color='white' href={learnMoreUrl}>
							<Button buttonType='secondary' size='large'>
								Learn More
							</Button>
						</Link>
					</SecondarySection>
				</Modal>
			)}
		</>
	);
};
