import React from 'react';
import styled, { css } from 'styled-components';
import { Paragraph } from 'components/Paragraph';
import { Title } from 'components/Title';
import { sm } from 'design-system/media-queries';
import { textSmall } from '../../design-system/style-mixins/text';
import { textColorMedium, white } from '../../design-system/variables/colors';
import { spaceBase, spaceLarge, spaceSmall, spaceBetween, spaceBig } from '../../design-system/variables/spaces';
import { boxShadow2 } from '../../design-system/style-mixins/shadows-and-scrims';
import { ContactWidgetDisplayOptions } from './ContactWidgetProps';

export const CopyWrapper = styled.div`
	align-self: stretch;
	display: flex;
	flex-direction: column;
	padding-top: ${spaceLarge};

	a {
		display: block;
	}
`;

export const ContactWidgetWrapper = styled.article<ContactWidgetDisplayOptions>`
	background: ${white};
	max-width: 385px;

	img {
		display: block;

		${(props) => css`
			${props.imgSize == 'lg' && 'width: inherit'};
			${props.imgSize == 'sm' && 'width: 65%'};
		`}
	}

	${textColorMedium}

	align-items: center;
	display: flex;
	flex-direction: column;
	text-align: center;
	width: 100%;

	${(props) =>
		props.card &&
		css`
			padding: ${spaceBig};
			${boxShadow2}
			${(props.imgSize == 'full' || props.headline) && 'padding-top: 0;'}
		`}

	${(props) =>
		props.headline &&
		css`
			span:first-child {
				margin: ${spaceBetween(spaceLarge, spaceBase)} 0 ${spaceBase};
			}
		`}

	${(props) =>
		props.row &&
		css`
			padding: ${spaceBase};
			padding-top: 0;

			${sm`
				flex-direction: row;
				max-width: none;
				padding: 0;
				width: min-content;

				${CopyWrapper} {
					justify-content: space-around;
					min-width: 300px;
				}
			`}

			${CopyWrapper} {
				padding: ${spaceBase};
			}

			img {
				height: 100%;
			}
		`}

	> p {
		${textSmall}
		margin: ${spaceSmall} 0 ${spaceBase};

		&:last-of-type {
			margin-bottom: 0;
		}
	}

	@media print {
		img {
			display: none;
		}
	}
`;

export const ContactWidgetTeamTitle = styled((props) => <Title {...props} size='small' />)`
	margin-top: ${spaceBase};
`;

export const HourBlock = styled((props) => <Paragraph {...props} size='small' />)`
	margin: -2px 0;
`;

export const ContactWidgetDetails = styled.section`
	margin: ${spaceBase};
`;
