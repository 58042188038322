import React, { FC, memo, useCallback, ReactNode } from 'react';
import styled from 'styled-components';
import { UnitSource } from 'pages/CuratedTrips/interfaces';
import { Checkbox } from './Checkbox';

interface Props {
	members: UnitSource[];
	onCheckboxClick?: Function;
	selectCondition?: Function;
	selected?: number;
	children?: ReactNode;
	onExpand?: Function;
	searchWord?: string;
	isParentLabelIncluded?: boolean;
}

const CheckboxGroupContainer = styled.div`
	align-items: flex-start;
	display: flex;
	flex-flow: column;
	width: 100%;
	${props => props.theme.checkboxGroupContainer}
`;

export const CheckboxGroup: FC<Props> = memo(
	({
		members,
		selected,
		selectCondition,
		onCheckboxClick,
		children,
		onExpand,
		searchWord,
		isParentLabelIncluded,
		...rest
	}) => {
		const handleExpandedChange = useCallback(
			(id, val) => {
				if (onExpand) {
					onExpand(id, val);
				}
			},
			[onExpand],
		);
		const handleClick = useCallback(
			member => {
				onCheckboxClick(member);
			},
			[onCheckboxClick],
		);
		const checkIfSelected = useCallback(value => (selectCondition ? selectCondition(value) : value === selected), [
			selected,
			selectCondition,
		]);

		return (
			<CheckboxGroupContainer {...rest}>
				{children && !isParentLabelIncluded ? children : null}

				{members.map(member => (
					<Checkbox
						key={member.label}
						size='large'
						onChange={handleClick}
						isSelected={checkIfSelected(member.id || member.label)}
						member={member}
						onExpandedChange={handleExpandedChange}
						searchWord={searchWord}
						isParentLabelIncluded={isParentLabelIncluded}
					/>
				))}
			</CheckboxGroupContainer>
		);
	},
);
