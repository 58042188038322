import { useLayoutEffect } from 'react';
import root from 'window-or-global';

export function useLockBodyScroll(state: boolean) {
	useLayoutEffect(() => {
		let scrollValue;
		const bodyStyles = root.document.body.style;

		if (state) {
			scrollValue = root.scrollY;
			bodyStyles.position = 'fixed';
			bodyStyles.overflow = 'hidden';
			bodyStyles.top = `-${scrollValue}px`;
			bodyStyles.width = '100%';
		} else {
			scrollValue = bodyStyles.top;
			bodyStyles.position = '';
			bodyStyles.overflow = '';
			bodyStyles.top = '';
			bodyStyles.width = '';
			root.scrollTo(0, parseInt(scrollValue || '0') * -1);
		}
	}, [state]);
}
