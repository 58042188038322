import React, { useState, useRef, useCallback, FormEventHandler, useEffect } from 'react';
import { StyledInputRange, Balloon, BalloonLegend, StyledThumb, StyledTrack } from './RangeSlider.styles';

export const thumbWidthPx = 24;

interface RangeSliderProps {
	max: number;
	min: number;
	step: number;
	initialValue: number;
	disabled?: boolean;
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	name?: string;
	id?: string;
	className?: string;
	style?: React.CSSProperties;
	slots?: {
		ballonLegend?: React.ReactNode;
	};
}

const forceNumber = (n: string) => {
	let parsedValue = Number(n);
	if (Number.isNaN(n) || typeof n === 'undefined') {
		parsedValue = 0;
	}
	return parsedValue;
};

export const RangeSlider = ({
	max,
	min,
	step,
	initialValue,
	onChange,
	slots: { ballonLegend },
	...nativeProps
}: RangeSliderProps) => {
	const [value, setValue] = useState(initialValue);
	const [positionPercentage, setPositionPercentage] = useState(0);
	const inputRef = useRef<HTMLInputElement>(null);

	const calculatePercentagePosition = useCallback(() => Math.round((value / max) * 100), [max, value]);

	const onInput: FormEventHandler<HTMLInputElement> = (e) => {
		const target = e.target as HTMLInputElement;
		setValue(forceNumber(target.value));
	};

	useEffect(() => {
		setPositionPercentage(calculatePercentagePosition());
	}, [value]);

	useEffect(() => {
		setValue(initialValue);
	}, [initialValue]);

	return (
		<StyledInputRange thumbWidthPx={thumbWidthPx} left={positionPercentage}>
			<Balloon left={positionPercentage}>
				{value}
				{ballonLegend && <BalloonLegend>{ballonLegend}</BalloonLegend>}
			</Balloon>
			<StyledThumb thumbWidthPx={thumbWidthPx} left={positionPercentage} />
			<StyledTrack left={positionPercentage} isDisabled={nativeProps.disabled} />
			<input
				type="range"
				ref={inputRef}
				value={value}
				onInput={onInput}
				max={max}
				min={min}
				step={step}
				onChange={onChange}
				{...nativeProps}
			/>
		</StyledInputRange>
	);
};
