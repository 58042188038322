import styled, { css } from 'styled-components';
import { spaceBase, spaceSmall, spaceTiny, spaceMedium, spaceBig } from 'design-system/variables/spaces';
import { transparentDark10 } from 'design-system/style-mixins/shadows-and-scrims';
import { textBase, fontSans, bold, textSmall, textTiny } from 'design-system/style-mixins/text';
import {
	textColorDark,
	textColorLight,
	grayMedium,
	grayLight,
	primaryLinkColor,
	mint,
} from 'design-system/variables/colors';
import { Dispatch, SetStateAction } from 'react';
import { brokenImg16x9, truncateText } from 'design-system/utils';
import { Subtext } from 'components/Subtext';
import { xsSmOnly, md } from 'design-system/media-queries';
import { Icon } from 'components/Icon';
import { Button } from 'components/Button';
import { RequestToBookCta } from 'components/RequestToBookCta';

export const ListCardContainer = styled.div<{ isList?: boolean; isPlaceholder?: boolean }>`
	display: flex;
	flex-direction: ${props => (props.isList ? 'row' : 'column')};
	justify-content: ${props => props.isList && 'space-between'};
	margin-bottom: ${spaceBase};
	box-shadow: 0 1px 4px ${transparentDark10};
`;

export const MainContainer = styled.main<{ isList: boolean }>`
	${textBase}
	${fontSans}
	${textColorDark}
	display: flex;
	align-items: center;
	flex-direction: column;
	flex-flow: row wrap;
	text-align: left;
	user-select: none;
	padding: 0;
	max-width: 100%;
	width: 100%;
`;

export const ImageContainer = styled.header<{
	isList?: boolean | Dispatch<SetStateAction<boolean>>;
	isPlaceholder?: boolean;
}>`
	position: relative;
	overflow: hidden;

	&::before {
		content: '';
		display: block;
		padding-bottom: 56.25%;
	}

	> :first-child {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	${props =>
		props.isList &&
		css`
			max-width: 282px;
			width: 100%;
			padding-bottom: 0;
		`}
`;

export const Image = styled.img<{ src?: string; isList?: boolean; isDesktop?: boolean }>`
	background-color: ${transparentDark10};
	background-image: url(${props => props.src}), url(${brokenImg16x9});
	background-position: center;
	background-size: cover;
	display: block;
	object-fit: cover;
	height: 100%;
	position: absolute;
	top: 0;
	z-index: -1;
`;

export const TitleContainer = styled.div<{
	hasPropertyName?: boolean;
	isList?: boolean | Dispatch<SetStateAction<boolean>>;
}>`
	${textBase}
	display: flex;
	flex-direction: column;
	flex: 1 0 0;
	margin-right: ${spaceBase};
	overflow: hidden;
	border: 0;
`;

export const CardTitleWrapper = styled.div`
	display: flex;
	align-items: center;

	> *:not(:last-child) {
		margin-right: ${spaceSmall};
	}
`;

export const CardTitle = styled.div`
	${textColorDark}
	${bold}
	${truncateText}
  	cursor: pointer;

	a:hover,
	a:focus {
		text-decoration: none;
	}
`;

export const Subtitle = styled.div`
	${textColorLight}
	${textSmall}
	${truncateText}
	line-height: 1.2;
`;

export const StatsLabel = styled(props => <Subtext {...props} />)`
	${textColorLight}
	font-weight: normal;

	${xsSmOnly`
		${textColorLight}
	`};
`;

export const SpecsLabel = styled(props => <StatsLabel {...props} />)`
	margin-left: ${spaceTiny};
	${textSmall}
`;

export const ValueLabel = styled(props => <Subtext {...props} />)<{
	isList?: boolean | Dispatch<SetStateAction<boolean>>;
}>`
	${textColorLight}
	margin-right: 0 !important;
	white-space: nowrap;
`;

export const SpecsContainer = styled.div<{ isList?: boolean | Dispatch<SetStateAction<boolean>> }>`
	${textTiny}
	display: flex;
	order: 4;
	align-items: center;
	margin-top: ${spaceBase};
	width: 100%;
	${bold}
	justify-content: flex-start;

	${props =>
		props.isList &&
		css`
			${md`
				${textBase}
				margin-top: 0;
				font-weight: normal;
				flex: 1 0 0;
				order: initial;
				> div + div {
					margin-left: ${spaceMedium}
				}
			`};
		`};
`;

export const SpecsIcon = styled(props => <Icon {...props} />)`
	overflow: visible;
	fill: ${grayMedium};
	height: ${spaceBase};
	width: ${spaceBase};
`;

export const ItemContainer = styled.div``;

export const CardMain = styled.section`
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	justify-content: space-between;
	padding: ${spaceBase} ${spaceBase} ${spaceBase};
	width: 100%;
`;

export const Specs = styled(props => <ItemContainer {...props} />)`
	display: flex;
	${textBase}
	flex-direction: row;
	align-items: baseline;
`;

export const CardFooter = styled.section<{ isList?: boolean | Dispatch<SetStateAction<boolean>> }>`
	${textTiny}
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	background-color: ${grayLight};
	padding: ${spaceBase};
	width: 100%;

	${props =>
		!props.isList &&
		css`
			> * + * {
				margin-top: ${spaceBase};
			}
		`}

	${props =>
		props.isList &&
		css`
			${md`
				flex-direction: row;
			`}
		`}
`;

export const CardFooterInner = styled.div<{ isList?: boolean; isPlaceholder?: boolean }>`
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	width: 100%;

	a,
	button {
		flex: 1 1 auto;
	}

	${props =>
		props.isList &&
		css`
			${md`
				width: auto;
				justify-content: initial;

				> a + a {
					margin-left: ${spaceBase};
				}
			`}
		`}

	${props =>
		props.isPlaceholder &&
		css`
			> * {
				opacity: 0;
			}
		`}
`;

export const ValueContainer = styled.div<{ isList?: boolean | Dispatch<SetStateAction<boolean>> }>`
	${textBase}
	align-items: center;
	display: flex;
	justify-content: flex-start;
	margin-left: auto;
	width: 100%;
	order: 3;
	text-align: right;
	flex: 0;

	${props =>
		props.isList &&
		css`
			${md`
				order: initial;
				width: auto;
				min-width: 60px;
				justify-content: flex-end;
			`}
		`}

	span {
		margin-right: ${spaceTiny};
	}

	svg {
		fill: ${primaryLinkColor};
	}
`;

export const FeaturedLabel = styled.div`
	${fontSans}
	${textTiny}
	${bold}
	background: ${mint};
	position: absolute;
	left: 12px;
	top: 6px;
	line-height: 1;
	padding: ${spaceTiny} ${spaceSmall};
	text-transform: uppercase;
`;

export const Date = styled.div<{ rightAlign?: boolean }>`
	${textBase}

	${props =>
		props.rightAlign &&
		css`
			text-align: right;
		`}
`;

export const ArrowIcon = styled(props => <Icon {...props} />)<{ isList?: boolean; isPlaceholder?: boolean }>`
	fill: ${grayMedium};

	${props =>
		props.isList &&
		css`
			${md`
				margin-left: ${spaceBig};
				margin-right: ${spaceBig};
			`}
		`}

	${props =>
		props.isPlaceholder &&
		css`
			opacity: 0;
		`}
`;

export const ButtonCustomized = styled(Button)<{ target?: string; href?: string }>``;

export const StyledR2BCta = styled(props => <RequestToBookCta {...props} />)`
	flex: 1 1 auto;
`;
