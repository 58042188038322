import React from 'react';

import { DropdownSelectProps } from './Dropdown.types';
import { SelectContainer, SelectItem } from './Dropdown.styles';

export const DropdownSelect: React.FC<DropdownSelectProps> = ({ items, handleClick, current, className }) => (
	<SelectContainer className={className}>
		{items.map(item => (
			<SelectItem key={item.key} enableToggle={current === item.key} onClick={() => handleClick(item)}>
				{item.value}
			</SelectItem>
		))}
	</SelectContainer>
);
