import { UserValues, OfflineChatPost } from 'pages/Chat/Chat.Interfaces';

export const BuildPostObject = (values: UserValues): OfflineChatPost => {
	return {
		description: values.description,
		email: values.email || values.emailAddress,
		external: true,
		name: values.name || `${values.firstName} ${values.lastName}`,
		retURL: 'www.inspirato.com', // needs to be served up to the page
		webProspectId: values.isPublic ? null : values.accountUserId,
	};
};

export const BuildUrlEncodedFormData = (jsonObject: object): string => {
	let urlEncodedObj = '';
	const jsonObjectKeys = Object.keys(jsonObject);
	jsonObjectKeys.forEach((x, i) => {
		if (i === 0) {
			urlEncodedObj += `${x}=${encodeURI(jsonObject[x])}`;
		}

		if (i > 0) {
			urlEncodedObj += `&${x}=${encodeURI(jsonObject[x])}`;
		}
	});
	return urlEncodedObj;
};
