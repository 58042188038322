import React, { FC, useState, useEffect, useRef, Fragment } from 'react';
import smoothscroll from 'smoothscroll-polyfill';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { GridWrap } from 'components/GridWrap';
import { Pagination } from 'components/Pagination';
import { useIsDesktop } from 'components/hooks';
import { initializeExperiences } from './redux/actions';
import { ResultsCount } from './ResultsCount';
import { Sort } from './Sort';
import { Filters } from './Filters';
import { Results } from './Results';
import { ExperienceCard } from './types';
import { spaceMedium, spaceHuge, spaceMassive, spaceSuperMassive } from '../../design-system/variables/spaces';
import { sm, md, lg } from '../../design-system/media-queries';
import { NoResults } from './NoResults';
import { setOffset } from './redux/actions/pagination';
import { getOffset, getTotalExperienceCount } from './redux/selectors';
import { PAGE_SIZE } from './redux/constants';

interface Props {
	data: ExperienceCard[];
}

const ResultsHeader = styled.div`
	margin: ${spaceMedium} 0;

	${sm`
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-direction: row-reverse;
	`}
`;

const ListingWrapper = styled(props => <GridWrap {...props} />)`
	padding-bottom: ${spaceHuge};

	${md`
		padding-bottom: ${spaceMassive};
	`}
	${lg`
		padding-bottom: ${spaceSuperMassive};
	`}
`;

export const Listing: FC<Props> = ({ data }) => {
	// Redux Hooks
	const dispatch = useDispatch();
	const totalCount = useSelector(state => getTotalExperienceCount(state));
	const offset = useSelector(state => getOffset(state));
	const [isDesktop] = useIsDesktop(768);
	const [resultsChangedCount, setResultsChangedCount] = useState(0);
	const mounted = useRef(null);
	const [hasSearched, setHasSearched] = useState(false);
	// React Hooks
	const listingRef = useRef(null);

	const scrollToTop = () => {
		listingRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
	};

	const handleResultsChanged = () => {
		setResultsChangedCount(resultsChangedCount + 1);
	};

	const handleUpdate = value => {
		if (offset !== value) {
			setResultsChangedCount(resultsChangedCount + 1);
			dispatch(setOffset(value));
		}
	};

	useEffect(() => {
		smoothscroll.polyfill();
		dispatch(initializeExperiences(data));
	}, []);

	useEffect(() => {
		if (!mounted.current) {
			mounted.current = true;
		} else if (!hasSearched) {
			setHasSearched(true);
		} else {
			scrollToTop();
		}
	}, [resultsChangedCount]);

	return (
		<div ref={listingRef}>
			<Filters />
			<ListingWrapper width='base'>
				<ResultsHeader>
					{totalCount > 0 && isDesktop && <Sort />}
					<ResultsCount />
				</ResultsHeader>
				{totalCount > 0 ? (
					<>
						<Results onResultsChanged={handleResultsChanged} />
						{totalCount > PAGE_SIZE && (
							<Pagination
								simple={false}
								hideRangeLabel
								title='Sample Title'
								pageSize={PAGE_SIZE}
								total={totalCount}
								pageNeighbors={1}
								offset={offset}
								onPageUpdate={handleUpdate}
								style={{ justifyContent: 'center' }}
							/>
						)}
					</>
				) : (
					<NoResults />
				)}
			</ListingWrapper>
		</div>
	);
};
