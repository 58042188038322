import axios from 'axios';

import { SearchCriteria } from '../TripFinder.types';
import { fetchProductData } from './TreadstoneApi';
import {
	getUnitsThatTripFinderCanDisplay,
	getMappedOptionsServerSupports,
	getStatesOptions,
	getCountriesOptions,
} from '../utils';

const instance = axios.create({
	headers: {
		Authorization:
			'Bearer MDlhNWFlNWI5LWM4OWUtNDJlZS1iODk3LTJiZTM4ZmU3NjBmNXw2MzU5NTQ3NDU1OTI3MzMzNjN8Mnwy%7c6C9NbRFrzmd53MLx%2bRGQXZZ5k6cxwRuj0PNTyHB9BDw%3d',
	},
});

export const fetchSearchResults = async (searchCriteriaUrl: string) => {
	const productData = await fetchProductData();
	const searchResults = await instance
		.get(searchCriteriaUrl)
		.then(response => response.data as Promise<SearchCriteria[]>);

	const optionsTheServerActuallySupports = getMappedOptionsServerSupports(searchResults);
	const unitsThatTripFinderCanDisplay = getUnitsThatTripFinderCanDisplay(productData);
	const stateOptions = getStatesOptions(unitsThatTripFinderCanDisplay);
	const countryOptions = getCountriesOptions(unitsThatTripFinderCanDisplay);

	return [...optionsTheServerActuallySupports, ...stateOptions, ...countryOptions];
};
