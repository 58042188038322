import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { PRICING_AVAILABILITY_CHECK_INITIATED, PRICING_AVAILABILITY_CHECK_COMPLETED } from '../constants';
import { ProposalAccommodationElement, DateRange, UnitPricingAvailabilityResponse } from '../../../../interfaces';
import { State } from '../context';
import { checkUnitPricingAvailability } from '../../../../services/dryflyApiService';

export const checkPricingAvailability = (accommodations: ProposalAccommodationElement[]) => async (
	dispatch: ThunkDispatch<State, undefined, Action>,
) => {
	dispatch({ type: PRICING_AVAILABILITY_CHECK_INITIATED });

	const responses: UnitPricingAvailabilityResponse[] = [];

	for (let i = 0; i < accommodations.length; i++) {
		const dateRanges: DateRange[] = accommodations[i].accommodationDateRanges.map(r => {
			return {
				endDate: r.checkOutDate,
				startDate: r.checkInDate,
			};
		});

		try {
			const pricingAvailabilityResponse = await checkUnitPricingAvailability(
				Number(accommodations[i].residenceId),
				dateRanges,
			);

			responses.push(pricingAvailabilityResponse);
		} catch (err) {
			alert(err);
		}
	}

	dispatch({ type: PRICING_AVAILABILITY_CHECK_COMPLETED, payload: { responses } });
};
