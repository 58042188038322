import React, { FC } from 'react';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { experiencesReducer } from './redux/reducers';
import { Listing } from './Listing';
import { ExperienceCard } from './types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const composeEnhancers = composeWithDevTools({ name: 'pass-experiences' }) || compose;
const middleware = [thunk];
const store = createStore(experiencesReducer, composeEnhancers(applyMiddleware(...middleware)));

interface Props {
	experienceCards: ExperienceCard[];
}

export const ExperienceCardListing: FC<Props> = ({ experienceCards }) => {
	return <Provider store={store}>{experienceCards && <Listing data={experienceCards} />}</Provider>;
};
