import React, { FC } from 'react';
import styled, { css } from 'styled-components';

import { black } from 'design-system/variables/colors';
import { IconButton } from 'components/Icon/IconButton';

const StyledIcon = styled(props => <IconButton {...props} />)`
	${props =>
		props.theme.isDropdownOpen &&
		css`
			svg {
				fill: ${black};
			}
		`}
`;

export const SearchIcon: FC<{}> = () => <StyledIcon buttonType='containerless' iconName='search' iconSize='md' />;
