import styled from 'styled-components';
import { white, black } from 'design-system/variables/colors';
import { spaceBig, spaceSmall, spaceBase } from 'design-system/variables/spaces';
import { IconButton, Button } from 'components/';
import { boxShadow2 } from 'design-system/style-mixins/shadows-and-scrims';
import { xsSmOnly, md } from 'design-system/media-queries';

export const OfflineChatModalStyled = styled.div<{ isOpen: boolean }>`
	align-items: center;
	justify-content: center;
	overflow-y: hidden;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 10000001;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: ${props => (props.isOpen ? 'flex' : 'none')};
`;

export const ModalHeader = styled.div`
	background-color: ${black};
	display: flex;
	height: ${spaceBig};
	justify-content: space-between;
	padding: ${spaceSmall};
	padding-left: ${spaceBase};
`;

export const ModalContent = styled.div`
	${boxShadow2};
	background-color: ${white};
	${xsSmOnly`
		width:100%;
		margin: 0 10%;
	`};
	${md`
		width:50%;
	`};
`;

export const ModalBody = styled.div`
	padding: ${spaceBase};
`;

export const CloseButton = styled(props => <IconButton {...props} />)`
	padding-bottom: ${spaceSmall};
	svg {
		fill: ${white};

		&:hover {
			fill: ${white};
		}
	}
`;

export const SubmitButton = styled(props => <Button {...props} />)`
	width: 100%;
`;
