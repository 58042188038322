import { FC, useContext, ReactNode, cloneElement } from 'react';

import { Context } from '../Context';

interface Props {
	children: ReactNode;
	label: string;
}

export const AccessibleSelectedOption: FC<Props> = ({ children, label }) => {
	const { activeId } = useContext(Context);
	const attributes = {
		role: 'option',
		'aria-selected': true,
		id: `selected-${label}`,
		isActive: activeId === `selected-${label}`,
	};

	return cloneElement(children, { ...attributes });
};
