export enum TripFinder {
	Placeholder = 'Where would you like to go?',
}

export const SortedCategories = [
	{ type: 3, header: 'Vacation Styles', sortOrder: 1, searchCriteria: [] },
	{ type: 1, header: 'Destinations', sortOrder: 5, searchCriteria: [] },
	{ type: 2, header: 'Regions', sortOrder: 2, searchCriteria: [] },
	{ type: 4, header: 'Countries', sortOrder: 3, searchCriteria: [] },
	{ type: 5, header: 'States & Provinces', sortOrder: 4, searchCriteria: [] },
	{ type: 6, header: 'Hotels &amp; Resorts', sortOrder: 6, searchCriteria: [] },
	{ type: 7, header: 'Accommodations', sortOrder: 7, searchCriteria: [] },
	{ type: 8, header: 'Experiences', sortOrder: 8, searchCriteria: [] },
];

export enum UnitParentCategory {
	Residences = 1,
	SuitesRooms = 2,
	Experiences = 3,
}

export const AccentMap = {
	á: 'a',
	ã: 'a',
	// tslint:disable-next-line:object-literal-sort-keys
	â: 'a',
	Á: 'A',
	Â: 'A',
	è: 'e',
	é: 'e',
	É: 'E',
	È: 'E',
	í: 'i',
	ñ: 'n',
	Ñ: 'n',
	ó: 'o',
	Ó: 'o',
	Ô: 'o',
	ô: 'o',
};

export const RegExp = {
	NOT_LETTERS: /[^a-zA-Z ]/g,
	SPECIAL_SYMBOLS: /[,.'’]/g,
	MULTIPLE_SPACES: / +(?= )/g,
};
