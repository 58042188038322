import styled, { css } from 'styled-components';
import { textColorDark, dividerGrayLight, white } from 'design-system/variables/colors';
import { spaceLarge, spaceBig, spaceBase } from 'design-system/variables/spaces';
import { IconButton } from 'components/Icon';
import { Paragraph } from 'components/Paragraph';
import { xs } from '../../design-system/media-queries';

export const modalStyles = css`
	position: relative;
	align-items: center;
	display: flex;
	justify-content: center;
	flex-direction: column;
	text-align: center;
	padding: ${spaceLarge} ${spaceBig};
	height: 100vh;

	${xs`
		position: static;
		height: auto;
		width: calc(100% - ${spaceBig});
	`}
`;

export const Copy = styled(Paragraph)`
	${textColorDark}

	text-align: center;
`;

export const SignInSection = styled.section`
	align-items: center;
	border-bottom: ${dividerGrayLight};
	display: flex;
	justify-content: center;
	flex-direction: column;
	padding: ${spaceLarge} 0;
`;

export const SecondarySection = styled.section`
	max-width: 480px;
	align-items: center;
	display: flex;
	justify-content: center;
	flex-direction: column;
	padding: ${spaceLarge} 0;
`;

export const CloseButton = styled(props => (
	<IconButton buttonType='containerless' iconName='close' iconSize='md' {...props} />
))`
	position: absolute;
	top: ${spaceBase};
	right: ${spaceBase};

	${xs`
		svg,
		&:hover svg {
			fill: ${white};
		}
	`}
`;
