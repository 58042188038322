import React, { memo } from 'react';

import { NavigationProps } from './NavigationProps';

import { useIsDesktop } from '../hooks';

import { PrimaryNavigation } from './PrimaryNavigation';
import { MobileNavigationMenu } from './MobileNavigationMenu';

export const Navigation: React.FC<NavigationProps> = memo(({ links, ...rest }) => {
	const [isDesktop] = useIsDesktop();

	if (links.length !== 0) {
		// @ts-ignore
		return isDesktop ? <PrimaryNavigation links={links} {...rest} /> : <MobileNavigationMenu {...rest} />;
	}
});
