import axios from 'axios';
import { ReactConfigurationViewModel } from 'interfaces/ResponsivePageViewModel';
import { Dispatch, SetStateAction, createContext, useEffect, useState } from 'react';
import { object, string, array } from 'yup';

const tripDataSchema = object({
	tripTags: array().of(string()).required(),
	nextBookingDate: string().required(),
	numberOfDaysBeyondEndDate: string().required(),
});

const responseDataSchema = object({
	value: array().of(tripDataSchema).required(),
});

export type TripData = {
	tripTags: string[];
	isFlex: boolean;
	nextBookingDate: string;
	numberOfDaysBeyondEndDate: string;
	updatedNumberOfDaysBeyondEndDate: number;
};
export const PassTripDataContext = createContext<[TripData, Dispatch<SetStateAction<TripData>>]>(null);

export function PassTripDataProvider(props) {
	return <PassTripDataContext.Provider {...props} />;
}

export function usePassTripData(configuration: ReactConfigurationViewModel, tripId: string) {
	const [data, setData] = useState<TripData | null>(null);

	useEffect(() => {
		if (data || !tripId) return;

		axios({
			method: 'post',
			url: `${configuration.acsPassListBaseUrl}${configuration.acsPassListRequestUrl}`,
			data: {
				count: true,
				filter: `search.in(assetOptionId, '${tripId}', '|')`,
				skip: 0,
				top: 100,
			},
			headers: {
				'Api-Key': configuration.acsPassListApiKey,
			},
		})
			.then(({ data }) => {
				const validatedResponse = responseDataSchema.validateSync(data);
				const tripData = validatedResponse.value.pop();
				const isFlex = tripData.tripTags.includes('Flex');
				const nextBookingDate = tripData.nextBookingDate.split('T')[0]
				setData({ ...tripData, nextBookingDate, updatedNumberOfDaysBeyondEndDate: +tripData.numberOfDaysBeyondEndDate, isFlex });
			})
			.catch((error) => {
				console.error(error);
			});

		return () => setData(null);
	}, [tripId]);

	return [data, setData] as const;
}
