import { DIVIDER_UPDATED } from './../constants';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { getErrorMessages } from '../../../../shared/utils';
import {
	updateProposalDivider,
	createProposalDivider,
	deleteProposalElement,
} from '../../../../services/tripRecommendationsApiService';
import { ProposalDividerRequest, TripProposalElement } from '../../../../interfaces';
import { PROPOSAL_ELEMENTS_UPDATED, DIVIDER } from '../constants';
import { State } from '../context';
import { updateProposalElements } from './proposalElements';
import { clearFieldError, setFieldError } from './errors';

export const updateDivider = (id: number, label: string) => (
	dispatch: ThunkDispatch<State, undefined, Action>,
	getState: () => State,
) => {
	const { metadata, proposalElements } = getState();
	const fieldName = `${DIVIDER}_${id}`;
	const { divider } = proposalElements.find(
		(element: TripProposalElement) => element.divider !== null && element.divider.id === id,
	);
	divider.label = label;

	const request: ProposalDividerRequest = {
		label,
	};

	dispatch(clearFieldError(fieldName));

	updateProposalDivider(metadata.id, id, request)
		.then(() => {
			dispatch({ type: DIVIDER_UPDATED, payload: { id, divider } });
		})
		.catch(error => {
			const errorMessage = getErrorMessages(error, 'Label');
			dispatch(setFieldError(fieldName, errorMessage));
		});
};

export const createDivider = (label: string, order?: number) => (
	dispatch: ThunkDispatch<State, undefined, Action>,
	getState: () => State,
) => {
	const { metadata } = getState();
	const fieldName = `${DIVIDER}_0`;
	const request: ProposalDividerRequest = {
		label,
		order,
	};

	dispatch(clearFieldError(fieldName));

	createProposalDivider(metadata.id, request)
		.then(() => {
			dispatch(updateProposalElements(metadata.id));
		})
		.catch(error => {
			const errorMessage = getErrorMessages(error, 'Label');
			dispatch(setFieldError(fieldName, errorMessage));
		});
};

export const deleteDivider = (dividerId: number, proposalElementId: number) => (
	dispatch: ThunkDispatch<State, undefined, Action>,
	getState: () => State,
) => {
	const { metadata } = getState();
	const fieldName = `${DIVIDER}_${dividerId}`;

	dispatch(clearFieldError(fieldName));

	deleteProposalElement(metadata.id, proposalElementId)
		.then(() => {
			dispatch(updateProposalElements(metadata.id));
		})
		.catch(error => {
			const errorMessage = getErrorMessages(error, 'Label');
			dispatch(setFieldError(fieldName, errorMessage));
		});
};
