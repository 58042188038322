import React from 'react';
import styled from 'styled-components';
import TabProps from './Tab/TabProps';
import TabListingProps from './TabListingProps';
import { Tab } from './Tab';
import { sm } from '../../design-system/media-queries';
import { spaceBase, spaceLarge } from '../../design-system/variables/spaces';
import { dividerGray } from '../../design-system/variables/colors';
import { containerWidth } from '../../design-system/style-mixins/grid-and-layout';

export const TabListing: React.FunctionComponent<TabListingProps> = (props: TabListingProps) => {
	const { tabs } = props;

	const TabListingElement = styled.div`
		&& {
			border-bottom: ${dividerGray};
			margin-left: auto;
			margin-right: auto;
			margin-top: ${spaceBase};
			${containerWidth};
		}
		${sm`
			margin-top: ${spaceLarge};
		`}
	`;

	return (
		<TabListingElement className='row center-xs'>
			{tabs.map((tab: TabProps, index: number) => (
				<Tab {...tab} key={index} />
			))}
		</TabListingElement>
	);
};

export default TabListing;
