import axios from 'axios';
import wretch from 'wretch';
import { CuratedTripApiRequest, TripResults } from '../interfaces';

const { CancelToken } = axios;
let cancel;
let cancelFilters;

export const getCuratedTripPagedAsync = async (
	getCuratedTripsUrl: string,
	filterParameters: CuratedTripApiRequest,
): Promise<TripResults> => {
	if (cancel !== undefined) {
		cancel('bounce');
	}

	return axios
		.post(
			getCuratedTripsUrl,
			{
				...filterParameters,
			},
			{
				cancelToken: new CancelToken(function executor(c) {
					// An executor function receives a cancel function as a parameter
					cancel = c;
				}),
			},
		)
		.then((response) => response.data as Promise<TripResults>)
		.catch((error) => {
			return error;
		});
};

export const getCuratedTripFilters = async (url: string, id: number) => {
	if (cancelFilters !== undefined) {
		cancelFilters('bounce');
	}

	return axios
		.post(
			url,
			{
				curatedListId: id,
			},
			{
				cancelToken: new CancelToken(function executor(c) {
					// An executor function receives a cancel function as a parameter
					cancelFilters = c;
				}),
			},
		)
		.then((response) => response.data)
		.catch((error) => {
			return error;
		});
};

export const getHeroImage = (apiUrl: string, id: number, cancelToken) => {
	return axios
		.get(`${apiUrl}/${id}`, {
			cancelToken,
			headers: {
				Authorization:
					'Bearer MDlhNWFlNWI5LWM4OWUtNDJlZS1iODk3LTJiZTM4ZmU3NjBmNXw2MzU5NTQ3NDU1OTI3MzMzNjN8Mnwy%7c6C9NbRFrzmd53MLx%2bRGQXZZ5k6cxwRuj0PNTyHB9BDw%3d',
			},
		})
		.then((res) => res.data)
		.catch((error) => {
			if (axios.isCancel(error)) {
				return;
			}
			// throw new Error(error.message);
		});
};

export const getPassResults = async (passOfferingsUrl, params) => {
	return wretch(`${passOfferingsUrl}/results`)
		.post(params)
		.json((response) => response)
		.catch((e) => new Error(e));
};

let refinedFiltersAbortable = null;

export const getPassRefinedFilters = async (passOfferingsUrl, params) => {
	if (refinedFiltersAbortable) {
		refinedFiltersAbortable.abort();
	}
	const controller = new AbortController();
	refinedFiltersAbortable = controller;
	return wretch(`${passOfferingsUrl}/filters`)
		.signal(controller)
		.post(params)

		.json((response) => response)
		.catch((e) => new Error(e));
};

export const getPassFilters = async (passOfferingsUrl) => {
	return Promise.all([fetch(`${passOfferingsUrl}/filters`), fetch(`${passOfferingsUrl}/cascaded-locations`)])
		.then((responses) => Promise.all(responses.map((response) => response.json())))
		.then((res) => ({ ...res[0], ...res[1] }))
		.catch((e) => new Error(e));
};
