/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useRef, useCallback, useMemo, memo, useLayoutEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import { grayDark } from 'design-system/variables/colors';
import { useLockBodyScroll, useDropdown, useStateIsMobile } from 'components/hooks';
import { KeyboardAccessibility } from 'components/KeyboardAccessibility';
import { AutocompleteDropdown } from './AutocompleteDropdown';
import { DestinationsTagCarousel } from './DestinationsTagCarousel';
import { DestinationAutocompleteProps } from './DestinationAutocompleteProps';
import {
	CloseButton,
	Wrapper,
	SearchWrapper,
	SearchIcon,
	SearchLoader,
	checkboxOuterContainer,
	nestedCheckboxGroup,
	checkboxContainer,
	checkboxLabel,
	wrapperStyle,
} from './DestinationAutocomplete.styles';
import { MOBILE } from './constants';
import {
	updateResultsCheckedState,
	getInitialSelectedResults,
	filterSearchResults,
	filterSections,
	getDestinationIndex,
	definePlaceholder,
	addDestination,
} from './utils';

export const DestinationAutocomplete: React.FC<DestinationAutocompleteProps> = memo(
	({
		placeholder = 'Search anywhere',
		data,
		additionalSectionData,
		onApplyClicked,
		onFilterOpen,
		onDestinationsChanged,
		isLoading,
		isDisabled,
	}) => {
		const dropdownRef = useRef(null);
		const wrapperRef = useRef(null);
		const [initialSearchResults, setInitialSearchResults] = useState(data);
		const [unfilteredSectionData, setUnfilteredSectionData] = useState(additionalSectionData);
		const [isVisible, toggleVisibility] = useDropdown(dropdownRef, wrapperRef, "[data-node='search-results']", [
			'[class*="ClearFilters__FilterButtonClear"]',
		]);
		const [value, changeValue] = useState('');
		const [searchResults, setSearchResults] = useState(initialSearchResults);
		const [additionalResults, setAdditionalResults] = useState([]);
		const [destinations, setDestinations] = useState([]);
		const isMobile = useStateIsMobile();
		const [searchWrapperHeight, setSearchWrapperHeight] = useState(0);

		useLayoutEffect(() => {
			setSearchWrapperHeight(
				wrapperRef.current && wrapperRef.current.scrollHeight + (isMobile ? MOBILE.OFFSET_TOP : 0),
			);
		}, [wrapperRef, isMobile]);

		const sectionFilterData = useMemo(() => filterSections(unfilteredSectionData), [unfilteredSectionData]);
		const inputPlaceholder = useMemo(() => definePlaceholder({ destinations, placeholder }), [
			destinations,
			placeholder,
		]);
		const didMountRef = useRef(false);
		const isModal = isVisible && isMobile;

		const filterResults = (keyword = value) => {
			const filteredResults = filterSearchResults({
				initialSearchResults,
				destinations,
				keyword,
			});

			setSearchResults(filteredResults);
		};

		const clearInputValue = () => {
			changeValue('');
			filterResults('');
			setAdditionalResults(sectionFilterData(''));
			document.documentElement.style.setProperty('--mobile-dest-filter-z-index', 12);
		};

		const removeDestination = useCallback(
			(destinationIndex) => {
				setDestinations([
					...destinations.slice(0, destinationIndex),
					...destinations.slice(destinationIndex + 1),
				]);
			},
			[destinations],
		);

		useEffect(() => {
			setAdditionalResults(sectionFilterData(value));
		}, [sectionFilterData]);

		const removeAllDestinations = () => {
			setDestinations([]);
			setAdditionalResults([]);
			changeValue('');
			setSearchResults(data);
		};

		const selectResult = (selected) => {
			const destinationIndex = getDestinationIndex({ selected, destinations });

			if (destinationIndex !== -1) {
				removeDestination(destinationIndex);
			} else {
				addDestination({ destinations, selected, setDestinations, searchResults, clearInputValue });
			}
		};

		const updateRegionsExpandedState = useCallback(
			(id, isExpanded) => {
				const updatedRegions = [...searchResults].map((region) => {
					if (region.id === id) {
						region.expanded = isExpanded;
					}
					return region;
				});

				setSearchResults(updatedRegions);
			},
			[searchResults],
		);

		const handleRemoveDestination = (dest) => {
			if (!isVisible) {
				toggleVisibility(true);
			}
			removeDestination(dest);
		};

		const handleKeywordChange = (e) => {
			const { value: keyword } = e.target;
			if (!isVisible) {
				toggleVisibility(true);
			}
			changeValue(keyword);
			filterResults(keyword);
			setAdditionalResults(sectionFilterData(keyword));
		};

		const onApply = useCallback(() => {
			toggleVisibility(false);
		}, [destinations]);

		const setDestFilterZIndexValue = () => {
			document.documentElement.style.setProperty('--mobile-dest-filter-z-index', '12');
		};

		const closeDropdown = useCallback(() => {
			setDestFilterZIndexValue();
			toggleVisibility(false);
		}, []);

		useEffect(() => {
			const { updatedDestinations, updatedAdditional } = updateResultsCheckedState({
				results: searchResults,
				additionalResults: additionalSectionData,
				destinations,
			});
			setUnfilteredSectionData(updatedAdditional);
			setSearchResults(updatedDestinations);
			onDestinationsChanged && onDestinationsChanged(destinations);
		}, [destinations]);

		useEffect(() => {
			if (didMountRef.current) {
				if (!isVisible) {
					onApplyClicked && onApplyClicked(destinations);
					clearInputValue();
				} else {
					onFilterOpen();
				}
			} else {
				didMountRef.current = true;
			}
		}, [isVisible]);

		useEffect(() => {
			setInitialSearchResults(data);

			const initialSelectedResults = getInitialSelectedResults({ data, additionalSectionData });
			setDestinations(initialSelectedResults);
		}, [data]);

		useEffect(() => {
			filterResults('');
			setAdditionalResults(sectionFilterData(''));
		}, [initialSearchResults]);

		useLockBodyScroll(isModal);

		return (
			<ThemeProvider
				theme={{
					isModal,
					height: searchWrapperHeight,
					wrapperStyle,
					checkboxOuterContainer,
					checkboxContainer,
					checkboxLabel,
					nestedCheckboxGroup,
				}}
			>
				<KeyboardAccessibility
					isMulti
					menuId='dropdown-results'
					selectedOptionsMenuId='scroll-drag-container'
					isMenuOpen={isVisible}
				>
					<Wrapper data-node='search-results'>
						<SearchWrapper ref={wrapperRef} isDisabled={isDisabled}>
							{isLoading && <SearchLoader spinnerSize='1rem' />}
							<SearchIcon
								buttonType='containerless'
								iconName='search'
								iconSize='sm'
								fill={grayDark}
								isDisabled={isDisabled}
								isLoading={isLoading}
							/>

							<DestinationsTagCarousel
								placeholder={inputPlaceholder}
								openDropdown={toggleVisibility}
								changeValue={handleKeywordChange}
								isLoading={isDisabled}
								isMobile={isMobile}
								removeDestination={handleRemoveDestination}
								{...{
									value,
									isVisible,
									updateRegionsExpandedState,
									searchResults,
									additionalResults,
									destinations,
									selectResult,
								}}
							/>
						</SearchWrapper>
						{isModal && (
							<CloseButton
								buttonType='containerless'
								iconName='close'
								iconSize='md'
								color={grayDark}
								onClick={closeDropdown}
							/>
						)}
						{isVisible && (
							<AutocompleteDropdown
								ref={dropdownRef}
								shadow={4}
								clearSearch={clearInputValue}
								searchWord={value}
								regions={searchResults}
								clearAll={removeAllDestinations}
								additionalSections={additionalResults}
								{...{ selectResult, destinations, onApply, updateRegionsExpandedState }}
							/>
						)}
					</Wrapper>
				</KeyboardAccessibility>
			</ThemeProvider>
		);
	},
);
