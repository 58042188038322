import React, { memo, useContext, useEffect, useRef } from 'react';
import styled, { css, keyframes } from 'styled-components';

import { Link } from 'components/Link/Link';
import { NavigationLinkProps } from './NavigationLinkProps';

import { useVisibilityToggle, useTouchScreenDevice, useDropdown } from '../hooks';

import { md } from '../../design-system/media-queries';
import { black, white, tapTransparent, focusOutlineColor } from '../../design-system/variables/colors';

import { AllCaps } from '../AllCaps';
import { SubNavigation } from './SubNavigation';
import { transition250ms } from '../../design-system/style-mixins/animations';
import { PrimaryNavigationContext } from './PrimaryNavigationContext';

interface MenuItemProps {
	active?: boolean | Function;
	onMouseLeave?: Function;
	onMouseEnter?: Function;
	onBlur?: Function;
	onFocus?: Function;
	childNavigationType?: string;
}

interface MenuLinkProps {
	isActive?: boolean | Function;
	theme: { color: string };
}
const subnavFadeIn = keyframes`
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
		visibility: visible;
	}
`;

const subnavFadeInAnimation = css`
	animation: ${subnavFadeIn} 0.25s ease both;
`;

const MenuItem = styled.li<MenuItemProps>`
	${tapTransparent}
	${md`
		position: ${props => (props.childNavigationType === 'multi-column' ? 'static' : 'relative')};
		align-items: center;
		display: flex;
		height: 100%;
		justify-content: center;
		padding-left: 0;
		padding-right: 0;

		&:hover,
		&:focus-within {
			.submenu {
				display: flex;
				${subnavFadeInAnimation}
			}

			span {
				&:after {
					background-color: ${black};
				}
			}
		}
	`}
`;

const MenuLink = styled(Link)<MenuLinkProps>`

	&[data-focus-visible-added] {
		outline: none;

		span {
			outline: 2px solid ${focusOutlineColor};
			outline-offset: 2px;
			transition: none;
		}
	}

	${md`
		height: 100%;
		display: flex;
		align-items: center;
		position: relative;

		&:hover {
			cursor: pointer;
			text-decoration: none;

			span:after {
				background-color: ${black};
			}
		}

		span {
			color: ${white} !important;
			${transition250ms}

			&:after {
				background-color: transparent;
				position: absolute;
				bottom: -1px;
				content: ' ';
				height: 2px;
				left: 0;
				right: 0;
			}
		}

		${props =>
			props.isActive &&
			css`
				span:after {
					background-color: ${black};
				}
			`}

		${props =>
			props.theme.color === 'white' &&
			css`
				span {
					color: ${black} !important;
				}
			`}
	`}

	&:focus {
		text-decoration: none;
	}
`;

export const NavigationTab: React.FC<NavigationLinkProps> = memo(({ linkTitle, url, childNavigation, isActive }) => {
	const [isChildNavigationVisible, showChildNavigation, hideChildNavigation] = useVisibilityToggle();
	const { openedTab, setOpenedTab } = useContext(PrimaryNavigationContext);
	const [, checkIfTouchDevice] = useTouchScreenDevice();
	const dropdownTrigger = useRef(null);
	const dropdownBody = useRef(null);
	const [isDropdownOpen, toggleDropdown] = useDropdown(dropdownBody, dropdownTrigger);
	const onTabClicked = e => {
		if (checkIfTouchDevice() || isActive) {
			e.preventDefault();
		}

		return true;
	};

	const resetOpenedTab = () => {
		if (isDropdownOpen) {
			return;
		}
		// eslint-disable-next-line no-unused-expressions
		childNavigation && hideChildNavigation();
		setOpenedTab(null);
	};
	const setCurrentTabAsOpened = () => {
		if (isDropdownOpen) {
			return;
		}
		// eslint-disable-next-line no-unused-expressions
		childNavigation && showChildNavigation();
		setOpenedTab(url);
	};

	const openDropdown = () => {
		toggleDropdown();
		showChildNavigation();
		setOpenedTab(url);
	};

	useEffect(() => {
		if (!isDropdownOpen && isChildNavigationVisible && openedTab === url) {
			hideChildNavigation();
			setOpenedTab(null);
		}
	}, [isDropdownOpen]);

	useEffect(() => {
		if (isChildNavigationVisible && openedTab !== url) {
			isDropdownOpen && toggleDropdown();
			hideChildNavigation();
		}
	}, [openedTab]);

	return (
		<MenuItem
			onMouseLeave={resetOpenedTab}
			onMouseEnter={setCurrentTabAsOpened}
			onBlur={resetOpenedTab}
			onFocus={setCurrentTabAsOpened}
			childNavigationType={childNavigation && childNavigation.type}
		>
			<MenuLink isActive={isActive} color='white' href={url} onClick={onTabClicked}>
				<AllCaps size='small'>{linkTitle}</AllCaps>
			</MenuLink>
			{childNavigation && <SubNavigation {...childNavigation} />}
		</MenuItem>
	);
});
