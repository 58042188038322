import styled, { css } from 'styled-components';
import { lightWeight, textBig, textLarge, textMedium, fontSans } from '../../../src/design-system/style-mixins/text';
import { textColorDark } from '../../../src/design-system/variables/colors';

const large = css`
	${textBig}
	line-height: 30px;
`;

const medium = css`
	${textLarge}
	line-height: 27px;
`;

const small = css`
	${textMedium};
	line-height: 23px;
`;

const sizeStyles = {
	large,
	medium,
	small,
};

/**
 * Titles:
 * Use in cards, places that are not headlines.
 */
export const Title = styled.span<{ size?: 'small' | 'medium' | 'large' }>`
	${fontSans}
	${textColorDark}
	${lightWeight}
	letter-spacing: initial;
	${props => sizeStyles[props.size] || sizeStyles.medium}
`;

export default Title;
