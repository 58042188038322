import React, { useRef } from 'react';
import ReactModal from 'react-modal';
import styled, { createGlobalStyle, css } from 'styled-components';

import { white } from 'design-system/variables/colors';
import { transparentDark80 } from 'design-system/style-mixins/shadows-and-scrims';

const DisableScroll = createGlobalStyle`
    html {
        overflow-y: hidden;
    }
`;

export const ModalAdapter: React.FC<any> = ({ className, isOpen, persistOpen, overflowAuto, maxWidth, ...rest }) => {
	const contentClassName = `${className}__content`;
	const overlayClassName = `${className}__overlay`;

	ReactModal.setAppElement('body');

	return (
		<>
			{isOpen && !overflowAuto && <DisableScroll />}
			<ReactModal
				portalClassName={className}
				className={contentClassName}
				overlayClassName={overlayClassName}
				isOpen={isOpen || persistOpen}
				{...rest}
			/>
		</>
	);
};

export const Modal = styled(props => <ModalAdapter {...props} />)<{ overflowAuto: boolean; contentStyles: any }>`
	position: relative;
	z-index: 100;
	
	${props =>
		props.zIndex &&
		css`
			z-index: ${props.zIndex};
		`}

	&__overlay {
		position: fixed;
		top: 0;
		left: 0;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		height: 100%;
		width: 100%;
		background-color: ${props => props.overlayColor || transparentDark80};
	}

	&__content {
		visibility: ${props => (props.isPersisted || props.isOpen ? 'visible' : 'hidden')};
		background: ${props => props.background || white};
		outline: 0;
		width: 100%;
		overflow-y: scroll;
		${props =>
			props.contentStyles &&
			css`
				${props.contentStyles}
			`}
		${props =>
			props.overflowAuto &&
			css`
				overflow: auto;
			`}
		${props =>
			props.maxWidth &&
			css`
				max-width: ${props.maxWidth};
			`}
	}
`;
