import React, { useState, useEffect, memo, useCallback } from 'react';
import styled, { ThemeProvider, css } from 'styled-components';
import root from 'window-or-global';

import { TextButton } from 'components/TextButton';
import { QuickSearchContainer } from 'components';
import { HeaderProps } from './HeaderProps';

import { spaceLarge, spaceBase, spaceMassive } from '../../design-system/variables/spaces';
import { white, dividerGrayLight, black } from '../../design-system/variables/colors';
import { sm, md, lg } from '../../design-system/media-queries';

import { Navigation, MobileNavigation } from '../Navigation';
import { Modal } from '../Modal';
import { Logo } from './Logo';
import { UserControls } from './UserControls';
import { useIsDesktop, useVisibilityToggle } from '../hooks';
import { transition250ms } from '../../design-system/style-mixins/animations';

const CallbackButton = styled(props => <TextButton {...props} />)``;

const Wrapper = styled.div<{ theme: { color: string } }>`
	align-items: center;
	display: flex;
	justify-content: space-between;
	min-height: 65px;
	padding: 0 ${spaceBase};
	position: relative;
	z-index: 9000;
	background-color: ${props => props.theme.color};
	border-bottom: ${dividerGrayLight};
	${transition250ms}

	${props =>
		props.theme.color !== 'white' &&
		css`
			background-color: transparent;
			background-image: linear-gradient(
				to bottom,
				hsla(0, 0%, 0%, 0.5) 0%,
				hsla(0, 0%, 0%, 0.494) 8.1%,
				hsla(0, 0%, 0%, 0.476) 15.5%,
				hsla(0, 0%, 0%, 0.448) 22.5%,
				hsla(0, 0%, 0%, 0.412) 29%,
				hsla(0, 0%, 0%, 0.37) 35.3%,
				hsla(0, 0%, 0%, 0.324) 41.2%,
				hsla(0, 0%, 0%, 0.275) 47.1%,
				hsla(0, 0%, 0%, 0.225) 52.9%,
				hsla(0, 0%, 0%, 0.176) 58.8%,
				hsla(0, 0%, 0%, 0.13) 64.7%,
				hsla(0, 0%, 0%, 0.088) 71%,
				hsla(0, 0%, 0%, 0.052) 77.5%,
				hsla(0, 0%, 0%, 0.024) 84.5%,
				hsla(0, 0%, 0%, 0.006) 91.9%,
				hsla(0, 0%, 0%, 0) 100%
			);
			border-bottom: 1px solid rgba(197, 197, 197, 0);

			&:hover,
			&:focus-within {
				background-color: ${white};
				border-bottom: ${dividerGrayLight};
				background-image: none;

				a span,
				button {
					color: ${black} !important;
				}
				svg {
					fill: ${black};
				}
			}
		`};

	${sm`
        min-height: ${spaceMassive};
        padding: 0 ${spaceLarge};
    `}

	${CallbackButton} {
		order: 5;
	}
`;

const SearchAndAccountControls = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;

	${sm`
		order: 0;
	`};

	${md`
		order: 3;
	`}

	${lg`
		flex-basis: 240px;
	`}
`;

const ControlsWrapper = styled(props => <Wrapper {...props} />)`
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 10000;
	border-bottom: ${dividerGrayLight};
	padding: 0 ${spaceBase};
	background: ${white};

	&:hover {
		background-color: ${white};
		background-image: none;
	}

	${sm`
		padding: 0 ${spaceLarge};
	`}
`;

export const Header: React.FC<HeaderProps> = memo(({ navigationLinks, authData, whiteTheme = false, baseUrl }) => {
	const [color, setTheme] = useState(whiteTheme ? 'white' : 'transparent');
	const [isSearchVisible, setSearchVisibility] = useState(false);
	const [isControlsVisible, setControlsVisibility] = useState(false);
	const setHoverTheme = () => !whiteTheme && setTheme('white');
	const setTransparentTheme = () => !whiteTheme && setTheme('transparent');
	const [isModalMode, showModal, hideModal] = useVisibilityToggle();
	const [isSearchActivated, activateSearch] = useState(false);
	const [isDesktop] = useIsDesktop();

	const onToggleSearchVisibility = useCallback(isVisible => {
		setSearchVisibility(isVisible);
	}, []);

	const onToggleControlsVisibility = useCallback(isVisible => {
		setControlsVisibility(isVisible);
	}, []);

	const handleMouseOut = useCallback(() => {
		if (isSearchVisible || isControlsVisible) {
			return;
		}
		setTransparentTheme();
	}, [isSearchVisible, isControlsVisible, whiteTheme]);

	useEffect(() => {
		isModalMode && !whiteTheme && setHoverTheme();
		if (isDesktop && isModalMode) {
			hideModal();
		}
	}, [isModalMode, isDesktop]);

	useEffect(() => {
		if (!isModalMode && isSearchActivated) {
			const searchNode = root.document.querySelector('[data-node="search"]');
			const evt = root.document.createEvent('MouseEvents');
			evt.initEvent('click', true, false);
			searchNode.dispatchEvent(evt);
			activateSearch(false);
		}
	}, [isModalMode, isSearchActivated]);

	const onClickModalHeader = e => {
		const isSearchClicked = Boolean(e.target.closest(`[data-node="search"]`));
		activateSearch(isSearchClicked);
		hideModal();
	};

	return (
		<ThemeProvider theme={{ color }}>
			{isModalMode ? (
				<Modal isOpen overflowAuto overlayColor={white}>
					<ControlsWrapper onClick={onClickModalHeader}>
						<Logo />
						<Navigation
							links={navigationLinks}
							authData={authData}
							showModal={showModal}
							hideModal={hideModal}
							isModalMode={isModalMode}
						/>
						<SearchAndAccountControls>
							{authData.isLoggedIn && (
								<QuickSearchContainer {...{ baseUrl }} onToggleVisibility={onToggleSearchVisibility} />
							)}
						</SearchAndAccountControls>
					</ControlsWrapper>
					{navigationLinks.length > 0 && <MobileNavigation links={navigationLinks} {...authData} />}
				</Modal>
			) : (
				// eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
				<Wrapper onMouseOver={setHoverTheme} onMouseOut={handleMouseOut} data-node='header'>
					<Logo logoName='logo-inspirato' />
					<Navigation
						links={navigationLinks}
						authData={authData}
						showModal={showModal}
						hideModal={hideModal}
						isModalMode={isModalMode}
					/>
					<SearchAndAccountControls>
						{authData.isLoggedIn && (
							<QuickSearchContainer {...{ baseUrl }} onToggleVisibility={onToggleSearchVisibility} />
						)}
						<UserControls {...authData} onToggleVisibility={onToggleControlsVisibility} />
					</SearchAndAccountControls>
				</Wrapper>
			)}
		</ThemeProvider>
	);
});
