import React, { FC } from 'react';
import { Label } from 'components/Label/Label';
import { Header } from '../SearchResultsSet.styles';

interface Props {
	category: string;
}

export const Category: FC<Props> = ({ category }) => (
	<Header>
		<Label size='medium'>{category}</Label>
	</Header>
);
