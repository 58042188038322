import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { getSafeHtml } from 'services/Helpers';
import { useDispatch } from 'react-redux';
import { getFilteredResults } from 'pages/CuratedTrips/redux/actions';
import { ButtonCustomized } from 'components/Cards/ListCard/ListCard.styles';
import { useClickProps } from 'components/Cards/useClickProps';
import { ImageCardProps } from './ImageCardProps';
import { ImageOrientation } from '../../enums/ImageOrientation';
import { getNormalizedImageStyle } from '../../services/ImageUtilities';
import { spaceBase, spaceLarge, spaceMedium } from '../../design-system/variables/spaces';
import { shadowHover } from '../../design-system/style-mixins/animations';
import { white, textColorMedium } from '../../design-system/variables/colors';
import { xsOnly } from '../../design-system/media-queries';
import { richText } from '../../design-system/utils';

import { ActionLink } from '../ActionLink';
import { Title } from '../Title';

type orientationString = 'landscape' | 'portrait';

const Container = styled.section`
	display: flex;
	flex-direction: column;
	height: 100%;
	padding-bottom: ${spaceBase};
`;

const Wrapper = styled.div<{ isContainerless: boolean }>`
	align-self: center;
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	overflow: hidden;
	width: 100%;

	${props =>
		!props.isContainerless &&
		css`
			background-color: ${white};
			${shadowHover}
		`}
`;

const ImageWrapper = styled.div`
	order: 0;
	width: 100%;
`;

const Image = styled.div<{ orientation: orientationString }>`
	background-position: center center;
	background-size: cover;
	display: block;
	height: 0;

	${props =>
		props.orientation &&
		css`
			padding-bottom: ${(props.orientation === 'landscape' ? 9 / 16 : 5 / 4) * 100}%;
		`}
`;

const CopyWrapper = styled.div`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	justify-content: start;
	padding: 0 ${spaceBase};
	${textColorMedium}

	${xsOnly`
		display: block;
		width: 100%;
	`}
`;

const Headline = styled(props => <Title {...props} />)`
	display: block;
	margin-bottom: ${spaceMedium};
	margin-top: ${spaceLarge};
	text-align: center;
`;

const BodyText = styled.div`
	${richText}
	flex-grow: 1;
`;

const CallToAction = styled(props => <ActionLink {...props} />)``;

const CallToActionWrapper = styled.div`
	margin-bottom: ${spaceLarge};
	margin-top: ${spaceLarge};

	${CallToAction} {
		margin: auto;
		text-decoration: none;
		text-overflow: ellipsis;
		white-space: nowrap;
		padding: 11px ${spaceMedium};
	}
`;

export const ImageCard: React.FC<ImageCardProps> = ({
	bodyText,
	callToAction,
	isContainerless,
	headline,
	imageOrientation,
	image,
}) => {
	const dispatch = useDispatch();

	const handleClick = () => {
		const newParam = callToAction.url.replace('?filter=', '');
		dispatch(getFilteredResults(newParam));
	};
	const props = {
		buttonType: callToAction.secondary ? 'secondary' : 'primary',
	};
	const clickProps = useClickProps(props, callToAction.url, callToAction.newWindow, handleClick);

	const getOrientation = (): orientationString => {
		switch (imageOrientation) {
			case ImageOrientation.Landscape:
				return 'landscape';
			case ImageOrientation.Portrait:
				return 'portrait';
			default:
				return 'landscape';
		}
	};

	const imageElement = useRef(null);
	const [imageStyle, setImageStyle] = useState({});

	useEffect(() => {
		if (image && imageElement.current) {
			setImageStyle(
				getNormalizedImageStyle(
					image.fullPath,
					imageElement.current.clientWidth,
					imageElement.current.clientHeight,
				),
			);
		}
	}, [imageElement]);

	return (
		<Container>
			<Wrapper isContainerless={isContainerless}>
				{image && (
					<ImageWrapper>
						<Image orientation={getOrientation()} style={imageStyle} ref={imageElement} />
					</ImageWrapper>
				)}

				<CopyWrapper>
					<Headline>{headline}</Headline>

					{bodyText && (
						<div>
							<BodyText dangerouslySetInnerHTML={getSafeHtml(bodyText)} />
						</div>
					)}

					{callToAction.canDisplayAsTextLink && (
						<CallToActionWrapper className='center-xs'>
							<ButtonCustomized {...clickProps}>{callToAction.linkTitle}</ButtonCustomized>
						</CallToActionWrapper>
					)}
				</CopyWrapper>
			</Wrapper>
		</Container>
	);
};
