export enum SortFilters {
	CheckInDate = 1,
	Price = 2,
	InspiratoOnly = 3,
}

export enum SortDirection {
	Ascending = 1,
	Descending = 2,
}
