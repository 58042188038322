import styled from 'styled-components';
import { Label } from 'components/';
import { bold } from 'design-system/style-mixins/text';
import { spaceBase } from 'design-system/variables/spaces';

export const Form = styled.form`
	display: flex;
	flex-direction: column;
	width: 100%;
`;

export const Instructions = styled(props => <Label {...props} />)`
	${bold}

	align-self: center;
	margin-bottom: ${spaceBase};
`;
