import React from 'react';
import { getSafeHtml } from 'services/Helpers';
import styled, { createGlobalStyle, css } from 'styled-components';
import { CopyBlockProps } from './CopyBlockProps';
import { CopyBlockDisplayType } from './CopyBlockDisplayType';
import { HorizontalAlignment } from '../../enums/HorizontalAlignment';
import { sm, md, xsSmOnly, xsOnly } from '../../design-system/media-queries';
import { Subhead } from '../Subhead';
import { black, white, textColorMedium } from '../../design-system/variables/colors';
import { spaceBase, spaceLarge, spaceBig } from '../../design-system/variables/spaces';
import { gtAmerica } from '../../design-system/variables/fonts';
import { richText } from '../../design-system/utils';

const Head = styled.h3`
	margin-bottom: ${spaceBase};
`;

const Body = styled.div`
	${richText}
	${textColorMedium}
	margin: auto;

	${xsSmOnly`
		margin-left: auto;
		margin-right: auto;
		width: auto;
	`}
`;

const Container = styled.div<{
	displayType: CopyBlockDisplayType;
	horizontalAlignment: HorizontalAlignment;
}>`
	background-position: center center;
	background-size: cover;
	min-height: initial;
	position: relative;
	width: auto;
	${props =>
		props.displayType === CopyBlockDisplayType.TravelSection &&
		css`
			background: ${white};
			margin-right: 0;
			max-width: 750px;
			padding: ${spaceBase};
			${Head} {
				font: ${gtAmerica};
				color: ${black};
				text-align: left;
			}
			${Body} {
				text-align: left;
			}
			${sm`
				margin-right: ${spaceBase};
				padding: ${spaceLarge};
			`}
		`}
	${props => {
		switch (props.horizontalAlignment) {
			case HorizontalAlignment.Start:
				return css`
					margin-right: auto;
					justify-content: flex-start;
					text-align: start;
					${Body} {
						margin-right: auto;
						text-align: left;
					}
				`;
			case HorizontalAlignment.End:
				return css`
					margin-left: auto;
					justify-content: flex-end;
					text-align: end;
					${Body} {
						margin-left: auto;
						text-align: right;
					}
				`;
			default:
				return css`
					margin-left: auto;
					margin-right: auto;
					justify-content: center;
					text-align: center;
					${Body} {
						margin-left: auto;
						margin-right: auto;
						text-align: center;
					}
				`;
		}
	}}
	${md`
width: 100%
	`}

	&.copy-block--has-background,
	&.copy-block--is-travel-section {
		background: ${white};
		margin-right: 0;
		max-width: 750px;
		padding: ${spaceBase};
	}

	${sm`
		&.copy-block--has-background,
		&.copy-block--is-travel-section {
			margin-right: ${spaceBase};
			padding: ${spaceLarge};
		}
	`}

	&.copy-block--has-background .copy-block__heading,
	&.copy-block--is-travel-section .copy-block__heading {
		${richText}
	}

	&.copy-block--has-background .copy-block__body,
	&.copy-block--is-travel-section .copy-block__body {
		text-align: left
	}
`;

const Styles = createGlobalStyle<any>`
	.section-component__components-per-row--2-of-2 ${Container} {
		padding-left: 0;
	}

	.expandable-card__drawer ${Container} {
		max-width: 100%;
		padding: 0;
	}

	.expandable-card__drawer ${Container}.copy-block--has-background,
	.expandable-card__drawer ${Container}.copy-block--is-travel-section {
		padding: ${spaceBase};
	}

	${xsOnly`
		.section-component__components-per-row--1-of-2 ${Container} {
			padding-bottom: ${spaceBig};
		}
		.section-component__components-per-row--2-of-2 ${Container} {
			padding-top: ${spaceBig};
		}
	`}

	${sm`
		.section-component__components-per-row--2-of-2 ${Container} {
			padding-left: ${spaceBase};
		}

		.expandable-card__drawer ${Container} {
			max-width: 550px;
		}

		.expandable-card__drawer ${Container}.copy-block--has-background,
		.expandable-card__drawer ${Container}.copy-block--is-travel-section {
			max-width: 750px;
			padding: ${spaceLarge};
		}
	`}
`;

export const CopyBlock: React.FunctionComponent<CopyBlockProps> = (props: CopyBlockProps) => {
	const {
		backgroundImage,
		body,
		bodyColor,
		displayType,
		heading,
		headingColor,
		horizontalAlignment,
		subhead,
	} = props;

	const WrapperStyle =
		backgroundImage && backgroundImage.hasImage ? { backgroundImage: `url('${backgroundImage.fullPath}')` } : null;
	const headStyle = headingColor ? { color: `${headingColor}` } : null;
	const bodyStyle = bodyColor ? { color: `${bodyColor}` } : null;
	
	return (
		<React.Fragment>
			<Styles />
			<Container
				style={WrapperStyle}
				displayType={displayType}
				horizontalAlignment={horizontalAlignment}
				className='copy-block'
			>
				{heading && heading.length > 0 && (
					<Head style={headStyle} dangerouslySetInnerHTML={getSafeHtml(heading)} />
				)}
				{subhead && subhead.length > 0 && <Subhead dangerouslySetInnerHTML={getSafeHtml(subhead)} />}
				{body && body.length > 0 && <Body style={bodyStyle} dangerouslySetInnerHTML={getSafeHtml(body)} />}
			</Container>
		</React.Fragment>
	);
};
