import { useState, useEffect } from 'react';
import root from 'window-or-global';
import { containerWidthPx } from '../../design-system/style-mixins/grid-and-layout';

export const useIsDesktop = (minWidth: number = containerWidthPx) => {
	const [isDesktop, setIsDesktop] = useState(root.innerWidth >= minWidth);

	const handleResize = () => {
		setIsDesktop(root.innerWidth >= minWidth);
	};

	useEffect(() => {
		handleResize();
		root.addEventListener('resize', handleResize);

		return () => root.removeEventListener('resize', handleResize);
	}, []);

	return [isDesktop, setIsDesktop];
};
