import React from 'react';
import styled, { css } from 'styled-components';

import { transition250ms } from 'design-system/style-mixins/animations';
import { AuthButtonsProps } from './AuthButtonsProps';

import { textTiny } from '../../design-system/style-mixins/text';
import { md } from '../../design-system/media-queries';
import { spaceMedium, spaceBase } from '../../design-system/variables/spaces';
import { white, grayDark, primaryLinkColor } from '../../design-system/variables/colors';

import { Link } from '../Link';
import { allCapsStyle } from '../AllCaps/AllCaps';

const Wrapper = styled.div`
	align-items: center;
	display: flex;
	flex-grow: 1;
	flex-shrink: 0;
	justify-content: center;
	margin-top: ${spaceMedium};

	${md`
		align-items: center;
		display: flex;
		justify-content: flex-end;
		margin-top: 0;
	`}
`;

const StyledLink = styled(props => <Link {...props} />)<{ theme: { color: string } }>`
	${allCapsStyle}
	${textTiny}

	${props =>
		props.theme.color !== 'white' &&
		css`
			color: ${white} !important;
		`}
	&:hover {
		text-decoration: none;
	}
	${transition250ms}
`;

const SignIn = styled(props => <StyledLink {...props} />)`
	padding-left: ${spaceBase};
	padding-right: ${spaceBase};
	white-space: nowrap;
	color: ${grayDark};
`;

const Register = styled(props => <StyledLink {...props} />)`
	color: ${primaryLinkColor};
`;

export const AuthButtons: React.FC<AuthButtonsProps> = ({ onRegister }) => (
	<Wrapper>
		<SignIn color='gray' href='/signin'>
			Sign in
		</SignIn>
		<Register color='primary' href='' onClick={onRegister}>
			Register
		</Register>
	</Wrapper>
);
