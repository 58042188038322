import styled, { css } from 'styled-components';

import { CheckboxControls } from 'components/Checkbox/Checkbox';

import { md, sm, xsOnly } from 'design-system/media-queries';
import { fontSans, textBase } from 'design-system/style-mixins/text';
import { boxShadow4 } from 'design-system/style-mixins/shadows-and-scrims';
import {
	textColorDisabled,
	primaryLinkColor,
	textColorMedium,
	textColorDark,
	dividerGray,
	paleBlue,
	white,
	error,
	gray,
	dividerGrayLight,
	grayLight,
} from 'design-system/variables/colors';
import { spaceBase, spaceMedium, spaceLarge, spaceSmall, spaceTiny } from 'design-system/variables/spaces';
import { LoaderSpinner } from 'components/Loader';
import { Icon, IconButton } from 'components/Icon';
import { Label } from 'components/Label';
import { Pill } from 'components/Pill';

export const autocompleteDropdownWidth = css`
	width: 550px;

	${xsOnly`
		width: 100vw;
	`}
`;

export const autocompleteWidth = css`
	width: 370px;
	max-width: 100%;

	${xsOnly`
		width: 100vw;
	`}
`;

export const SearchLoader = styled(props => <LoaderSpinner {...props} />)`
	position: absolute;
	left: ${spaceSmall};
	top: 50%;
	transform: translateY(-50%);
	z-index: 99999999;
	background: #fff;
`;

export const SearchIcon = styled(props => <Icon {...props} />)`
	margin-right: 12px;

	${props =>
		props.isLoading &&
		css`
			opacity: 0;
		`}

	${props =>
		props.isDisabled &&
		css`
			opacity: 0.5;
		`}
`;

export const SearchInputWrapper = styled.div`
	position: relative;
	flex: 1 0 92px;
	outline: none;
	height: 20px;
	line-height: 20px;
	user-select: none;
	width: 125px;
`;

export const SearchInputTrigger = styled.button`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
	z-index: 1;
	cursor: text;
`;

export const SearchInput = styled.input`
	${fontSans}
	${textBase}
	${textColorDark}
	background-clip: padding-box;
	margin: 0 0 0;
	padding: 0;
	border: none;
	box-sizing: border-box;
	flex: 1 0 92px;
	outline: none;
	line-height: 20px;
	width: 100%;
	right: 0;
	z-index: 99999;

	&::placeholder {
		${fontSans}
		${textBase}
		${textColorDisabled}
		font-weight: normal;
	}
`;

export const ClearButton = styled(props => <IconButton {...props} />)`
	position: absolute;
	top: 10px;
	right: ${spaceBase};

	${sm`
		right: ${spaceLarge};
	`}

	${md`
		right: ${spaceSmall};
	`}
`;

export const SearchWrapper = styled.div`
	${fontSans}
	background-color: ${white};
	border: 1px solid ${gray};
	box-sizing: border-box;
	${textColorMedium}
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	height: auto;
	min-height: 40px;
	outline: none;
	width: 100%;
	padding: ${spaceTiny} 0 ${spaceTiny} ${spaceSmall};
	position: relative;

	${props =>
		props.theme.isModal &&
		css`
			margin: ${spaceBase};
			width: calc(100vw - 3.5rem);
		`}


	${props =>
		props.isDisabled &&
		`
		pointer-events: none;
		border-color: ${gray};
		color: ${gray};

		input:disabled::placeholder,
		input:disabled {
			background: #fff;
			color: ${gray};
		}
	`}
`;

export const wrapperStyle = css`
	${autocompleteDropdownWidth}
	background-color: ${white};
	overflow-y: auto;
	z-index: 1000;

	${sm`
		${boxShadow4}
		position: absolute;
		left: 0;
		top: calc(100% + 5px);
	`}

	${props =>
		props.theme.isModal &&
		css`
			flex-grow: 1;
			height: 100%;
		`}
`;

export const Wrapper = styled.div`
	position: relative;
	${autocompleteWidth}

	${props =>
		props.theme.isModal &&
		css`
			display: flex;
			flex-direction: column;
			height: 100%;
			width: 100%;
			background: ${white};
			position: fixed;
			top: 0;
			left: 0;
			z-index: 10001;
			overflow: hidden;
		`}
`;

export const CloseButton = styled(props => <IconButton {...props} />)<{ color: string }>`
	position: fixed;
	right: 5px;
	top: ${spaceBase};

	svg {
		margin: 0;
	}

	${xsOnly`
		right: 1px;
	`}
`;

export const DestinationsWrapper = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	flex: 1 0 60%;
	align-items: flex-start;
`;

export const DestinationPillWrapper = styled.div<{ isActive: boolean }>`
	display: flex;
	border: 1px solid ${props => (props.isActive ? primaryLinkColor : 'transparent')};
	margin-right: ${spaceTiny};
`;

export const DestinationPill = styled(props => <Pill {...props} />)`
	&& {
		padding: 3px ${spaceSmall} 3px ${spaceBase};
		min-width: 90px;
		transition: unset;

		* {
			transition: unset;
		}

		${props =>
			props.isDisabled &&
			css`
				pointer-events: none;
				border-color: ${gray};
				color: ${gray};
				background-color: ${grayLight};
				svg {
					fill: ${gray};
				}
			`}
	}
`;

export const DestinationTagInput = styled.input`
	width: 0;
	overflow: hidden;
	opacity: 0;
	padding: 0;
	margin: 0;
	border: none;
`;

export const checkboxOuterContainer = css`
	margin: 0;
`;

export const checkboxContainer = css`
	margin: 0;
	padding: ${spaceSmall} ${spaceLarge};

	@media (hover), (min-width: 0\0), (min--moz-device-pixel-ratio: 0) {
		:hover {
			background-color: ${paleBlue};
		}
	}
`;

export const checkboxLabel = css`
	padding-left: ${spaceBase};

	&[data-is-parent] {
		padding-top: ${spaceTiny};
		padding-bottom: ${spaceTiny};
		padding-right: 0.75rem;
	}

	> span {
		width: 100%;
		margin-left: -${spaceBase};
	}
`;

export const nestedCheckboxGroup = css`
	padding: 0;

	> ${CheckboxControls} {
		margin: 0 auto;
	}
`;

export const DestinationsTagCarouselWrapper = styled.div`
	max-width: calc(100% - 36px);
	width: calc(100% - 36px);
	height: 30px;
	display: flex;
	align-items: center;
	position: relative;
`;

export const CheckboxGroupWrapper = styled.div`
	flex-grow: 1;
`;

export const ExceedDestinationsQuantity = styled(props => <Label {...props} />)`
	color: ${error};
`;

export const DestinationsAccordionWrapper = styled.div`
	display: flex;
	flex-direction: column;

	${sm`
		/* max-height subtracts search filter container height from viewport height */
		max-height: calc(100vh - 106px);
	`}
	${props => props.theme.wrapperStyle}
`;

export const DestinationsDropdownWrapper = styled.div`
	display: flex;
	flex-direction: column;

	${sm`
		/* max-height subtracts search filter container height from viewport height */
		max-height: calc(100vh - 106px);
	`}
	${props => props.theme.wrapperStyle}
`;

export const DestinationsAccordionHeader = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: ${spaceMedium} ${spaceBase} 0 ${spaceBase};
	padding-bottom: ${spaceBase};
`;

export const DestinationsDropdownHeader = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: ${spaceBase} ${spaceBase} ${spaceSmall} ${spaceBase};
	border-bottom: ${dividerGray};
	padding-bottom: ${spaceSmall};
`;

export const FilterButtonActionsContainer = styled.div`
	border-top: ${dividerGrayLight};
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: ${spaceBase};
	width: 100%;

	${sm`
		padding: 0 ${spaceBase};
	`}
`;

export const DestinationsDropdownResults = styled.div`
	flex: 1;
	overflow-y: auto;
	height: 100%;
	padding: ${spaceSmall} 0 ${spaceBase};

	${sm`
		max-height: 480px;
	`}
`;
