import React, { memo } from 'react';

import { ChildNavigation } from '../NavigationLinkProps';
import { SubNavigationTypes } from '../enums';

import { SingleColumnWithPromoNavigation } from './SingleColumnWithPromoNavigation';
import { SingleColumnNavigation } from './SingleColumnNavigation';
import { MultiColumnsNavigation } from './MultiColumnsNavigation';

export const SubNavigation: React.FC<ChildNavigation> = memo(({ type, ...restProps }) => {
	switch (type) {
		case SubNavigationTypes.MultiColumn:
			return <MultiColumnsNavigation {...restProps} />;
		case SubNavigationTypes.Promo:
			return <SingleColumnWithPromoNavigation {...restProps} />;
		case SubNavigationTypes.SingleColumn:
		default:
			return <SingleColumnNavigation {...restProps} />;
	}
});
