export enum Constants {
	id = 'referral-source',
	name = 'ReferralSource',
}

export enum MemberFirstName {
	id = 'referral_first_name',
	name = 'ReferralFirstName',
	label = 'Member First Name',
}

export enum MemberLastName {
	id = 'referral_last_name',
	name = 'ReferralLastName',
	label = 'Member Last Name',
}

export const ReferralSourcesTypes = [
	{ key: 0, value: 'How did you hear about us?' },
	{ key: 1, value: 'Member Referral' },
	{ key: 2, value: 'Received a Mailing' },
	{ key: 3, value: 'Television Advertisement' },
	{ key: 4, value: 'Print Advertisement' },
	{ key: 5, value: 'Online Advertisement' },
	{ key: 6, value: 'Event' },
	{ key: 7, value: 'Social Media' },
	{ key: 8, value: 'Read an Article' },
	{ key: 9, value: 'Other' },
];
