import React from 'react';

import { ContactWidgetProps } from './ContactWidgetProps';

import { MemberContactWidget } from './MemberContactWidget';
import { PublicUserContactWidget } from './PublicUserContactWidget';

export const ContactWidget: React.FC<ContactWidgetProps> = ({ type, info, displayOpts, imageServerUrl }) =>
	type === 'public' ? (
		<PublicUserContactWidget {...displayOpts} />
	) : (
		<MemberContactWidget info={info} imageServerUrl={imageServerUrl} displayOpts={displayOpts} />
	);
