/* eslint-disable no-bitwise */
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { sortWith, descend, ascend, prop } from 'ramda';
import { SortFilters, SortDirection } from '../../enums';
import { SET_RESULTS, RESET_RESULTS } from '../constants';
import { State } from '../context';
import { monthYearRegex, getChecked } from './util';

export const getResults = () => (dispatch: ThunkDispatch<State, undefined, Action>, getState: () => State) => {
	dispatch({ type: RESET_RESULTS });

	const currentExperienceTypes = getState().experienceTypes;
	const currentDates = getState().dates;
	const currentLocations = getState().locations;

	const checkedDates = [...new Set(getChecked(currentDates))];
	const checkedTypes = [...new Set(getChecked(currentExperienceTypes))];
	const checkedLocations = [...new Set(getChecked(currentLocations))];

	const isDate = result =>
		checkedDates.length > 0 ? checkedDates.includes(result.filterDate.replace(monthYearRegex, '/01/')) : true;
	const isExperience = result => (checkedTypes.length > 0 ? checkedTypes.includes(result.experienceType) : true);
	const isLocation = result =>
		checkedLocations.length > 0 ? checkedLocations.includes(result.filterLocation) : true;

	const getReducedResults = (
		results,
		opts: { location?: boolean; date?: boolean; experienceType?: boolean } = {},
	) => {
		const HAS_LOC = 1; // 001
		const HAS_DATE = 1 << 1; // 010
		const HAS_TYPE = 1 << 2; // 100
		let bitwiseNumber = 0;

		// This uses the bitwise | to form a union
		if (opts['location'] === true) {
			bitwiseNumber |= HAS_LOC;
		}
		if (opts['date'] === true) {
			bitwiseNumber |= HAS_DATE;
		}
		if (opts['experienceType'] === true) {
			bitwiseNumber |= HAS_TYPE;
		}

		switch (bitwiseNumber) {
			case HAS_DATE | HAS_TYPE:
				return results.filter(result => isDate(result) && isExperience(result));
			case HAS_LOC | HAS_DATE:
				return results.filter(result => isDate(result) && isLocation(result));
			case HAS_LOC | HAS_TYPE:
				return results.filter(result => isExperience(result) && isLocation(result));
			case bitwiseNumber | (HAS_LOC | HAS_DATE | HAS_TYPE):
				return results.filter(result => isDate(result) && isExperience(result) && isLocation(result));
			default:
		}
	};

	let newResults = getReducedResults(getState().experiences, { location: true, date: true, experienceType: true });

	const newResultsWithoutLocation = getReducedResults(getState().experiences, {
		location: false,
		date: true,
		experienceType: true,
	});
	const newResultsWithoutType = getReducedResults(getState().experiences, {
		location: true,
		date: true,
		experienceType: false,
	});
	const newResultsWithoutDate = getReducedResults(getState().experiences, {
		location: true,
		date: false,
		experienceType: true,
	});

	const enabledDates = newResultsWithoutDate.reduce((acc, curr) => {
		acc.push(curr.filterDate.replace(monthYearRegex, '/01/'));
		return acc;
	}, []);
	const enabledTypes = newResultsWithoutType.reduce((acc, curr) => {
		acc.push(curr.experienceType);
		return acc;
	}, []);
	const enabledLocations = newResultsWithoutLocation.reduce((acc, curr) => {
		acc.push(curr.filterLocation);
		return acc;
	}, []);

	const newExperienceTypes = currentExperienceTypes.map(type => ({
		...type,
		disabled: !enabledTypes.includes(type.id),
	}));
	const newDates = currentDates.map(date => ({
		...date,
		disabled: !enabledDates.includes(date.id),
	}));
	const newLocations = currentLocations.map(location => ({
		...location,
		disabled: !enabledLocations.includes(location.id),
	}));

	let sort = null;
	// Sort Results
	switch (getState().sort.key) {
		case SortFilters.InspiratoOnly:
			sort = sortWith([descend(prop('inspiratoOnly')), ascend(prop('sortDate')), descend(prop('intValue'))]);
			newResults = sort(newResults);
			break;
		case SortFilters.CheckInDate:
			if (getState().sort.direction === SortDirection.Descending) {
				sort = sortWith([descend(prop('sortDate')), descend(prop('inspiratoOnly')), descend(prop('intValue'))]);
			} else {
				sort = sortWith([ascend(prop('sortDate')), descend(prop('inspiratoOnly')), descend(prop('intValue'))]);
			}
			newResults = sort(newResults);
			break;
		case SortFilters.Price:
			if (getState().sort.direction === SortDirection.Descending) {
				sort = sortWith([descend(prop('intValue')), descend(prop('inspiratoOnly')), descend(prop('sortDate'))]);
			} else {
				sort = sortWith([ascend(prop('intValue')), descend(prop('inspiratoOnly')), descend(prop('sortDate'))]);
			}
			newResults = sort(newResults);
			break;
		default:
			return;
	}

	dispatch({
		type: SET_RESULTS,
		payload: { newDates, newExperienceTypes, newResults, newLocations },
	});
};
