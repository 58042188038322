import React, { FC, memo } from 'react';
import moment from 'moment';
import { renderLoaderOrContent } from 'components/Loader/LoaderSkeleton';
import { CardFooterInner, ItemContainer, Date, StatsLabel, ArrowIcon } from '../ListCard.styles';

const checkInDateFormat = {
	full: 'MMM DD, YYYY',
	day: 'dddd',
};

interface Props {
	isPlaceholder: boolean;
	isListAndIsDesktop: boolean;
	checkInDate: any;
	checkOutDate: any;
}

export const DateContent: FC<Props> = memo(({ isPlaceholder, isListAndIsDesktop, checkInDate, checkOutDate }) => {
	return (
		<CardFooterInner isList={isListAndIsDesktop}>
			<ItemContainer>
				<Date>
					{renderLoaderOrContent(isPlaceholder, moment.utc(checkInDate).format(checkInDateFormat.full), {
						width: '5em',
					})}
				</Date>
				<StatsLabel>
					{renderLoaderOrContent(isPlaceholder, moment.utc(checkInDate).format(checkInDateFormat.day), {
						width: '5em',
					})}
				</StatsLabel>
			</ItemContainer>
			<ArrowIcon isList={isListAndIsDesktop} isPlaceholder={isPlaceholder} iconName='arrow-long' iconSize='md' />
			<ItemContainer style={{ textAlign: 'right' }}>
				<Date>
					{renderLoaderOrContent(isPlaceholder, moment.utc(checkOutDate).format(checkInDateFormat.full), {
						width: '5.5em',
					})}
				</Date>
				<StatsLabel>
					{renderLoaderOrContent(isPlaceholder, moment.utc(checkOutDate).format(checkInDateFormat.day), {
						width: '5.5em',
					})}
				</StatsLabel>
			</ItemContainer>
		</CardFooterInner>
	);
});
