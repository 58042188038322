import { useEffect } from 'react';
import root from 'window-or-global';

// Hook to help handle clicking outside of element
export const useClickOff = (ref: any, clickOffHandler: Function) => {
	useEffect(() => {
		const handleClick = e => {
			ref.current && !ref.current.contains(e.target) && clickOffHandler(e);
		};

		if (ref.current) {
			root.addEventListener('mousedown', handleClick);
			root.addEventListener('touchend', handleClick);
		}

		// unmount previous first in case input have changed
		return () => {
			root.removeEventListener('mousedown', handleClick);
			root.removeEventListener('touchend', handleClick);
		};
	}, [clickOffHandler, ref.current]);
};
