import * as React from 'react';
import styled, { css } from 'styled-components';

import { CallToActionProps } from 'components/ActionLink/CallToActionProps';
import { containerWidth } from 'design-system/style-mixins/grid-and-layout';
import { CmsComposer } from 'components/CmsComposer';
import {
	SectionComponentProps,
	SectionComponentBottomPadding,
	SectionComponentTopPadding,
	SectionComponentsPerRow,
} from './SectionComponentProps';
import {
	getColumnCssClasses,
	determineLayoutStyle,
	getComponentsPerRowCssClass,
	getComponentsPerRowSchema,
} from './Helpers';
import { BackgroundImage } from './BackgroundImage';
import { Header } from './Header';
import { Action } from './Action';
import {
	spaceHuge,
	spaceMassive,
	spaceMedium,
	spaceSmall,
	spaceSuperMassive,
} from '../../design-system/variables/spaces';
import { white } from '../../design-system/variables/colors';
import { lg, md, sm, xsOnly } from '../../design-system/media-queries';
import { sectionComponentCmsMap } from './sectionComponentCmsMap';

const getWrapperPadding = (padding: SectionComponentTopPadding | SectionComponentBottomPadding, PaddingEnum: any) => {
	if (padding === PaddingEnum.ExtraPadding) {
		return '120px';
	}
	if (padding === PaddingEnum.Collapsed) {
		return 0;
	}
};
const Container = styled.section<{
	backgroundColor: string;
	topPadding: SectionComponentTopPadding;
	bottomPadding: SectionComponentBottomPadding;
}>`
	padding: ${spaceHuge} ${spaceSmall};
	position: relative;

	${sm`
		padding-left: ${spaceMedium};
		padding-right: ${spaceMedium};
	`}

	${md`
		padding-bottom: ${spaceMassive};
		padding-top: ${spaceMassive};
	`}

	${lg`
		padding-bottom: ${spaceSuperMassive};
		padding-top: ${spaceSuperMassive};
	`}

	${props => css`
		background: ${props.backgroundColor};
	`}

	&& {
		${props =>
			css`
				padding-top: ${getWrapperPadding(props.topPadding, SectionComponentTopPadding)};
			`}

		${props =>
			css`
				padding-bottom: ${getWrapperPadding(props.bottomPadding, SectionComponentBottomPadding)};
			`}
	}


	.responsive-page__content--is-fenced & {
		&:first-child {
			${sm`
				display: flex;
				height: calc(100vh - 290px);
				min-height: 734px;
			`}

			${md`
				height: calc(100vh - 352px);
				min-height: 727px;
			`}

			${lg`
				height: calc(100vh - 336px);
				min-height: 743px;
			`}
		}
		.section-component--collapsetop {
			padding-top: ${spaceHuge};
		}
	}
`;

const CallToActionWrapper = styled.div<{ callToAction: CallToActionProps; rowReverse: boolean }>`
	&& {
		margin-left: auto;
		margin-right: auto;
		position: relative;
	}
	${containerWidth};
	${props =>
		props.callToAction &&
		props.callToAction.canDisplayAsTextLink &&
		css`
			padding-bottom: 55px;
		`}
	${props =>
		props.rowReverse &&
		css`
			flex-direction: row-reverse;
		`}
`;

const Columns = styled.div<{ componentsPerRow: string; componentsPerRowSchema: number[] }>`
	${props =>
		props.componentsPerRow === SectionComponentsPerRow.fullScreen &&
		css`
			left: 50%;
			margin-left: -50vw;
			margin-right: -50vw;
			right: 50%;
			width: 100vw;
		`}
	${props => {
		const [, totalColumns] = props.componentsPerRowSchema;
		if (totalColumns === 2) {
			return css`
				&& {
					padding-left: 0;
					padding-right: 1rem;
					${xsOnly`
						padding-right: 0;
					`}
				}
			`;
		}
	}}
`;

Container.defaultProps = {
	backgroundColor: white,
};

const SectionComponent: React.FC<SectionComponentProps> = props => {
	const {
		animationSettings,
		backgroundImage,
		backgroundColor,
		callToAction,
		componentsPerRow,
		headline,
		modifier,
		nestedComponents,
		subhead,
		textColor,
		topPadding,
		bottomPadding,
		rowReverse,
		verticalAlignment,
		body,
	} = props;

	const URL_GET_STARTED = '/public/getstarted/';
	const isRegistrationCTA = callToAction && callToAction.url.toLowerCase() === URL_GET_STARTED;
	const columnCssClasses = getColumnCssClasses(componentsPerRow);

	return (
		<Container {...{ backgroundColor, topPadding, bottomPadding }} className={`${modifier || ''}`}>
			<BackgroundImage {...backgroundImage} />
			<Header {...{ animationSettings, headline, textColor, subhead, body }} />
			{nestedComponents && nestedComponents.length > 0 && (
				<CallToActionWrapper
					className={`row ${determineLayoutStyle({
						nestedComponents,
						verticalAlignment,
					})}`}
					callToAction={callToAction}
					rowReverse={rowReverse}
				>
					{nestedComponents.map((component, index) => {
						return (
							<Columns
								componentsPerRow={componentsPerRow}
								className={`${getComponentsPerRowCssClass(
									componentsPerRow,
									index,
								)} ${columnCssClasses}`}
								key={index}
								componentsPerRowSchema={getComponentsPerRowSchema(componentsPerRow, index)}
							>
								<CmsComposer {...component} componentMap={sectionComponentCmsMap} />
							</Columns>
						);
					})}
				</CallToActionWrapper>
			)}
			<Action {...{ callToAction, isRegistrationCTA }} />
		</Container>
	);
};

export { SectionComponent };
