import React, { memo } from 'react';
import styled from 'styled-components';

import { NavigationLinkProps } from '../NavigationLinkProps';

import { md, xsSmOnly } from '../../../design-system/media-queries';
import { spaceBase } from '../../../design-system/variables/spaces';
import { boxShadow3 } from '../../../design-system/style-mixins/shadows-and-scrims';
import { white } from '../../../design-system/variables/colors';

import { Column } from './Column';

interface SingleColumnNavigationProps {
	links: NavigationLinkProps[];
}

const Wrapper = styled.div`
	z-index: 9000;
	flex-direction: column;
	cursor: auto;
	padding-bottom: ${spaceBase};
	padding-top: ${spaceBase};

	${xsSmOnly`
		&:last-child {
			padding-bottom: 0;
		}
	`}

	${md`
		visibility: hidden;
		${boxShadow3}
		background: ${white};
		left: -${spaceBase};
		min-width: 170px;
		padding: ${spaceBase};
		position: absolute;
		top: 81px;
	`}
`;

export const SingleColumnNavigation: React.FC<SingleColumnNavigationProps> = memo(({ links }) => (
	<Wrapper className='submenu'>
		<Column links={links} />
	</Wrapper>
));
