import React, { FC, memo, useState } from 'react';
import { Notification } from 'components';
import styled from 'styled-components';

const ListStyleWrapper = styled.ul`
	list-style-type: none;
`;

export const BannerWrapper = styled.div`
	height: 0;
`;

export const BannerErrorWrapper = styled(BannerWrapper)`
	max-width: 550px;
	left: 228px;
	z-index: 99999;
	position: absolute;
	top: 80px;
	font-size: 12px;
`;

const RoundedNotification = styled(props => <Notification {...props}/>)`
	border-radius: 5px;
`;

export const ErrorBanner: FC<{ isOpen: boolean, message: string, handleClose }> = ({ isOpen, message, handleClose }) => {
	return (
		<RoundedNotification
			isOpen={isOpen}
			type={'error'}
			centerContent={false}
			onClose={handleClose}
			canClose={true}
		>
			<ListStyleWrapper>
				<li>{message}</li>
			</ListStyleWrapper>
		</RoundedNotification>
	);
};
