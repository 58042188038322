import styled, { keyframes } from 'styled-components';
import React, { FC } from 'react';
import { white } from '../../design-system/variables/colors';
import { spaceTiny } from '../../design-system/variables/spaces';
import { transparentDark70 } from '../../design-system/style-mixins/shadows-and-scrims';
import { Icon } from 'components/Icon';

const spinner = keyframes `
	20% {
		transform: rotate(0);
	}
	80%,100% {
		transform: rotate(1turn);
	}
`;
const logoEnter = keyframes `
	from {
		opacity: 0;
		transform: translateY(${spaceTiny});
	}
`;
const diamondsEnter = keyframes `
	to {
		opacity: 1;
	}
`;
const Wrapper = styled.div`
	--circle-color: ${transparentDark70};
	--circle-diameter: 98px;
	--circle-radius: (var(--circle-diameter) / 2);
	--svg-location: 50%;
	--svg-dimension: 142px;
	--svg-half-dimension: (var(--svg-dimension) / 2);
	--svg-icon-dimension: 32px;
	--svg-icon-half-dimension: (var(--svg-icon-dimension) / 2);
	--primary-color: ${white};
	--drop-shadow: drop-shadow( 1px 1px 1px rgba(0, 0, 0, 0.3));
	--fadein-animation-duration: 400ms;
	--fadein-animation: var(--fadein-animation-duration) ease-in-out forwards;
	--spinner-animation: 6s ease-in-out alternate infinite;
`;
const Circle = styled.div`
	animation: ${diamondsEnter} var(--fadein-animation);
	width: var(--circle-diameter);
	height: var(--circle-diameter);
	background-color: var(--circle-color);
	border-radius: 50%;
	position: absolute;
	top: calc(var(--svg-location) - var(--circle-radius));
	left: calc(var(--svg-location) - var(--circle-radius));
`;
const SvgContainer = styled.div `
	width: var(--svg-dimension);
	height: var(--svg-dimension);
	position: absolute;
	top: calc(var(--svg-location) - var(--svg-half-dimension));
	left: calc(var(--svg-location) - var(--svg-half-dimension));
`;
const DiamondContainerSVG = styled.svg`
	filter: var(--drop-shadow);
	position:absolute;
	width: var(--svg-dimension);
	height: var(--svg-dimension);

	polygon {
		animation:
			${diamondsEnter} var(--fadein-animation),
			${spinner} var(--spinner-animation);
		animation-delay:
			calc(var(--fadein-animation-duration) / 5),
			var(--fadein-animation-duration);
		transform-origin: 50%;
		fill: transparent;
		stroke: var(--primary-color);
		opacity: 0;

		&:first-of-type {
			animation-delay:
			calc(var(--fadein-animation-duration) / 2.5),
			var(--fadein-animation-duration);
			animation-direction: normal, alternate-reverse;
		}
	}
`;
const Logo = styled(Icon)`
	filter: var(--drop-shadow);
	fill: var(--primary-color);
	position:absolute;
	transform-origin: 50%;
	animation: ${logoEnter} var(--fadein-animation);
	top: calc(var(--svg-location) - var(--svg-icon-half-dimension));
	left: calc(var(--svg-location) - var(--svg-icon-half-dimension));
`;

/**
 * Loaders:
 * Used for progress & loading indicators.
 */

export const LoaderCompass: FC<LoaderCompassProps> = () => {
	return (
		<Wrapper>
			<Circle />
			<SvgContainer>
				<DiamondContainerSVG viewBox='0 0 44 98'>
					<polygon points='44 49 22 98 0 49 22 0'/>
					<polygon points='40 49 22 89 4 49 22 9'/>
				</DiamondContainerSVG>
				<Logo iconName='logo-mark' iconSize='lg'/>
			</SvgContainer>
		</Wrapper>
	);
};
