import React, { Fragment, memo, useContext, useEffect, useCallback } from 'react';

import { KeyboardAccessibilityContext } from 'components/KeyboardAccessibility';
import { transformSearchResultsToMenuOptionsIds } from './utils';
import { SearchResultsSetProps } from './SearchResultsSetProps';

import { Category, Result } from './components';
import { Wrapper } from './SearchResultsSet.styles';

export const SearchResultsSet: React.FC<SearchResultsSetProps> = memo(
	({ searchResults, identificator, id, ...rest }) => {
		const { setActiveId } = useContext(KeyboardAccessibilityContext);
		const setFirstResultsAsActive = useCallback(() => {
			const menuOptionsIds = transformSearchResultsToMenuOptionsIds(searchResults, identificator);

			setActiveId(menuOptionsIds[0]);
		}, [searchResults]);
		useEffect(() => {
			setFirstResultsAsActive();
		}, []);

		useEffect(() => {
			setFirstResultsAsActive();
		}, [searchResults]);

		const wrapperAttributes = {
			'data-node': 'search-results',
			id,
		};

		return (
			<Wrapper {...wrapperAttributes}>
				{searchResults.map(({ category, items }) => (
					<Fragment key={category}>
						<Category category={category} />
						{items.map(item => (
							<Result key={item.id || item.name} {...{ item, identificator, ...rest }} />
						))}
					</Fragment>
				))}
			</Wrapper>
		);
	},
);
