import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { includes } from 'ramda';
import root from 'window-or-global';
import TabProps from './TabProps';
import { sm } from '../../../design-system/media-queries';
import { textBase } from '../../../design-system/style-mixins/text';
import { spaceSmall, spaceTiny } from '../../../design-system/variables/spaces';
import {
	black,
	dividerMedium,
	grayDark,
	primaryLinkColor,
	textColorDark,
	textColorMedium,
} from '../../../design-system/variables/colors';

const Anchor = styled.a<{ isActive: boolean }>`
	${textBase}

	color: ${primaryLinkColor};
	display: inline-block;
	height: 42px;
	padding: ${spaceSmall} 0;
	position: relative;

	&:hover {
		border-bottom: ${dividerMedium};
		${textColorDark};
		cursor: pointer;

		svg {
			cursor: pointer;
			fill: ${black};
		}
	}

	svg {
		fill: ${grayDark};
		margin-left: -${spaceTiny};
		vertical-align: -3px;

		${sm`
			margin-left: 0;
		`}
	}

	${props =>
		props.isActive &&
		css`
			border-bottom: ${dividerMedium};
			&& {
				${textColorDark}
			}
		`}
`;

const TabElement = styled.div<{ isExternalUrl: boolean }>`
	margin-bottom: -1px; /* tab border sits over listing border */
	padding-left: 0;
	padding-right: 0;

	${props =>
		props.isExternalUrl &&
		css`
			padding-right: 0;
			${sm`padding-right: 0;`}
		`}

	a {
		${textColorMedium};
	}
`;

export const Tab: React.FunctionComponent<TabProps> = (props: TabProps) => {
	const { title, url, isExternalUrl, isActiveTab = false } = props;
	const [isActive, setIsActive] = useState(false);

	useEffect(() => {
		setIsActive(includes(url, root.location.href));
	}, [root.location.href, url]);

	useEffect(() => {
		setIsActive(isActiveTab || includes(url, root.location.href));
	}, []);

	return (
		<TabElement isExternalUrl={isExternalUrl} className='col-xs col-sm-3 col-md-2'>
			<Anchor isActive={isActive} href={url} target={isExternalUrl ? '_blank' : '_self'}>
				{title}
				{isExternalUrl ? (
					<svg className='ds-icon-link-external ds-icon-sm'>
						<use xlinkHref='#ds-icon-link-external' />
					</svg>
				) : null}
			</Anchor>
		</TabElement>
	);
};
