import styled, { createGlobalStyle, css } from 'styled-components';
import { lightWeight, textBase } from 'design-system/style-mixins/text';
import { textColorMedium, textColorLight, white, textColorDark, paleBlue, primaryLinkColor, grayMedium } from 'design-system/variables/colors';
import { spaceBase, spaceSmall, spaceLarge } from 'design-system/variables/spaces';

export const InputContainer = styled.label`
	align-items: center;
	cursor: pointer;
	display: flex;
	width: 100%;

	svg {
		pointer-events: none;
	}

	input {
		border: 0;
		margin: 0;
		margin-bottom: 0 !important;
		padding-left: ${spaceBase};
		width: 100%;

		&::placeholder {
			color: ${textColorMedium};
		}
	}

	> div {
		width: 100%;
	}
`;

const dayBackgroundBefore = css`
	content: "";
	position: absolute;
	top: 2px;
	left: 0;
	width: 100%;
	height: 50px;
	z-index: -1;
`;

export const GlobalStyle = createGlobalStyle`
	.DayPickerInput {
		width: 100%;

		.DayPicker {
			&:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
				background-color: ${white};
			}

			.DayPicker-Month {
				border-collapse: separate;
			}

			.DayPicker-Day {
				${lightWeight}
				${textColorDark}
				${textBase}

				border-radius: 0;
				height: 53px;
				width: 53px;
				margin: 1px;

				&--outside,
				&--outside:hover {
					${textColorLight}

					background-color: ${white};
				}

				/* Leaving this commented to be revisited for the gradient
				TODO: Needs to have the height of the gradient worked out
				&--selected:not(.DayPicker-Day--outside) + .DayPicker-Day--outside.DayPicker-Day--selected {
					background-image: linear-gradient(90deg, ${paleBlue} 11%, ${white});
					${dayBackgroundBefore}
				}*/

				&--disabled:not(.DayPicker-Day--outside),
				&--disabled:not(.DayPicker-Day--outside):hover {
					opacity: 0.25;
				}

				&:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
					border: 1px solid ${grayMedium};
					border-radius: 50%;
					background-color: ${white};
				}

				&--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):not(.DayPicker-Day--start):not(.DayPicker-Day--end) {
					background-color: transparent;
					border-radius: 0;
					${textColorDark}

					&::before {
						background-color: ${paleBlue};
						${dayBackgroundBefore}
					}

					&:hover {
						border: 1px solid ${grayMedium};
						border-radius: 50%;
						background-color: ${white};

						&::before {
							background-color: transparent;
							background-image: linear-gradient(90deg, ${paleBlue}, ${white});
						}
					}
				}

				.DayPicker-Day--start + .DayPicker-Day--disabled.DayPicker-Day--selected {
					background-color: red !important;
				}

				&--start:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside), &--end:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
					background-color: ${primaryLinkColor} !important;
					border-radius: 50% !important;
					color: ${white} !important;
				}

				&--start:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside)::before, &--end:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside)::before {
					${dayBackgroundBefore}
				}

				&--start.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):not(.DayPicker-Day--end)::before {
					background-image: linear-gradient(90deg, ${white}, ${paleBlue});
				}

				&--end.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):not(.DayPicker-Day--start)::before {
					background-image: linear-gradient(90deg, ${paleBlue}, ${white});
				}
			}
		}
	}

	.DayPicker-NavButton {
		height: ${spaceBase};
		width: ${spaceBase};
	}

	.DayPicker-NavButton--prev {
		&& {
			left: ${spaceLarge};
		}
	}

	.DayPicker-NavButton--next {
		&& {
			margin-right: ${spaceSmall};
		}
	}

	.DayPicker-Caption {
		&& {
			${textColorDark}
			text-align: center;
		}
	}

	.DayPicker-Weekday {
		abbr {
			${textColorMedium}
		}
	}
`;
