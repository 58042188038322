import React, { FC } from 'react';
import { Icon } from 'components/Icon/Icon';
import { error, info, success, warning, white, blue, black } from '../../../src/design-system/variables/colors';
import { NotificationContainer, CloseButton, Content } from './NotificationStyles';

const notificationTypes = {
	default: {
		backgroundColor: blue,
		icon: '',
	},
	error: {
		backgroundColor: error,
		color: white,
		icon: 'error',
	},
	info: {
		backgroundColor: info,
		icon: 'info',
	},
	question: {
		backgroundColor: black,
		color: white,
		icon: 'help',
	},
	success: {
		backgroundColor: success,
		icon: 'check',
	},
	warning: {
		backgroundColor: warning,
		icon: 'arrow-long',
	},
};

interface NotificationProps {
	className?: string;
	type?: 'default' | 'error' | 'info' | 'question' | 'success' | 'warning';
	canClose?: boolean;
	isOpen: boolean;
	centerContent?: boolean;
	onClose?: () => void;
}

export const Notification: FC<NotificationProps> = ({
	children,
	type = 'default',
	canClose = true,
	isOpen = false,
	centerContent,
	onClose,
	className,
	...rest
}) => {
	const notificationType = notificationTypes[type];

	return (
		<NotificationContainer
			backgroundColor={notificationType.backgroundColor}
			className={className}
			color={notificationType.color}
			isOpen={isOpen}
			hasIcon={!!notificationType.icon}
			{...rest}
		>
			{notificationType.icon && (
				<Icon iconName={notificationType.icon} iconSize='md' fill={notificationType.color}></Icon>
			)}
			<Content centerContent={centerContent}>{children}</Content>
			{canClose && (
				<CloseButton
					buttonType='containerless'
					iconName='close'
					iconSize='sm'
					color={notificationType.color}
					onClick={onClose}
				></CloseButton>
			)}
		</NotificationContainer>
	);
};
