import { Keys } from 'enums/Keyboard';
import { scrollTo } from 'components/KeyboardAccessibility/utils';
import { transformSearchResultsToMenuOptionsIds } from 'components/SearchResultsSet/utils';
import { AccentMap } from './enums';

const onUpDownPressed = ({ showResults, isResultsVisible, searchResults, setActiveId, activeId, e }) => {
	const menuOptionsId = transformSearchResultsToMenuOptionsIds(searchResults, 'identificator');
	const activeIndex = menuOptionsId.findIndex(id => activeId === id);
	const lastIndex = menuOptionsId.length - 1;
	let nextIndex;

	if (e.key === Keys.ArrowUp) {
		nextIndex = activeIndex === 0 ? lastIndex : activeIndex - 1;
	} else {
		if (!isResultsVisible) {
			showResults();
		}
		nextIndex = activeIndex === lastIndex ? 0 : activeIndex + 1;
	}

	setActiveId(menuOptionsId[nextIndex]);
};

const onEnterPressed = ({ activeId, selectResult, searchResults }) => {
	if (activeId) {
		const selected = searchResults
			.map(category => category.items)
			.flat()
			.find(item => item.identificator === activeId);

		selectResult(selected.name, selected);
	}
};

export const handleKeyDownUtil = ({
	isResultsVisible,
	selectResult,
	searchResults,
	showResults,
	setActiveId,
	activeId,
	e,
}) => {
	switch (e.key) {
		case Keys.ArrowUp:
		case Keys.ArrowDown:
			onUpDownPressed({ showResults, isResultsVisible, searchResults, setActiveId, activeId, e });
			break;
		case Keys.Enter:
			onEnterPressed({ activeId, selectResult, searchResults });
			break;
		default:
			break;
	}
};

export const scrollToSelectedOption = (menuEl: HTMLElement, focusedEl: HTMLElement) => {
	if (!menuEl || !focusedEl) {
		return;
	}
	const menuRect = menuEl.getBoundingClientRect();
	const focusedRect = focusedEl.getBoundingClientRect();
	const overScroll = focusedEl.offsetHeight / 10;

	if (focusedRect.top - overScroll < menuRect.top) {
		scrollTo(menuEl, 0, Math.max(focusedEl.offsetTop - overScroll - 50, 0));
	} else if (focusedRect.bottom + overScroll > menuRect.bottom) {
		scrollTo(
			menuEl,
			0,
			Math.min(
				focusedEl.offsetTop + focusedEl.clientHeight - menuEl.offsetHeight + overScroll - 40,
				menuEl.scrollHeight,
			),
		);
	}
};

function normalizeSpecialCharacters(term) {
	let ret = "";

	for (let i = 0; i < term.length; i++) {
		ret += AccentMap[term.charAt(i)] || term.charAt(i);
	}

	return ret;
}

function sanitizeString(string) {
	let str = string.toLowerCase();

	//replace multiple spaces with single space:
	str = str.replace(/ +(?= )/g, '');
	str = str.trim();

	str = str.replace(/,/g, '');
	str = str.replace(/\./g, '');
	str = str.replace(/\'/g, '');
	str = str.replace(/\’/g, '');

	str = normalizeSpecialCharacters(str);

	return str;
}

export function matchesString(stringToCompare, search) {
	if (stringToCompare === undefined ||
		stringToCompare === "" ||
		search === undefined ||
		search === "") {
		return false;
	}

	stringToCompare = sanitizeString(stringToCompare);
	search = sanitizeString(search);

	const indexOfSearchInString = stringToCompare.indexOf(search);
	const searchFoundAtStartOfString = indexOfSearchInString === 0;
	const searchFoundAtStartOfWord = indexOfSearchInString > 0 &&
		stringToCompare[indexOfSearchInString - 1] === " ";

	if (searchFoundAtStartOfString || searchFoundAtStartOfWord) {
		return true;
	}

	return false;
}

export function findMatchingResults({ data, keyword }) {
	const matchingSearchResults = data.reduce((acc, current, index, array) => {
		let results = [];
		switch(current.category) {
			case 'Destinations':
			results = current.items.filter((destination) => matchesString(destination.Name, keyword));
			break;
			case 'Hotels & Resorts':
			results = current.items.filter((property) => {
				const destination = array[0].items.find(item => item.Id === property.DestinationID);

				const matchesOnProperty = matchesString(property.Name, keyword);
				if (matchesOnProperty) {
					return true;
				}

				if (destination && destination.Name) {
					return matchesString(destination.Name, keyword);
				}
				return false;
			})
			break;
			case 'Accomodations':
			results = current.items.filter((residence) => {
				const destination = array[0].items.find(item => item.Id === residence.Destination.Id);
				const matchesOnResidenceName = matchesString(residence.Name, keyword);
				const matchesOnResortName = matchesString(residence.ResortName, keyword);

				if (matchesOnResidenceName || matchesOnResortName) {
					return true;
				}

				if (destination && destination.Name) {
					return matchesString(destination.Name, keyword);
				}

				return matchesOnResidenceName;
			})
			break;
		}


		if(results.length) {
			acc.push({...current, items: results});
		}
		return acc;
	}, []);

	return matchingSearchResults;
}
