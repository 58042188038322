import React, { FC, useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import root from 'window-or-global';

import { spaceBase, spaceTiny } from 'design-system/variables/spaces';
import { sm } from 'design-system/media-queries';
import { ValidationRules } from 'services/ValidationRules';
import { i18n as i18n2Instance, locales } from 'services/i18n';
import { registrationService } from 'services';
import { getSafeHtml } from 'services/Helpers';

import { Country as CountryEnum } from 'enums/Country';
import { Button } from 'components/Button';
import { ReferralSources } from './ReferralSources';
import { FirstName } from '../shared/FirstName';
import { LastName } from '../shared/LastName';
import { Password } from '../shared/Password';
import { Country } from '../shared/Country';
import { Email } from '../shared/Email';
import { FormContext, FormStateProps } from '../shared/FormContext';

import { Analytics } from './Enums';

const Form = styled.form`
	display: flex;
	flex-direction: column;
`;

const SubmitButton = styled(Button)`
	margin-bottom: ${spaceBase};
	width: 100%;
`;

const UserAnalytics = styled.div`
	display: none;
`;

const FirstNameWrapper = styled.div`
	&& {
		${sm`
			padding-right: ${spaceTiny}
		`}
	}
`;

const LastNameWrapper = styled.div`
	&& {
		${sm`
			padding-left: ${spaceTiny}
		`}
	}
`;

export const RegistrationForm: FC<{}> = () => {
	const [state, setState] = useState<FormStateProps>({});
	const [analytics, setAnalytics] = useState(null);
	const [isErrorShown, setIsErrorShown] = useState(false);
	const formRef = useRef(null);
	const { t, i18n } = useTranslation(null, { i18n: i18n2Instance });
	const validationRules = new ValidationRules(t);
	const setFieldValue = updatedField =>
		setState(prevState => {
			return { ...prevState, ...updatedField };
		});
	const setAnalyticsCookie = () => {
		const emailEncoded = encodeURIComponent(state.Email);

		setAnalytics(`<iframe src="${Analytics.URL}${emailEncoded}"></iframe>`);
	};
	const submitForm = e => {
		e.preventDefault();
		const formElements = [...formRef.current.elements];
		const hasInvalidInputs = formElements.some(input => input.dataset.isInvalid);

		if (!hasInvalidInputs) {
			registrationService(state).then(data => {
				if (data.Success) {
					setAnalyticsCookie();
					root.location.reload();
				}
			});
		} else {
			setIsErrorShown(true);
		}
	};

	useEffect(() => {
		setFieldValue({ CountryID: CountryEnum.UnitedStates.code });
	}, []);

	useEffect(() => {
		const countryId = state.CountryID;
		if (!countryId) {
			return;
		}
		const newCountryKey = Object.keys(CountryEnum).find(key => {
			return CountryEnum[key].code === countryId;
		});
		const newLocale = CountryEnum[newCountryKey].locale || locales.English;
		i18n.changeLanguage(newLocale);
	}, [state.CountryID]);

	return (
		<FormContext.Provider value={{ validationRules, setFieldValue, isErrorShown, t, state }}>
			<Form ref={formRef}>
				<div className='row'>
					<FirstNameWrapper className='col-xs-12 col-sm-6'>
						<FirstName />
					</FirstNameWrapper>
					<LastNameWrapper className='col-xs-12 col-sm-6'>
						<LastName />
					</LastNameWrapper>
				</div>
				<div className='row'>
					<div className='col-xs-12'>
						<Email />
					</div>
				</div>
				<div className='row'>
					<div className='col-xs-12'>
						<Password />
					</div>
				</div>
				<Country />
				<ReferralSources />
				<UserAnalytics dangerouslySetInnerHTML={getSafeHtml(analytics)} />
				<div className='row'>
					<div className='col-xs-12'>
						<SubmitButton type='submit' buttonType='primary' size='base' onClick={submitForm}>
							View availability
						</SubmitButton>
					</div>
				</div>
			</Form>
		</FormContext.Provider>
	);
};
