import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIsDesktop } from 'components/hooks';
import { CheckboxGroup } from 'components/Checkbox';
import { FilterButtonActions } from 'components/FilterButtonActions';
import { FilterAccordion } from 'components/Accordion';
import { Dropdown } from 'components/Dropdown';
import { FilterContainer, FilterLabel } from '../Filters.styles';
import { useCheckedCount } from '../utils';
import { getLocationsFilter } from '../../redux/selectors';
import { getResults, clearLocationFilter, updateLocationFilter } from '../../redux/actions';

export const LocationFilter: FC = () => {
	// Redux
	const dispatch = useDispatch();
	const locations = useSelector(state => getLocationsFilter(state));
	// React
	const [isOpen, setIsOpen] = useState(false);
	// Custom
	const [selectedCount] = useCheckedCount(locations);
	const [isDesktop] = useIsDesktop(768);

	const toggleOpen = () => setIsOpen(!isOpen);

	const handleClick = ({ id }) => {
		dispatch(updateLocationFilter(id));
		if (!isDesktop) {
			dispatch(getResults());
		}
	};
	const handleClear = () => {
		dispatch(clearLocationFilter());
		dispatch(getResults());
	};
	const handleApply = () => {
		dispatch(getResults());
		toggleOpen();
	};

	const Filter = (
		<FilterContainer>
			{isDesktop && <FilterLabel>Location</FilterLabel>}
			<CheckboxGroup onCheckboxClick={handleClick} members={locations} />
		</FilterContainer>
	);

	const Footer = (
		<FilterButtonActions onClearClick={handleClear} onApplyClick={handleApply} isDisabled={selectedCount === 0} />
	);

	if (!isDesktop) {
		return (
			<FilterAccordion title='Location' accordionId='LOCATION'>
				{Filter}
			</FilterAccordion>
		);
	}

	return (
		<Dropdown
			title={`Location ${selectedCount > 0 ? `(${selectedCount})` : ''}`}
			footer={Footer}
			onOpen={() => {}}
			controlledOpen={{ open: isOpen, action: toggleOpen }}
			onClose={() => dispatch(getResults())}
		>
			{Filter}
		</Dropdown>
	);
};
