import pluralize from 'pluralize';

const pluralizedWords = {};

export default (word: string): string => {
	if (word in pluralizedWords) {
		return pluralizedWords[word];
	}

	pluralizedWords[word] = pluralize(word);
	return pluralizedWords[word];
};
