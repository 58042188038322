import * as React from 'react';
import root from 'window-or-global';
import styled from 'styled-components';

const DrawerContainer = styled.div<{ isOpen: boolean; isDraw: boolean }>`
	top: 0;
	left: 0;
	transform: ${props =>
		props.isDraw
			? 'translateX(var(--var-position, -100%)) !important'
			: props.isOpen
			? 'none'
			: 'translateX(-100%)'};
	position: fixed;
	z-index: 1010;
	height: 100vh;
	transition: ${props => (props.isDraw ? 'none !important' : '0.3s')};
	background: white;
`;

const Backdrop = styled.div<{ isOpen: boolean; isDraw: boolean }>`
	top: 0;
	left: 0;
	position: fixed;
	z-index: 1000;
	width: 100vw;
	height: 100vh;
	background-color: #181818;
	transition-duration: 0.3s;
	visibility: ${props => (props.isOpen || props.isDraw ? 'visible' : 'hidden')};
	opacity: ${props => (props.isDraw ? 'calc(0.4 * var(--var-degree, 0.5)) !important' : props.isOpen ? '0.4' : '0')};
	transition: ${props => props.isDraw && 'none !important'};
`;

interface Props {
	closeDrawer: () => void;
	openDrawer: () => void;
	width: any;
	sideZoneWidth: any;
	isOpen: boolean;
}

const SWIPE_THRESHOLD = 20;

export const Drawer: React.FC<Props> = ({ closeDrawer, openDrawer, width, sideZoneWidth, isOpen, children }) => {
	const drawerEl = React.useRef(null);
	const backdropEl = React.useRef(null);

	const [isDraw, setIsDraw] = React.useState(false);
	const initialX = React.useRef(null);
	const initialY = React.useRef(null);
	const isTouching = React.useRef(null);
	const initialXShift = React.useRef(null);
	const windowWidth = React.useRef(null);

	const moveDrawer = x => {
		if (drawerEl) {
			const position = Math.min(100, ((x - initialXShift.current) / ((windowWidth.current * width) / 100)) * 100);
			const drawerPosition = position - 100;
			const backdropDegree = position / 100;
			requestAnimationFrame(() => {
				drawerEl.current.style.setProperty('--var-position', `${drawerPosition}%`);
				backdropEl.current.style.setProperty('--var-degree', `${backdropDegree}`);
			});
		}
	};

	const handleTouchMove = event => {
		let handleIsDraw = isDraw;

		if (event.changedTouches.length === 1 && isTouching.current && !handleIsDraw) {
			const touch = event.changedTouches[0];
			let dx = touch.pageX - initialX.current;
			const direction = dx > 0 ? 'r' : 'l';
			dx = Math.abs(dx);
			const dy = Math.abs(touch.pageY - initialY.current);
			if (dx > dy && dx > SWIPE_THRESHOLD && ((direction === 'r' && !isOpen) || (direction === 'l' && isOpen))) {
				handleIsDraw = true;
				initialXShift.current = touch.pageX - (isOpen ? (windowWidth.current * width) / 100 : 0);
				setIsDraw(handleIsDraw);
			}
		}

		if (handleIsDraw) {
			event.preventDefault();
			moveDrawer(event.changedTouches[0].pageX);
		}
	};

	const handleTouchStart = event => {
		if (event.changedTouches.length === 1) {
			const touch = event.changedTouches[0];
			const touchOnLeftSide = touch.pageX < sideZoneWidth;

			if (touchOnLeftSide || isOpen) {
				isTouching.current = true;
				initialX.current = touch.pageX;
				initialY.current = touch.pageY;

				root.removeEventListener('touchmove', handleTouchMove);
				root.addEventListener('touchmove', handleTouchMove, { passive: false, capture: false });
			}
		}
	};

	const handleTouchEnd = event => {
		if (event.changedTouches.length === 1 && isDraw) {
			const touch = event.changedTouches[0];

			if (!isOpen && touch.pageX - initialXShift.current > root.document.documentElement.clientWidth / 3) {
				openDrawer();
			} else if (isOpen && touch.pageX + initialXShift.current > root.document.documentElement.clientWidth / 2) {
				closeDrawer();
			}
		}

		root.removeEventListener('touchmove', handleTouchMove);
		setIsDraw(false);
		initialX.current = null;
		initialY.current = null;
		isTouching.current = false;
		initialXShift.current = null;
	};

	React.useEffect(() => {
		initialX.current = null;
		initialY.current = null;
		isTouching.current = false;
		initialXShift.current = null;
		windowWidth.current = null;
		root.addEventListener('touchstart', handleTouchStart, false);
		root.addEventListener('touchend', handleTouchEnd, false);
		return () => {
			root.removeEventListener('touchstart', handleTouchStart);
			root.removeEventListener('touchmove', handleTouchMove);
			root.removeEventListener('touchend', handleTouchEnd);
		};
	}, []);

	return (
		<React.Fragment>
			<Backdrop isOpen={isOpen} isDraw={isDraw} ref={backdropEl} onClick={closeDrawer} />
			<DrawerContainer isOpen={isOpen} isDraw={isDraw} ref={drawerEl} style={{ width: `${width}%` }}>
				{children}
			</DrawerContainer>
		</React.Fragment>
	);
};
