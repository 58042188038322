import { ThunkDispatch } from 'redux-thunk';
import { State } from 'react-spring';
import { Action } from 'redux';
import { getUnitCardText } from '../../../../services/dryflyApiService';
import { SET_UNIT_DETAILS } from '../constants';

export const setUnitDetails = (accommodationIds: string[]) => async (dispatch: ThunkDispatch<State, undefined, Action>) => {
	const getUnitCardTextPromise = getUnitCardText(accommodationIds);

	getUnitCardTextPromise.then(unitDetails => {
		dispatch({ type: SET_UNIT_DETAILS, payload: { unitDetails } });
	});

	return getUnitCardTextPromise;
};
