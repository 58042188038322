import styled, { css } from 'styled-components';
import { spaceSmall, spaceBase } from 'design-system/variables/spaces';
import { textSmall } from 'design-system/style-mixins/text';
import { white, gray, blue, textColorDark, itemHover, grayDark, black } from 'design-system/variables/colors';
import { Button } from '../Button';
import { Icon } from '../Icon';
import { LoaderSpinner } from '../Loader';
import { DropdownButtonProps } from './DropdownButton.types';

export const DropdownLabel = styled.span`
	display: flex;
	align-items: center;
	margin-right: ${spaceSmall};
	font-size: 1rem;
`;

export const DropdownArrow = styled(Icon)<DropdownButtonProps>`
	fill: ${gray};
	transform: rotate(0.25turn);
	transition: fill 100ms ease-out;

	${props =>
		props.isActive &&
		css`
			transform: rotate(-0.25turn);
		`}
`;

export const Loader = styled(LoaderSpinner)`
	position: absolute;
	height: auto;

	svg {
		width: 2em;
		height: 2em;
	}
`;

export const MainButton = styled(Button)<DropdownButtonProps>`
	${textSmall}
	${textColorDark}
	background-color: ${white};
	border-color: ${gray};
	padding: 7px ${spaceSmall} 7px ${spaceBase};
	text-transform: initial;
	letter-spacing: initial;
	line-height: 1.5;
	font-weight: normal;
	user-select: none;

	&:hover,
	&:active {
		${textColorDark}
		background-color: ${itemHover};
		border-color: transparent;

		&:not(:disabled) ${DropdownArrow} {
			fill: ${black};
		}
	}

	&:disabled,
	&:disabled:hover {
		background-color: ${white};
		border-color: ${gray};
		color: ${gray};
		fill: ${gray};
	}

	${props =>
		props.isActive &&
		css`
			background: ${blue};
			border-color: transparent;

			&:hover,
			&:active {
				background: ${blue};
				border-color: transparent;
			}

			${DropdownArrow} {
				fill: ${black};
			}
		`}

	${props =>
		props.isLoading &&
		css`
			&:disabled,
			&:disabled:hover {
				cursor: wait;
			}

			${DropdownLabel},
			${DropdownArrow} {
				opacity: 0;
			}
		`}
`;
