import { Button } from 'components/Button';
import React from 'react';
import { getSafeHtml } from 'services/Helpers';
import styled, { createGlobalStyle } from 'styled-components';
import { xsOnly } from 'design-system/media-queries';
import { CallToActionProps } from 'components/ActionLink/CallToActionProps';
import { black, grayDark, white } from '../../design-system/variables/colors';
import { gtAmerica, sabon } from '../../design-system/variables/fonts';
import { spaceBase, spaceLarge, spaceMedium } from '../../design-system/variables/spaces';
import { ComponentProps } from '../../interfaces/ComponentProps';
import { InquiryCardProps } from './InquiryCardProps';
import { textBaseSerif, lightWeight, textBase } from '../../design-system/style-mixins/text';

export interface InquiryCardProps extends ComponentProps {
	icon: any;
	heading: string;
	bodyCopy: string;
	phoneLabel: string;
	phoneNumber: string;
	emailLabel: string;
	emailAddress: string;
	callToAction: CallToActionProps;
}

const IconHolder = styled.div`
	align-items: center;
	display: flex;
	height: 100px;
	justify-content: center;
	margin-bottom: ${spaceBase};
`;

const Container = styled.div`
	background: ${white};
	display: flex;
	flex-direction: column;
	justify-content: center;
	overflow: hidden;
	padding: ${spaceLarge};
	max-width: 250px;

	${xsOnly`
		width: auto;
	`}
`;

const Icon = styled.img`
	height: 100%;
`;

const Title = styled.h3`
	font-size: 24px;
	line-height: 27px;
	letter-spacing: normal;
	font-family: ${gtAmerica};
	color: ${black};
	${lightWeight}
	margin-bottom: ${spaceMedium};
`;

const Copy = styled.div`
	line-height: 27px;
	font-family: ${sabon};
	${textBaseSerif}
	color: ${grayDark};
`;

const Label = styled.span`
	display: block;
	color: ${grayDark};
	font-family: ${gtAmerica};
	margin-top: ${spaceBase};
	${textBase}
	${textBaseSerif}
`;

const Link = styled(props => <Button {...props} />)<{ href: string; target: any }>`
	margin-top: ${spaceLarge};
	width: 100%;
`;

const CardContent = styled.div``;

// TODO: reuse ExpandableCard component when it's introduced
const Styles = createGlobalStyle`
	.expandable-card.expandable-card--is-open ${Container} {
		margin-top: -1px;
		padding-bottom: 3rem;
		width: 100%
	}

	.expandable-card.expandable-card--is-open ${CardContent} {
		margin: auto;
		max-width: 300px
	}
`;

export const InquiryCard = (props: InquiryCardProps) => {
	const { icon, heading, bodyCopy, phoneLabel, phoneNumber, emailLabel, emailAddress, callToAction } = props;

	return (
		<React.Fragment>
			<Styles />
			<Container className='center-xs'>
				<CardContent>
					{icon && icon.fullPath && <IconHolder>{icon && <Icon src={icon.fullPath} alt='' />}</IconHolder>}

					<Title>{heading}</Title>

					<Copy dangerouslySetInnerHTML={getSafeHtml(bodyCopy)} />

					{phoneLabel && <Label>{phoneLabel}</Label>}

					{phoneNumber && <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>}

					{emailLabel && <Label>{emailLabel}</Label>}

					{emailAddress && <a href={`mailto:${emailAddress}`}>{emailAddress}</a>}

					{callToAction.canDisplayAsTextLink && (
						<Link
							buttonType='primary'
							href={callToAction.url}
							target={callToAction.newWindow ? '_blank' : '_self'}
						>
							{callToAction.linkTitle}
						</Link>
					)}
				</CardContent>
			</Container>
		</React.Fragment>
	);
};
