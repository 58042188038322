import React from 'react';
import styled from 'styled-components';
import { ComponentProps } from '../../interfaces/ComponentProps';
import { bold, textBase } from '../../design-system/style-mixins/text';
import { xsOnly } from '../../design-system/media-queries';

export interface ContactUsPhoneEmailProps extends ComponentProps {
	phoneIcon: any;
	phoneHeader: string;
	phoneNumber: string;
	emailIcon: any;
	emailHeader: string;
	emailAddress: string;
}

const Container = styled.div`
	${textBase}
	display: flex;
	justify-content: center;
	${xsOnly`
		display: inline-block
	`}
	@media (max-width: 350px) {
		margin-left: -20px;
	}
`;

const Wrapper = styled.div`
	align-items: center;
	display: flex;
	margin-left: 20px;
	margin-right: 20px;
`;

const Holder = styled.div`
	display: flex;
	padding-bottom: 20px;
	padding-right: 20px;
	padding-top: 20px;
`;

const Icon = styled.div<{ alt?: string }>`
	background-size: cover;
	height: 33px;
	width: 33px;
`;

const Info = styled.div`
	text-align: left;
`;

const Header = styled.div`
	font-weight: ${bold};
	${xsOnly`
		text-align: left;
	`}
`;

export const ContactUsPhoneEmail = (props: ContactUsPhoneEmailProps) => {
	const { phoneIcon, phoneHeader, phoneNumber, emailIcon, emailHeader, emailAddress } = props;

	const phoneIconStyles = phoneIcon ? { backgroundImage: `url(${phoneIcon.fullPath});` } : {};
	const emailIconStyles = emailIcon ? { backgroundImage: `url(${emailIcon.fullPath});` } : {};

	return (
		<Container>
			<Wrapper>
				<Holder>
					<Icon style={phoneIconStyles} />
				</Holder>
				<Info>
					<Header>{phoneHeader}</Header>
					<a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
				</Info>
			</Wrapper>
			<Wrapper>
				<Holder>
					<Icon style={emailIconStyles} />
				</Holder>
				<Info>
					<Header>{emailHeader}</Header>
					<a href={`mailto:${emailAddress}`}>{emailAddress}</a>
				</Info>
			</Wrapper>
		</Container>
	);
};

export default ContactUsPhoneEmail;
