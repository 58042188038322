import styled from 'styled-components';
import { spaceTiny, spaceBase, spaceMedium } from 'design-system/variables/spaces';
import { textSmall, textTiny, textBase } from 'design-system/style-mixins/text';
import { textColorLight } from 'design-system/variables/colors';
import { xsSmOnly } from 'design-system/media-queries';
import { Subtext } from 'components/Subtext/Subtext';

export const SpecsContainer = styled.div`
	${textTiny}
	display: flex;
	order: 4;
	align-items: center;
	margin-top: ${spaceBase};
	margin-left: ${spaceMedium};
	justify-content: space-between;

	${xsSmOnly`
		margin-left: 0;
	`};
`;

export const ItemContainer = styled.div``;

export const Spec = styled(ItemContainer)`
	display: flex;
	${textBase}
	flex-direction: row;
	align-items: baseline;
`;

export const SpecLabel = styled(props => <Subtext {...props} />)`
	margin-left: ${spaceTiny};
	${textSmall}
	${textColorLight}
	font-weight: normal;
	display: inline-block;

	${xsSmOnly`
		${textColorLight}
	`};
`;
