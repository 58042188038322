import root from 'window-or-global';
import { useScript } from './Helpers';

export const useLoadVidyardVideo = (vidyardEmbedCode: string): boolean[] => {
	if (!vidyardEmbedCode) {
		const isLoaded = false;

		return [isLoaded];
	}
	// Since vidyard.com doesn't provide API to run video within React ecosystem,
	// we need to load script using `<script src=...>` approach and run it using `window.<method>()`
	const vidyardSrc = `//play.vidyard.com/${vidyardEmbedCode}.js?v=3.1&type=lightbox&width=960&height=540`;
	const vidyardId = `vidyard_embed_code_${vidyardEmbedCode}`;
	return useScript(vidyardSrc, vidyardId);
};

export const watchVidyardVideo = (vidyardEmbedCode: string) => {
	root[`fn_vidyard_${vidyardEmbedCode}`]();
};
