import React, { FC, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import root from 'window-or-global';
import { Modal } from 'components/Modal';
import { Icon } from 'components/Icon';
import { Button } from 'components/Button';
import { Headline } from 'components/Headline';
import { useIsDesktop } from 'components/hooks';
import { AccordionGroup } from 'components/Accordion';
import { getResults, clearDateFilter, clearExperiencesFilter, clearSort, clearLocationFilter } from '../redux/actions';
import { DateFilter } from './DateFilter';
import { ExperienceTypeFilter } from './ExperienceTypeFilter';
import {
	getDateFilter,
	getExperienceTypesFilter,
	getSort,
	getLocationsFilter,
	getHasInspiratoOnly,
} from '../redux/selectors';
import { useCheckedCount } from './utils';
import { SortFilters, SortDirection } from '../enums';
import { LocationFilter } from './LocationFilter';
import { DropdownButton } from '../../DropdownButton';
import {
	FilterControls,
	CloseIcon,
	FooterContainer,
	FilterButtonClear,
	FilterWrapper,
	FilterGridWrap,
} from './Filters.styles';
import { Sort } from '../Sort';

export const Filters: FC = () => {
	const dispatch = useDispatch();
	const [isOpen, setIsOpen] = useState(false);
	const dates = useSelector(state => getDateFilter(state));
	const [datesCheckedCount] = useCheckedCount(dates);

	const experienceTypes = useSelector(state => getExperienceTypesFilter(state));
	const [experiencesCheckedCount] = useCheckedCount(experienceTypes);

	const locations = useSelector(state => getLocationsFilter(state));
	const [locationsCheckedCount] = useCheckedCount(locations);

	const sort = useSelector(state => getSort(state));
	const hasInspiratoOnly = useSelector(state => getHasInspiratoOnly(state));
	const getRoot = () => root.document.body;

	const handleClearAll = () => {
		dispatch(clearSort());
		dispatch(clearDateFilter());
		dispatch(clearExperiencesFilter());
		dispatch(clearLocationFilter());
		dispatch(getResults());
	};

	const [isDesktop] = useIsDesktop(768);

	const isDisabled = () =>
		datesCheckedCount === 0 &&
		experiencesCheckedCount === 0 &&
		locationsCheckedCount === 0 &&
		sort.key === (hasInspiratoOnly ? SortFilters.InspiratoOnly : SortFilters.CheckInDate) &&
		sort.direction === SortDirection.Ascending;

	const handleModal = () => {
		setIsOpen(!isOpen);
	};

	useEffect(() => {
		if (isDesktop) {
			setIsOpen(false);
		}
	}, [isDesktop]);

	const ClearFilters = (
		<span style={{ marginLeft: isDesktop ? 'auto' : 'inherit', display: 'block' }}>
			<FilterButtonClear onClick={handleClearAll} isDisabled={isDisabled()}>
				<Icon iconName='close' iconSize='sm' />
				<span style={{ whiteSpace: 'nowrap' }}>Clear Filters</span>
			</FilterButtonClear>
		</span>
	);

	return (
		<FilterWrapper>
			<FilterGridWrap width='base'>
				{!isDesktop && (
					<DropdownButton hideArrow onClick={handleModal}>
						Filters
					</DropdownButton>
				)}
				{isDesktop ? (
					<FilterControls>
						<DateFilter />
						<ExperienceTypeFilter />
						<LocationFilter />
					</FilterControls>
				) : (
					<Modal
						isOpen={isOpen}
						onRequestClose={() => setIsOpen(false)}
						parentSelector={getRoot}
						contentStyles={{ height: '100%' }}
					>
						<CloseIcon onClick={() => setIsOpen(false)}>
							<Icon iconName='close' iconSize='md' />
						</CloseIcon>

						<div style={{ padding: '30px 15px 120px 15px', marginTop: '10px' }}>
							<Headline size='medium' style={{ textAlign: 'center', marginBottom: '15px' }}>
								Filters
							</Headline>
							<AccordionGroup>
								<DateFilter />
								<ExperienceTypeFilter />
								<LocationFilter />
							</AccordionGroup>
							<FooterContainer>
								<div
									style={{
										padding: '20px',
										display: 'flex',
										justifyContent: 'space-between',
										backgroundColor: 'white',
										zIndex: 150,
									}}
								>
									{ClearFilters}
									<Button buttonType='primary' onClick={() => setIsOpen(false)}>
										Apply
									</Button>
								</div>
							</FooterContainer>
						</div>
					</Modal>
				)}
				{!isDesktop && <Sort />}
				{isDesktop && ClearFilters}
			</FilterGridWrap>
		</FilterWrapper>
	);
};
