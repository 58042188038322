import React, { FC, Fragment } from 'react';
import styled, { createGlobalStyle } from 'styled-components';

import { xsOnly } from 'design-system/media-queries';
import { gray, white, black } from 'design-system/variables/colors';
import { lightWeight, textBig } from 'design-system/style-mixins/text';

import { spaceBase } from 'design-system/variables/spaces';
import { IconCard } from 'components/IconCard';
import { IconCardProps } from 'components/IconCard/IconCardProps';
import { StoryProps } from './StoryProps';

const Wrapper = styled.div`
	counter-reset: story-list;
	position: relative;
	z-index: 0;

	&::after {
		border-left: 2px dotted ${gray};
		bottom: 0;
		content: '';
		display: block;
		left: 50%;
		position: absolute;
		top: 0;
		transform: translate(-50%);
		z-index: -1;

		${xsOnly`
			display: none;
        `}
	}
`;

const Item = styled.div`
	align-items: center;
	counter-increment: story-list;
	display: flex;
	padding-bottom: ${spaceBase};
	padding-top: ${spaceBase};

	&:before {
        ${textBig}
        ${lightWeight}
		background-color: ${black};
		border-radius: 50px;
		color: ${white};
		content: counter(story-list);
		display: inline-block;
		height: 50px;
		left: calc(50% - 25px);
		padding-top: 3px;
		position: absolute;
		text-align: center;
		width: 50px;
	}

    ${xsOnly`
        align-items: flex-start;
    `}
`;

const Styles = createGlobalStyle`
    ${Item} {
        &:nth-child(even) {
            margin-bottom: -4.5rem;
            margin-left: auto;
            margin-top: -4.5rem;
        }

        ${xsOnly`
            &:nth-child(even) {
                margin: auto;
            }
        `}
    }
`;

export const Story: FC<StoryProps> = ({ nestedComponents = [] }) =>
	nestedComponents.length ? (
		<Fragment>
			<Styles />
			<Wrapper>
				{nestedComponents.map(component => (
					<Item className='col-xs-12 col-sm-6 col-md-5 col-lg-5 story__item'>
						{/* story__item class should be saved, cause other components refers to it in createGlobalStyle dependecies */}
						{component.componentName === 'icon-card' && <IconCard {...(component as IconCardProps)} />}
					</Item>
				))}
			</Wrapper>
		</Fragment>
	) : null;
