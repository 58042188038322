import React from 'react';
import styled from 'styled-components';

import { LoaderSpinner } from 'components/Loader';
import { MemberActivityProps } from './MemberActivityProps';

import { white } from '../../design-system/variables/colors';
import { spaceBase } from '../../design-system/variables/spaces';

import MemberActivityData from './MemberActivityData';
import MemberActivityError from './MemberActivityError';

const MemberActivityWrapper = styled.section`
	align-items: center;
	background-color: ${white};
	display: flex;
	justify-content: space-between;
	padding: ${spaceBase};
	text-align: left;
`;

export const MemberActivity: React.FC<MemberActivityProps> = ({
	favoritesCount,
	favoritesUrl,
	firstName,
	hasData,
	hasErrors,
	hasTravelCreditAndPerks,
	linkTitle,
	memberSinceYear,
	profilePhotoUrl,
	travelCreditAndPerksAmount,
	travelCreditAndPerksUrl,
	tripHeaderText,
	tripUrl,
	tripUrlText,
	url,
}) => {
	if (!hasErrors && !hasData) {
		return <LoaderSpinner />;
	}

	return (
		<MemberActivityWrapper>
			{hasData ? (
				<MemberActivityData
					{...{
						favoritesCount,
						favoritesUrl,
						firstName,
						hasTravelCreditAndPerks,
						linkTitle,
						memberSinceYear,
						profilePhotoUrl,
						travelCreditAndPerksAmount,
						travelCreditAndPerksUrl,
						tripHeaderText,
						tripUrl,
						tripUrlText,
						url,
					}}
				/>
			) : (
				<MemberActivityError {...{ linkTitle, url }} />
			)}
		</MemberActivityWrapper>
	);
};
