import { useEffect, useState, useCallback } from 'react';

function useAsync<TData, TError>(asyncFunction: () => Promise<TData>, immediate = true) {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState<TData>(null);
	const [error, setError] = useState<TError>(null);

	// The fetch function wraps asyncFunction and
	// handles setting state for pending, value, and error.
	// useCallback ensures the below useEffect is not called
	// on every render, but only if asyncFunction changes.
	const execute = useCallback(() => {
		setLoading(true);
		setData(null);
		setError(null);
		const request = asyncFunction();
		request
			.then(response => setData(response))
			.catch(e => setError(e))
			.finally(() => setLoading(false));

		return request;
	}, [asyncFunction]);

	// Call fetch if we want to fire it right away.
	// Otherwise fetch can be called later, such as
	// in an onClick handler.
	useEffect(() => {
		if (immediate) {
			execute();
		}
	}, [execute, immediate]);

	return { execute, loading, data, error };
}

export { useAsync };
