import styled from 'styled-components';
import { IconButton } from 'components/Icon/IconButton';

export const NextPrevButtonsWrapper = styled.div<{ orientation?: string }>`
	display: flex;
	flex-direction: ${props => (props.orientation === 'vertical' ? 'column-reverse' : 'row')};
`;

export const PrevButton = styled(IconButton)<{ orientation?: string; buttonHeight?: string; buttonWidth?: string }>`
	height: ${props => (props.buttonHeight ? `${props.buttonHeight} !important` : '40px')};
	width: ${props => (props.buttonWidth ? props.buttonWidth : '40px')};

	svg {
		transform: ${props => (props.orientation === 'vertical' ? 'rotate(90deg)' : 'rotate(180deg)')};
	}
`;

export const NextButton = styled(IconButton)<{ orientation?: string; buttonHeight?: string; buttonWidth?: string }>`
	height: ${props => (props.buttonHeight ? props.buttonHeight : '40px')};
	width: ${props => (props.buttonWidth ? props.buttonWidth : '40px')};

	svg {
		transform: ${props => (props.orientation === 'vertical' ? 'rotate(-90deg)' : 'rotate(0deg)')};
	}
`;
