import React, { FC, useState } from 'react';
import { Formik, FormikBag } from 'formik';
import { UserValues } from 'pages/Chat/Chat.Interfaces';
import { object, string } from 'yup';
import mock from 'xhr-mock';
import wretch from 'wretch';
import { PubUserForm } from './PubUserForm';
import { BuildPostObject } from '../../../helper/ConvertFormValuesToPostModel';
import SuccessMessage from '../../Messages/SuccessMessage';
import ErrorMessage from '../../Messages/ErrorMessage';

const RequiredTextErrorMessage = 'This field is required';
const MinRequiredTextErrorMessage = 'This field needs at least one character';

const PubUserValidationSchema = object({
	name: string()
		.required(RequiredTextErrorMessage)
		.min(1, MinRequiredTextErrorMessage)
		.max(80, 'This field needs to be less then 80 characters'),
	email: string()
		.required(RequiredTextErrorMessage)
		.email('Please provide a valid email'),
	description: string()
		.required(RequiredTextErrorMessage)
		.min(1, MinRequiredTextErrorMessage)
		.max(80000, 'This field needs to be less then 80000 characters'),
});

const SubmitPubUser = (setSuccess: Function) => async (
	values: UserValues,
	{ setSubmitting, ...rest }: FormikBag<null, UserValues>,
) => {
	mock.teardown();
	const postValues = BuildPostObject(values);

	try {
		const response = await wretch('/LiveChat/PostChatData/')
			.post(postValues)
			.json();

		if (response) {
			setSuccess(true);
		} else {
			setSuccess(false);
		}
	} catch (e) {
		setSuccess(false);
	}
};

export const PubUserFormik: FC<UserValues> = props => {
	const initialValues: UserValues = { ...props, name: '', email: '', description: '' };
	const [success, setSuccess] = useState(null);
	const renderFormik = () => {
		let whatToReturn = null;
		if (success === null) {
			whatToReturn = (
				<Formik
					initialValues={initialValues}
					onSubmit={SubmitPubUser(setSuccess)}
					component={PubUserForm}
					validationSchema={PubUserValidationSchema}
				/>
			);
		} else if (success) {
			whatToReturn = <SuccessMessage></SuccessMessage>;
		} else {
			whatToReturn = <ErrorMessage></ErrorMessage>;
		}
		return whatToReturn;
	};

	return renderFormik();
};
