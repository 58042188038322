import React, { forwardRef, ChangeEvent } from 'react';
import { Icon } from 'components/Icon';
import { InputLabel, RequiredContainer } from '../Form.styles';
import { TextAreaField, TextAreaWrapper } from './TextArea.styles';
import { TextAreaProps } from './TextArea.types';
import { HelpBlock } from '../HelpBlock';

export const TextArea = forwardRef(
	(
		{
			disabled,
			hasError,
			helpText,
			hideLabel,
			id,
			labelSize,
			labelText,
			onChange,
			onFocus,
			onBlur,
			placeholder,
			maxLength,
			required,
			rows,
			value,
			...rest
		}: TextAreaProps,
		ref,
	) => {
		const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) =>
			typeof onChange === 'function' && onChange(event.target.value);
		const handleFocus = () => typeof onFocus === 'function' && onFocus();
		const handleBlur = () => typeof onBlur === 'function' && onBlur();

		return (
			<TextAreaWrapper {...rest}>
				<InputLabel size={labelSize} as='label' htmlFor={id} hideLabel={hideLabel}>
					{labelText}
					{required && (
						<RequiredContainer>
							<Icon iconName='required' iconSize='md' />
						</RequiredContainer>
					)}
				</InputLabel>
				<TextAreaField
					rows={rows}
					id={id}
					disabled={disabled}
					placeholder={placeholder}
					value={value}
					hasError={hasError}
					ref={ref}
					required={required}
					onChange={handleChange}
					onFocus={handleFocus}
					onBlur={handleBlur}
					maxLength={maxLength}
				/>
				{helpText && <HelpBlock hasError={hasError} helpText={helpText} />}
			</TextAreaWrapper>
		);
	},
);
