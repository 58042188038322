import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { State } from '../context';
import { UPDATE_LOCATION_FILTER, CLEAR_LOCATION_FILTER } from '../constants';

export const updateLocationFilter = (id: string) => (
	dispatch: ThunkDispatch<State, undefined, Action>,
	getState: () => State,
) => {
	const locationFilter = getState().locations.map(location => ({
		...location,
		checked: id === location.id && !location.disabled ? !location.checked : location.checked,
	}));
	dispatch({ type: UPDATE_LOCATION_FILTER, payload: locationFilter });
};

export const clearLocationFilter = () => (dispatch: ThunkDispatch<State, undefined, Action>, getState: () => State) => {
	const clearedLocations = getState().locations.map(location => ({
		...location,
		checked: false,
	}));
	dispatch({ type: CLEAR_LOCATION_FILTER, payload: clearedLocations });
};
