import * as React from 'react';
import root from 'window-or-global';

import { DropdownProps } from './Dropdown.types';
import {
	DropdownHeader,
	DropdownMain,
	DropdownFooter,
	DropdownContainer,
	DropdownInnerWrapper,
	FilterDropdownMenu,
} from './Dropdown.styles';
import { DropdownButton } from '../DropdownButton';

export const Dropdown: React.FC<DropdownProps> = ({
	id,
	align,
	title,
	onOpen,
	onClose,
	controlledOpen,
	children,
	header,
	footer,
	width,
	className,
	overflowY,
	boundariesSelector,
	additionalSelectors = [],
	hideButtonArrow,
	isLoading,
	isDisabled,
	...rest
}) => {
	const [isOpen, setIsOpen] = React.useState(false);

	const DropdownButtonEl = React.useRef(null);
	const DropdownMenuEl = React.useRef(null);

	const handleControlledOpen = () => {
		if (!controlledOpen) {
			setIsOpen(!isOpen);
		} else {
			controlledOpen.action();
		}
	};

	const buttonContainsTarget = React.useCallback(
		event => DropdownButtonEl && DropdownButtonEl.current && DropdownButtonEl.current.contains(event.target),
		[DropdownButtonEl],
	);
	const menuContainsTarget = React.useCallback(
		event => DropdownMenuEl && DropdownMenuEl.current && DropdownMenuEl.current.contains(event.target),
		[DropdownMenuEl],
	);
	const handleClickOutside = event => {
		const selectors = [boundariesSelector, ...additionalSelectors];
		const isInside =
			boundariesSelector ||
			(additionalSelectors.length &&
				selectors.some(selector => {
					const el = root.document.querySelector(selector);
					return el && el.contains(event.target);
				}));

		if (buttonContainsTarget(event) || menuContainsTarget(event) || isInside) {
			return; // inside click
		}
		// outside click
		handleControlledOpen();

		if (typeof onClose === 'function') {
			onClose();
		}
	};

	React.useEffect(() => {
		if ((controlledOpen && controlledOpen.open) || isOpen) {
			root.addEventListener('mousedown', handleClickOutside);
			root.addEventListener('touchend', handleClickOutside);
		} else {
			root.removeEventListener('mousedown', handleClickOutside);
			root.removeEventListener('touchend', handleClickOutside);
		}

		return () => {
			root.removeEventListener('mousedown', handleClickOutside);
			root.removeEventListener('touchend', handleClickOutside);
		};
	}, [isOpen, controlledOpen]);

	const handleClick = () => {
		if (
			typeof onOpen === 'function' &&
			((!isOpen && !controlledOpen) || (controlledOpen && !controlledOpen.open)) &&
			onOpen
		) {
			onOpen();
		}

		if (typeof onClose === 'function' && (isOpen || (controlledOpen && controlledOpen.open))) {
			onClose();
		}

		handleControlledOpen();
	};

	const FancyMenu = React.forwardRef((props, ref) => (
		<FilterDropdownMenu shadow={4} align={align} ref={ref} width={width} overflowY={overflowY}>
			{props.children}
		</FilterDropdownMenu>
	));

	return (
		<DropdownContainer id={id} className={`${className || ''}`} {...rest}>
			<DropdownButton
				ref={DropdownButtonEl}
				onClick={handleClick}
				isActive={isOpen || (controlledOpen && controlledOpen.open)}
				isLoading={isLoading}
				isDisabled={isDisabled}
				hideArrow={hideButtonArrow}
			>
				{title}
			</DropdownButton>
			{(isOpen || (controlledOpen && controlledOpen.open)) && (
				<FancyMenu ref={DropdownMenuEl}>
					<DropdownInnerWrapper>
						{header && <DropdownHeader>{header}</DropdownHeader>}
						<DropdownMain>{children}</DropdownMain>
					</DropdownInnerWrapper>
					{footer && <DropdownFooter>{footer}</DropdownFooter>}
				</FancyMenu>
			)}
		</DropdownContainer>
	);
};
