import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { TestimonialsProps } from './TestimonialsProps';
import MemberTestimonial from './Testimonial/MemberTestimonial';
import { transparentDark15, transparentDark30 } from '../../design-system/style-mixins/shadows-and-scrims';
import {
	spaceBase,
	spaceHuge,
	spaceMassive,
	spaceMedium,
	spaceSmall,
	spaceSuperMassive,
} from '../../design-system/variables/spaces';
import { lg, md, sm } from '../../design-system/media-queries';
import { paleBlue, white } from '../../design-system/variables/colors';
import { TestimonialProps } from './Testimonial/TestimonialProps';

const Container = styled.section`
	background-color: ${transparentDark30};
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	min-height: calc(100vh - 48px);
	/* NOTE: This spacing is custom to marketing content project. It is slightly different to the usual grid spacing due to the way it is set up with umbraco components. */
	padding-bottom: ${spaceHuge};
	padding-left: ${spaceSmall};
	padding-right: ${spaceSmall};
	padding-top: ${spaceHuge};
	position: relative;
	transition: opacity 1s ease-in-out;
	${sm`
		min-height: 600px;
		padding-bottom: 195px; /* 30px above the video play button, to ensure the text never overflows the video button. */
		padding-left: ${spaceMedium};
		padding-right: ${spaceMedium};
	`}
	${md`
		padding-bottom: ${spaceMassive};
		padding-top: ${spaceMassive};
	`}
	${lg`
		padding-bottom: ${spaceSuperMassive};
		padding-top: ${spaceSuperMassive};
	`}
`;

const Tabs = styled.nav`
	align-items: center;
	bottom: 0;
	display: flex;
	justify-content: center;
	left: 0;
	margin-bottom: -27px;
	margin-top: -27px;
	position: absolute;
	width: 100%;
	z-index: 2;
`;

const highlightedTab = css`
	box-shadow: 0 0 0 1px ${white}, 0 4px 16px ${transparentDark15};
	transform: scale(1.3);
`;

const Tab = styled.div<{ isSelected: boolean }>`
	background-color: ${paleBlue};
	background-position: center;
	background-size: cover;
	border-radius: 50%;
	box-shadow: 0 0 0 1px ${white}, 0 2px 8px ${transparentDark15}; /* Not in standard mixin. Second shadow replaces border. For smoother antialiased look. */
	cursor: pointer;
	flex-shrink: 0;
	height: 60px;
	margin: 0 ${spaceBase};
	transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
	width: 60px;
	z-index: 1;
	outline: none;
	&:hover {
		${highlightedTab}
	}
	${props => props.isSelected && highlightedTab}
`;

export const Testimonials: React.FunctionComponent<TestimonialsProps> = ({ headline, testimonials }) => {
	const initialSelectedTabIndex = testimonials.find(p => p.isSelected).tabIndex || 0;
	const [selectedTabIndex, setSelectedTabIndex] = useState(initialSelectedTabIndex);
	const getTabStyle = (testimonial: TestimonialProps): object => {
		return {
			backgroundImage: testimonial.memberImageURL ? `url('${testimonial.memberImageURL}&width=180')` : '',
		};
	};

	return (
		<Container>
			{testimonials.map(testimonial => {
				const isSelected = testimonial.tabIndex === selectedTabIndex;
				return <MemberTestimonial {...{ ...testimonial, isSelected }} headline={headline} />;
			})}

			<Tabs>
				{testimonials.map((testimonial, index) => {
					return (
						<Tab
							onClick={() => setSelectedTabIndex(testimonial.tabIndex)}
							onKeyDown={() => setSelectedTabIndex(testimonial.tabIndex)}
							style={getTabStyle(testimonial)}
							key={testimonial.tabIndex}
							isSelected={testimonial.tabIndex === selectedTabIndex}
							role="tab"
							tabIndex={index}
						/>
					);
				})}
			</Tabs>
		</Container>
	);
};
