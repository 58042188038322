/* eslint-disable no-nested-ternary */
import React, { memo } from 'react';
import styled from 'styled-components';

import { md } from 'design-system/media-queries';
import { dividerGrayLight } from 'design-system/variables/colors';
import { SearchResultsSet } from 'components/SearchResultsSet';
import { AccessibleMenu } from 'components/KeyboardAccessibility';
import { SearchResultsProps } from '../QuickSearchProps';

import { NoResultsFound } from './NoResultsFound';

const Wrapper = styled.div`
	border-top: ${dividerGrayLight};
	overflow-y: auto;
	z-index: 9001;
	width: 100%;
	max-height: calc(100vh - 105px);

	${md`
		max-height: 300px;
		width: 375px;
	`}
`;

export const SearchResults: React.FC<SearchResultsProps> = memo(({ searchResults, ...rest }) => (
	<AccessibleMenu>
		<Wrapper id='search-results'>
			{searchResults.length ? (
				<SearchResultsSet {...{ searchResults, ...rest }} identificator='identificator' />
			) : (
				<NoResultsFound />
			)}
		</Wrapper>
	</AccessibleMenu>
));
