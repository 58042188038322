import { useMemo } from 'react';

interface ClickProps {
	buttonType: string;
	as?: string;
	href?: string;
	target?: string;
	onClick?: Function;
}

export const useClickProps = (initialProps, url, newWindow, onClick) => {
	return useMemo(() => {
		let props: ClickProps = initialProps;
		if (!url) {
			return props;
		}

		if (url.startsWith('?filter=')) {
			props = {
				...props,
				onClick,
			};
		} else {
			props = {
				...props,
				as: 'a',
				href: url,
				target: newWindow ? '_blank' : '_self',
			};
		}

		return props;
	}, [url, newWindow]);
};
