import React from 'react';
import styled from 'styled-components';
import { ViewType } from 'pages/DestinationList/ViewType';
import { Icon } from 'components/Icon/Icon';
import { spaceLarge, spaceSmall, spaceTiny, spaceBase } from '../../design-system/variables/spaces';
import { boxShadow2 } from '../../design-system/style-mixins/shadows-and-scrims';
import { white, black, grayDark, tapTransparent, blue } from '../../design-system/variables/colors';
import { md } from '../../design-system/media-queries';
import { textBase, fontSans } from '../../design-system/style-mixins/text';

const ToggleButtonContainer = styled.div`
	border-radius: 30px;
	display: inline-flex;
	left: calc(50% - 93px);
	position: fixed;
	z-index: 10;
	align-items: center;
	bottom: ${spaceLarge};
	${boxShadow2}

	${md`
        bottom: auto;
        left: auto;
        position: absolute;
        top: 8px;
        right: ${spaceSmall};
    `}
`;

const ToggleButton = styled.div<{ active: boolean }>`
	cursor: pointer;
	display: flex;
	padding: 0.4rem ${spaceBase};
	color: ${props => (props.active ? black : grayDark)};
	background-color: ${props => (props.active ? blue : white)};
	${fontSans}
	${textBase}
	${tapTransparent}

	svg {
		fill: ${props => (props.active ? black : grayDark)};
		margin-right: ${spaceTiny};
	}
	&:first-of-type {
		border-radius: 30px 0 0 30px;
	}
	&:not(:first-of-type) {
		border-radius: 0 30px 30px 0;
	}
`;

const Label = styled.span<{ isActive?: boolean }>`
	color: ${props => (props.isActive ? black : grayDark)};
`;

interface ToggleItemProps {
	value: ViewType | boolean;
	iconName: string;
	label: string;
}

interface Props {
	toggleView: Function;
	isActive: boolean;
	toggleOne: ToggleItemProps;
	toggleTwo: ToggleItemProps;
	bgColorActive?: string;
	iconColorActive?: string;
	labelColorActive?: string;
}

const Toggle: React.FC<Props> = ({ toggleView, isActive, toggleOne, toggleTwo }) => {
	return (
		<ToggleButtonContainer>
			<ToggleButton active={isActive} onClick={() => toggleView(toggleOne.value)}>
				<Icon iconName={toggleOne.iconName} iconSize='md' />
				<Label isActive={isActive}>{toggleOne.label}</Label>
			</ToggleButton>
			<ToggleButton active={!isActive} onClick={() => toggleView(toggleTwo.value)}>
				<Icon iconName={toggleTwo.iconName} iconSize='md' />
				<Label isActive={!isActive}>{toggleTwo.label}</Label>
			</ToggleButton>
		</ToggleButtonContainer>
	);
};

export default Toggle;
