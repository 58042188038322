import React from 'react';
import styled from 'styled-components';
import { white, black, gray } from '../../design-system/variables/colors';
import { boxShadow1 } from '../../design-system/style-mixins/shadows-and-scrims';

const Checkbox = styled.input.attrs(props => ({
	type: 'checkbox',
}))`
	display: none;
`;

const Slider = styled.span.attrs(
	({
		enabledColor,
		disabledColor,
		height,
		width,
	}: {
		enabledColor?: string;
		disabledColor?: string;
		width: number;
		height: number;
	}) => ({
		enabledColor: enabledColor || black,
		disabledColor: disabledColor || gray,
		diameter: `${height - 4}px` || '24px',
		translateX: `${width - height}px` || '18px',
	}),
)`
	background-color: ${props => props.disabledColor};
	border-radius: 34px;
	bottom: 0;
	cursor: pointer;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
	transition: 0.25s ease-out;

	&:before {
		${boxShadow1}
		background-color: ${white};
		border-radius: 50%;
		bottom: 2px;
		content: '';
		height: ${props => props.diameter};
		left: 2px;
		position: absolute;
		transition: 0.25s ease-out;
		width: ${props => props.diameter};
	}

	input:checked + & {
		background-color: ${props => props.enabledColor};
	}

	input:focus + & {
		${boxShadow1}
	}

	input:checked + &:before {
		transform: translateX(${props => props.translateX});
	}
`;

const ToggleSwitchContainer = styled.label.attrs((props: { height: number; width: number }) => ({
	height: `${props.height}px` || '28px',
	width: `${props.width}px` || '46px',
}))<{ height: string; width: string }>`
	display: inline-block;
	height: ${props => props.height};
	position: relative;
	width: ${props => props.width};
`;

export const ToggleSwitch = props => (
	<ToggleSwitchContainer {...props}>
		<Checkbox />
		<Slider {...props} />
	</ToggleSwitchContainer>
);
