import React, { FC } from 'react';
import styled from 'styled-components';
import { textColorDark } from 'design-system/variables/colors';
import { Link } from 'components/Link';
import { useDispatch } from 'react-redux';
import { getResults, clearDateFilter, clearExperiencesFilter, clearSort } from '../redux/actions';

const NoResultsText = styled.span`
	${textColorDark}
	margin-right: 5px;
`;
export const NoResults: FC = () => {
	const dispatch = useDispatch();

	const handleClearAll = () => {
		dispatch(clearSort());
		dispatch(clearDateFilter());
		dispatch(clearExperiencesFilter());
		dispatch(getResults());
	};
	return (
		<div>
			<NoResultsText>Not finding what you're looking for?</NoResultsText>
			<Link color='primary' onClick={handleClearAll}>
				Clear all filters
			</Link>
		</div>
	);
};
