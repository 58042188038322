let config: {
	dryflyApiUrl: string;
	imageServer: string;
	tripRecsApiUrl: string;
	oktaDomainUrl: string;
};

export const setConfig = (
	apiGatewayBaseUrl: string,
	dryFlyApiBaseUrl: string,
	imageServer: string,
	oktaDomainUrl: string,
	isDevTripRecs: boolean = false,
	isDevDryFly: boolean = false,
) => {
	if (config) {
		return;
	}



	config = {
		dryflyApiUrl: isDevDryFly ? dryFlyApiBaseUrl : `${apiGatewayBaseUrl.replace('trip-recommendations', 'dryfly')}`,
		imageServer,
		tripRecsApiUrl: `${
			isDevTripRecs ? 'http://localhost:40200/api' : apiGatewayBaseUrl
		}/proposals`,
		oktaDomainUrl
	};
};

export const getConfig = () => {
	if (!config) {
		throw new Error('A configuration must be set.');
	}

	return config;
};
