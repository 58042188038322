import styled from 'styled-components';
import { textBase } from 'design-system/style-mixins/text';
import { textColorMedium, dividerGrayLight, textColorDark, grayMedium } from 'design-system/variables/colors';
import { spaceBetween, spaceBase, spaceSmall } from 'design-system/variables/spaces';

export const DetailContainer = styled.section`
	${textBase}
	${textColorDark}
    align-content: flex-end;
	display: flex;
	justify-content: flex-end;
	margin: ${spaceBetween(spaceSmall, spaceBase)} 0;
`;

export const DetailWrapper = styled.section`
	${textBase}
	${textColorDark}
    align-content: stretch;

	border-bottom: ${dividerGrayLight};

	&:first-of-type {
		border-top: ${dividerGrayLight};
	}

	&:last-of-type {
		border-bottom: none;
	}

	&:last-of-type,
	&:nth-last-of-type(2) {
		margin-bottom: ${spaceBase};

	}

`;

export const DetailLabel = styled.span`
	${textColorMedium}
	content: 'Dates';
	margin-right: auto;
`;

export const SubDetail = styled.div`
    text-align: right;
    color: ${grayMedium};
    font-size: 14px;
	margin: -${spaceSmall} 0 ${spaceSmall} 0;
`;
