import { Button } from 'components/Button';
import { Title } from 'components/Title';
import styled, { createGlobalStyle, css } from 'styled-components';
import { md, sm, xsOnly } from '../../../design-system/media-queries';
import { boxShadow3, transparentDark15 } from '../../../design-system/style-mixins/shadows-and-scrims';
import { blue, error, grayDark, paleBlue, white } from '../../../design-system/variables/colors';
import { spaceBase, spaceLarge, spaceMedium, spaceSmall, spaceTiny } from '../../../design-system/variables/spaces';

export const Form = styled.form`
	display: flex;
	flex-direction: column;
`;

export const MessageField = styled.div<{ hasError?: boolean }>`
	${props =>
		props.hasError &&
		css`
			color: ${error};
			margin-bottom: ${spaceSmall};
			margin-top: ${spaceSmall};
		`}
`;

export const Styles = createGlobalStyle`
	@keyframes spin {
		to {
			transform: rotate(359deg);
		}
	}
	@keyframes grow {
		to {
			height: 14px;
			margin-top: -8px;
			right: 13px;
			width: 14px;
		}
	}
`;

export const CTAButton = styled(props => <Button {...props} />)<{ isSubmitting: boolean }>`
	margin-bottom: ${spaceBase};
	margin-top: ${spaceSmall};
	width: 100%;

	${md`
		margin-top: ${spaceBase};
	`}

	${props =>
		props.isSubmitting &&
		css`
			padding-right: 40px;
			position: relative;
			transition: padding-right 0.3s ease-out;
			&:after {
				animation: spin 0.6s infinite linear, grow 0.3s forwards ease-out;
				border-radius: 100%;
				content: '';
				height: 0px;
				margin-top: -2px;
				position: absolute;
				right: 6px;
				top: 50%;
				width: 0px;
				border: 2px solid ${transparentDark15};
				border-left-color: ${paleBlue};
				border-top-color: ${blue};
			}
		`}
`;

export const Headline = styled(props => <Title {...props} />)`
	margin-bottom: ${spaceMedium};
`;

export const FieldHalfSpace = styled.div``;

export const Wrapper = styled.div`
	${boxShadow3}
	background-color: ${white};
	display: block;
	margin-top: ${spaceLarge};
	padding: ${spaceMedium} ${spaceMedium} ${spaceSmall};
	width: 100%;

	${sm`
		margin: auto;
		max-width: 400px;
	`}
	${md`
		padding: ${spaceLarge} ${spaceLarge} ${spaceBase};
	`}

	${FieldHalfSpace} {
		&:first-child {
			padding-right: ${spaceTiny};
		}
		&:last-child {
			padding-left: ${spaceTiny};
		}
		&:only-child {
			padding-left: ${spaceSmall};
			padding-right: ${spaceSmall};
		}
	}

	${xsOnly`
		${FieldHalfSpace} {
			&:first-child {
				padding-right: ${spaceSmall};
			}
			&:last-child {
				padding-left: ${spaceSmall};
			}
		}
	`}
`;
