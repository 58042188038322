import React from 'react';
import styled, { css } from 'styled-components';
import { boxShadow2 } from '../../design-system/style-mixins/shadows-and-scrims';
import { spaceSmall } from '../../design-system/variables/spaces';
import { textColorDark, white, grayDark } from '../../design-system/variables/colors';

const light = css`
	${textColorDark}
	background-color: ${white};

	&:before {
		border-bottom: ${spaceSmall} solid ${white};
	}

`;

const dark = css`
	color: ${white};
	background-color: ${grayDark};

	&:before {
		border-bottom: ${spaceSmall} solid ${grayDark};
	}
`;

const topLeft = css`
	margin-top: ${spaceSmall};

	&:before {
		left: ${spaceSmall};
		top: -16px;
	}
	&:after {
		left: ${spaceSmall};
		top: -18px;
	}
`;

const top = css`
	margin-top: ${spaceSmall};

	&:before {
		left: calc(50% - ${spaceSmall});
		top: -16px;
	}
	&:after {
		left: calc(50% - ${spaceSmall});
		top: -18px;
	}
`;

const topRight = css`
	margin-top: ${spaceSmall};

	&:before {
		right: ${spaceSmall};
		top: -16px;
	}
	&:after {
		right: ${spaceSmall};
		top: -18px;
	}
`;

const rightTop = css`
	margin-right: 10px;

	&:before {
		top: ${spaceSmall};
		right: -16px;
		transform: rotate(90deg);
	}
	&:after {
		top: ${spaceSmall};
		right: -18px;
		transform: rotate(90deg);
	}
`;

const right = css`
	margin-right: 10px;

	&:before {
		top: calc(50% - ${spaceSmall});
		right: -16px;
		transform: rotate(90deg);
	}
	&:after {
		top: calc(50% - ${spaceSmall});
		right: -18px;
		transform: rotate(90deg);
	}
`;

const rightBottom = css`
	margin-right: 10px;

	&:before {
		bottom: ${spaceSmall};
		right: -16px;
		transform: rotate(90deg);
	}
	&:after {
		bottom: ${spaceSmall};
		right: -18px;
		transform: rotate(90deg);
	}
`;

const bottomRight = css`
	margin-bottom: ${spaceSmall};

	&:before {
		right: ${spaceSmall};
		bottom: -16px;
		transform: rotate(180deg);
	}
	&:after {
		right: ${spaceSmall};
		bottom: -18px;
		transform: rotate(180deg);
	}
`;

const bottom = css`
	margin-bottom: ${spaceSmall};

	&:before {
		left: calc(50% - ${spaceSmall});
		bottom: -16px;
		transform: rotate(180deg);
	}
	&:after {
		left: calc(50% - ${spaceSmall});
		bottom: -18px;
		transform: rotate(180deg);
	}
`;

const bottomLeft = css`
	margin-bottom: ${spaceSmall};

	&:before {
		left: ${spaceSmall};
		bottom: -16px;
		transform: rotate(180deg);
	}
	&:after {
		left: ${spaceSmall};
		bottom: -18px;
		transform: rotate(180deg);
	}
`;

const leftBottom = css`
	margin-left: ${spaceSmall};

	&:before {
		bottom: ${spaceSmall};
		left: -16px;
		transform: rotate(-90deg);
	}
	&:after {
		bottom: ${spaceSmall};
		left: -18px;
		transform: rotate(-90deg);
	}
`;

const left = css`
	margin-left: ${spaceSmall};

	&:before {
		top: calc(50% - ${spaceSmall});
		left: -16px;
		transform: rotate(-90deg);
	}
	&:after {
		top: calc(50% - ${spaceSmall});
		left: -18px;
		transform: rotate(-90deg);
	}
`;

const leftTop = css`
	margin-left: ${spaceSmall};

	&:before {
		top: ${spaceSmall};
		left: -16px;
		transform: rotate(-90deg);
	}
	&:after {
		top: ${spaceSmall};
		left: -18px;
		transform: rotate(-90deg);
	}
`;

const tooltipTheme = {
	light,
	dark,
};

const arrowStyles = {
	topLeft,
	top,
	topRight,
	rightTop,
	right,
	rightBottom,
	bottomRight,
	bottom,
	bottomLeft,
	leftBottom,
	left,
	leftTop,
};

interface TooltipProps {
	isOpen: boolean;

	/**
	 * @theme Defines colors of tooltip.
	 * Default is "light"
	 */
	theme?: 'light' | 'dark';

	/**
	 * @arrowPosition Defines where arrow is located.
	 * Default is "bottom"
	 */
	arrowPosition?:
		| 'topLeft'
		| 'top'
		| 'topRight'
		| 'rightTop'
		| 'right'
		| 'rightBottom'
		| 'bottomRight'
		| 'bottom'
		| 'bottomLeft'
		| 'leftBottom'
		| 'left'
		| 'leftTop';
}

/**
 *Tool Tip:
 *Used as a pop up with an arrow.
 *Has light and dark themes and arrow positions.
 */
export const Tooltip = styled.div<TooltipProps>`
	${boxShadow2}
	${props => tooltipTheme[props.theme] || tooltipTheme.light}
	${props => arrowStyles[props.arrowPosition] || arrowStyles.bottom}

	display: ${props => (props.isOpen ? 'block' : 'none')};
	padding: ${spaceSmall};
	position: relative;
	z-index: 10;

	/* tooltip arrow */
    &:before {
		border-left: ${spaceSmall} solid transparent;
		border-right: ${spaceSmall} solid transparent;
		border-top: ${spaceSmall} solid transparent;
		content: '';
		height: 0;
		position: absolute;
		width: 0;
		z-index: 2;
	}

	/* tooltip arrow shadow */
	&:after {
		border-bottom: ${spaceSmall} solid rgba(0, 0, 0, 0.03);
		border-left: ${spaceSmall} solid transparent;
		border-right: ${spaceSmall} solid transparent;
		border-top: ${spaceSmall} solid transparent;
		content: '';
		height: 0;
		position: absolute;
		width: 0;
		z-index: 1;
	}
`;
