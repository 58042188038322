import React from 'react';
import styled, { css } from 'styled-components';
import { getSafeHtml } from 'services/Helpers';
import { ComponentProps } from '../../interfaces/ComponentProps';
import { spaceBase, spaceBig } from '../../design-system/variables/spaces';
import { fontSerif, textBaseSerif } from '../../design-system/style-mixins/text';
import { textColorMedium } from '../../design-system/variables/colors';

export interface IconListItemProps extends ComponentProps {
	icon: any;
	listItem: string;
}

const Container = styled.div<{ fullPath: string }>`
	${fontSerif}
	${textBaseSerif}
	${textColorMedium}
	background-position: 8px center;
	background-repeat: no-repeat;
	background-size: 24px 24px;
	justify-content: flex-start;
	line-height: 27px;
	padding-bottom: ${spaceBase};
	padding-left: ${spaceBig};
	padding-right: ${spaceBase};
	padding-top: ${spaceBase};
	text-align: left;
	width: 100%;
	${props =>
		props.fullPath &&
		css`
			background-image: url(${props.fullPath});
		`}
	${props =>
		props.fullPath &&
		props.fullPath.indexOf('new') >= 0 &&
		css`
			background-position: left center;
			background-size: 40px 40px;
		`}
	p {
		margin: 0;
		padding: 0;
	}
`;

export const IconListItem = ({ icon, listItem, ...rest }: IconListItemProps) => {
	return <Container {...rest} dangerouslySetInnerHTML={getSafeHtml(listItem)} fullPath={icon && icon.fullPath} />;
};
