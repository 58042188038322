import React, { FC } from 'react';
import { PostMessageStyled } from './PostMessage.styles';

const ErrorMessage: FC = () => (
	<PostMessageStyled>
		<p>Uh oh. We've encountered a problem.</p>
		<p>Looks like we are unable to save your inquiry at this time. Apologies for the inconvenience!</p>
		<p>
			But we are here to help - for help finding what you're looking for, please contact our Sales Team at &nbsp;
			<a href='mailto:info@inspirato.com'>info@inspirato.com</a>.
		</p>
	</PostMessageStyled>
);

export default ErrorMessage;
