/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable global-require */
import { forwardRef } from 'react';
import { Icon } from 'components/Icon/Icon';
import { RichTextEditorProps } from './RichTextEditor.types';
import { HelpText, RequiredContainer, InputLabel } from '../Form.styles';
import { RichTextEditorWrapper } from './RichTextEditor.styles';
import { TextArea } from '../TextArea';

/* PLEASE NOTE:
 * You may only include one type of editor (classic, inline, or balloon) per page if you have multiple textarea (i.e. All must be classic or all must be inline)
 * In the future, we can look to create a "super build" to allow all of the editors to build at once
 * Documentation: https://ckeditor.com/docs/ckeditor5/latest/builds/guides/integration/advanced-setup.html#scenario-3-using-two-different-editors
 */

export const RichTextEditor = forwardRef(
	(
		{
			editorType,
			disabled,
			hasError,
			helpText,
			hideLabel,
			id,
			labelSize,
			labelText,
			onInit,
			onChange,
			onFocus,
			onBlur,
			placeholder,
			required,
			value,
			alignment,
			autosave,
			fallbackRows,
			fontBackgroundColor,
			fontColor,
			fontFamily,
			fontSize,
			heading,
			highlight,
			indentBlock,
			initialData,
			language,
			link,
			mediaEmbed,
			mention,
			plugins,
			removePlugins,
			simpleUpload,
			table,
			toolbar,
			typing,
			wordCount,
			...rest
		}: RichTextEditorProps,
		ref,
	) => {
		const handleInit = (editor: any) => typeof onInit === 'function' && onInit(editor);
		const handleChange = changedValue => typeof onChange === 'function' && onChange(changedValue);
		const handleFocus = () => typeof onFocus === 'function' && onFocus();
		const handleBlur = () => typeof onBlur === 'function' && onBlur();

		const config = Object.assign(
			{},
			{ el: ref },
			placeholder && { placeholder },
			alignment && { alignment },
			autosave && { autosave },
			{ disallowedContent: 'script' },
			fontBackgroundColor && { fontBackgroundColor },
			fontColor && { fontColor },
			fontFamily && { fontFamily },
			fontSize && { fontSize },
			heading && { heading },
			highlight && { highlight },
			indentBlock && { indentBlock },
			initialData && { initialData },
			language && { language },
			link && { link },
			mediaEmbed && { mediaEmbed },
			mention && { mention },
			plugins && { plugins },
			removePlugins && { removePlugins },
			simpleUpload && { simpleUpload },
			table && { table },
			toolbar && { toolbar },
			typing && { typing },
			wordCount && { wordCount },
		);

		function getTextEditor() {
			// CKEditor depends on window and therefore it cannot be initialized server-side.
			// We show a basic textarea as a fallback until window becomes defined.
			if (typeof window === 'undefined') {
				return (
					<TextArea
						id={id}
						hideLabel={hideLabel}
						placeholder={placeholder}
						rows={fallbackRows}
						value={value}
						onChange={handleChange}
						disabled={disabled}
						hasError={hasError}
						helpText={helpText}
						labelSize={labelSize}
						labelText={labelText}
						required={required}
						onFocus={handleFocus}
						onBlur={handleBlur}
					/>
				);
			}
			// Cannot import at the beginning of the file, will crash with ReferenceError: window is not defined
			const CKEditor = require('@ckeditor/ckeditor5-react');
			let ckEditor: NodeRequire;
			switch (editorType) {
				case 'inline':
					ckEditor = require('@ckeditor/ckeditor5-build-inline');
					break;
				case 'balloon':
					ckEditor = require('@ckeditor/ckeditor5-build-balloon');
					break;
				default:
					ckEditor = require('@ckeditor/ckeditor5-build-classic');
					break;
			}
			return (
				<CKEditor
					editor={ckEditor}
					config={config}
					data={value}
					disabled={disabled}
					onInit={handleInit}
					onChange={(event: any, editor) => handleChange(editor.getData())}
					onFocus={handleFocus}
					onBlur={handleBlur}
				/>
			);
		}

		return (
			<RichTextEditorWrapper hasError={hasError} {...rest}>
				<InputLabel size={labelSize} as='label' htmlFor={id} hideLabel={hideLabel}>
					{labelText}
					{required && (
						<RequiredContainer required={required}>
							<Icon iconName='required' iconSize='md' />
						</RequiredContainer>
					)}
				</InputLabel>
				{getTextEditor()}
				{helpText && <HelpText hasError={hasError}>{helpText}</HelpText>}
			</RichTextEditorWrapper>
		);
	},
);
