/* ACTIONS */
export const ACCOMMODATION_UPDATED = 'ACCOMMODATION_UPDATED';
export const NONPORTFOLIO_ACCOMODATION_UPDATED = 'NONPORTFOLIO_ACCOMODATION_UPDATED'
export const DIVIDER_UPDATED = 'DIVIDER_UPDATED';
export const SET_IS_LOADING = 'SET_IS_LOADING';
export const SET_DONE_LOADING = 'SET_DONE_LOADING';
export const SET_UNIT_DETAILS = 'SET_UNIT_DETAILS';
export const SET_ERRORS = 'SET_ERRORS';
export const ERRORS_CLEARED = 'ERRORS_CLEARED';
export const MESSAGE_UPDATED = 'MESSAGE_UPDATED';
export const PRICING_AVAILABILITY_CHECK_COMPLETED = 'PRICING_AVAILABILITY_CHECK_COMPLETED';
export const PRICING_AVAILABILITY_CHECK_INITIATED = 'PRICING_AVAILABILITY_CHECK_INITIATED';
export const PRICING_AVAILABILITY_CLEARED = 'PRICING_AVAILABILITY_CLEARED';
export const PROPOSAL_ELEMENTS_UPDATED = 'PROPOSAL_ELEMENTS_UPDATED';
export const PROPOSAL_NAME_UPDATED = 'PROPOSAL_NAME_UPDATED';
export const PROPOSAL_REQUEST_CREATED = 'PROPOSAL_REQUEST_CREATED';
export const SELECTED_RECIPIENT_UPDATED = 'SELECTED_RECIPIENT_UPDATED';
export const SELECTED_RECIPIENT_CLEARED = 'SELECTED_RECIPIENT_CLEARED';
export const UPDATE_DIRTY = 'UPDATE_DIRTY';
export const UPDATE_DIRTY_FALSE = 'UPDATE_DIRTY_FALSE'

/* FIELD NAMES : FOR VALIDATION HANDLING */
export const ACCOMMODATION = 'accommodation';
export const NPO_ACCOMMODATION = 'npo-accommodation';
export const ACCOMMODATION_NOTE = 'accommodation-note';
export const ACCOMMODATION_DATE_RANGE = 'accommodation-date-range';
export const DIVIDER = 'divider';
export const PROPOSAL_NAME = 'proposal-name';
export const PROPOSAL_MESSAGE = 'proposal-message';
export const SECTION_HEADER = 'section-header';
export const SELECTED_RECIPIENT = 'selected-recipient';
export const SEND_PROPOSAL_BUTTON = 'send-proposal-button';
export const NPO_ACCOMMODATION_NOTE = 'npo-accommodation-note';
