import React from 'react';
import styled, { css } from 'styled-components';
import { fontSans, textBase } from '../../design-system/style-mixins/text';
import {
	disabled,
	focusOutlineColor,
	primaryLinkColor,
	primaryLinkColorHover,
	tapTransparent,
} from '../../design-system/variables/colors';
import { Icon, svgSizes } from '../Icon/Icon';

const isDisabledStyles = css`
	&& {
		color: ${disabled};
		cursor: not-allowed;

		svg {
			fill: ${disabled};
		}
	}
`;

const ButtonContainer = styled.button<{ isDisabled?: boolean; isToggled?: boolean; isIconPositionReversed?: boolean }>`
	border-style: initial; /* Override to avoid default button style */
	border: none;
	background: transparent;
	box-sizing: border-box;
	cursor: pointer;
	display: flex;
	align-items: center;
	line-height: normal;/* Override to avoid default button style */
	outline: none;
	padding: 0;
	justify-content: space-between;
	text-decoration: none;
	user-select: none;
	flex-direction: ${props => (props.isIconPositionReversed ? 'row-reverse' : 'row')};
	${fontSans}
	${textBase} /* Override if necessary */
	color: ${primaryLinkColor};
	${tapTransparent}

	svg {
		display: flex;
		fill: ${primaryLinkColor};
	}

	&:visited {
		color: ${primaryLinkColor};

		svg {
			fill: ${primaryLinkColor};
		}

		&:hover {
			color: ${primaryLinkColor};

			svg {
				fill: ${primaryLinkColor};
			}
		}
	}

	&:hover {
		color: ${primaryLinkColorHover};

		svg {
			fill: ${primaryLinkColorHover};
		}
	}

	&:active {
		color: ${primaryLinkColor};

		svg {
			fill: ${primaryLinkColor};
		}
	}

	&:focus {
		color: ${primaryLinkColor};

		svg {
			fill: ${primaryLinkColor};
		}
	}

	${props =>
		props.isDisabled &&
		css`
			${isDisabledStyles}
		`}

	&:disabled {
		${isDisabledStyles}
	}

	&[data-focus-visible-added] {
		outline: 2px solid ${focusOutlineColor};
		outline-offset: 2px;
	}
`;

const ToggleButton = styled(props => <ButtonContainer {...props} />)`
	svg {
		transform: ${props => (!props.isDisabled && props.isToggled ? 'rotate(-90deg)' : 'rotate(90deg)')};
		transition: transform 0.25s ease-out;
	}
`;

export type TextButtonStates = 'isDisabled';

export type position = 'right' | 'left';

interface TextButtonProps {
	children: any;
	iconSize?: svgSizes;
	iconName?: string;
	iconPosition?: position;
	isDisabled?: boolean;
	enableToggle?: boolean;
	isToggled?: boolean;
	fill?: string;
	onClick?: any;
	style?: object;
	disabled?: boolean;
}

/**
 * Text Button:
 * Button that looks like text.
 * Used as simple dropdown button, clear, apply, etc.
 */
export const TextButton: React.FunctionComponent<TextButtonProps> = ({
	children,
	iconName,
	iconPosition = 'right',
	iconSize,
	isDisabled,
	enableToggle,
	fill,
	...rest
}) => {
	const [isToggled, setToggle] = React.useState(false);

	const toggle = () => setToggle(!isToggled);

	const icon = iconName ? <Icon iconName={iconName} fill={fill} {...{ iconSize }} /> : '';
	const isIconPositionReversed = iconPosition === 'left';

	if (enableToggle) {
		return (
			<ToggleButton
				onClick={toggle}
				disabled={isDisabled}
				{...{ isDisabled, isToggled, isIconPositionReversed }}
				{...rest}
			>
				{children}
				{icon}
			</ToggleButton>
		);
	}
	return (
		<ButtonContainer disabled={isDisabled} {...{ isDisabled, isIconPositionReversed }} {...rest}>
			{children}
			{icon}
		</ButtonContainer>
	);
};
