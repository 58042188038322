import styled, { css } from 'styled-components';
import EmblaCarouselReact from 'embla-carousel-react';
import { CarouselDots } from 'components/CarouselControls/CarouselDots';
import { white, black } from 'design-system/variables/colors';
import { spaceSmall } from 'design-system/variables/spaces';
import { IconButton } from 'components/Icon';
import { ImageCarouselButtonProps } from './ImageCarousel.types';

export const StyledEmblaCarousel = styled(EmblaCarouselReact)<{ theme: boolean }>`
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;

	&:fullscreen {
		background-color: ${props => (props.theme === 'dark' ? `${white}` : `{black}`)};
	}

	&.is-draggable {
		cursor: move;
		cursor: grab;
	}

	&.is-dragging {
		cursor: grabbing;
	}
`;

export const ImageCarouselContainer = styled.ul`
	display: flex;
	list-style: none;
	margin: 0;
	padding: 0;
`;

const buttonPositionStyles = css`
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	transition: 0.2s opacity;

	${props =>
		props.theme === 'dark' &&
		css`
			&,
			&:hover {
				background-color: transparent;
				border-color: transparent;
			}

			svg {
				fill: ${black};
			}
		`}

	${props =>
		props.showHideArrows &&
		css`
			opacity: 0.0001;

			${StyledEmblaCarousel}:hover &,
			&:focus {
				opacity: 1;
			}
		`}

	&:active {
		transform: translateY(calc(-50% + 1px));
	}
`;

export const ImageCarouselPrevButton = styled(IconButton)<ImageCarouselButtonProps>`
	${buttonPositionStyles}
	left: 0;

	svg {
		transform: rotate(180deg);
	}
`;

export const ImageCarouselNextButton = styled(IconButton)<ImageCarouselButtonProps>`
	${buttonPositionStyles}
	right: 0;
`;

export const ImageCarouselDots = styled(CarouselDots)`
	position: absolute;
	right: 0;
	bottom: ${spaceSmall};
	left: 0;
	margin: 0 auto;
`;
