import React from 'react';
import styled from 'styled-components';

import { TourPreviewButtonProps } from './TourPreviewButtonProps';

import { spaceBase } from '../../../design-system/variables/spaces';
import { Button } from '../../Button';

const StyledButton = styled(props => <Button {...props} />)`
	bottom: ${spaceBase};
	position: absolute;
	right: ${spaceBase};
	z-index: 21;
`;

export const TourPreviewButton: React.FunctionComponent<TourPreviewButtonProps> = ({ showTourPreview, darkTheme }) => (
	<StyledButton buttonType={darkTheme ? 'secondary' : 'secondaryInvert'} size='small' onClick={showTourPreview}>
		3D TOUR
	</StyledButton>
);
