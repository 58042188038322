import React, { FC } from 'react';
import styled from 'styled-components';

import { spaceBig, spaceBase } from 'design-system/variables/spaces';
import { textColorDark } from 'design-system/variables/colors';
import { md } from 'design-system/media-queries';
import { textBase, fontSans } from 'design-system/style-mixins/text';

import { AccessibleInput } from 'components/KeyboardAccessibility/components/AccessibleInput';
import { TripFinder } from '../enums';

interface Props {
	value: string;
	handleInput: Function;
	handleFocus: Function;
	handleKeyDown: Function;
}

const Input = styled.input`
	${textBase}
	${fontSans}
	border: none;
	border-color: transparent;
	box-shadow: none;
	${textColorDark}
	flex-basis: 92px;
	flex-grow: 1;
	flex-shrink: 0;
	height: ${spaceBig};
	margin-bottom: 0;
	margin-left: 0;
	margin-right: 0;
	outline-style: none;
	padding: 0;

	${md`
		margin-left: ${spaceBase};
	`}
`;

export const SearchInput: FC<Props> = ({ value, handleInput, handleFocus, handleKeyDown }) => {
	const onInput = e => handleInput(e.target.value);

	return (
		<AccessibleInput handleKeyDown={handleKeyDown}>
			<Input
				type='text'
				placeholder={TripFinder.Placeholder}
				value={value}
				onInput={onInput}
				onFocus={handleFocus}
			/>
		</AccessibleInput>
	);
};
