import React from 'react';
import styled, { css } from 'styled-components';

import { MemberActivityDataProps } from './MemberActivityDataProps';

import { spaceBase, spaceBig } from '../../../design-system/variables/spaces';
import { xsOnly, sm } from '../../../design-system/media-queries';
import { textColorDark, textColorLight } from '../../../design-system/variables/colors';
import { textBase, fontSans, textSmall } from '../../../design-system/style-mixins/text';

import { Link } from '../../Link';

const Wrapper = styled.div`
	align-items: center;
	display: flex;
	flex-basis: 1024px;
	justify-content: space-between;
	margin: auto;
	padding-left: ${spaceBase};
	padding-right: ${spaceBase};

	${xsOnly`
        align-items: center;
        display: flex;
        flex-direction: column;
        height: auto;
        padding-bottom: ${spaceBig};
        padding-left: 0;
        padding-right: 0;
        padding-top: ${spaceBig};
        text-align: center;
    `}
`;

const WelcomeSection = styled.div`
	align-items: center;
	display: flex;
	flex-grow: 0;
	justify-content: space-around;
	margin-right: ${spaceBase};

	${xsOnly`
        align-items: center;
        flex-direction: column;
        margin-right: 0;
    `}
`;

const MiniPhoto = styled.div<{ src: string }>`
	background-position: center;
	background-size: cover;
	border-radius: 50%;
	height: 40px;
	margin-right: ${spaceBase};
	width: 40px;
	${props => css`
		background-image: url(${props.src});
	`}

	${xsOnly`
        height: 80px;
        margin-right: 0;
        page-break-after: always;
        width: 80px;
    `}
`;

const MemberDataSection = styled.div`
	display: flex;
	flex: 0 0 auto;
	flex-direction: column;
	justify-content: space-between;
	margin-right: ${spaceBase};

	${xsOnly`
        margin-bottom: ${spaceBase};
        margin-right: 0;
    `}
`;

const MemberDataSectionFavourites = styled(props => <MemberDataSection {...props} />)`
	${xsOnly`
        display: none;
    `}
`;

const Label = styled.div`
	${textColorLight};
	margin-top: ${spaceBase};
	text-align: center;

	${sm`
        ${textSmall}
        margin-top: 0;
        text-align: left;
    `}
`;

const MebmerSinceText = styled.div`
	${textBase};
	${fontSans};
	${textColorDark};
	page-break-after: always;
	line-height: 15px;
`;

const StyledLink = styled(props => <Link {...props} />)`
	${textBase};
	${fontSans};
`;

const FavoritesStyledLink = styled(props => <StyledLink {...props} />)`
	display: flex;
	text-decoration: none;
`;

export const MemberActivityData: React.FunctionComponent<MemberActivityDataProps> = ({
	firstName,
	memberSinceYear,
	hasTravelCreditAndPerks,
	profilePhotoUrl,
	travelCreditAndPerksAmount,
	travelCreditAndPerksUrl,
	favoritesUrl,
	favoritesCount,
	tripHeaderText,
	tripUrl,
	tripUrlText,
	linkTitle,
	url,
}) => (
	<Wrapper>
		<WelcomeSection>
			<MiniPhoto src={profilePhotoUrl} />
			<MemberDataSection>
				<Label> Hi {firstName}</Label>
				<MebmerSinceText>Member Since: {memberSinceYear}</MebmerSinceText>
			</MemberDataSection>
		</WelcomeSection>
		<MemberDataSection>
			<Label>{tripHeaderText}</Label>
			<StyledLink color='primary' href={tripUrl}>
				{tripUrlText}
			</StyledLink>
		</MemberDataSection>
		{hasTravelCreditAndPerks && (
			<MemberDataSection>
				<Label>Credit Balance:</Label>
				<StyledLink color='primary' href={travelCreditAndPerksUrl}>
					{travelCreditAndPerksAmount}
				</StyledLink>
			</MemberDataSection>
		)}
		<MemberDataSectionFavourites>
			<Label>Favorites:</Label>
			<FavoritesStyledLink color='primary' href={favoritesUrl}>
				{favoritesCount}
			</FavoritesStyledLink>
		</MemberDataSectionFavourites>
		<Link color='primary' href={url}>
			{linkTitle}
		</Link>
	</Wrapper>
);
