import { createSelector } from 'reselect';
import { ProposalError } from '../../../../interfaces';

export const getProposalErrors = state => state.errors;
const getFieldName = (state, fieldName) => fieldName;

export const getFieldError = createSelector(
	[getProposalErrors, getFieldName],
	(errors, fieldName) => {
		const filteredErrors = errors.filter((error: ProposalError) => {
			return error.key === fieldName;
		});

		return filteredErrors.length > 0 ? filteredErrors[0].message : '';
	},
);
