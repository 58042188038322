export enum Sort {
	Featured,
	CheckInDate,
	Occupancy,
	Bedrooms,
	LengthOfStay,
	EstimatedValue,
	New,
}

export enum Months {
	Jan,
	Feb,
	Mar,
	Apr,
	May,
	Jun,
	Jul,
	Aug,
	Sep,
	Oct,
	Nov,
	Dec,
}

export enum Filters {
	CheckInDate = 'CHECK_IN_DATE',
	Destinations = 'DESTINATIONS',
	Occupancy = 'OCCUPANCY',
	TripType = 'TRIP_TYPE',
	LengthOfStay = 'LENGTH_OF_STAY',
}

export enum SortDirection {
	None = 0,
	Ascending = 1,
	Descending = 2,
}

export enum LocationType {
	Region = 0,
	Destination = 1,
	Property = 2,
	Unit = 3,
}

// TODO: Rename all unitSourceId's to unitParentCategoryId to match server side
export enum UnitParentCategory {
	Home = 1,
	Hotel = 2,
	Other = 3,
}

export enum GA_TRACKERS_ID {
	PASS_TRACKER = 'UA-20182096-3',
}
