import React, { memo } from 'react';
import styled from 'styled-components';
import { md, xsSmOnly } from '../../../../design-system/media-queries';
import { boxShadow3 } from '../../../../design-system/style-mixins/shadows-and-scrims';
import { white } from '../../../../design-system/variables/colors';
import { spaceLarge, spaceMedium, spaceBase } from '../../../../design-system/variables/spaces';
import { NavigationLinkProps } from '../../NavigationLinkProps';
import { Column } from '../Column';
import { PromoCardProps } from './PromoCard/PromoCardProps';
import { PromoCard } from './PromoCard/PromoCard';

interface SingleColumnWithPromoNavigationProps {
	links: NavigationLinkProps[];
	promoCard: PromoCardProps;
}

const Wrapper = styled.div`
	display: flex;
	flex-direction: column-reverse;
	z-index: 9000;
	cursor: auto;

	${md`
		visibility: hidden;
		align-items: center;
		flex-direction: row;
		left: -${spaceLarge};
		min-width: initial;
		padding: ${spaceLarge} ${spaceMedium} ${spaceLarge} ${spaceLarge};
		width: 690px;
		${boxShadow3}
		background: ${white};
		position: absolute;
		top: 81px;
	`}
`;

const NavPromoCard = styled(PromoCard)`
	${xsSmOnly`
		margin: ${spaceLarge} 0 ${spaceBase};
		padding: ${spaceBase};
	`}
`;

export const SingleColumnWithPromoNavigation: React.FC<SingleColumnWithPromoNavigationProps> = memo(
	({ links, promoCard }) => (
		<Wrapper className='submenu'>
			<Column links={links} />
			<NavPromoCard {...promoCard} />
		</Wrapper>
	),
);
