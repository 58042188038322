import styled from 'styled-components';
import { textLarge, fontSerif } from '../../design-system/style-mixins/text';
import { textColorDark } from '../../design-system/variables/colors';

/**
 * Subhead:
 * Commonly used under headlines. Sometimes can be used on it's own above a paragraph, etc.
 */
export const Subhead = styled.span`
    ${fontSerif}
    ${textLarge}
    ${textColorDark}
    font-weight: normal;
    letter-spacing: initial;
    line-height: 30px;
`;
