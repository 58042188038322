import React, { memo } from 'react';
import styled, { css } from 'styled-components';

import { Icon } from 'components/Icon/Icon';
import { NavigationLinkProps } from '../NavigationLinkProps';

import { md } from '../../../design-system/media-queries';
import { spaceBase, spaceSmall } from '../../../design-system/variables/spaces';

import { Link } from '../../Link';
import { AllCaps } from '../../AllCaps';
import { focusOutlineColor } from 'design-system/variables/colors';

interface ColumnProps {
	links: NavigationLinkProps[];
}

const Menu = styled.ul`
	list-style: none;
	display: flex;
	flex-direction: column;

	${md`
		display: flex;
		flex-direction: column;
	`}
`;

const MenuLink = styled(props => <Link {...props} />)<{ isActive: boolean }>`
	padding: ${spaceBase} 0 ${spaceBase} ${spaceBase};
	white-space: nowrap;
	line-height: 13px;
	display: flex;
	align-items: center;

	:hover {
		text-decoration: underline !important;
	}
	${md`
		padding: ${spaceSmall} 0 ${spaceSmall} 0;
	`}

	${props =>
		props.isActive &&
		css`
			text-decoration: underline;
		`}
	&[data-focus-visible-added] {
		outline: none;
		text-decoration: none;

		span {
			outline: 2px solid ${focusOutlineColor};
			outline-offset: 2px;
			transition: none;
		}
	}
`;

export const Column: React.FC<ColumnProps> = memo(({ links }) => (
	<Menu>
		{links.map(({ url, linkTitle, isExternal }) => {
			const isActiveLink = false; // TODO: How are we going to detect current active link? If it should be just a regular href check?

			return (
				<li>
					<MenuLink
						isActive={isActiveLink}
						href={url}
						target={isExternal && '_blank'}
						key={linkTitle}
						color='black'
					>
						<AllCaps size='small'>{linkTitle}</AllCaps>
						{isExternal && <Icon iconName='link-external' iconSize='sm' width='15px' height='13px' />}
					</MenuLink>
				</li>
			);
		})}
	</Menu>
));
