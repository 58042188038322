import { useCallback, useEffect, useState } from 'react';
import debounce from 'lodash.debounce';

// This hook allows you to debounce any fast changing value.
export function useDebounce<TValue>(value: TValue, delay: number) {
	const [current, setCurrent] = useState(value);
	const setCurrentDebounced = useCallback(debounce(setCurrent, delay), [setCurrent, delay]);
	useEffect(() => {
		setCurrentDebounced(value);
	}, [value, delay]);

	return current;
}
