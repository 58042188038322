import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { PROPOSAL_REQUEST_CREATED, SELECTED_RECIPIENT_CLEARED, UPDATE_DIRTY, UPDATE_DIRTY_FALSE } from '../constants';
import { SendProposalRequest, UnitDetails } from '../../../../interfaces';
import { State } from '../context';
import { getTripProposal } from '../../../../services/tripRecommendationsApiService';
import { SET_IS_LOADING, SET_DONE_LOADING } from '../../../../shared/redux/constants';
import { setInitialData } from '../../../../shared/redux/actions';
import { getProposalElements } from '../selectors';
import { getProposalAccommodationIds } from '../../utils';
import { getRecipientId } from '../selectors/metadata';
import { setUnitDetails } from './unitDetails';
import { fetchMemberDetails } from './member';

import { pricingAvailabilityChangesSelector } from '../selectors/sendProposal';

export const fetchProposal = (id: number) => async (dispatch: ThunkDispatch<State, undefined, Action>) => {
	dispatch({ type: SET_IS_LOADING });

	const proposal = await getTripProposal(id);
	dispatch(setInitialData(proposal));
	dispatch({ type: SET_DONE_LOADING });
};

export const updateDirty = ()  => {
	return { type: UPDATE_DIRTY };
};

export const updateDirtyFalse = () => {
	return {type: UPDATE_DIRTY_FALSE };
}

export const refreshProposal = () => async (
	dispatch: ThunkDispatch<State, undefined, Action>,
	getState: () => State,
) => {
	const proposalId = getState().metadata.id;

	await dispatch(fetchProposal(proposalId));

	const proposalElements = getProposalElements(getState());
	const accommodationIds = getProposalAccommodationIds(proposalElements);
	const recipientId = getRecipientId(getState());
	const { selectedRecipient, unitDetails } = getState();
	const newlyAddedAccommodations = accommodationIds.filter(id => !Object.keys(unitDetails).includes(id));

	if (newlyAddedAccommodations.length > 0) {
		// only fetch details if new units were added prior to refresh
		await dispatch(setUnitDetails(accommodationIds));
	}

	if (recipientId && (!selectedRecipient || recipientId !== selectedRecipient.accountUserID)) {
		dispatch(fetchMemberDetails(recipientId));
	} else if (selectedRecipient) {
		dispatch({ type: SELECTED_RECIPIENT_CLEARED });
	}
	dispatch({ type: SET_DONE_LOADING });
};

export const createProposalRequest = (
	sentBy: string,
	emailSubject: string,
	additionalRecipientEmailAddresses: string[] = [],
) => async (dispatch: ThunkDispatch<State, undefined, Action>, getState: () => State) => {
	const { selectedRecipient, unitDetails } = getState();
	const pricingAvailabilityChanges = pricingAvailabilityChangesSelector(getState());
	var proposalRequest: SendProposalRequest = {
		sentBy,
		recipient: selectedRecipient,
		additionalRecipientEmailAddresses,
		emailSubject,
		unitDetails,
		pricingAvailabilityChanges,
	};
	dispatch({ type: PROPOSAL_REQUEST_CREATED, payload: { proposalRequest } });
};
