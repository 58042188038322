import moment from 'moment';

export const monthYearRegex = new RegExp('/[0-9]+/');

export const sortDate = data =>
	data.sort((a, b) => {
		return moment.utc(a.filterDate || a.id).unix() - moment.utc(b.filterDate || b.id).unix();
	});

export const sortPrice = data =>
	data.sort((a, b) => {
		return parseInt(a.value) - parseInt(b.value);
	});

export const getChecked = data =>
	data.reduce((acc: string[], curr) => {
		if (curr.checked) {
			acc.push(curr.id);
		}
		return acc;
	}, []);
