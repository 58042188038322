import root from 'window-or-global';

let REACT_APP_BASE_API_URL;
let REACT_APP_IMAGE_SERVER;
let REACT_APP_BASE_WEB_API_URL;

if (root.process && root.process.env) {
	({ REACT_APP_BASE_API_URL, REACT_APP_IMAGE_SERVER, REACT_APP_BASE_WEB_API_URL } = process.env);
}

export const configuration = {
	// baseApiUrl: REACT_APP_BASE_API_URL || 'https://dryfly-trunk.dev.inspirato.com',
	baseApiUrl: REACT_APP_BASE_API_URL || '',
	// baseWebApiUrl: REACT_APP_BASE_WEB_API_URL || 'https://dryfly-api-trunk.dev.inspirato.com',
	baseWebApiUrl: REACT_APP_BASE_WEB_API_URL || 'https://api-qa.inspirato.com',
	// baseApiUrl: REACT_APP_BASE_API_URL || 'https://nightly.inspirato.com',
	imageServer: REACT_APP_IMAGE_SERVER || 'https://cms.inspirato.com',
};

export default configuration;
