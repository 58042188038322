import React, { FC, useEffect, useRef, useState, Fragment } from 'react';
import { ChatModel } from './Chat.Interfaces';
import ChatInvite from './components/ChatInvite/ChatInvite';
import ChatButton from './components/ChatButton/ChatButton';
import { liveAgentChatProvider } from './Providers/liveAgent.provider';
import { getChatData } from './services/chatService';
import { ChatModal } from './components/modal/ChatModal';
import { OfflineForm } from './components/OfflineForm/OfflineForm';

const Chat: FC = () => {
	const [showChatButton, setShowChatButton] = useState(false);
	const [showInvite, setShowInvite] = useState(false);
	const [showOfflineForm, setShowOfflineForm] = useState(false);
	const [isOnline, setIsOnline] = useState(false);
	const [chatModel, setChatModel] = useState<ChatModel>(null);
	const ref = useRef(null);

	const displayChat = isAvailableOnline => {
		setIsOnline(isAvailableOnline);

		if (chatModel.enableInvitation && (isAvailableOnline || (!isAvailableOnline && !chatModel.isMember))) {
			setShowInvite(true);
		} else {
			setShowInvite(false);
		}

		if (
			(!chatModel.enableInvitation && !chatModel.isMember) ||
			(!chatModel.enableInvitation && isAvailableOnline)
		) {
			setShowChatButton(true);
		} else {
			setShowChatButton(false);
		}
	};

	const handleStartChat = () => {
		isOnline ? liveAgentChatProvider.startChat(chatModel.buttonId) : setShowOfflineForm(true);
	};

	const closeModal = () => setShowOfflineForm(false);

	const renderChat = () => {
		if (!chatModel) {
			return;
		}

		return (
			<Fragment>
				<ChatInvite
					body={chatModel.invitationBodyText}
					title={chatModel.invitationTitle}
					onClose={() => setShowChatButton(true)}
					onStartChat={handleStartChat}
					showInvite={showInvite}
					isOnline={isOnline}
				/>
				<ChatButton
					isOnline={isOnline}
					isPublic={chatModel.isPublic}
					onClick={handleStartChat}
					showChatButton={showChatButton}
				/>
				<ChatModal onClose={closeModal} isOpen={showOfflineForm}>
					<OfflineForm {...chatModel} />
				</ChatModal>
			</Fragment>
		);
	};

	useEffect(() => {
		getChatData().then(setChatModel);
	}, []);

	useEffect(() => {
		if (chatModel) {
			renderChat();
			liveAgentChatProvider.initialize(chatModel, displayChat);
			liveAgentChatProvider.showWhenOnline(chatModel.buttonId, ref.current);
		}
	}, [chatModel]);

	useEffect(() => renderChat, [showChatButton, showInvite]);

	return (
		<Fragment>
			<div ref={ref}></div>
			{renderChat()}
		</Fragment>
	);
};

export default Chat;
