import React from 'react';
import styled from 'styled-components';
import { textShadow } from '../../../design-system/style-mixins/shadows-and-scrims';
import { fontSans, textSmall } from '../../../design-system/style-mixins/text';
import { white } from '../../../design-system/variables/colors';
import { spaceBase } from '../../../design-system/variables/spaces';
import { ImageTitleProps } from './ImageTitleProps';

const StyledImageTitle = styled.span`
	padding: 0 ${spaceBase} 10px ${spaceBase};
	bottom: 0;
	left: 0;
	position: absolute;
	z-index: 1;
	${textSmall}
	color: ${white};
	${fontSans}
	${textShadow}
	font-weight: normal;
`;

export const ImageTitle: React.FunctionComponent<ImageTitleProps> = ({ title }) => (
	<StyledImageTitle>{title}</StyledImageTitle>
);
