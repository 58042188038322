import React, { FC } from 'react';
import styled from 'styled-components';

import { Label } from 'components/Label';
import { textColorLight } from 'design-system/variables/colors';
import { spaceSmall, spaceTiny } from 'design-system/variables/spaces';
import { textMedium } from 'design-system/style-mixins/text';

import { PASSWORD_RULES } from './enums';

const Item = styled.div`
	display: flex;
	align-items: flex-start;
`;

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;

	${Item}:not(:last-of-type) {
		margin-bottom: ${spaceTiny};
	}
`;

const Rule = styled(Label)`
	${textColorLight}
`;
const Dot = styled.div`
	margin-right: ${spaceSmall};
	${textMedium}
	${textColorLight};
`;

export const PasswordRules: FC<{}> = () => (
	<Wrapper>
		{PASSWORD_RULES.map(rule => (
			<Item key={rule}>
				<Dot>&#8226;</Dot>
				<Rule size='small'>{rule}</Rule>
			</Item>
		))}
	</Wrapper>
);
