import React, { FC } from 'react';
import styled from 'styled-components';
import { Link } from 'components/Link';
import { grayDark } from '../../../design-system/variables/colors';
import { spaceMedium } from '../../../design-system/variables/spaces';
import { textSmall } from '../../../design-system/style-mixins/text';

const Wrapper = styled.div`
	text-align: center;
`;
const SignInText = styled.div`
	color: ${grayDark};
	margin-bottom: ${spaceMedium};
	${textSmall}
`;

export const SignIn: FC<{}> = () => (
	<Wrapper>
		<SignInText>
			Already signed up?&nbsp;
			<Link color='primary' href='https://www.inspirato.com/signin'>
				Sign In
			</Link>
		</SignInText>
	</Wrapper>
);
