import React, { forwardRef, MutableRefObject } from 'react';
import { Icon } from 'components/Icon';
import { validateValueByRules } from 'services/ValidationRules';
import { SelectField, SelectWrapper, SelectFieldContainer, SelectIcon } from './Select.styles';
import { SelectProps } from './Select.types';
import { InputLabel, RequiredContainer } from '../Form.styles';
import { HelpBlock } from '../HelpBlock';

export const Select = forwardRef(
	(
		{
			disabled,
			hasError,
			helpText,
			hideLabel,
			id,
			isGuestModal,
			labelSize,
			labelText,
			options,
			onChange,
			onFocus,
			onBlur,
			required,
			value,
			validate,
			validationRules,
			onValidation = () => {},
			...rest
		}: SelectProps,
		ref,
	) => {
		const innerRef: MutableRefObject<any> = React.useRef(ref);

		const validateFn: Function = validate || validateValueByRules;
		const validateBeforeProceed = val => onValidation(innerRef.current, ...validateFn(val, validationRules));

		const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
			if (typeof onChange === 'function') {
				validateBeforeProceed(e.target.value);
				onChange(options.find(option => option.value === e.target.value));
			}
		};
		const handleFocus = () => typeof onFocus === 'function' && onFocus();
		const handleBlur = () => typeof onBlur === 'function' && onBlur();

		return (
			<SelectWrapper {...rest}>
				<InputLabel size={labelSize} as='label' htmlFor={id} hideLabel={hideLabel}>
					{labelText}
					{required && (
						<RequiredContainer>
							<Icon iconName='required' iconSize='md' />
						</RequiredContainer>
					)}
				</InputLabel>
				<SelectFieldContainer>
					<SelectIcon iconName='arrow' iconSize='md' />
					<SelectField
						id={id}
						disabled={disabled}
						value={value?.value}
						hasError={hasError}
						ref={ref}
						required={required}
						onChange={handleChange}
						onFocus={handleFocus}
						onBlur={handleBlur}
						isGuestModal={isGuestModal}
					>
						{options.map(option => (
							<option key={option.key} value={option.value}>
								{option.value}
							</option>
						))}
					</SelectField>
				</SelectFieldContainer>
				{helpText && <HelpBlock hasError={hasError} helpText={helpText} />}
			</SelectWrapper>
		);
	},
);
