import { createSelector } from 'reselect';
import { ExperienceCard } from '../../types';

export const getTotalExperienceCount = state => state.results.length;

export const getDateFilter = state => state.dates;

export const getExperienceTypesFilter = state => state.experienceTypes;

export const getSearchResults = state => state.results;

export const getSort = state => state.sort;

export const getSortOptions = state => state.sortOptions;

export const getLocationsFilter = state => state.locations;

export const getOffset = state => state.offset;

export const getResultsDestinationsCount = createSelector(
	getLocationsFilter,
	locations => locations.reduce((acc, location) => (!location.disabled ? acc + 1 : acc), 0),
);

const isInspiratoOnly = (card: ExperienceCard) => card.inspiratoOnly;

export const getHasInspiratoOnly = createSelector(
	getSearchResults,
	results => results.some(isInspiratoOnly) && !results.every(isInspiratoOnly),
);
