export const AccentMap = {
	á: 'a',
	ã: 'a',
	// tslint:disable-next-line:object-literal-sort-keys
	â: 'a',
	Á: 'A',
	Â: 'A',
	è: 'e',
	é: 'e',
	É: 'E',
	È: 'E',
	í: 'i',
	ñ: 'n',
	Ñ: 'n',
	ó: 'o',
	Ó: 'o',
	Ô: 'o',
	ô: 'o',
};

export const defaultSearchResults = [
	{
		category: 'Destinations',
		items: []
	},
	{
		category: 'Hotels & Resorts',
		items: []
	},
	{
		category: 'Accomodations',
		items: []
	}
];
