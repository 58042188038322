import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { ComponentProps } from 'interfaces/ComponentProps';
import { useScript } from 'services/Helpers';
import root from 'window-or-global';
import { fetchAvisWizardNumber } from 'components/AvisCarRental/CarRentalRewardRepository';
import { LoaderSpinner } from 'components/Loader';
import { convertToValidName } from 'components/AvisCarRental/helpers';
import { Paragraph } from 'components/Paragraph';
import { Container, Headline, Styles } from 'components/AvisCarRental/AvisCarRental.styles';

export interface AvisCarRentalProps extends ComponentProps {
	callToAction: any;
	headline: string;
}

// avis script docs can be found here: https://qabook1.carrental.com/dcweb/dw_/builds/1.0/docs/
const AVIS_URL = 'https://book1.carrental.com/dcweb/dw_/dcwdw_.min.js#version=1.0&appId=inspsl&e=P';
const AVIS_ELEMENT_ID = 'dcwdw-sdk';
const AVIS_WIZARD_NUMBER_PLACEHOLDER = "Your Avis President's Club Wizard Number...";
const DATE_FORMAT = 'MMDDYY';
const AVIS_CONFIGS_DEFAULT = {
	emailAddress: '',
	rewardNumber: '',
	firstName: '',
	lastName: '',
};

let formInitializationIntervalId = null;

export const AvisCarRental: React.FC<AvisCarRentalProps> = ({ callToAction, headline }) => {
	const avisElRef = useRef();
	const today = moment.now();
	const pickupDate = moment(today).format(DATE_FORMAT);
	const dropoffDate = moment(today)
		.add(1, 'days')
		.format(DATE_FORMAT);
	const [scriptIsLoaded, scriptLoadError] = useScript(AVIS_URL, AVIS_ELEMENT_ID, avisElRef.current);
	const [isFormInDOM, setIsFormInDOM] = useState(false);
	const [submitButton, setSubmitButton] = useState(null);
	const [avisWizardNumberEl, setAvisWizardNumberEl] = useState(null);
	const [avisConfigs, setAvisConfigs] = useState(AVIS_CONFIGS_DEFAULT);

	// check whether the form is in the DOM
	useEffect(() => {
		if (!scriptIsLoaded || isFormInDOM) {
			return;
		}
		formInitializationIntervalId = setInterval(() => {
			if (isFormInDOM || submitButton) {
				root.clearInterval(formInitializationIntervalId);
			}
			// @ts-ignore
			setIsFormInDOM(!!avisElRef.current && avisElRef.current.querySelector('button'));
		}, 70);
		return () => {
			root.clearInterval(formInitializationIntervalId);
		};
	}, [scriptIsLoaded]);

	// when form is loaded find a few DOM elements
	useEffect(() => {
		if (!isFormInDOM) {
			return;
		}
		const domEl = avisElRef.current;
		// @ts-ignore
		setSubmitButton(domEl && domEl.querySelector('button'));
		// @ts-ignore
		setAvisWizardNumberEl(domEl && domEl.querySelector('.dcwdw_field_wizardNumber input'));
	}, [isFormInDOM]);

	// load data on start
	useEffect(() => {
		fetchAvisWizardNumber().then(data => {
			if (data) {
				setAvisConfigs({
					emailAddress: data.emailAddress,
					rewardNumber: data.rewardNumber,
					firstName: convertToValidName(data.firstName),
					lastName: convertToValidName(data.lastName),
				});
			}
		});
	}, []);

	// update any form field values or labels here
	if (submitButton && avisWizardNumberEl) {
		if (callToAction && callToAction.linkTitle) {
			submitButton.innerHTML = `<span>${callToAction.linkTitle}</span>`;
		}
		// @ts-ignore
		avisWizardNumberEl.setAttribute('placeholder', AVIS_WIZARD_NUMBER_PLACEHOLDER);
		avisWizardNumberEl.value = avisConfigs.rewardNumber;
	}

	return (
		<>
			<Styles />
			<Container ref={avisElRef} formIsLoaded={isFormInDOM}>
				<Headline as='h3' size='medium'>
					{headline}
				</Headline>
				<div
					id='dcwdw-cont'
					data-awd-number='D338600'
					data-country-residence='US'
					data-pickup-time='0900'
					data-dropoff-time='0900'
					data-pickup-date={pickupDate}
					data-dropoff-date={dropoffDate}
					data-cci={avisConfigs.emailAddress}
					data-email-address={avisConfigs.emailAddress}
					data-first-name={avisConfigs.firstName}
					data-last-name={avisConfigs.lastName}
				/>
			</Container>
			{!isFormInDOM && <LoaderSpinner />}
			{scriptLoadError && <Paragraph>Avis script loading error</Paragraph>}
		</>
	);
};
