export enum Keys {
	Backspace = 'Backspace',
	Enter = 'Enter',
	ArrowUp = 'ArrowUp',
	ArrowDown = 'ArrowDown',
	Tab = 'Tab',
	Escape = 'Escape',
	ArrowLeft = 'ArrowLeft',
	ArrowRight = 'ArrowRight',
}
