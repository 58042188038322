import { css, keyframes } from 'styled-components';
import { boxShadow2, boxShadow3 } from './shadows-and-scrims';

/* Timing Animations
  ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
export const transition100ms = css`
	transition: all 100ms linear;
`;

export const transition200ms = css`
	transition: all 0.2s ease-in;
`;

export const transition250ms = css`
	transition: all 0.25s ease-in;
`;

/* Action Animations
  ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

/**
 * Shadow hovers:
 * Use for clickable boxes that use boxShadow.
 * "Increase/Raises" card to higher depth point.
 */
export const shadowHover = css`
	${boxShadow2}
	transition: all 0.25s cubic-bezier(.25, .8, .25, 1);

	&:hover {
		${boxShadow3}
	}
`;

/**
 * Blip:
 * Object slowly appears, then disappears.
 * Used for Flat Loader dots animation.
 */
export const blip = keyframes`
	0%,
	100% {
		opacity: 1;
	}
	60% {
		opacity: 0;
	}
`;

/**
 * Dash:
 * Used for specifically for Spinner Loader animation.
 */
export const dash = keyframes`
	0% {
		stroke-dasharray: 1,200;
		stroke-dashoffset: 0;
	}
	50% {
		stroke-dasharray: 89,200;
		stroke-dashoffset: -35;
	}
	100% {
		stroke-dasharray: 89,200;
		stroke-dashoffset: -124;
	}
`;

/**
 * Fade in:
 * used to quickly fade an item into view
 */
export const fadeIn = keyframes`
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
`;
export const fadeInAnimation = css`
	animation: ${fadeIn} 0.25s ease both;
`;

/**
 * Fade out:
 * used to quickly fade an item out of view
 */
export const fadeOut = keyframes`
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
`;
export const fadeOutAnimation = css`
	animation: ${fadeOut} 0.25s ease both;
`;

/**
 * Rotate:
 * Basic rotation
 */
export const rotate = keyframes`
	100% {
		transform: rotate(360deg);
	}
`;
