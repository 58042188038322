import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { State } from '../context';
import { UpdateTripProposalRequest } from '../../../../interfaces';
import { SELECTED_RECIPIENT, SELECTED_RECIPIENT_UPDATED } from '../constants';
import { updateTripProposal } from '../../../../services/tripRecommendationsApiService';
import { MemberOption } from '../../../../models/MemberOption';

import { setFieldError } from './errors';

export const updateSelectedRecipient = (selectedRecipient: MemberOption, modifiedBy: string) => (
	dispatch: ThunkDispatch<State, undefined, Action>,
	getState: () => State,
) => {
	const { proposalName, metadata, message } = getState();
	const request: UpdateTripProposalRequest = {
		name: proposalName,
		message,
		recipientId: selectedRecipient ? selectedRecipient.accountUserID.toString() : null,
		ownerId: metadata.ownerId,
		pvaTeamId: metadata.pvaTeamId,
		modifiedBy,
	};

	updateTripProposal(metadata.id, request)
		.then(() => {
			dispatch({ type: SELECTED_RECIPIENT_UPDATED, payload: { selectedRecipient } });
		})
		.catch(error => {
			dispatch(setFieldError(SELECTED_RECIPIENT, error.message));
		});
};
