import { Subhead } from 'components/Subhead';
import { containerWidth } from 'design-system/style-mixins/grid-and-layout';
import React from 'react';
import styled, { css } from 'styled-components';
import { lg, xsOnly, xsSmOnly } from '../../design-system/media-queries';
import { svgShadowLight, textShadow, transparentDark30 } from '../../design-system/style-mixins/shadows-and-scrims';
import { fontSans, textSmall, textTiny } from '../../design-system/style-mixins/text';
import { grayDark, white } from '../../design-system/variables/colors';
import { spaceBase, spaceBig, spaceLarge, spaceMedium, spaceSuperMassive } from '../../design-system/variables/spaces';
import { HorizontalAlignment } from '../../enums/HorizontalAlignment';
import { ActionLink } from '../ActionLink';
import { allCapsStyle } from '../AllCaps/AllCaps';
import { IconButton } from '../Icon';
import { Link } from '../Link';

export const HeroSubtitle = styled(Subhead)`
	&& {
		color: ${white};
	}

	p {
		color: inherit;
	}
	.ds-headline-big,
	.ds-headline-large,
	.ds-headline-medium {
		color: inherit;
		padding-bottom: ${spaceBig};
		padding-top: ${spaceMedium};
	}
`;

export const HeroTitle = styled.h1<{ hidden: boolean }>`
	color: ${white};
	margin-bottom: ${spaceLarge};
	margin-top: 0;
	padding-left: 0;
	padding-right: 0;

	${props =>
		props.hidden &&
		css`
			border: 0;
			clip: rect(0 0 0 0);
			-webkit-clip-path: inset(50%);
			clip-path: inset(50%);
			height: 1px;
			margin: -1px;
			overflow: hidden;
			padding: 0;
			position: absolute;
			white-space: nowrap;
			width: 1px;
		`}

	.hero__conditional-title-break {
		display: block;

		:after {
			content: '  ';
			display: none;
			white-space: pre;
		}
	}

	${xsSmOnly`
		.hero__conditional-title-break,
		.hero__conditional-title-break:after {
			display: inline
		}
	`}

	span {
		display: block;
	}
`;

export const ImgLogo = styled.img`
	display: block;
	filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feGaussianBlur in="SourceAlpha" stdDeviation="4" /><feOffset dx="1" dy="2" result="offsetblur" /><feFlood flood-color="rgba(0,0,0,0.2)" /><feComposite in2="offsetblur" operator="in" /><feMerge><feMergeNode /><feMergeNode in="SourceGraphic" /></feMerge></filter></svg>#filter');
	${svgShadowLight}
	margin-bottom: ${spaceBase};
	margin-left: auto;
	margin-right: auto;
	max-width: 600px;
	width: 100%;
`;

export const HeroWrapper = styled.div<{ horizontalAlignment: HorizontalAlignment }>`
	${props => {
		switch (props.horizontalAlignment) {
			case HorizontalAlignment.Start:
				return `
					${ImgLogo} {
						margin-left: 0;
					}
					${HeroSubtitle},
					${HeroTitle} {
						text-align: left;
					}
				`;
			case HorizontalAlignment.End:
				return `
					${ImgLogo} {
						margin-right: 0;
					}
					${HeroSubtitle},
					${HeroTitle} {
						text-align: right;
					}
				`;
			default:
				return `
					${HeroSubtitle},
					${HeroTitle} {
						text-align: center;
					}
				`;
		}
	}}
`;

export const HeroFooter = styled.footer`
	box-sizing: border-box;
	max-width: 100%;
	opacity: 0;
	z-index: 1;
`;

export const HeroContent = styled.header`
	margin: auto;
	${containerWidth}
	opacity: 0;
	width: 100%;
	z-index: 100;

	${xsOnly`
		width: 100%;
	`}
`;

export const LinkTermsAndConditions = styled(props => <Link {...props} />)`
	margin: ${spaceBase} auto -${spaceBase} auto;
	opacity: 0;
	${textSmall}
	${fontSans}
	${textShadow}
`;

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	background-repeat: no-repeat;
	background-size: cover;
	min-height: calc(100vh - 215px);
	overflow: hidden;
	padding-top: ${spaceLarge};
	padding-bottom: ${spaceLarge};
	padding-left: ${spaceLarge};
	padding-right: ${spaceLarge};
	position: relative;
	z-index: 0;

	&:before {
		background-color: ${transparentDark30};
		content: '';
		display: block;
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
	}

	.responsive-page--navigation-is-transparent & {
		min-height: 100vh;
		padding-top: ${spaceSuperMassive} !important;
	}
	.has-banner & {
		min-height: calc(100vh - 254px);
	}
`;

export const Container = styled.section<{ automaticHeight: boolean }>`
	display: flex;
	flex-direction: column;

	${HeroContent} {
		animation-delay: 1s;
		animation-duration: 1s;
		animation-fill-mode: forwards;
		animation-name: fade-in-and-up;
		animation-timing-function: ease;
		transform: translateY(20px);
	}

	${HeroFooter}, ${LinkTermsAndConditions} {
		animation-delay: 2s;
		animation-duration: 1s;
		animation-fill-mode: forwards;
		animation-name: fade-in-and-up;
		animation-timing-function: ease;
	}

	picture img {
		height: auto;
		left: 50%;
		max-width: 100%;
		min-height: 100%;
		min-width: 100%;
		object-fit: cover;
		position: absolute;
		top: 50%;
		-webkit-transform: translateX(-50%) translateY(-50%);
		transform: translateX(-50%) translateY(-50%);
		width: auto;
		z-index: -2;
	}
	${props =>
		props.automaticHeight &&
		css`
			&& ${Wrapper} {
				min-height: 450px;

				${xsOnly`
				min-height: 200px;
				padding-bottom: ${spaceLarge};
				padding-top: ${spaceBig};
			`}
				${lg`
				min-height: 500px;
			`}
			}
		`}
	${props =>
		!props.automaticHeight &&
		css`
			&& ${Wrapper} {
				.responsive-page--navigation-is-transparent.has-banner & {
					min-height: calc(100vh - 39px);
				}
			}
		`}
`;

export const HeroIconButton = styled(props => <IconButton {...props} />)`
	margin-top: ${spaceLarge};
	svg {
		transform: rotate(90deg);
		fill: ${grayDark};
	}
`;

export const Video = styled.video`
	height: auto;
	left: 50%;
	margin-top: -1px;
	min-height: 100%;
	min-width: 100%;
	position: absolute;
	top: 50%;
	transform: translateX(-50%) translateY(-50%);
	width: auto;
	z-index: -1;
	${xsOnly`display: none`}
`;

export const LinkVideo = styled(props => <Link {...props} />)`
	&& {
		${allCapsStyle}
		${textTiny}
		color: ${white};
		${textShadow}
		margin-top: ${spaceLarge};
		height: 23px;
		z-index: 1;
		&:hover {
			text-decoration: none;
		}
	}
`;

export const HeroActionLink = styled(props => <ActionLink {...props} />)`
	display: inline-block;
	margin-top: ${spaceLarge};

	& + .hero__video-play {
		margin-left: ${spaceLarge};
	}

	@media screen and (max-width: 435px) {
		& + .hero__video-play {
			margin-left: 0;
		}
		& + .hero__video-play:before {
			content: ' ';
			display: block;
		}
	}
`;
